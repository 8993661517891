import React, { Component } from "react";
import { Div, NavTabParam, Breadcrumb } from "../../components";
import { PathRoute } from "../../utilities";

class CenterTop extends Component {
  render() {
    return <Div>{this.props.children}</Div>;
  }
}

export default CenterTop;
