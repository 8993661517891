import React, { Component } from "react";
import { Div, Title, I, Image, Label } from "../../components";
import { connect } from "react-redux";
import { Color } from "../../utilities";

class Dashboard extends Component {
  render() {
    return (
      <Div>
        <Div bg={Color.NODE.WHITE} rounded margin="3" padding="4" shadow>
          <Div inline="between">
            <Div>
              <Title type="h6" value="Bem vindo" />
              {/* <Title value={this.props.user.nome} /> */}
            </Div>
            {/* {this.props.user.imagemLogo && (
              <Div inline="end">
                <Image
                  src={this.props.user.imagemLogo}
                  border
                  className="img-fluid logo-cliente"
                  width="200"
                  shadow
                />
              </Div>
            )} */}
          </Div>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
 //user: store.loginState.user,
});

export default connect(mapStateToProps)(Dashboard);
