import React, { Component } from "react";

import { Navbar, NavbarItemMenu } from "../../components";
import { Type, Utils } from "../../utilities";
import NavbarSubMenu from "../navbar/menu-left/NavbarSubMenu";
import NavbarItem from "../navbar/menu-left/NavbarItem";
import { connect } from "react-redux";

const ID_NAVBAR = Utils.uuidString();

const ID_PERSONALIZACAO = Utils.uuidString();
const ID_PESQUISA_PRECO = Utils.uuidString();
const ID_HORARIO = Utils.uuidString();
const ID_ASSINATURAS = Utils.uuidString();
const ID_LISTA_COMPRAS = Utils.uuidString();
const ID_SAIR = Utils.uuidString();


class MenuLeft extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loja: true,
		};
	}

	isUserMaster() {
		return false;
	}


	renderNavBarUser() {
		return (
			<Navbar title="Mobioh" id={ID_NAVBAR} active={this.props.active}>
				
				<NavbarItemMenu
					iconLeft={Type.ICON.CLOCK}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Horários"
					target={`#${ID_HORARIO}`}
					rota="#"
				/>
				<NavbarSubMenu id={ID_HORARIO} parent={ID_HORARIO}>
					<NavbarItem
						iconLeft={Type.ICON.SHOOPPING_BAG}
						value="Entregas"
						rota="/admin/horarios/entregas"
					/>
					<NavbarItem
						iconLeft={Type.ICON.SHOOPPING_BAG}
						value="Retiradas"
						rota="/admin/horarios/retiradas"
					/>

					<NavbarItem
						iconLeft={Type.ICON.PERCENT}
						value="Taxas"
						rota="/admin/horarios/taxas"
					/>
				</NavbarSubMenu>
				<NavbarItemMenu
					iconLeft={Type.ICON.COG}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Personalização"
					target={`#${ID_PERSONALIZACAO}`}
					rota="#"
				/>
				<NavbarSubMenu id={ID_PERSONALIZACAO} parent={ID_PERSONALIZACAO}>
					<NavbarItem
						iconLeft={Type.ICON.INFO}
						value="Informações Gerais"
						rota="/admin/personalizacao/geral"
					/>
					<NavbarItem
						iconLeft={Type.ICON.PLUS_SQUARE}
						value="Personalização de cores"
						rota="/admin/personalizacao/cores"
					/>

				</NavbarSubMenu>

				<NavbarItemMenu
					iconLeft={Type.ICON.PRODUCT}
					iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Pesquisa de Preço"
					target={`#${ID_PESQUISA_PRECO}`}
					rota="#"
				/>

				<NavbarSubMenu id={ID_PESQUISA_PRECO} parent={ID_PESQUISA_PRECO}>
					<NavbarItem
						iconLeft={Type.ICON.SHOPPING_CART}
						value="Concorrentes"
						rota="/admin/pesquisas/concorrentes"
					/>

					<NavbarItem
						iconLeft={Type.ICON.TICKET}
						value="Pesquisas"
						rota="/admin/pesquisas/listar"
					/>
				</NavbarSubMenu>

				<NavbarItemMenu
					iconLeft={Type.ICON.REORDER}
					//iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Assinaturas"
					target={`#${ID_ASSINATURAS}`}
					rota="/admin/assinaturas"
				/>


				{/* <NavbarItemMenu
					iconLeft={Type.ICON.LIST_ALT}
					//iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Lista de Compras"
					target={`#${ID_LISTA_COMPRAS}`}
					rota="/admin/listacompras/editar"
				/> */}

				<NavbarItemMenu
					iconLeft={Type.ICON.SIGNOUT}
					//	iconRight={Type.ICON.ARROW_ANGLE_DOWN}
					value="Sair"
					target={`#${ID_SAIR}`}
					rota="#"
				/>

			</Navbar>
		);
	}

	render() {
		return this.isUserMaster() ? this.renderNavBarUserMaster() : this.renderNavBarUser();
	}
}

const mapStateToProps = (store) => ({
	user: {},
});

export default connect(mapStateToProps)(MenuLeft);
