import React, { Component } from "react";
import { Div, If, Label } from "../..";
import Tooltip from "../../tooltip/Tooltip";
import DatePicker from "../../datepicker/DatePicker";
import { Type, Color, Pattern, Utils, Mask } from "../../../utilities";
import InputGroup from "./InputGroup";
import I from "../../i/I";
import moment from "moment";

const DOWN = "down";
const UP = "up";

class InputDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      dateSelect: props.dateSelect || props.value,
    };
  }

  handlerChange(value) {
    this.setState({ dateSelect: value });
  }

  getDataFormatada(data) {
    return Utils.isDateValid(data)
      ? moment(data).format(Pattern.DATE.DD_MM_YYYY)
      : "";
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      dateSelect: Utils.isValueValid(props.value) ? props.value : "",
    });
  }

  renderDatePicker() {
    const { show, dateSelect } = this.state;
    const {
      bg,
      colorText,
      responsive,
      rounded,
      direction,
      colorIcon,
      bgIcon,
      label,
      value,
    } = this.props;

    return (
      <Div responsive={responsive} rounded={rounded}>
        {label && (
          <Div inline="start">
            <Label value={label}></Label>
          </Div>
        )}
        {direction === UP && (
          <Tooltip
            show={show}
            className="bottom-50"
            direction={this.props.direction}
          >
            <DatePicker
              pattern={this.props.pattern}
              handlerChangeDay={(data) => {
                this.setState(
                  {
                    show: !show,
                    dateSelect: moment(data).format(Pattern.DATE.DD_MM_YYYY),
                  },
                  () => this.props.handlerChangeDay(data)
                );
              }}
              borderLeft="default"
              bg={bg || Color.NODE.PRIMARY}
              colorText={colorText || Color.NODE.WHITE}
            ></DatePicker>
            <I icon="fa fa-caret-down"></I>
          </Tooltip>
        )}
        <Div>
          <InputGroup
            prepend
            bgIcon={bgIcon}
            colorIcon={colorIcon}
            icon={Type.ICON.CALENDAR}
            handlerChange={(e) => {
              //this.props.handlerChangeDay(e.target.value);
              //this.handlerChange(e.target.value);
            }}
            handlerClear={() => {
              this.setState(
                {
                  dateSelect: null,
                  value: null,
                  dateSelect: null,
                },
                this.props.handlerChangeDay("")
              );
            }}
            handlerClick={(e) => this.setState({ show: !show })}
            value={this.getDataFormatada(value) || dateSelect}
          ></InputGroup>
        </Div>
        {direction === DOWN && (
          <Tooltip
            show={show}
            direction={this.props.direction}
            className="top-20"
          >
            <I icon="fa fa-caret-up"></I>
            <DatePicker
              pattern={this.props.pattern}
              handlerChangeDay={(data) => {
                this.setState(
                  {
                    show: !show,
                    dateSelect: moment(data).format(Pattern.DATE.DD_MM_YYYY),
                  },
                  () => this.props.handlerChangeDay(data)
                );
              }}
              borderLeft="default"
              bg={bg || Color.NODE.PRIMARY}
              colorText={colorText || Color.NODE.WHITE}
            ></DatePicker>
          </Tooltip>
        )}

        <If and value1={Utils.isValueValid(value)}>
          <I
            icon={Type.ICON.CLOSEX}
            style={{ position: "absolute", top: 40, right: 20 }}
            colorText={Color.NODE.DANGER}
            pointer
            handlerClick={() => {
              this.setState(
                {
                  dateSelect: null,
                  value: null,
                  dateSelect: null,
                },
                this.props.handlerChangeDay("")
              );
            }}
          />
        </If>
      </Div>
    );
  }

  render() {
    return this.renderDatePicker();
  }
}

export default InputDate;
