import React, {Component} from "react";
import {Checkbutton, Div, I, If, InputCep, InputDefault, InputInteger, Label, ModalNotification, Select, SelectValue, TextArea,} from "../../components";
import {Color, DateUtils, Http, Mask, Type, Utils} from "../../utilities";
import ClienteLogin from "../cliente/ClienteLogin";
import AdicionarProdutosCarrinhoLista from "../produtos/listascompras/web/AdicionarProdutosCarrinhoLista";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import Zindex from "../utils/Zindex";
import ContatoWhatsappGenerico from "../whatsapp/ContatoWhatsappGenerico";

import "./carrinho.css";

const styles = {
  enderecoSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "white",
  },
  enderecoNaoSelecionado: {
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
  },
  selecioneHorario: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
    borderRadius: 5,
    padding: 10,
    cursor: "pointer",
    width: "100%",
    lineHeight: 0.9,
  },
  labelAtencao: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  containterHorario: {
    backgroundColor: "#F2F2F2",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  containterHorarioSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    padding: 15,
    borderRadius: 10,
    margin: 10,
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
  },
};

class TemplatePedidoEntrega extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enderecoEntregaSelecionado: false,
      enderecoCasaSelecionado: true,
      horarios: props.parametros.horarios || [],
      pedido: props.pedido,
      //cliente:
      //   JSON.parse(localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB)) ||
      //   new ClienteLogin(),

      cliente: UsuarioUtils.getUsuarioLojaWeb() || new ClienteLogin(),
      outroHorarioSelecionado: {},
    };
  }

  UNSAFE_componentWillReceiveProps({parametros, pedido}) {
    this.setState({horarios: parametros.horarios, pedido});
  }

  componentDidMount() {
    let enderecoEntrega = this.getEnderecoSelecionadoParaEntrega();

    let todosHorarios = this.getHorariosEntrega();

    if (Utils.isValueValid(enderecoEntrega)) {
      this.setState(
          {
            pedido: {
              ...this.state.pedido,
              enderecoEntrega,
            },
          },
          () => {
            this.props.handlerEnderecoSelecionado(enderecoEntrega);
            this.props.handlerHorarioSelecionado(todosHorarios[0]);
          }
      );
    }
  }

  getHorariosEntregaSelect() {
    let horarios = this.state.horarios;

    let horariosEntrega = [];

    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
          (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    let horariosSelect = [new SelectValue("", "Selecione um horário")];

    horariosEntrega.forEach((horario) => {
      horariosSelect.push(
          new SelectValue(
              horario.idEmpresaHorarioEntrega,
              `${horario.dataEntrega} ${horario.descricao}`
          )
      );
    });

    return horariosSelect;
  }

  getHorariosEntrega() {
    let horarios = this.state.horarios;
    let horariosEntrega = [];
    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
          (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    return horariosEntrega;
  }

  getDataCliente(cliente) {
    return {
      ...cliente,
      cep: Mask.clearMask(cliente.cep),
    };
  }

  sincronizarCliente() {
    let cliente = this.getDataCliente(this.state.cliente);
    Http.post("cliente", cliente)
        .then(({status, data}) => {
          if (status) {
            // localStorage.setItem(
            //   Keys.KEY_CLIENTE_LOJA_WEB,
            //   JSON.stringify(cliente)
            // );
            UsuarioUtils.setUsuarioLojaWeb(cliente);
            this.renderSucesso();
            this.forceUpdate();
          }
        })
        .catch((error) => console.log(error));
  }

  handlerChange({name, value}) {
    this.setState(
        {
          cliente: {
            ...this.state.cliente,
            [name]: value,
          },
        },
        () => this.renderTrocarEndereco()
    );
  }

  handlerChangeEndereco({name, value}) {
    this.setState(
        {
          cliente: {
            ...this.state.cliente,
            [name]: value,
          },
        },
        () => this.renderTrocarEndereco()
    );
  }

  getItensCarrinho = () => {
    const {itens} = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  renderAdicionarProdutoLista(listas) {
    const {itens} = this.props.carrinho;
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
          <AdicionarProdutosCarrinhoLista
              listas={listas}
              produtos={itens}
              empresa={this.props.empresa}
              //handlerCancel={() => this.visualizarProduto(produto, updateItem)}
              //handlerNovaListaCadastrada={() =>
              //  this.visualizarProduto(produto, updateItem)
              // }
          />
      ),
    });
  }

  renderEnderecoEntrega() {
    let cliente = this.state.cliente;

    let enderecos = cliente.enderecos;

    let enderecoPrincipal = Array.from(enderecos).filter(
        (end) => end.principal
    )[0];

    return (
        <Div col="12" rounded column>
          <Label
              value="Informe os seguintes dados para atualizar o endereço de entrega"
              family="Light"
              style={{fontSize: 30}}
          />
          <Div inline="start">
            <InputDefault
                handlerChange={(e) => {
                  enderecoPrincipal.logradouro = e.target.value;
                  this.setState({cliente}, () => this.renderTrocarEndereco());
                }}
                label="Logradouro"
                responsive="4"
                name="logradouro"
                value={enderecoPrincipal.logradouro}
            />
            <InputInteger
                handlerChange={(e) => {
                  enderecoPrincipal.numero = e.target.value;
                  this.setState({cliente}, () => this.renderTrocarEndereco());
                }}
                label="Número"
                responsive="2"
                name="numero"
                value={enderecoPrincipal.numero}
            />
            <InputDefault
                handlerChange={(e) => {
                  enderecoPrincipal.complemento = e.target.value;
                  this.setState({cliente}, () => this.renderTrocarEndereco());
                }}
                label="Complemento"
                responsive="3"
                name="complemento"
                value={enderecoPrincipal.complemento}
            />
            <InputDefault
                handlerChange={(e) => {
                  enderecoPrincipal.bairro = e.target.value;
                  this.setState({cliente}, () => this.renderTrocarEndereco());
                }}
                label="Bairro"
                responsive="3"
                name="bairro"
                value={enderecoPrincipal.bairro}
            />
            <InputCep
                handlerChange={(e) => {
                  enderecoPrincipal.cep = e.target.value;
                  this.setState({cliente}, () => this.renderTrocarEndereco());
                }}
                label="CEP"
                responsive="4"
                name="cep"
                value={enderecoPrincipal.cep}
            />
          </Div>
          <Label
              style={{
                backgroundColor: Color.ECOMMERCE.LARANJA,
                color: "white",
                padding: 20,
                fontSize: 20,
                width: 250,
                borderRadius: 10,
                textAlign: "center",
              }}
              pointer
              family="Light"
              value="Atualizar endereço"
              icon={Type.ICON.REFRESH}
              handlerClick={() => this.sincronizarCliente()}
          />
        </Div>
    );
  }

  renderSucesso() {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
          <Label
              value="Endereço atualizado com sucesso!"
              style={{fontSize: 25, textAlign: "center"}}
              family="Light"
          />
      ),
      handlerConfirm: null,
      handlerCancel: () => console.log("CANCELADO"),
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  renderHorariosEntrega() {
    let todosHorarios = this.getHorariosEntrega();

    let horarioSelecionado = todosHorarios.filter((h) => h.active)[0] || {};

    console.log("temp horario", horarioSelecionado)

    return (
        <Div inline="center" className="w-100">
          <Select
              responsive="12"
              style={{
                border: "none",
                borderBottom: `1px solid #f2f2f2`,
                textAlignLast: "center",
              }}
              valueSelected={horarioSelecionado.idEmpresaHorarioEntrega}
              handlerChange={(e) => {
                let idEmpresaHorarioEntregaSelecionado = e.target.value;
                Array.from(todosHorarios).map(
                    (horario) => (horario.active = false)
                );

                let horarioSelecionado = todosHorarios.filter(
                    (h) =>
                        h.idEmpresaHorarioEntrega === idEmpresaHorarioEntregaSelecionado
                )[0];
                if (Utils.isValueValid(horarioSelecionado)) {
                  horarioSelecionado.active = !horarioSelecionado.active;
                }

                this.setState(
                    {
                      horarios: [...this.state.horarios],
                    },
                    () => this.props.handlerHorarioSelecionado(horarioSelecionado)
                );
              }}
              values={this.getHorariosEntregaSelect()}
          />
        </Div>
    );
  }

  getHorariosSelecaoBody(todosHorarios) {
    let horarioSelecionado = this.state.outroHorarioSelecionado;
    let horariosSplit = Utils.quebrarArray(todosHorarios, 3);

    return (
        <Div column>
          <Div
              className="d-flex flex-column justify-content-start"
              marginTop="3"
              style={{color: "#25D366", width: "100%", padding: "0 4px"}}
          >
            <ContatoWhatsappGenerico
                empresa={this.props.empresa}
                zIndex={Zindex.MIL_E_DOIS}
                title="Entre em contato com um de nossos atendentes para antecipar um pedido"
                value={{mensagem: "Olá, desejo antecipar minha entrega!"}}
                width="100%"
                height="47px"
                corPrimaria={this.props.corPrimaria}
            />
          </Div>

          {horariosSplit.map((horarios, index) => {
            return (
                <Div inline="center">
                  {Array.from(horarios).map((horario, key) => {
                    let isSelected =
                        horarioSelecionado.descricao === horario.descricao &&
                        horarioSelecionado.dataEntrega === horario.dataEntrega;
                    return (
                        <Div
                            column
                            style={{
                              color: isSelected ? "white" : Color.ECOMMERCE.BLACK_GRAY,
                              backgroundColor: isSelected
                                  ? this.props.corPrimaria
                                  : Color.ECOMMERCE.CINZA,
                              padding: 21,
                              borderRadius: 5,
                              height: 90,
                              opacity: 1,
                              margin: 5,
                              justifyContent: "center",
                              fontSize: 14,
                              lineHeight: 0.8,
                            }}
                            pointer
                            handlerClick={() =>
                                this.setState({outroHorarioSelecionado: horario}, () =>
                                    this.renderSelecionarOutroHorario(todosHorarios)
                                )
                            }
                        >
                          <Label
                              value={`${horario.dataEntrega}`}
                              family="Bold"
                              pointer
                          />
                          <Label
                              value={`${horario.descricao}`}
                              family="Bold"
                              pointer
                          />
                        </Div>
                    );
                  })}
                </Div>
            );
          })}
        </Div>
    );
  }

  renderSelecionarOutroHorario(horarios) {
    ModalNotification.renderMessage({
      title: "Selecione outro horário",
      styleButtonConfirm: {
        border: "none",
        backgroundColor: this.props.corPrimaria,
      },
      styleTitle: {
        color: "#FFF",
      },
      styleHeader: {
        border: "none",
        backgroundColor: this.props.corPrimaria,
      },
      body: this.getHorariosSelecaoBody(horarios),
      handlerConfirm: () => {
        let outroHorario = this.state.outroHorarioSelecionado;
        Array.from(horarios).map((horario) => (horario.active = false));

        let horarioSelecionado = horarios.filter(
            (h) =>
                h.descricao === outroHorario.descricao &&
                h.dataEntrega === outroHorario.dataEntrega
        )[0];
        if (Utils.isValueValid(horarioSelecionado)) {
          horarioSelecionado.active = !horarioSelecionado.active;
        }

        this.setState(
            {
              horarios: [...this.state.horarios],
            },
            () => this.props.handlerHorarioSelecionado(horarioSelecionado)
        );
      },
      handlerCancel: () => {
      },
    });
  }

  renderHorariosEntregaNovo() {
    let todosHorarios = this.getHorariosEntrega();

    let horarioSelecionado =
        todosHorarios.filter((h) => h.active)[0] || todosHorarios[0];

    return (
        <Div inline="start" className="w-100" style={{cursor: "pointer"}}>
          <Label
              value="AGENDE SEU HORÁRIO"
              style={styles.labelInfo}
              family="SemiBold"
          />
          <Div
              row
              style={styles.selecioneHorario}
              handlerClick={() => this.renderSelecionarOutroHorario(todosHorarios)}
          >
            <Div column>
              <Label value="Selecione seu Horário" family="Bold"/>
              <Label
                  value={`${horarioSelecionado.dataEntrega} ${horarioSelecionado.descricao}`}
                  family="Light"
                  style={{fontSize: 22}}
              />
            </Div>
            <I
                pointer
                icon={Type.ICON.ARROW_CHEV_DOWN}
                style={{
                  color: Color.ECOMMERCE.CINZA,
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 7,
                  marginLeft: 10,
                }}
            />
          </Div>
        </Div>
    );
  }

  renderDiasPrevisaoEntrega() {
    let diasPrevisaoEntrega = this.props.parametros ? this.props.parametros.diasPrevisaoEntrega : 0;
    let dataHoje = this.state.horarios[0].dataEntrega;
    
    return (
        <Div inline="start" className="w-100" style={{cursor: "pointer"}}>
          <Label
              value="PREVISÃO DE ENTREGA"
              style={styles.labelInfo}
              family="SemiBold"
          />
          <Div
            row
            style={styles.selecioneHorario}
          >
            <Div column>
              <Label 
                style={{ 
                  margin: 0,
                  padding: "10px",
                  color: Color.EMPRESA.SECUNDARIA
                }} 
                value={`Data: ${dataHoje}`} 
                family="Bold"
              />
            </Div>
          </Div>
        </Div>
    );
  }

  getEnderecoSelecionadoParaEntrega() {
    let carrinho = CarrinhoUtils.getCarrinho();

    if (Utils.isValueValid(carrinho.enderecoEntrega)) {
      return carrinho.enderecoEntrega;
    } else {
      let usuario = UsuarioUtils.getUsuarioLojaWeb();
      if (Utils.isValueValid(usuario.enderecos)) {
        let enderecoPrincipal = Array.from(usuario.enderecos).filter(
            (e) => e.principal
        )[0];
        return enderecoPrincipal;
      } else {
        return {};
      }
    }
  }

  render() {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    let enderecos = Array.from(usuario.enderecos);

    const { aceitaEntregaExpress } = this.props.parametros;

    let pedido = this.state.pedido || {};

    let diasPrevisaoEntrega = this.props.parametros ? this.props.parametros.diasPrevisaoEntrega : 0

    return (
        <Div inline="start" className="w-100">
          <Div col="6" marginTop="4" style={{padding: "0 0 20px"}}>
            <Label
                value="LOCAL DE ENTREGA"
                style={styles.labelInfo}
                family="SemiBold"
            />
            {enderecos.map((end, index) => {
              let enderecoEntrega = this.getEnderecoSelecionadoParaEntrega();

              let isSelected = false;

              if (Utils.isValueValid(enderecoEntrega)) {
                isSelected =
                    enderecoEntrega.idClienteEndereco === end.idClienteEndereco;
              }

              return (
                  <Div
                      column
                      key={index}
                      handlerClick={() => {
                        pedido = {
                          ...pedido,
                          enderecoEntrega: {...end},
                        };

                        this.setState(
                            {
                              enderecoCasaSelecionado: true,
                              enderecoEntregaSelecionado: false,
                              pedido,
                            },
                            () => this.props.handlerEnderecoSelecionado(end)
                        );
                      }}
                      className="w-100"
                  >
                    <Div
                        inline="between"
                        margin="2"
                        padding="2"
                        rounded
                        pointer
                        style={
                          isSelected
                              ? {
                                ...styles.enderecoSelecionado,
                                backgroundColor: this.props.corPrimaria,
                              }
                              : styles.enderecoNaoSelecionado
                        }
                        className="w-100"
                    >
                      <Div
                          inline="center"
                          style={{width: "100%", padding: "5px 0"}}
                      >
                        <Div
                            className="d-flex flex-column align-items-start justify-content-center"
                            pointer
                        >
                          <Label
                              value={`${end.descricao}: ${end.logradouro} n° ${end.numero}, ${end.bairro}`}
                              style={{fontSize: 14, marginBottom: 0}}
                              family="SemiBold"
                          />
                        </Div>
                      </Div>
                    </Div>
                  </Div>
              );
            })}

            <Div column marginBottom="4">
              <Label
                  value="ATENÇÃO! Trocar o endereço pode alterar o valor do frete"
                  style={styles.labelAtencao}
                  family="Bold"
              />
            </Div>

            <If and value1={diasPrevisaoEntrega === 0}>
              {this.renderHorariosEntregaNovo()}
            </If>

            <If and value1={diasPrevisaoEntrega > 0}>
              {this.renderDiasPrevisaoEntrega()}
            </If>

            <Div
                inline="start"
                marginTop="3"
                style={{color: "#25D366", marginBottom: 20}}
            >

              <If and value1={aceitaEntregaExpress}>
                <ContatoWhatsappGenerico
                    width="100%"
                    height="50px"
                    corPrimaria={this.props.corPrimaria}
                    empresa={this.props.empresa}
                    zIndex={Zindex.MIL_E_DOIS}
                    title="Entre em contato com um de nossos atendentes para antecipar um pedido"
                    value={{mensagem: "Olá, desejo antecipar minha entrega!"}}
                />

                <Label
                    value="Deseja antecipar sua entrega? Entre em contato via Whatsapp"
                    family="Bold"
                    style={{
                      ...styles.labelAtencao,
                      width: "100%",
                      textAlign: "center",
                    }}
                />
              </If>   
            </Div>

            <Checkbutton
                corPrimaria={Color.EMPRESA.TERCIARIA}
                style={{
                  width: "100%",
                  height: "50px",
                  borderRadius: "5px",
                  color: Color.EMPRESA.PRIMARIA,
                  textTransform: "uppercase",
                  fontSize: 16
                }}
                checked={true}
                title="Salve aqui a sua lista de compra!"
                onClick={() => {
                  let listas = JSON.parse(
                      JSON.stringify(this.props.listasDeCompra)
                  );
                  Array.from(listas).forEach((lista) => {
                    Array.from(lista.itens).forEach((p) => {
                      p.valorSubTotalItem =
                          Number(p.precoVenda) * Number(p.quantidade);
                    });
                  });

                  this.renderAdicionarProdutoLista(listas);
                }}
            />

              <Div column marginTop="4">
            <InputDefault
                paddingLeft="0"
                paddingRight="0"
                formcontrol={false}
                responsive="12"
                value={pedido.responsavelRecebimento}
                label="Responsável pelo recebimento"
                handlerChange={(e) => {
                  pedido = {
                    ...pedido,
                    responsavelRecebimento: e.target.value,
                  };
                  this.props.handlerPedido(pedido);
                }}
                style={{
                  border: "none",
                  borderBottom: `1px solid #f2f2f2`,
                  textAlignLast: "center",
                }}
            />
              </Div>

            <TextArea
                paddingLeft="0"
                paddingRight="0"
                responsive="12"
                placeHolder="Observações"
                style={{textAlign: "center", textAlignLast: "center"}}
                handlerChange={(e) => {
                  pedido = {
                    ...pedido,
                    obs: e.target.value,
                  };
                  this.props.handlerPedido(pedido);
                }}
            />
          </Div>
        </Div>
    );
  }
}

export default TemplatePedidoEntrega;
