import React, { Component } from "react";
import {
  Div,
  I,
  If,
  Image,
  InputDefault,
  Label,
  ModalNotification,
} from "../../components";
import ItemProdutoAbaixouPreco from "../produtos/ItemProdutoAbaixouPreco";

import { Color, Mask, Type, Utils } from "../../utilities";
import ProdutoDetalhe from "../produtos/web/ProdutoDetalhe";
import "./carrinho.css";
import { ProdutoUtils } from "..";
import CarrinhoUtils from "../utils/CarrinhoUtils";

import {ReactComponent as IconProduto} from "../../assets/images/icon-produto.svg";
import {ReactComponent as IconCalendario} from "../../assets/images/icon-calendario.svg";

const styles = {
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
  },
  labelSubTotalItem: {
    fontSize: 20,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
  },
};

class TemplateItensCarrinho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarTodos: props.mostrarTodos || false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ mostrarTodos, parametros }) {
    this.setState({ mostrarTodos, parametros });
  }

  getItensCarrinho = () => {
    const { itens } = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        handlerAddItem={this.adicionarItemNoCarrinho}
      />
    );
  };

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );

    if (produto.quantidade === 0) {
      this.forceUpdate();
    }
  }

  renderProdutos = (produtos) => {
    return Array.from(produtos).map((prod, index) => {
      return (
        <ItemProdutoAbaixouPreco
          key={index}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          quantidade={prod.quantidade}
          handlerClick={() => this.visualizarProduto(prod)}
          handlerAddItemCarrinho={(quantidade) => {
            prod.quantidade = quantidade;
            prod.valorSubTotalItem = (
              Number(quantidade) * Number(prod.precoVenda)
            ).toFixed(2);
            prod.selecionado = true;
            this.adicionarItemNoCarrinho(prod);
          }}
        />
      );
    });
  };

  renderObservacaoItem = (id, idParent, produto) => {
    return (
      <Div
        id={id}
        className="collapse w-100"
        bg={Color.NODE.LIGHT}
        padding="2"
        parent={`#${idParent}`}
      >
        <InputDefault
          label={`Observação do item ${produto.descricao}`}
          value={produto.obs}
          handlerChange={(e) => {
            produto.obs = e.target.value;
            this.adicionarItemNoCarrinho(produto);
            this.forceUpdate();
          }}
          maxLength="150"
        />
      </Div>
    );
  };

  getQuantidadeLabel(produto) {
    let isAtacado =
      Number(produto.quantidadeMinimaAtacado) > 0 &&
      Number(produto.precoAtacado) > 0 &&
      Number(produto.quantidade) >= Number(produto.quantidadeMinimaAtacado);
    let preco = isAtacado
      ? Number(produto.precoAtacado)
      : Number(produto.precoVenda);

    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${produto.quantidade}un x ${Number(preco).toLocaleString()}`;
  }

  addQuantidadeItem(item) {
    CarrinhoUtils.addQuantidadeItem(item);
    this.adicionarItemNoCarrinho(item);
    this.props.handlerAddItem();
  }

  removeQuantidadeItem(item) {
    CarrinhoUtils.removeQuantidadeItem(item);
    this.adicionarItemNoCarrinho(item);
    this.props.handlerAddItem();
  }

  renderProdutosLista = (produtos) => {
    let idParent = Utils.uuidString();
    let corPrimaria = this.props.corPrimaria;
    return (
      <Div className="w-100" id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          let idObservacaoItem = Utils.uuidString();
          return (
            <Div column col="12" key={index}>
              <Div
                inline="between"
                padding="2"
                col="12"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start" col="6">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="70px"
                  />
                  <Label
                    className="text-ellipsis descricao-item"
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={{ width: "70%" }}
                  />
                </Div>

                <Div inline="center" col="3" style={styles.containerAddItem}>
                  <I
                    icon={Type.ICON.MINUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      prod.selecionado = true;
                      this.removeQuantidadeItem(prod);
                    }}
                  />
                  <Label
                    value={this.getQuantidadeLabel(prod)}
                    family="Light"
                    margin="3"
                    className="mx-auto"
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    pointer
                    style={{ color: corPrimaria, fontSize: 20 }}
                    handlerClick={() => {
                      prod.selecionado = true;
                      this.addQuantidadeItem(prod);
                    }}
                  />
                </Div>

                <Label
                  value={Mask.formatValueBr(prod.valorSubTotalItem)}
                  col="1"
                  family="SemiBold"
                  style={{...styles.labelSubTotalItem, color: corPrimaria}}
                />
                <Div inline="end" col="2">
                  <Div column margin="1">
                    <I
                      icon={Type.ICON.INFO}
                      style={{
                        fontSize: 20,
                        color: corPrimaria,
                      }}
                      pointer
                      toggle="collapse"
                      target={`#${idObservacaoItem}`}
                    />
                  </Div>
                  <Div
                    col="2"
                    inline="center"
                    margin="1"
                    pointer
                    handlerClick={() => {
                      prod.quantidade = 0;
                      prod.valorSubTotalItem = 0;
                      prod.selecionado = false;
                      this.adicionarItemNoCarrinho(prod);
                      this.props.handlerAddItem();
                    }}
                  >
                    <I
                      icon={Type.ICON.CLOSEX}
                      style={{ fontSize: 20 }}
                      pointer
                      colorText={Color.NODE.DANGER}
                    />
                  </Div>
                </Div>
              </Div>
              <Div col="12">
                {this.renderObservacaoItem(idObservacaoItem, idParent, prod)}
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  getEntrega() {
    let parametros = this.props.parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  getPrevisaoEntrega() {
    let previsao = "";

    if(Utils.isValueValid(this.props.parametros)) {
      let horarios = Array.from(this.props.parametros.horarios);
      previsao = `${horarios[0].dataEntrega} - ${horarios[horarios.length - 1].dataEntrega}`
    }

    return previsao;
  }

  render() {
    const { cabecalho } = this.props.carrinho;

    let itens = this.getItensCarrinho();

    let entrega = this.getEntrega();

    if (Array.from(itens).length === 0) this.props.history.replace("/");

    return (
      <>
        <Div
          className="d-flex flex-column align-self-start align-items-start justify-content-between"
          responsive="8"
        >
          <Div inline="start" col="12" marginLeft="3">
            <Div inline="center" margin="2">
              <IconCalendario />
              <Label
                value={`Previsão de entrega: ${this.getPrevisaoEntrega()}`}
                style={styles.labelLoja}
                family="Light"
                margin="2"
              />
            </Div>
            <Div inline="center" margin="2">
              <IconProduto />
              <Label
                value={`${this.props.carrinho.itens.length} produtos`}
                style={styles.labelLoja}
                family="Light"
                margin="2"
              />
            </Div>
            <Label style={styles.labelBorda} />
          </Div>
          <Div className="w-100">{this.renderProdutosLista(itens || [])}</Div>
        </Div>
        <If and value1={!this.state.mostrarTodos}></If>
      </>
    );
  }
}

export default TemplateItensCarrinho;
