import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, Header, I, If, Label } from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";
import PesquisaProdutoMobile from "../pesquisa/PesquisaProdutoMobile";
import HeaderNavegaoWebMobile from "./mobile/HeaderNavegaoWebMobile";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";
import {
  DepartamentosLojaInstantaneaMobile,
  EmpresaUtils,
  LoginClienteMenu,
  ProdutoUtils,
} from "..";
import MenuCliente from "../cliente/MenuCliente";
import "./header.css";
import InputSearchLojaInstantaneaMobile from "../../components/forms/input/lojainstantanea/InputSearchLojaInstantaneaMobile";
import EventUtils from "../utils/EventUtils";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";

const ID_PESQUISA = Utils.uuidString();
const ID_INPUT_SEARCH = Utils.uuidString();

const styles = {
  btnSearch: {
    position: "absolute",
    transition: "0.5s",
    right: 10,
    transform: `rotate(90deg)`,
    color: Color.ECOMMERCE.LARANJA_CLARO,
    fontSize: 20,
  },
  btnSearchActive: {
    position: "absolute",
    transition: "0.5s",
    left: 10,
    transform: `rotate(90deg)`,
    color: Color.ECOMMERCE.LARANJA_CLARO,
    fontSize: 20,
  },
};

class HeaderLojaWebMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zIndexHeader: 1002,
      menus: props.menus || [],
      resultadoPesquisa: [],
      textoPesquisa: "",
      realizarLogin: false,
      mostrarMenuUsuario: false,
      activePesquisa: false,
      activeMenuDepartamento: false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ menus: props.menus, empresa: props.empresa });
  }

  componentDidMount() {
    this.consultarParametrosPedido();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data }, () =>
              CarrinhoUtils.calcularValorTaxaEntrega(
                this.state.parametros,
                this.props.carrinho,
                this.props.atualizarCarrinho
              )
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  realizarPesquisa(texto) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
            },
            () => {
              if (data.sucessoPesquisa) {
                this.props.history.push("/resultadopesquisa", {
                  resultadoPesquisa: data,
                  textoPesquisa: texto,
                  empresa: this.state.empresa,
                  classificacoes: this.state.classificacoes,
                });
                this.togglerMenuPesquisa();
              }

              this.setState({ textoPesquisa: texto });
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  fecharMenuBusca() {
    let div = document.getElementById(ID_PESQUISA);
    if (Utils.isValueValid(div)) {
      div.classList.toggle("collapsed");
      let input = document.getElementById(ID_INPUT_SEARCH);
      if (Utils.isValueValid(input)) {
        input.click();
      }
    }
  }

  adicionarItemNoCarrinho(produto) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      null,
      this.state.parametros
    );
  }

  removerItemCarrinho(produto) {
    let { carrinho } = this.props;

    let itens = Array.from(carrinho.itens);
    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    if (Utils.isValueValid(produtoSelecionado)) {
      itens.splice(itens.indexOf(produtoSelecionado), 1);
      carrinho.itens = itens;
      this.props.atualizarCarrinho(carrinho);
    }

    document.dispatchEvent(
      ProdutoUtils.eventChangeProduto({
        quantidade: produto.quantidade,
        codigoBarras: produto.codigoBarras,
      })
    );
  }

  abrirMenuBusca() {
    let div = document.getElementById(ID_PESQUISA);
    if (Utils.isValueValid(div)) {
      if (!div.classList.contains("collapsed")) {
        document.getElementById(ID_INPUT_SEARCH).click();
      }
      div.classList.add("collapsed");
    }
  }

  togglerMenuPesquisa() {
    if (this.state.activePesquisa) {
      this.fecharMenuBusca();
      this.setState({ textoPesquisa: "" });
    } else {
      this.abrirMenuBusca();
      Utils.scrollTop();
    }
    this.setState({ activePesquisa: !this.state.activePesquisa });
  }

  render() {
    let idContainer = Utils.uuidString();
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <Div
        id={idContainer}
        responsive="12"
        style={{
          backgroundColor: "white",
          position: "fixed",
          top: 0,
          zIndex: this.state.zIndexHeader,
          height: 110,
          width: "100%",
        }}
        inline="center"
      >
        <Div>
          <Header history={this.props.history} title={this.props.title}>
            <HeaderNavegaoWebMobile
              empresa={this.state.empresa}
              {...this.props}
              handlerRemoveItem={(produto) => {
                this.removerItemCarrinho(produto);
              }}
              handlerAddItem={this.adicionarItemNoCarrinho}
              handlerLogin={() => this.setState({ realizarLogin: true })}
              handlerMenuCliente={() =>
                this.setState({ mostrarMenuUsuario: true })
              }
            />
            <If and value1={this.state.realizarLogin}>
              <Div
                style={{
                  position: "absolute",
                  right: 130,
                  top: 70,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  padding: 10,
                  zIndex: 1002,
                }}
              >
                <LoginClienteMenu
                  history={this.props.history}
                  handlerCloseMenu={() =>
                    this.setState({ realizarLogin: false })
                  }
                />
              </Div>
            </If>
            <If and value1={this.state.mostrarMenuUsuario}>
              <Div
                style={{
                  position: "absolute",
                  right: 130,
                  top: 70,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 5,
                  padding: 10,
                  zIndex: 1002,
                }}
              >
                <MenuCliente
                  handlerCloseMenu={() =>
                    this.setState({ mostrarMenuUsuario: false })
                  }
                  history={this.props.history}
                />
              </Div>
            </If>
          </Header>
          <PesquisaProdutoMobile
            id={ID_PESQUISA}
            handlerRemoveBuscas={() => this.forceUpdate()}
            resultadoPesquisa={this.state.resultadoPesquisa}
            textoPesquisa={this.state.textoPesquisa}
            empresa={this.state.empresa}
            handlerPesquisa={(texto, idBtnFecharMenu) =>
              this.setState({ textoPesquisa: texto }, () =>
                this.realizarPesquisa(texto, idBtnFecharMenu)
              )
            }
            activePesquisa={this.state.activePesquisa}
            handlerClosePesquisa={() => {
              this.setState({
                activePesquisa: !this.state.activePesquisa,
                textoPesquisa: "",
              });
            }}
          />
        </Div>
        <InputSearchLojaInstantaneaMobile
          idInput={ID_INPUT_SEARCH}
          idPesquisa={ID_PESQUISA}
          handlerChangePesquisa={(texto) => {
            this.realizarPesquisa(texto);
          }}
          handlerFecharPesquisa={() => this.fecharMenuBusca()}
          textoPesquisa={this.state.textoPesquisa}
          handlerAbrirPesquisa={() => {
            this.setState({ activePesquisa: true }, () =>
              this.props.handlerAbrirPesquisa()
            );
          }}
          handlerChangeTexto={(textoPesquisa) =>
            this.setState({ textoPesquisa })
          }
          corPrimaria={corPrimaria}
        />
        <Div
          inline="start"
          className="border-bottom border-top"
          style={{
            top: 110,
            position: "absolute",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <DepartamentosLojaInstantaneaMobile
            history={this.props.history}
            menus={this.props.menus}
            empresa={this.state.empresa}
            //css={"btn-deptos"}
            handlerToggleDepartamentos={this.props.handlerToggleDepartamentos}
          />
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.menusState
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderLojaWebMobile);
