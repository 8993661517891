import React from "react";
import { EmpresaUtils } from ".";
import { Div, MessageSpinner } from "../components";
import { Color } from "../utilities";

const Aguarde = (props) => {
  return (
    <Div>
      <MessageSpinner
        value="Carregando , aguarde"
        style={{
          backgroundColor: Color.ECOMMERCE.CINZA_ESCURO,
          color: "white",
          borderRadius: 10,
          margin: 20,
          padding: 10,
          fontSize: 30,
        }}
      />
    </Div>
  );
};

export default Aguarde;
