import React from "react";
import { Div, Label } from "../../components";
import { Color } from "../../utilities";

const renderTemplateFrente = () => {
  return (
    <Div
      style={{
        backgroundColor: "#f2f2f2",
        width: 300,
        height: 170,
        borderRadius: 10,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
      }}
    >
      <Div inline="start">
        <Label
          value="5432 1234 5678 1234"
          style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 18 }}
          family="SemiBold"
        />

        <Label
          style={{
            border: `5px solid ${Color.ECOMMERCE.LARANJA}`,
            borderRadius: 50,
            transform: `translateX(200px)`,
            position: "absolute",
          }}
        />
        <Label
          style={{
            borderTop: `3px solid ${Color.ECOMMERCE.LARANJA}`,
            width: 71,
            transform: `translateX(200px)`,
            position: "absolute",
          }}
        />
        <Label
          style={{
            transform: `translateX(280px)`,
            position: "absolute",
            color: Color.ECOMMERCE.LARANJA,
          }}
          value="Número do cartão"
          family="Light"
        />
      </Div>

      <Div inline="between">
        <Label
          value="XXX"
          family="Light"
          style={{ color: Color.ECOMMERCE.CINZA }}
        />
        <Div inline="start">
          <Div column>
            <Label
              value="Valid"
              style={{ lineHeight: "10px", color: Color.ECOMMERCE.CINZA }}
              family="Light"
            />
            <Label
              value="Thru"
              style={{ lineHeight: "10px", color: Color.ECOMMERCE.CINZA }}
              family="Light"
            />
          </Div>

          <Div inline="start">
            <Label
              marginLeft="3"
              value="01/2030"
              style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
              family="Light"
            />
            <Label
              style={{
                border: `5px solid ${Color.ECOMMERCE.LARANJA}`,
                borderRadius: 50,
                transform: `translateX(100px)`,
                position: "absolute",
              }}
            />
            <Label
              style={{
                borderTop: `3px solid ${Color.ECOMMERCE.LARANJA}`,
                width: 50,
                transform: `translateX(100px)`,
                position: "absolute",
              }}
            />
            <Label
              style={{
                transform: `translateX(160px)`,
                position: "absolute",
                color: Color.ECOMMERCE.LARANJA,
              }}
              value="Validade"
              family="Light"
            />
          </Div>
        </Div>
      </Div>

      <Div inline="start">
        <Label
          value="Mariana Silva"
          family="Light"
          style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
        />
        <Label
          style={{
            border: `5px solid ${Color.ECOMMERCE.LARANJA}`,
            borderRadius: 50,
            transform: `translateX(110px)`,
            position: "absolute",
          }}
        />
        <Label
          style={{
            borderTop: `3px solid ${Color.ECOMMERCE.LARANJA}`,
            width: 161,
            transform: `translateX(110px)`,
            position: "absolute",
          }}
        />
        <Label
          style={{
            transform: `translateX(280px)`,
            position: "absolute",
            color: Color.ECOMMERCE.LARANJA,
          }}
          value="Seu nome"
          family="Light"
        />
      </Div>
    </Div>
  );
};

const renderTemplateVerso = () => {
  return (
    <Div
      style={{
        backgroundColor: "#f2f2f2",
        width: 300,
        height: 170,
        borderRadius: 10,
        paddingTop: 25,
      }}
    >
      <Label
        style={{ border: `20px solid black`, width: "100%" }}
        family="Light"
      />

      <Div
        inline="between"
        style={{ margin: 5, padding: 5, width: 250, backgroundColor: "white" }}
      >
        <Label family="Light" value="XXXX XXXXXXX" />
        <Div inline="start">
          <Label family="Light" value="123" />
          <Label
            style={{
              border: `5px solid ${Color.ECOMMERCE.LARANJA}`,
              borderRadius: 50,
              transform: `translateX(40px)`,
              position: "absolute",
            }}
          />
          <Label
            style={{
              borderTop: `3px solid ${Color.ECOMMERCE.LARANJA}`,
              width: 55,
              transform: `translateX(40px)`,
              position: "absolute",
            }}
          />
          <Label
            style={{
              transform: `translateX(105px)`,
              position: "absolute",
              color: Color.ECOMMERCE.LARANJA,
            }}
            value="Código de segurança"
            family="Light"
          />
        </Div>
      </Div>
    </Div>
  );
};

const TemplateCartaoInformativo = (props) => {
  return (
    <Div>
      <Label
        family="SemiBold"
        value="Orientações de preenchimento"
        style={{ color: Color.ECOMMERCE.LARANJA }}
      />
      <Div>
        <Label
          value="FRENTE"
          family="Light"
          style={{ fontSize: 13, color: "#2CB8E5" }}
        />
        {renderTemplateFrente()}
        <Label
          value="VERSO"
          family="Light"
          style={{
            marginTop: 5,
            marginBottom: 5,
            fontSize: 13,
            color: "#2CB8E5",
          }}
        />
        {renderTemplateVerso()}
      </Div>
    </Div>
  );
};

export default TemplateCartaoInformativo;
