import React from "react";
import { Component } from "react";
import { Div, Label, TextArea } from "../../../components";
import { col, responsive } from "../../css";

class TextAreaWithState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      texto: "",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ texto: props.value });
  }

  getClassDefault = (props) => {
    let classe = "";
    classe = classe.concat(col(props));
    classe = classe.concat(responsive(props));
    return classe;
  };

  render() {
    const {
      handlerChange,
      name,
      rows,
      placeHolder,
      maxLength,
      defaultValue,
      style,
      value,
      col,
      label,
      fontSizeLabel,
    } = this.props;
    return (
      <Div className={this.getClassDefault(this.props)} col={col}>
        {label && (
          <Label
            value={label}
            style={{ fontSize: Number(fontSizeLabel) }}
          ></Label>
        )}
        <TextArea
          placeholder={placeHolder}
          maxLength={maxLength}
          name={name}
          className="form-control"
          rows={rows}
          style={style}
          value={this.state.texto}
          handlerChange={(e) => {
            this.setState({ texto: e.target.value });
          }}
          handlerBlur={() => handlerChange(this.state.texto)}
        />
      </Div>
    );
  }
}

export default TextAreaWithState;
