import React, { Component } from "react";
import {
  Div,
  I,
  If,
  Label,
  Select,
  SelectValue,
  TextArea,
} from "../../components";
import { Color, Http, Type, Utils } from "../../utilities";

const styles = {
  filialSelecionada: {
    border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    opacity: 0.9,
    color: "white",
  },
  filialNaoSelecionada: {
    border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    opacity: 0.9,
    color: "#878787",
  },
  labelInfo: {
    color: "#878787",
    fontSize: 18,
  },

  containterHorario: {
    backgroundColor: "#F2F2F2",
    padding: 10,
    borderRadius: 10,
    margin: 5,
    fontSize: 13,
  },
  containterHorarioSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    padding: 10,
    borderRadius: 10,
    margin: 5,
    fontSize: 13,
  },
  item: {
    width: Utils.isViewTabletAll() ? window.innerWidth / 1.5 : "100%",
  },
};

class TemplatePedidoRetiradaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: {},
      erroContinarPagamento: "",
      horarios: props.parametros.horarios || [],
    };
  }

  UNSAFE_componentWillReceiveProps({ parametros }) {
    this.setState({ horarios: parametros.horarios });
  }

  UNSAFE_componentWillMount() {
    this.consultarEmpresa();
  }

  consultarEmpresa() {
    Http.get("/empresa")
      .then(({ status, data }) => {
        if (status) {
          this.setState({ empresa: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getHorariosRetiradaSelect() {
    let horarios = this.state.horarios;

    let horariosRetirada = [];

    if (Utils.isValueValid(horarios)) {
      horariosRetirada = this.getHorariosRetirada();
    }

    let horariosSelect = [
      new SelectValue("", "Agende seu horário (Selecione um horário)"),
    ];

    horariosRetirada.forEach((horario) => {
      horariosSelect.push(
        new SelectValue(
          horario.idEmpresaHorarioEntrega,
          `${horario.dataEntrega} ${horario.descricao}`
        )
      );
    });

    return horariosSelect;
  }

  getHorariosRetirada() {
    let horarios = this.state.horarios;

    let horariosRetirada = Array.from(horarios).filter(
      (h) => h.tipoEntrega === "RETIRADA"
    );

    return horariosRetirada;
  }

  renderHorariosRetirada() {
    let todosHorarios = this.getHorariosRetirada();

    let horarioSelecionado = todosHorarios.filter((h) => h.active)[0] || {};

    return (
      <Div inline="center" marginTop="2" style={styles.item}>
        <Select
          responsive="12"
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
          }}
          valueSelected={horarioSelecionado.idEmpresaHorarioEntrega}
          handlerChange={(e) => {
            let idEmpresaHorarioEntregaSelecionado = e.target.value;
            Array.from(todosHorarios).map(
              (horario) => (horario.active = false)
            );

            let horarioSelecionado = todosHorarios.filter(
              (h) =>
                h.idEmpresaHorarioEntrega === idEmpresaHorarioEntregaSelecionado
            )[0];
            if (Utils.isValueValid(horarioSelecionado)) {
              horarioSelecionado.active = !horarioSelecionado.active;
            }

            this.setState(
              {
                horarios: [...this.state.horarios],
              },
              () => this.props.handlerHorarioSelecionado(horarioSelecionado)
            );
          }}
          values={this.getHorariosRetiradaSelect()}
        />
      </Div>
    );
  }

  render() {
    let filiais = this.state.empresa.filiais || [];
    let { pedido } = this.props;
    return (
      <Div inline="center" style={styles.item}>
        <Div column>
          <Label
            value="Selecione a loja em que deseja retirar os produtos"
            style={styles.labelInfo}
            family="SemiBold"
            col="12"
          />
          {Array.from(filiais).map((filial, index) => {
            let isFilialSelecionada =
              this.props.filialSelecionadaRetirada.idFilial === filial.idFilial;
            return (
              <Div
                key={index}
                className="d-flex flex-column align-items-start justify-content-start p-2 mt-2 mx-3"
                pointer
                rounded
                handlerClick={() => this.props.handlerFilialSelecionada(filial)}
                style={
                  isFilialSelecionada
                    ? {...styles.filialSelecionada, backgroundColor: this.props.corSecundaria}
                    : styles.filialNaoSelecionada
                }
              >
                <If and value1={!isFilialSelecionada}>
                  <Label value={filial.fantasia} family="Bold" />
                </If>
                <If and value1={isFilialSelecionada}>
                  <Div
                    inline="between"
                    className="align-self-start"
                    className="w-100"
                  >
                    <Label value={filial.fantasia} family="Bold" />
                  </Div>
                </If>
                <Label
                  value={`${filial.logradouro} n° ${filial.numero} ${filial.bairro} ${filial.nomeCidade}`}
                  family="Light"
                />
              </Div>
            );
          })}

          <Div inline="center" style={styles.item}>
            {this.renderHorariosRetirada()}

            <TextArea
              responsive="12"
              label="Observações"
              handlerChange={(e) => {
                pedido = {
                  ...pedido,
                  obs: e.target.value,
                };
                this.props.handlerPedido(pedido);
              }}
            />
          </Div>
        </Div>
      </Div>
    );
  }
}

export default TemplatePedidoRetiradaMobile;
