import React, {Component} from "react";
import {Div, If, Image, InputInteger, Label, Select, SelectValue,} from "../../components";
import {Color, Http, Mask, Utils} from "../../utilities";
import TemplateCartao from "./TemplateCartao";

import SocketClient from "../../components/websocket/SocketClient";

import ImagemQRCODE from "../../assets/images/bandeiras/icon-qrcode.png";
import ImagemVisa from "../../assets/images/bandeiras/icon-visa.png";
import ImagemMasterCard from "../../assets/images/bandeiras/icon-mastercard.png";
import ImagemAmericanExpress from "../../assets/images/bandeiras/icon-amex.png";
import ImagemHiperCard from "../../assets/images/bandeiras/icon-hipercard.png";
import ImagemElo from "../../assets/images/bandeiras/icon-elo.png";
import ImagemDiners from "../../assets/images/bandeiras/icon-diners.png";
import TemplateCartaoInformativo from "./TemplateCartaoInformativo";
import {HEADER_SOCKET, URL_TOKEN_SOCKET_NOVO,} from "../../utilities/axios/api";
import PixQrCode from "./PixQrCode";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

const styles = {
  labelPagamentoSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    opacity: 0.8,
    color: Color.ECOMMERCE.WHITE,
  },
  labelPagamentoNaoSelecionado: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
  },
  formaPagamentoOnline: {
    padding: 30,
    width: 200,
    backgroundColor: "#F2F2F2",
    color: "#878787",
    height: 100,
  },
  formaPagamentoOnlineSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    opacity: 0.8,
    color: Color.ECOMMERCE.WHITE,
    padding: 30,
    width: 200,
    height: 100,
  },
  bandeira: {
    padding: 10,
  },
  bandeiraSelecinada: {
    borderRadius: 15,
    padding: 10,
    backgroundColor: Color.ECOMMERCE.LARANJA,
  },
};

class TemplatePagamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagamentoOnline: true,
      pagamentoOffline: false,
      pagamentoTipoCreditoOnline: true,
      pagamentoTipoDebitoOnline: false,
      pagamentoTipoCreditoOffline: false,
      pagamentoTipoDebitoOffline: false,
      pagamentoTipoQrCodePix: false,
      pagamentoTipoDinheiro: true,
      pagamentoTipoVoucher: false,

      formasDePagamentoOnline: this.getFormasPagamentoOnline(),
      formasDePagamentoOffline: this.getFormasPagamentoOffline(),
      quantidadeMaximaParcelas: this.props.novosParamentros.quantidadeMaximaParcelas,
    };

    props.handlerRecebimento(this.getRecebimentoInicial());
    props.handlerTipoPagamento(true);
  }

  componentDidUpdate(prevState) {
    if(prevState.recebimentoPedido !== this.state.recebimentoPedido){
      let { pedido, recebimentoPedido  } = this.state;
      let { fantasia } = this.props.empresa;

      if(Utils.isNotUndefined(recebimentoPedido.tipo)){
        TagManagerUtils.InformacoesDePagamento(pedido, recebimentoPedido.tipo, fantasia);
      }
    }
  }

  getRecebimentoInicial() {
    let bandeiras = this.getBandeirasCartao();

    let formasDePagamento = this.state.pagamentoOnline
        ? this.state.formasDePagamentoOnline
        : this.state.formasDePagamentoOffline;

    return bandeiras
        ? {
          tipo: formasDePagamento[0].descricao,
          bandeiraCartao: this.state.pagamentoTipoCreditoOnline
              ? //||
                //this.state.pagamentoTipoDebitoOnline
              bandeiras[0].tipoBandeira || bandeiras[0].descricao
              : null,
          idEmpresaFormaPagamento: bandeiras[0].idEmpresaFormaPagamento,
          quantidadeParcelas: this.state.pagamentoOnline ? 1 : null,
        }
        : null;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      pedido: props.pedido,
      recebimentoPedido: props.recebimentoPedido,
    });
  }

  getFormasPagamentoOnline = () => {
    let formasPagamentosOnline = [
      {descricao: "CREDITO", active: true},
      {descricao: "DEBITO", active: false}];
    if(Utils.isValueValid(this.props.empresa.parametros.chavePix)) {
      formasPagamentosOnline.push({descricao: "PIX QRCODE", active: false});
    }
    return formasPagamentosOnline;
  };

  getFormasPagamentoOffline = () => {
    let parametros = this.props.parametros;
    let pagamentoDinheiro = Array.from(parametros.formasPagamentos).filter(
        (pagamento) => pagamento.dinheiro
    )[0];
    return [
      {
        descricao: "DINHEIRO",
        active: true,
        idEmpresaFormaPagamento: pagamentoDinheiro.idEmpresaFormaPagamento,
      },
      {descricao: "CREDITO", active: false},
      {descricao: "DEBITO", active: false},
      /*  { descricao: "VOUCHER", active: false },*/
    ];
  };

  getImagemBandeira = (bandeira) => {
    let descricao = String(bandeira.descricao);
    switch (descricao) {
      case "VISA":
        return ImagemVisa;
      case "MASTER CARD":
        return ImagemMasterCard;
      case "HIPERCARD":
        return ImagemHiperCard;
      case "AMERICAN EXPRESS":
        return ImagemAmericanExpress;
      case "QRCODE":
        return ImagemQRCODE;
      case "ELO":
        return ImagemElo;
      case "DINERS CLUB":
        return ImagemDiners;
      default:
        return ImagemQRCODE;
    }
  };

  getBandeirasCartao = () => {
    let formasDePagamento = this.props.parametros.formasPagamentos || [];

    const {
      pagamentoOnline,
      pagamentoOffline,
      pagamentoTipoCreditoOnline,
      pagamentoTipoDebitoOnline,
      pagamentoTipoCreditoOffline,
      pagamentoTipoDebitoOffline,
      pagamentoTipoDinheiro,
    } = this.state;

    let pagamentos = [];

    if (pagamentoOnline) {
      if (pagamentoTipoCreditoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.onLine === pagamentoOnline &&
                pg.credito === pagamentoTipoCreditoOnline &&
                !pg.qrCode
        );
      } else if (pagamentoTipoDebitoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.onLine === pagamentoOnline &&
                pg.debito === pagamentoTipoDebitoOnline &&
                !pg.qrCode
        );
      }
    } else {
      if (pagamentoTipoCreditoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.credito === pagamentoTipoCreditoOffline
        );
      } else if (pagamentoTipoDebitoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.debito === pagamentoTipoDebitoOffline
        );
      } else if (pagamentoTipoDinheiro) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.dinheiro === pagamentoTipoDinheiro
        );
      }
    }

    let pagamentosSelectValue = [];

    pagamentos.forEach((pagamento) => {
      pagamentosSelectValue.push({
        idEmpresaFormaPagamento: pagamento.idEmpresaFormaPagamento,
        descricao: pagamento.descricao,
        imagem: this.getImagemBandeira(pagamento),
        tipoBandeira: pagamento.tipoBandeira,
      });
    });

    return pagamentosSelectValue;
  };

  getBandeirasCartaoSelect = () => {
    let bandeiras = this.getBandeirasCartao();

    let pagamentosSelectValue = [new SelectValue("", "Selecione uma bandeira")];
    bandeiras.forEach((band) => {
      pagamentosSelectValue.push(
          new SelectValue(band.idEmpresaFormaPagamento, band.descricao)
      );
    });

    return pagamentosSelectValue;
  };

  getBandeirasSelect = () => {
    let formasDePagamento = this.props.parametros.formasPagamentos || [];

    const {
      pagamentoOnline,
      pagamentoOffline,
      pagamentoTipoCreditoOnline,
      pagamentoTipoDebitoOnline,
      pagamentoTipoCreditoOffline,
      pagamentoTipoDebitoOffline,
      pagamentoTipoDinheiro,
    } = this.state;

    let pagamentos = [];

    if (pagamentoOnline) {
      if (pagamentoTipoCreditoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.onLine === pagamentoOnline &&
                pg.credito === pagamentoTipoCreditoOnline &&
                !pg.qrCode
        );
      } else if (pagamentoTipoDebitoOnline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.onLine === pagamentoOnline &&
                pg.debito === pagamentoTipoDebitoOnline &&
                !pg.qrCode
        );
      }
    } else {
      if (pagamentoTipoCreditoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.credito === pagamentoTipoCreditoOffline
        );
      } else if (pagamentoTipoDebitoOffline) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.debito === pagamentoTipoDebitoOffline
        );
      } else if (pagamentoTipoDinheiro) {
        pagamentos = Array.from(formasDePagamento).filter(
            (pg) =>
                pg.offLine === pagamentoOffline &&
                pg.dinheiro === pagamentoTipoDinheiro
        );
      }
    }

    let pagamentosSelectValue = [];

    pagamentos.forEach((pagamento) => {
      pagamentosSelectValue.push(
          new SelectValue(pagamento.idEmpresaFormaPagamento, pagamento.descricao)
      );
    });

    return pagamentosSelectValue;
  };

  getValorTotalPedido = () => {
    let carrinho = this.props.carrinho;
    let {valorTaxaEntrega, valorDesconto} = carrinho.cabecalho;

    let valorTotalPedido = Number(
        this.props.pedido.valorTotal +
        valorTaxaEntrega +
        this.props.parametros.valorTaxaServico -
        valorDesconto
    );

    return valorTotalPedido;
  };

  getIdTransacaoPedido = () => {
    let idLancamentoPedidoApp = this.props.carrinho.cabecalho.idLancamentoPedidoApp;
    return Utils.isValueValid(idLancamentoPedidoApp) ? idLancamentoPedidoApp : Utils.uuidString();
  };

  getQuantidadeParcelamentoCredito = () => {
    let valorTotalPedido = Number(this.getValorTotalPedido());

    let quantidades = [];

    let i = 0;

    for (i = 1; i <= this.state.quantidadeMaximaParcelas; i++) {
      quantidades.push(
          new SelectValue(
              i,
              `Em ${i}x de ${Mask.formatValueBr(valorTotalPedido / i)}`
          )
      );
    }
    return quantidades;
  };

  getTipoPagamento = () => {
    return this.state.pagamentoTipoCreditoOnline ? "CREDITO" : "DEBITO";
  };

  getImagePagamento(pagamento) {
    switch (pagamento) {
      case "DINHEIRO":
        return "fa fa-money";
      case "CREDITO":
        return "fa fa-credit-card-alt";
      case "DEBITO":
        return "fa fa-credit-card";
      case "QRCODE":
        return "fa fa-qrcode";
      case "VOUCHER":
        return "fa fa-ticket";
      default:
        return "fa fa-money";
    }
  }

  setTipoDePagamento(pagamento, online) {
    switch (pagamento) {
      case "DINHEIRO":
        this.setState({
          pagamentoTipoCreditoOffline: false,
          pagamentoTipoDebitoOffline: false,
          pagamentoTipoQrCodePix: false,
          pagamentoTipoDinheiro: true,
          pagamentoTipoVoucher: false,
        });
        return;
      case "CREDITO":
        if (online) {
          this.setState({
            pagamentoTipoCreditoOnline: true,
            pagamentoTipoDebitoOnline: false,
            pagamentoTipoQrCodePix: false,
            pagamentoTipoDinheiro: false,
            pagamentoTipoVoucher: false,
          });
        } else {
          this.setState({
            pagamentoTipoCreditoOffline: true,
            pagamentoTipoDebitoOffline: false,
            pagamentoTipoQrCodePix: false,
            pagamentoTipoDinheiro: false,
            pagamentoTipoVoucher: false,
          });
        }
        return;
      case "DEBITO":
        if (online) {
          this.setState({
            pagamentoTipoCreditoOnline: false,
            pagamentoTipoDebitoOnline: true,
            pagamentoTipoQrCodePix: false,
            pagamentoTipoDinheiro: false,
            pagamentoTipoVoucher: false,
          });
        } else {
          this.setState({
            pagamentoTipoCreditoOffline: false,
            pagamentoTipoDebitoOffline: true,
            pagamentoTipoQrCodePix: false,
            pagamentoTipoDinheiro: false,
            pagamentoTipoVoucher: false,
          });
        }
        return;
      case "PIX QRCODE":
        this.setState({
          pagamentoTipoCreditoOnline: false,
          pagamentoTipoDebitoOnline: false,
          pagamentoTipoQrCodePix: true,
          pagamentoTipoDinheiro: false,
          pagamentoTipoVoucher: false,
        });
        return;
      case "VOUCHER":
        this.setState({
          pagamentoTipoCreditoOffline: false,
          pagamentoTipoDebitoOffline: false,
          pagamentoTipoQrCodePix: false,
          pagamentoTipoDinheiro: false,
          pagamentoTipoVoucher: true,
        });
        return;
      default:
        this.setState({
          pagamentoTipoCreditoOnline: true,
          pagamentoTipoDebitoOnline: false,
          pagamentoTipoQrCodePix: false,
          pagamentoTipoDinheiro: false,
          pagamentoTipoVoucher: false,
        });
    }
  }

  getTipoPagamentoOnlineOffline = () => {
    return [
      new SelectValue("ONLINE", "PAGAMENTO ONLINE"),
      new SelectValue("OFFLINE", "PAGAMENTO NA ENTREGA / RETIRADA"),
    ];
  };

  getFormasPagamentoOnlineSelect(formasPagamentoOnline) {
    let formasPagamento = [];

    if (Utils.isValueValid(formasPagamentoOnline)) {
      Array.from(formasPagamentoOnline).forEach((f) => {
        formasPagamento.push(new SelectValue(f.descricao, f.descricao));
      });
    }

    return formasPagamento;
  }

  getformasPagamentoOfflineSelect(formasPagamentoOffline) {
    let formasPagamento = [];

    if (Utils.isValueValid(formasPagamentoOffline)) {
      Array.from(formasPagamentoOffline).forEach((f) => {
        formasPagamento.push(new SelectValue(f.descricao, f.descricao));
      });
    }

    return formasPagamento;
  }

  handlerSocket() {
    return (
        <SocketClient
            url={URL_TOKEN_SOCKET_NOVO()}
            headers={HEADER_SOCKET()}
            topics={[
              `/user/recebimento/${this.props.carrinho.cabecalho.idLancamentoPedidoApp}`,
            ]}
            onMessage={(notify) => {
              console.log(notify);
              console.log(this.props.windowPagamentoDebito);
              if (Utils.isValueValid(this.props.windowPagamentoDebito)) {
                this.props.windowPagamentoDebito.close();
              }
              this.consultarSituacaoDoPedidoEFinalizar();
            }}
        />
    );
  }

  consultarSituacaoDoPedidoEFinalizar() {
    Http.get(
        `/pedido/${this.props.carrinho.cabecalho.idLancamentoPedidoApp}/situacao`
    )
        .then(({status, data}) => {
          if (status) {
            if (data.statusPagamento) {
              this.props.handlerSucessoPagamento(data);
            } else {
              this.props.handlerErrorPagamento(data);
            }
          }
        })
        .catch((erro) => console.log(erro));
  }

  mudarFormaDePagamentoOnline = (
      formaDePagamentoSelecionada,
      recebimentoPedido
  ) => {
    let formasDePagamento = this.props.parametros.formasPagamentos || [];
    let formasDePagamentoOnline = this.state.formasDePagamentoOnline;

    let pagamento =
        Array.from(formasDePagamentoOnline).filter(
            (f) => f.descricao === formaDePagamentoSelecionada
        )[0] || {};
    Array.from(formasDePagamentoOnline).map((pg) => (pg.active = false));
    pagamento.active = true;

    this.setState({formasDePagamentoOnline}, () =>
        this.setTipoDePagamento(pagamento.descricao, true)
    );

    let forma = Array.from(formasDePagamento).filter(f => f.descricao === formaDePagamentoSelecionada)[0];

    this.props.handlerRecebimento({
      ...forma,
      tipo: pagamento.descricao,
    });
  };

  render() {
    let {formasDePagamentoOnline, formasDePagamentoOffline} = this.state;

    let formasPagamentoOnlineSelect = this.getFormasPagamentoOnlineSelect(
        formasDePagamentoOnline
    );

    let formasPagamentoOfflineSelect = this.getformasPagamentoOfflineSelect(
        formasDePagamentoOffline
    );

    let formaPagamentoSelecionadaOnline =
        Array.from(formasDePagamentoOnline).filter((f) => f.active)[0] || {};

    let formaPagamentoSelecionadaOffline =
        Array.from(formasDePagamentoOffline).filter((f) => f.active)[0] || {};

    let pedido = this.state.pedido || {};
    let recebimentoPedido = this.state.recebimentoPedido || {};

    let bandeiraSelecionada =
        Array.from(this.getBandeirasCartao()).filter(
            (bandeira) =>
                bandeira.idEmpresaFormaPagamento ===
                recebimentoPedido.idEmpresaFormaPagamento
        )[0] || {};

    return (
        <Div inline="start" style={{marginTop: 20}} responsive="8">
          {this.handlerSocket()}
          <Div column className="align-self-start">
            <Div className="w-100">
              <Select
                  responsive="12"
                  style={{
                    border: "none",
                    borderBottom: `1px solid #f2f2f2`,
                    textAlignLast: "center",
                  }}
                  handlerChange={(e) => {
                    let tipo = e.target.value;
                    if (tipo === "ONLINE") {
                      this.setState(
                          {
                            pagamentoOnline: true,
                            pagamentoOffline: false,
                            pagamentoTipoCreditoOnline: true,
                            pagamentoTipoDebitoOnline: false,
                            pagamentoTipoQrCodePix: false,
                          },
                          () => {
                            this.props.handlerInitPagamento();
                            Array.from(formasDePagamentoOnline).map(
                                (pg) => (pg.active = false)
                            );
                            formasDePagamentoOnline[0].active = true;
                            this.setState({formasDePagamentoOnline});
                            this.props.handlerRecebimento(
                                this.getRecebimentoInicial()
                            );
                            this.props.handlerTipoPagamento(true);
                          }
                      );
                    } else {
                      this.setState(
                          {
                            pagamentoOnline: false,
                            pagamentoOffline: true,
                            pagamentoTipoCreditoOffline: false,
                            pagamentoTipoDebitoOffline: false,
                            pagamentoTipoDinheiro: true,
                            pagamentoTipoVoucher: false,
                          },
                          () => {
                            this.props.handlerInitPagamento();
                            Array.from(formasDePagamentoOffline).map(
                                (pg) => (pg.active = false)
                            );
                            formasDePagamentoOffline[0].active = true;
                            this.setState({formasDePagamentoOffline});

                            this.props.handlerRecebimento(
                                this.getRecebimentoInicial()
                            );
                            this.props.handlerTipoPagamento(false);
                          }
                      );
                    }
                  }}
                  values={this.getTipoPagamentoOnlineOffline()}
              />
            </Div>

            <If and value1={this.state.pagamentoOnline}>
              <Select
                  responsive="12"
                  handlerChange={(e) => {
                    this.mudarFormaDePagamentoOnline(e.target.value, recebimentoPedido);
                  }}
                  style={{
                    border: "none",
                    borderBottom: `1px solid #f2f2f2`,
                    textAlignLast: "center",
                  }}
                  values={formasPagamentoOnlineSelect}
                  valueSelected={formaPagamentoSelecionadaOnline.descricao}
              />
              <If
                  and
                  value1={
                    this.state.pagamentoTipoCreditoOnline ||
                    this.state.pagamentoTipoDebitoOnline
                  }
              >
                <TemplateCartao
                    tipoPagamento={this.getTipoPagamento()}
                    bandeiras={this.getBandeirasCartao()}
                    bandeirasSelect={this.getBandeirasSelect()}
                    pedido={this.props.pedido}
                    recebimentoPedido={this.props.recebimentoPedido}
                    handlerRecebimento={this.props.handlerRecebimento}
                    handlerSelecionarBandeira={(bandeira) =>
                        this.selecionarBandeiraCartao(bandeira)
                    }
                />
              </If>
              <Div col="12">
                <If and value1={this.state.pagamentoTipoCreditoOnline}>
                  <Select
                      padding="0"
                      responsive="12"
                      colorLabel={Color.ECOMMERCE.CINZA_ESCURO}
                      label="Número de parcelas"
                      values={this.getQuantidadeParcelamentoCredito()}
                      handlerChange={(e) => {
                        this.props.handlerRecebimento({
                          ...recebimentoPedido,
                          quantidadeParcelas: e.target.value,
                        });
                      }}
                      style={{
                        border: "none",
                        borderBottom: `1px solid #f2f2f2`,
                        textAlignLast: "center",
                      }}
                  />
                </If>
              </Div>

              <If and value1={this.state.pagamentoTipoQrCodePix}>
                <Div inline="center" col="12" marginTop="4">
                  <PixQrCode
                      idTransacaoPedido={this.getIdTransacaoPedido()}
                      valor={this.getValorTotalPedido()}
                      empresa={this.props.empresa}
                  />
                </Div>
              </If>
              <If
                  and
                  value1={this.state.pagamentoTipoDebitoOnline}
                  value2={false}
              >
                <Div inline="center" col="12" marginTop="4">
                  <Label
                      value="PAGAMENTO DO TIPO DÉBITO INDISPONÍVEL"
                      family="Light"
                  />
                </Div>
              </If>
            </If>

            <If and value1={this.state.pagamentoOffline}>
              <Div marginTop="2" inline="center" className="w-100">
                <Div col="12" padding="0">
                  <Select
                      responsive="12"
                      handlerChange={(e) => {
                        Array.from(formasDePagamentoOffline).map(
                            (pg) => (pg.active = false)
                        );

                        let pagamento = Array.from(formasDePagamentoOffline).filter(
                            (f) => f.descricao === e.target.value
                        )[0];

                        let isDinheiro = pagamento.descricao === "DINHEIRO";

                        pagamento.active = true;

                        this.setState({formasDePagamentoOffline}, () =>
                            this.setTipoDePagamento(pagamento.descricao, false)
                        );

                        let idEmpresaFormaPagamento = isDinheiro
                            ? pagamento.idEmpresaFormaPagamento
                            : recebimentoPedido.idEmpresaFormaPagamento;

                        let bandeiraCartao = isDinheiro
                            ? null
                            : recebimentoPedido.bandeiraCartao;

                        this.props.handlerRecebimento({
                          ...recebimentoPedido,
                          tipo: pagamento.descricao,
                          bandeiraCartao,
                          idEmpresaFormaPagamento,
                        });
                      }}
                      style={{
                        border: "none",
                        borderBottom: `1px solid #f2f2f2`,
                      }}
                      values={formasPagamentoOfflineSelect}
                      label="Forma de pagamento"
                      valueSelected={formaPagamentoSelecionadaOffline.descricao}
                  />
                </Div>
                <If and value1={this.state.pagamentoTipoVoucher}>
                  <Div inline="center" responsive="12" marginTop="4">
                    <Label
                        value="PAGAMENTO DO TIPO VOUCHER INDISPONÍVEL"
                        family="Light"
                    />
                  </Div>
                </If>

                <If and value1={this.state.pagamentoTipoDinheiro}>
                  <Div inline="center" marginTop="4" className="w-100">
                    <Div responsive="12" padding="0">
                      <InputInteger
                          responsive="12"
                          label="Precisa de troco?"
                          placeholder="Levar troco para"
                          value={pedido.levarTrocoPara}
                          handlerChange={(e) => {
                            this.props.handlerPedido({
                              ...pedido,
                              levarTrocoPara: e.target.value,
                            });
                          }}
                          style={{
                            border: "none",
                            borderBottom: `1px solid #f2f2f2`,
                          }}
                      />
                    </Div>
                  </Div>
                </If>

                <If
                    and
                    value1={
                      this.state.pagamentoTipoCreditoOffline ||
                      this.state.pagamentoTipoDebitoOffline
                    }
                >
                  <Div inline="center" className="w-100">
                    <Div className="w-100">
                      <Div inline="end">
                        <Select
                            responsive="12"
                            values={this.getBandeirasCartaoSelect()}
                            handlerChange={(e) => {
                              let bandeira = Array.from(
                                  this.getBandeirasCartao()
                              ).filter(
                                  (bandeira) =>
                                      bandeira.idEmpresaFormaPagamento ===
                                      e.target.value
                              )[0];

                              this.props.handlerRecebimento({
                                ...recebimentoPedido,
                                idEmpresaFormaPagamento:
                                bandeira.idEmpresaFormaPagamento,
                                bandeiraCartao:
                                    bandeira.tipoBandeira || bandeira.descricao,
                              });
                            }}
                            style={{
                              border: "none",
                              borderBottom: `1px solid #f2f2f2`,
                            }}
                        />
                        <Image
                            src={bandeiraSelecionada.imagem}
                            width="40px"
                            marginBottom="3"
                            style={
                              Utils.isViewMobile()
                                  ? {position: "absolute", left: 35}
                                  : {
                                    position: "absolute",
                                    transform: `translateX(-40px)`,
                                  }
                            }
                        />
                      </Div>
                      <If and value1={this.state.pagamentoTipoCreditoOffline}>
                        <Select
                            responsive="12"
                            colorLabel={Color.ECOMMERCE.CINZA_ESCURO}
                            label="Número de parcelas"
                            values={this.getQuantidadeParcelamentoCredito()}
                            handlerChange={(e) => {
                              this.props.handlerRecebimento({
                                ...recebimentoPedido,
                                quantidadeParcelas: e.target.value,
                              });
                            }}
                            style={{
                              border: "none",
                              borderBottom: `1px solid #f2f2f2`,
                              textAlignLast: "center",
                            }}
                        />
                      </If>
                    </Div>
                  </Div>
                </If>
              </Div>
            </If>
          </Div>

          <If
              and
              value1={
                this.state.pagamentoOnline &&
                (this.state.pagamentoTipoCreditoOnline ||
                    this.state.pagamentoTipoDebitoOnline)
              }
          >
            <Div responsive="4" marginLeft="4" inline="center" className="align-self-start">
              <TemplateCartaoInformativo/>
            </Div>
          </If>
        </Div>
    );
  }
}

export default TemplatePagamento;
