import React, { Component } from "react";
import {
  Div,
  I,
  Label,
  LabelHover,
  Image,
  ModalNotification,
  If,
} from "../../../../components";

import {
  Color,
  EndPoints,
  Http,
  Type,
  Utils,
  Routes,
} from "../../../../utilities";

import ProdutoUtils from '../../ProdutoUtils'
import "../../produtos.css";
import { connect } from "react-redux";
import CarouselProdutoGenericoNovo from "../../../../components/carousel/CarouselProdutoGenericoNovo";
import CarouselTagsListasSugeridas from "../../../../components/carousel/CarouselTagsListasSugeridas";

import ListasCombos from "../../../../assets/images/listascombos1.webp";
import ListaCompraSugeridaDetalhe from "./ListaCompraSugeridaDetalhe";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import EmpresaUtils from "../../../utils/EmpresaUtils";
import AssinaturaService from "../../../../service/AssinaturaService";

const styles = {
  itemListaActive: {
    backgroundColor: Color.ECOMMERCE.WHITE,
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 18,
    marginLeft: 10,
    padding: 10,
    borderRadius: 10,
  },
  itemLista: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 18,
    marginLeft: 10,
    padding: 10,
    borderRadius: 10,
  },
};

class ListasComprasSugeridas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listasSugeridas: [],
      sugeridas: true,
      minhasListas: false,
      itemActive: "SUGERIDAS",
      listasTags: new Map(),
    };
  }

  componentDidMount() {
    this.listasListasSugeridas();
  }


  listasListasSugeridas() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    Http.get( cliente ? `/assinatura/listacompra/sugeridas/cliente/${cliente.idCliente}` : EndPoints.LISTA_COMPRA_SUGERIDAS)
      .then(({ status, data }) => {
        if (status) {
          Array.from(data).forEach((produto) => {
            Array.from(produto.itens).forEach((item) => {
              item.quantidadeInicial = Number(item.quantidade);
              item.valorSubTotalItem = Number(item.preco);
              item.quantidade = ProdutoUtils.getQuantidadeItemView(item);
            });
          });

          let listasTags = Utils.groupBy(
            JSON.parse(JSON.stringify(data)),
            "tag"
          );

          this.setState({ listasTags, listasSugeridas: data });
          
        }
      })
      .catch((erro) => console.log(erro));
  }

  getListaTagSugerida() {
    let { listasSugeridas, sugeridas, itemActive, listasTags } = this.state;
    let listas = new Map(listasTags);

    return sugeridas ? listasSugeridas : listas.get(itemActive);
  }

  getProdutosListasSugeridas = () => {
    let { sugeridas, minhasListas } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let itens = [];

    if (sugeridas || (!sugeridas && !minhasListas)) {
      let sugeridasTag = this.getListaTagSugerida();
         
      Array.from(sugeridasTag).forEach((prod, index) => {
        let pausado = prod.assinatura ? prod.assinatura.pausado : false

        itens.push(
          <Div column key={index}>
            <Image
              src={prod.urlFoto}
              handlerClick={() =>
                this.visualizarListaCompra(JSON.parse(JSON.stringify(prod)))
              }
              styleImage={{
                width: 250,
                height: 250,
                borderRadius: 5,
                marginLeft: 10,
              }}
              pointer
            />
            <If and value1={prod.assinatura}>
              <Label
                value={ !pausado ?  `ASSINATURA ${prod.assinatura ? prod.assinatura.tipo : ""}` : "ASSINATURA PAUSADA"}
                style={{
                  fontSize: 15,
                  color: !pausado ? Color.RGBA.VERDE : Color.ECOMMERCE.LARANJA_ESCUDO,
                  marginTop: 10,
                }}
                family="Bold"
              />
            </If>
            <Label
              value={Utils.capitalize(prod.descricao)}
              style={{
                fontSize: 17,
                color: Color.ECOMMERCE.CINZA_ESCURO,
                marginTop: prod.assinatura ? "-5px" : 10,
              }}
              family="Bold"
            />
          </Div>
        );
      });
    } else if (minhasListas) {
      Array.from(this.props.listasDeCompra).forEach((lista, index) => {
        itens.push(
          <Div
            className="d-flex flex-column"
            style={{ width: "100%", marginTop: 10 }}
          >
            <LabelHover
              value={Utils.capitalize(lista.descricao)}
              pointer
              style={styles.itemListaActive}
              styleHover={{
                ...styles.itemListaActive,
                color: "white",
                backgroundColor: corPrimaria,
              }}
              family="Bold"
              handlerClick={() => this.visualizarListaCompra(lista)}
            />
          </Div>
        );
      });
    }

    return itens;
  };

  visualizarListaCompra = (lista) => {
    //console.log(JSON.stringify(lista))
    ModalNotification.render(
      "Informações da lista sugerida", 
      <ListaCompraSugeridaDetalhe
        produto={lista}
        handlerAddItem={(produto) => this.props.handlerAddItemCarrinho(produto)}
        history={this.props.history}
        empresa={this.props.empresa}
      />
    );
  };

  getAgrupamentosListas = () => {
    let itemActive = this.state.itemActive;
    let listasTags = new Map(this.state.listasTags);

    let itens = this.props.listaCompraCliente ? [
      {
        value: "Sugeridas",
        target: "SUGERIDAS",
        active: itemActive === "SUGERIDAS",
        icon: Type.ICON.SHOOPPING_BAG,
      },]
      : [
        {
          value: "Sugeridas",
          target: "SUGERIDAS",
          active: itemActive === "SUGERIDAS",
          icon: Type.ICON.SHOOPPING_BAG,
        },
        {
          value: "Minhas listas",
          target: "MINHAS_LISTAS",
          active: itemActive === "MINHAS_LISTAS",
          icon: Type.ICON.LIST_ALT,
        },
      ];

    Array.from(listasTags.keys()).forEach((key) => {
      itens.push({
        value: Utils.capitalize(key),
        target: key,
        active: itemActive === key,
      });
    });

    return itens;
  };

  renderAgrupamentosListas(usuarioLogado) {
    let tags = this.getAgrupamentosListas();

    if (!usuarioLogado) {
      tags.splice(1, 1);
    }

    if (tags.length > 4) {
      let itensRender = [];
      tags.forEach((item, index) => {
        itensRender.push(
          <Label
            pointer
            family="Light"
            handlerClick={() =>
              this.setState({
                sugeridas: item.target === "SUGERIDAS",
                minhasListas: item.target === "MINHAS_LISTAS",
                itemActive: item.target,
              })
            }
            key={index}
            style={item.active ? styles.itemListaActive : styles.itemLista}
          >
            <I
              icon={item.icon}
              style={{
                fontSize: 15,
                transform: `translateY(-2px)`,
                marginRight: 5,
              }}
            />
            {item.value}
          </Label>
        );
      });

      return (
        <CarouselTagsListasSugeridas
          itens={itensRender}
          slidesPerPage={4}
          arrowRight
        />
      );
    } else {
      return tags.map((item, index) => {
        return (
          <Label
            pointer
            family="Light"
            handlerClick={() =>
              this.setState({
                sugeridas: item.target === "SUGERIDAS",
                minhasListas: item.target === "MINHAS_LISTAS",
                itemActive: item.target,
              })
            }
            key={index}
            style={item.active ? styles.itemListaActive : styles.itemLista}
          >
            <I
              icon={item.icon}
              style={{
                fontSize: 15,
                transform: `translateY(-2px)`,
                marginRight: 5,
              }}
            />
            {item.value}
          </Label>
        );
      });
    }
  }

  render() {
    let listas = this.getProdutosListasSugeridas();
    let usuarioLogado = Utils.isValueValid(UsuarioUtils.getUsuarioLojaWeb());
    let { minhasListas, sugeridas } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    let sugeridasTag = sugeridas || (!sugeridas && !minhasListas);

    return (
      Utils.isValueValid(this.state.listasSugeridas) && (
        <Div
          style={{
            width: 1150,
            marginBottom: 30,
            backgroundColor: "#E5E5E5",
            borderRadius: 10,
            padding: 40,
          }}
        >
          <Div inline="start" col="12">
            <Div inline="start">
              <I
                icon={Type.ICON.LIST_UL}
                style={{
                  fontSize: 25,
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  marginBottom: 10,
                }}
              />
              <Label
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: "25px",
                  marginLeft: 10,
                }}
                family="Bold"
                value="LISTAS SUGERIDAS"
              />
            </Div>

            <Div inline="start" style={{ width: "70%", marginLeft: 10 }}>
              {this.renderAgrupamentosListas(usuarioLogado)}
            </Div>
          </Div>
          <Div inline="start">
            <Div className="d-flex align-self-start">
              <Image
                src={ListasCombos}
                styleImage={{ width: 450, height: 250 }}
              />
              {/**
                *  <Label
                value="Monte suas listas e receba em casa"
                family="Light"
                style={{
                  color: "white",
                  width: 250,
                  fontSize: 25,
                  position: "absolute",
                  transform: `translateY(50px)`,
                  marginLeft: 50,
                }}
              />
              <Label
                value="Nas listas sugeridas da loja você pode optar por assinar exatamente como são oferecidas"
                family="Light"
                style={{
                  color: "white",
                  width: 300,
                  fontSize: 15,
                  position: "absolute",
                  transform: `translateY(150px)`,
                  marginLeft: 50,
                  textAlign: "jutify",
                }}
              />
                */}
            </Div>
            <Div
              style={{ width: 600, marginLeft: 10 }}
              className="d-flex align-self-start"
            >
              <If and value1={minhasListas && listas.length === 0}>
                <Div column style={{ width: "100%", marginTop: 20 }}>
                  <Label
                    value="Você não possui nenhum lista cadastrada!"
                    family="Light"
                    style={{
                      fontSize: 20,
                      color: Color.ECOMMERCE.CINZA_ESCURO,
                    }}
                  />
                  <Div
                    inline="center"
                    style={{
                      fontSize: 20,
                      borderRadius: 5,
                      padding: 10,
                      border: `1px solid ${corPrimaria}`,
                      backgroundColor: "white",
                      color: corPrimaria,
                    }}
                    handlerClick={() => {
                      this.props.history.push(Routes.LISTAS_COMPRAS);
                    }}
                  >
                    <I
                      icon={Type.ICON.PLUS_SQUARE}
                      pointer
                      style={{
                        fontSize: 25,
                        marginBottom: 10,
                        marginRight: 10,
                      }}
                    />
                    <Label
                      value="Criar uma lista de compras"
                      family="Light"
                      pointer
                    />
                  </Div>
                </Div>
              </If>
              <If and value1={minhasListas && listas.length > 0}>
                <Div
                  column
                  style={{
                    width: "100%",
                    height: 250,
                    padding: 10,
                    overflow: "auto",
                  }}
                >
                  {listas.map((lista) => lista)}
                </Div>
              </If>
              <If and value1={sugeridasTag && listas.length > 2}>
                <CarouselProdutoGenericoNovo
                  itens={listas}
                  slidesPerPage={2}
                  arrowRight
                />
              </If>
              <If and value1={sugeridasTag && listas.length === 1}>
                {listas[0]}
              </If>
              <If and value1={sugeridasTag && listas.length === 2}>
                {listas[0]}
                {listas[1]}
              </If>
            </Div>
          </Div>
        </Div>
      )
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
    ...store.listasDeCompraState,
    ...store.empresaState,
  };
};

export default connect(mapStateToProps)(ListasComprasSugeridas);
