import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  InputDefault,
  ModalCustom,
  ModalNotification,
  Notification,
  Row,
  Select,
  SelectValue,
  Table,
} from "../../components";
import ConcorrentesService from "../../service/ConcorrentesService";
import DepartamentoService from "../../service/DepartamentoService";
import ProdutosService from "../../service/ProdutosService";
import { Http, Type, } from "../../utilities";
import TemplateFilterProdutos from "./template/TemplateFilterProdutos";

class InserirEditarProdutos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      InputProdutoPreco: {},
      produtosSlice: [],
      filtros: {
        departamentos: [new SelectValue("", "SELECIONE", true)],
        grupos: [new SelectValue("", "SELECIONE", true)],
      },
      produtosSelecionados: [],
      departamentos: [],
      departamentosSelecionados: [],
      grupos: [],
      idDepartamento: "",
      idGrupo: "",
      indexGrupo: 0,
      closeModal: false,
      idGestorCompras: "",
      selecionarTodos: false,
      user: {},
      paginacao: 0,
      concorrentes: [],
      concorrenteSelecionado: null,
      dataPadrao: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.buscarProdutos(1);
    this.getDataDepartamentos();
    this.getDataConcorrentes();
  }

  getDataConcorrentes() {
    ConcorrentesService.getConcorrentes().then(({ status, data }) => {
      if (status) {
        this.setState({ concorrentes: data });
      }
    })
  }

  getTipoPesquisaSelect() {
    let selectValue = [new SelectValue("", "SELECIONE", true)];

    this.state.concorrentes.forEach((item) => {
      selectValue.push(
        new SelectValue(item.idConcorrente, item.descricao, false)
      );
    });
    return selectValue;
  }

  getDataDepartamentos() {
    DepartamentoService.getDepartamentos().then(
      (data) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue("", "SELECIONE", true));
          Array.from(data).map((dep, index) => {
            depComDados.push(
              new SelectValue(
                dep.idDepartamentoWeb,
                (index + 1) + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              departamentos: depComDados,
            },
          });
        }
      }
    );
  }

  getDataSecoes(idDepartamento) {
    DepartamentoService.getSecoes(idDepartamento).then(
      (data) => {
        if (data) {
          let depComDados = [];
          depComDados.push(new SelectValue("", "SELECIONE", true));
          Array.from(data).map((dep, index) => {
            depComDados.push(
              new SelectValue(
                dep.idDepartamentoSecao,
                (index + 1) + " - " + dep.descricao,
                false
              )
            );
          });
          this.setState({
            filtros: {
              ...this.state.filtros,
              grupos: depComDados,
            },
          });
        }
      }
    );
  }

  async buscarProdutos(paginacao) {
    const { idDepartamento, idGrupo } = this.state;
    let obj = {
      numeroPagina: paginacao
    }

    if (idDepartamento !== "") {
      obj = {
        numeroPagina: paginacao,
        departamentos: [{ idFiltro: this.state.idDepartamento }],
      };
    }
    if (idDepartamento !== "" && idGrupo !== "") {
      obj = {
        numeroPagina: paginacao,
        departamentos: [{ idFiltro: this.state.idDepartamento }],
        secoes: [{ idFiltro: this.state.idGrupo }],
      };
    }

    await ProdutosService.buscarProdutos(obj).then(({ status, data, mensagem }) => {
      if (status) {
        this.setState({
          produtos: data,
          produtosSlice: data.length > 100 ? data.slice(0, 100) : data
        })
      }
    })
  }

  handlerInputProdutoPreco(data) {
    this.setState({
      InputProdutoPreco: data
    })
  }

  inserirProdutosPesquisa() {
    const { produtosSelecionados, concorrenteSelecionado, dataPadrao } = this.state;
    let validPrecoVenda = true;

    let newConcorrentesPrecos = produtosSelecionados.map(produto => {
      if (!produto.precoVenda) {
        validPrecoVenda = false
      }

      let obj = {
        idConcorrente: concorrenteSelecionado,
        idProdutoWeb: produto.idProdutoWeb,
        precoVenda: produto.precovendaconcorrente,
        dataExpiracao: produto.dataexpiracao ? produto.dataexpiracao + " 01:00:11.674" : dataPadrao + " 01:00:11.674"
      }
      return obj
    });

    if (validPrecoVenda) {

      if (newConcorrentesPrecos.length <= 0) {
        return
      }
      ConcorrentesService.createConcorrentesPrecos(newConcorrentesPrecos).then(({ data, status }) => {
        if (status) {
          Notification.success(data.mensagem);
          this.props.setLoadingProdutosPreco(true);
          this.setState({
            produtosSelecionados: [],
          })
        } else {
          Notification.danger(data.mensagem);
        }
      })
    } else {
      Notification.danger("Preço de venda não informado!")
    }


  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      produtos: [],
      produtosSlice: [],
      produtosSelecionados: [],
    });
  };

  mudarFiltrosModal(value, type) {
    if (type === "departamento") {
      if (value != "") {
        this.getDataSecoes(value);
      }
      this.setState(
        {
          idDepartamento: value,
          idGrupo: "",
          produtos: [],
          produtosSlice: [],
        },
        () =>
          value != ""
            ? this.buscarProdutos(1)
            : {}
      );
    }

    if (type === "grupo") {
      this.setState(
        {
          idGrupo: value,
          produtos: [],
          produtosSlice: [],
        },
        () => this.buscarProdutos(1)
      );
    }
  }

  async deletarProduto(produtoPesquisa) {
    produtoPesquisa.idUsuarioInativacao = this.props.idUsuario;
    await Http.delete(`/pesquisa/produto`, produtoPesquisa)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);

        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  deletarProdutoPesquisa(produtoPesquisa) {
    let deleteProduto = [];

    if (!!produtoPesquisa.length) {
      deleteProduto = [...produtoPesquisa];
    } else {
      deleteProduto = [produtoPesquisa];
    }

    ModalNotification.confirm(
      "Deseja remover este Produto? ",
      "Este Produto será removido da pesquisa!",
      () => this.deletarProduto(deleteProduto)
    );
  }

  deletarTodosProdutosPesquisa(produtosSelecionados) {
    ModalNotification.confirm(
      "Deseja remover todos os Produtos selecionados? ",
      "Estes Produtos serão removidos da pesquisa!",
      () => {
        this.deletarProduto(produtosSelecionados);
      }
    );

    this.setState({
      selecionarTodos: false,
    });
  }

  handlerChangeProdutoModal(e) {
    if (e.target.value === "") {
      this.buscarProdutos(1);
    }

  }

  renderFiltrosModal() {
    const { departamentos, grupos, } = this.state.filtros;

    return (
      <TemplateFilterProdutos
        departamentos={departamentos}
        grupos={grupos}
        handler={(value, type) => this.mudarFiltrosModal(value, type)}
        handlerSearch={(e) => this.getProdutosSearchModal(e)}
        handlerChange={(e) => this.handlerChangeProdutoModal(e)}
        produtosSelecionados={this.state.produtosSelecionados}
      />
    );
  }

  async getProdutosSearchModal(search) {
    const { idDepartamento, idGrupo } = this.state;
    let busca = {
      numeroPagina: 1,
      descricao: search,
    }
    if (idDepartamento !== "") {
      busca = {
        numeroPagina: 1,
        descricao: search,
        departamentos: [{ idFiltro: this.state.idDepartamento }],
      };
    }
    if (idDepartamento !== "" && idGrupo !== "") {
      busca = {
        numeroPagina: 1,
        descricao: search,
        departamentos: [{ idFiltro: this.state.idDepartamento }],
        secoes: [{ idFiltro: this.state.idGrupo }],
      };
    }


    if (search.trim() !== "") {
      await ProdutosService.buscarProdutos(busca).then(({ data, status, mensagem }) => {
        if (status) {
          this.setState({
            produtos: data,
            produtosSlice: data.length > 100 ? data.slice(0, 100) : data
          })
        } else {
          Notification.danger(mensagem);
        }
      })
    }
  }

  handleScroll = (e) => {
    const { produtosSlice, produtos } = this.state;
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let newProdutos = [];
      let start = produtosSlice.length;
      let end =
        produtos.length > 100
          ? produtosSlice.length + 100
          : produtosSlice.length - produtos.length;

      if (produtos.length >= produtosSlice.length) {
        newProdutos = [...produtosSlice, ...produtos.slice(start, end)];

        this.setState({
          produtosSlice: newProdutos,
        });
      }
    }
  };

  handlerSelectConcorrente = (e) => {
    this.setState({ concorrenteSelecionado: e.target.value })
  }

  render() {
    const {
      produtosSelecionados,
      produtosSlice,
      concorrenteSelecionado,
      dataPadrao
    } = this.state;

    return (
      <ModalCustom
        title="Produtos Disponíveis"
        showModal={this.props.closeModal}
        setShowModal={this.props.setCloseModal}
        confirm={() => this.inserirProdutosPesquisa()}
      >
        <Div
          col="12"
          style={{ overflow: "scroll", maxHeight: "75vh" }}
          handlerScroll={(e) => this.handleScroll(e)}
        >
          <Div col="12" inline>
            <Row col="12">

              <Select
                required
                responsive="4"
                label="Selecione o Concorrente"
                valueSelected={this.state.concorrenteSelecionado}
                handlerChange={(e) => {
                  this.handlerSelectConcorrente(e);
                }}
                values={this.getTipoPesquisaSelect()}
              />

              <InputDefault
                required
                label="Data de expiração"
                responsive="4"
                date="date"
                datetime="datetime"
                className="unstyled"
                max="1979-12-31"
                value={dataPadrao}
                //style={{ width: width }}
                handlerChange={(e) => {
                  this.setState({ dataPadrao: e.target.value })
                }}
              />
            </Row>
          </Div>
          {(dataPadrao && concorrenteSelecionado) && <Div>
            <Table
              columns={[
                { value: "Código de Barras", align: "center" },
                { value: "Descrição", align: "center" },
                { value: "Preço de Venda", align: "center" },
                { value: "Data de Expiração", align: "center" },

              ]}
              fields={[
                {
                  value: "codigoBarras",
                  type: Type.DADO.STRING,
                },
                {
                  value: "descricao",
                  type: Type.DADO.STRING,
                },
                {
                  input: true,
                  typeInput: "number",
                  value: "precovendaconcorrente",
                  width: "150px",
                },
                {
                  input: true,
                  typeInput: "date",
                  value: "dataexpiracao",
                  valueInput: dataPadrao
                },
              ]}
              bordered
              orientation="center"
              //pagination
              filter={this.renderFiltrosModal()}
              filterShow={true}
              data={produtosSlice}
              handlerChangeInput={(data) => {
                this.handlerInputProdutoPreco(data)
                let newProdutosSelecionados = produtosSelecionados;      
                if(!data.selected) {
                  data.selected = true;

                  if (data.selected) {
                    newProdutosSelecionados.push(data);
                  } else {
                    newProdutosSelecionados = produtosSelecionados.filter(
                      (item) => {
                        return item.codigoBarras === data.codigoBarras
                          ? false
                          : true;
                      }
                    );
                  }

                  this.setState({
                    produtosSelecionados: [...newProdutosSelecionados],
                  });
                }
              }}
              error="Nenhum produto encontrado"
              checkbox={{ nameChecked: "selected", valueChecked: true }}
              handlerCheckbox={(produto) => {
                produto.selected = !produto.selected;

                let newProdutosSelecionados = produtosSelecionados;

                if (produto.selected) {
                  newProdutosSelecionados.push(produto);
                } else {
                  newProdutosSelecionados = produtosSelecionados.filter(
                    (item) => {
                      return item.codigoBarras === produto.codigoBarras
                        ? false
                        : true;
                    }
                  );
                }

                this.setState({
                  produtosSelecionados: [...newProdutosSelecionados],
                });
              }}
            ></Table>
          </Div>}
        </Div>
      </ModalCustom>
    );
  }
}

export default InserirEditarProdutos;
