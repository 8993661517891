export default class SelectValue {
  value;
  id;
  selected;

  constructor(id, value, selected) {
    this.id = id;
    this.value = value;
    this.selected = selected;
  }

  getId() {
    return this.id
  }
}
