import React, { Component } from "react";
import { Div, I, If, Label } from "../../../components";

import ItemProdutoAbaixouPreco from "../ItemProdutoAbaixouPreco";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import { connect } from "react-redux";
import ProdutoUtils from "../ProdutoUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";

const styles = {
  title: {
    color: "#46160E",
    fontSize: "25px",
    marginLeft: 10,
  },
};

class ProdutosQueAbaixaramPreco extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
    };
  }

  componentDidMount() {
    this.listarProdutos();
  }

  listarProdutos() {
    Http.get(EndPoints.PRODUTOS_BAIXARAM_PRECO)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutosQueAbaixaramPreco = () => {
    let itens = [];

    let produtosSeparados = Utils.isValueValid(this.state.produtos)
      ? Utils.quebrarArray(this.state.produtos, 4)
      : [];

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="center" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <Div className="d-flex justify-content-start align-items-start align-self-start">
                  <ItemProdutoAbaixouPreco
                    history={this.props.history}
                    key={index}
                    image={prod.urlImagemPrincipalThumbnail}
                    descricao={prod.descricao}
                    precoUnitario={prod.precoUnitario}
                    precoVenda={prod.precoVenda}
                    precoAtacado={prod.precoAtacado}
                    codigoBarras={prod.codigoBarras}
                    handlerClick={(updateItem) =>
                      this.props.handlerClick(prod, updateItem)
                    }
                    quantidade={prod.quantidade}
                    disponivel={prod.disponivel}
                    empresa={this.props.empresa}
                    percentualEconomia={prod.percentualEconomia}
                    estoqueDisponivel={prod.estoqueDisponivel}
                    quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
                    possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                    fatorEmGrama={prod.fatorEmGrama}
                    precosConcorrentes={prod.precosConcorrentes || []}
                    handlerAddItemCarrinho={(quantidade) => {
                      prod = ProdutoUtils.calcularValorProduto(
                        prod,
                        quantidade
                      );
                      this.props.handlerAddItemCarrinho(prod);
                    }}
                  />
                </Div>
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  getProdutos() {
    return Array.from(this.state.produtos).map((prod, index) => {
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPreco
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              //prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              //this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosQueAbaixaramPreco() {
    return (
      <Div style={{ width: 1150, marginBottom: 30 }}>
        <Div inline="start" col="12">
          <I
            icon={Type.ICON.LEVELDOWN}
            style={{
              fontSize: 25,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 15,
            }}
          />
          <Label
            style={{
              color: "#878787",
              fontSize: "25px",
              marginLeft: 10,
            }}
            family="Bold"
            value="PRODUTOS QUE ABAIXARAM O PREÇO"
          />
        </Div>
        <CarouselProdutoGenericoNovo
          timeSlide={7000}
          itens={this.getProdutos()}
          slidesPerPage={4}
          arrows
        />
      </Div>
    );
  }

  render() {
    return (
      <If and value1={Utils.isValueValid()}>
        {this.renderProdutosQueAbaixaramPreco()}
      </If>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
  };
};

export default connect(mapStateToProps)(ProdutosQueAbaixaramPreco);
