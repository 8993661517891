import React, { Component } from "react";

import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import { connect } from "react-redux";
import ItemProdutoAbaixouPrecoMobile from "../ItemProdutoAbaixouPrecoMobile";
import CarouselProdutosGenericoMobile from "../../../components/carousel/CarouselProdutosGenericoMobile";
import ProdutoUtils from "../ProdutoUtils";
import { Div, I, If, Label } from "../../../components";
import SpaceUtils from "../../utils/SpaceUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";

const styles = {
  title: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 12,
    marginLeft: 5,
  },
};

const WIDTH_CONTAINER = SpaceUtils.MOBILE_CONTAINER_ATUAL;

class ProdutosQueAbaixaramPrecoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
    };
  }

  componentDidMount() {
    this.listarProdutos();
  }

  listarProdutos() {
    Http.get(EndPoints.PRODUTOS_BAIXARAM_PRECO)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutosQueAbaixaramPreco = () => {
    let itens = [];

    let produtosSeparados = Utils.isValueValid(this.state.produtos)
      ? Utils.quebrarArray(
          this.props.produtos,
          SpaceUtils.getQuantidadeItensView()
        )
      : [];

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="start" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <ItemProdutoAbaixouPrecoMobile
                  history={this.props.history}
                  key={index}
                  image={prod.urlImagemPrincipalThumbnail}
                  descricao={prod.descricao}
                  precoUnitario={prod.precoUnitario}
                  precoVenda={prod.precoVenda}
                  precoAtacado={prod.precoAtacado}
                  codigoBarras={prod.codigoBarras}
                  handlerClick={(updateItem) =>
                    this.props.handlerClick(prod, updateItem)
                  }
                  quantidade={prod.quantidade}
                  disponivel={prod.disponivel}
                  empresa={this.props.empresa}
                  percentualEconomia={prod.percentualEconomia}
                  estoqueDisponivel={prod.estoqueDisponivel}
                  quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
                  possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                  fatorEmGrama={prod.fatorEmGrama}
                  handlerAddItemCarrinho={(quantidade) => {
                    prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                    this.props.handlerAddItemCarrinho(prod);
                  }}
                />
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  getProdutos() {
    return Array.from(this.state.produtos).map((prod, index) => {
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPrecoMobile
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosQueAbaixaramPreco() {
    return (
      <Div style={{ width: WIDTH_CONTAINER }}>
        <Div inline="center" col="12">
          <I
            icon={Type.ICON.LEVELDOWN}
            style={{
              fontSize: 15,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 10,
            }}
          />
          <Label
            value="PRODUTOS QUE ABAIXARAM O PREÇO"
            style={styles.title}
            family="Bold"
          />
        </Div>
        <CarouselProdutoGenericoNovo
          timeSlide={7000}
          isMobile
          itens={this.getProdutos()}
          slidesPerPage={SpaceUtils.getQuantidadeItensView()}
          offset={30}
          isMobile
          arrows
        />
      </Div>
    );
  }

  render() {
    return (
      <If and value1={Utils.isValueValid(this.state.produtos)}>
        {this.renderProdutosQueAbaixaramPreco()}
      </If>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
  };
};

export default connect(mapStateToProps)(ProdutosQueAbaixaramPrecoMobile);
