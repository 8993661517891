import React from "react";
import ReactDOM from "react-dom";
import { Spinner } from "../../components";

export default class Loading {
  static ID = "loading-root";

  static show() {
    ReactDOM.render(<Spinner />, document.getElementById(Loading.ID));
  }

  static hide() {
    ReactDOM.unmountComponentAtNode(document.getElementById(Loading.ID));
  }

  static showTime() {
    setTimeout(() => {
      ReactDOM.render(<Spinner />, document.getElementById(Loading.ID));
    }, 200);
  }

  static hideTime() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(document.getElementById(Loading.ID));
    }, 200);
  }
}
