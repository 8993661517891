import React, { Component } from "react";

import {
  Form,
  Row,
  Label,
  Notification,
  SectionForm,
  Button,
  InputInteger,
  Table,
  Div,
  InputNumberBR,
} from "../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import HorariosService from "../../service/HorariosService";
import { Type, Utils } from "../../utilities";
import HorarioEditarTaxas from "./HorarioEditarTaxas";


class HorarioTaxas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kmInicial: 0,
      kmFinal: 0,
      valorTaxaEntrega: 0,
      horarios: [],
      closeModal: false,
      loadingHorarios: false,
      idEmpresaTaxaEntrega: "",
    };

  }

  UNSAFE_componentWillMount() {
    this.getHorarios();
  }


  componentDidUpdate(prevState) {
    if (this.state.loadingHorarios) {
      this.getHorarios();
    }
  }

  async getHorarios() {
    await HorariosService.getHorariosTaxas().then(({ status, data }) => {

      if (status) {
        this.setState({
          horarios: data,
          loadingHorarios: false,
        })
      }
    })
  }

  setLoadingHorarios = (boolean) => {
    this.setState({
      loadingHorarios: boolean
    })
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
      valorTaxaEntrega: 0
    });
  };


  addNewHorario(e) {
    e.preventDefault();
    const { kmInicial, kmFinal, valorTaxaEntrega } = this.state;
    let newHorario = {
      idEmpresaTaxaEntrega: Utils.uuidv4(),
      kmInicial,
      kmFinal,
      ativo: true,
      valorTaxaEntrega,
    }

    this.cadastrarHorario([newHorario]);
  }


  editarHorario(horario) {
    this.setState({
      kmInicial: horario.kmInicial,
      kmFinal: horario.kmFinal,
      valorTaxaEntrega: horario.valorTaxaEntrega,
      closeModal: true,
      idEmpresaTaxaEntrega: horario.idEmpresaTaxaEntrega,
    })
  }

  cadastrarHorario(horario) {
    HorariosService.createHorariosTaxas(horario).then(({ data }) => {
      if (data.status) {
        Notification.success(data.mensagem);
        this.getHorarios();
      } else {
        Notification.danger(data.mensagem);
      }
    });
  }

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({  [name]: value  });
  };

  render() {

    return (
      <>
        <HorarioEditarTaxas
          closeModal={this.state.closeModal}
          setCloseModal={this.setCloseModal}
          setLoadingHorarios={this.setLoadingHorarios}
          kmInicial={this.state.kmInicial}
          kmFinal={this.state.kmFinal}
          valorTaxaEntrega={this.state.valorTaxaEntrega}
          idEmpresaTaxaEntrega={this.state.idEmpresaTaxaEntrega}
        />
        <Form id="formCadastroHorario" name="formCadastroHorario" handlerSubmit={e => e.preventDefault()}>
          <SectionForm title="Taxas de Horários" borderLeft="primary">

            <Row col="12" style={{ marginTop: "25px" }}>
              <Label
                col="12"
                value="Adicionar Taxas"
              ></Label>
            </Row>

            <Row col="12">
              <InputNumberBR
                //placeholder="número max"
                responsive="3"
                isInputGroup={false}
                label="Km Inicial"
                name="kmInicial"
                value={this.state.kmInicial}
                handlerChange={
                  e => this.setState({
                    kmInicial: e.target.value,
                  })}
              />
              <InputNumberBR
                //placeholder="número max"
                responsive="3"
                isInputGroup={false}
                label="Km Final"
                name="kmFinal"
                value={this.state.kmFinal}
                handlerChange={
                  e => this.setState({
                    kmFinal: e.target.value,
                  })}
              />
              <InputNumberBR
                //placeholder="número max"
                responsive="3"
                isInputGroup={false}
                label="Valor da Taxa de Entrega"
                name="valorTaxaEntrega"
                value={this.state.valorTaxaEntrega}
                handlerChange={
                  e => this.setState({
                    valorTaxaEntrega: e.target.value,
                  })}
              />
              <Div
                col="3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "17px"
                }}
              >
                <Button
                  type="button"
                  responsive="12"
                  value="Adicionar"
                  btn="primary"
                  float="right"
                  handlerClick={(e) => this.addNewHorario(e)}
                ></Button>
              </Div>

            </Row>

            <Div padding="2">
              <Table
                columns={[
                  { value: "Km Inicial", align: "center" },
                  { value: "Km Final", align: "center" },
                  { value: "Valor da Taxa de Entrega", align: "center" },
                  { value: "Status", align: "center" }
                ]}
                fields={[
                  {
                    value: "kmInicial",
                    type: Type.DADO.CURRENCY,
                  },
                  {
                    value: "kmFinal",
                    type: Type.DADO.CURRENCY,
                  },
                  {
                    value: "valorTaxaEntrega",
                    type: Type.DADO.CURRENCY,
                  },
                  {
                    value: "ativo",
                    type: Type.DADO.BOOLEAN,
                    valueTrue: "ATIVO",
                    valueFalse: "DESATIVADO"
                  },
                ]}
                bordered
                orientation="center"
                pagination
                edit
                sizeEdit="2"
                handlerEdit={(rede) => this.editarHorario(rede)}
                data={this.state.horarios}
                error="Nenhum horário adicionado!"
              ></Table>
            </Div>
          </SectionForm>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  ...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setEmpresa },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HorarioTaxas);
