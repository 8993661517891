import React, { Component } from "react";
import ProdutoUtils from "../ProdutoUtils";
import {
  Div,
  I,
  If,
  Image,
  Label,
  ModalNotification,
  TooltipText,
} from "../../../components";
import { Color, Mask, Type, Utils } from "../../../utilities";
import LoginCliente from "../../cliente/LoginCliente";
import RecuperarSenha from "../../cliente/RecuperarSenha";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import UsuarioUtils from "../../utils/UsuarioUtils";

import "./produtocombo.css";

const WIDTH = 250;
const HEIGHT = 250;

const styles = {
  descricao: {
    fontSize: "16px",
    lineHeight: "16px",
    color: "black",
    height: 40,
  },
  precoUnitario: {
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    borderRadius: 5,
    lineHeight: "20px",
    textDecoration: "line-through",
  },
  precoVenda: {
    borderRadius: 5,
    lineHeight: "5px",
    color: Color.ECOMMERCE.LARANJA,
    width: 100,
    fontWeight: "bold",
    fontSize: 20,
  },
  precoVendaIndisponivel: {
    textDecoration: "line-through",
  },
  precoAtacado: {
    color: "#46160E",
    lineHeight: "13px",
    fontSize: 13,
  },
  label: {
    marginLeft: 10,
    marginTop: 100,
    color: Color.ECOMMERCE.LARANJA,
    width: WIDTH,
    fontWeight: "bold",
  },
  containerPercentualEconomia: {
    backgroundColor: "#31CDFF",
    color: "white",
    borderRadius: 50,
    padding: 5,
    position: "absolute",
    transform: "translate(50px, -80px)",
    textAlign: "center",
  },
};

class ItemProdutoCombo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      quantidade: 0,
      imagemDefault: null,
    };

    this.thisUpdate = this.thisUpdate.bind(this);

    document.addEventListener("alterarQuantidadeProduto", (event) => {
      let details = event.detail;
      if (
        Utils.isValueValid(details) &&
        String(details.codigoBarras) === String(props.codigoBarras)
      ) {
        this.consultarProdutoCarrinho(this.props.codigoBarras);
      }
    });

    this.adicionarItem = this.adicionarItem.bind(this);
    this.removerItem = this.removerItem.bind(this);
  }

  componentDidMount() {
    this.consultarProdutoCarrinho(this.props.codigoBarras);
  }

  componentWillReceiveProps(props) {
    this.setState({ imagemDefault: null });
  }

  getDescricaoProduto(descricao) {
    let descricoes = String(descricao).split(" ");
    let descricaoNormal = "";

    Array.from(descricoes).forEach((desc, index) => {
      if (index < 4) {
        descricaoNormal = descricaoNormal.concat(" ").concat(desc);
      }
    });

    return descricaoNormal;
  }

  usuarioEstaLogado() {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    return Utils.isValueValid(usuario);
  }

  renderViewRecuperarSenha() {
    ModalNotification.renderModalLogin(
      <Label
        className="modal-title"
        style={{
          color: Color.ECOMMERCE.LARANJA,
          fontSize: 25,
          marginTop: 20,
        }}
        family="Bold"
        value="Recuperar senha"
      />,
      <RecuperarSenha />
    );
  }

  renderViewLogin(callback) {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: Color.ECOMMERCE.LARANJA,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Dados de acesso"
        />
        <Label
          value="Esqueci meus dados!"
          style={{
            color: Color.EMPRESA.TERCIARIA,
          }}
          className="hover-link"
          family="Light"
          handlerClick={() => this.renderViewRecuperarSenha()}
        />
      </>,
      <LoginCliente
        handlerConfirm={() => {
          callback();
          setTimeout(() => {
            Utils.refreshPage();
          }, 100);
        }}
        empresa={this.props.empresa}
        {...this.props}
      />
    );
  }

  consultarProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();
    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];
      if (Utils.isValueValid(produtoNoCarrinho)) {
        this.setState({ quantidade: produtoNoCarrinho.quantidade });
      } else {
        this.setState({ quantidade: 0 });
      }
    } else {
      this.setState({ quantidade: 0 });
    }
  }

  isProdutoValido(produto) {
    return ProdutoUtils.validarPreco(
      produto,
      "Produto indisponível , não possui preço de venda"
    );
  }

  renderProdutoIndisponivel() {
    return (
      <Div
        className="view-opaca-item-combo"
        style={{
          width: WIDTH,
          borderRadius: 10,
          height: HEIGHT,
        }}
      >
        <Div
          style={{
            position: "absolute",
            top: 100,
            left: WIDTH / 4.5,
          }}
        >
          <Label
            value="Indisponível"
            style={{ color: Color.ECOMMERCE.WHITE, fontSize: 25 }}
            family="Bold"
            pointer
          />
        </Div>
      </Div>
    );
  }

  addQuantidadeItem(possuiQuantidadeFracionada, fatorEmGrama) {
    let quantidade = Number(this.state.quantidade);

    if (possuiQuantidadeFracionada && Utils.isValueValid(fatorEmGrama)) {
      quantidade = quantidade + Number(fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    this.setState({ quantidade }, () =>
      this.props.handlerAddItemCarrinho(this.state.quantidade)
    );
  }

  removeQuantidadeItem(possuiQuantidadeFracionada, fatorEmGrama) {
    let quantidade = Number(this.state.quantidade);

    if (possuiQuantidadeFracionada && Utils.isValueValid(fatorEmGrama)) {
      quantidade = quantidade - Number(fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    this.setState({ quantidade }, () =>
      this.props.handlerAddItemCarrinho(this.state.quantidade)
    );
  }

  getQuantidadeLabel() {
    let quantidade = this.state.quantidade || 0;

    let labelQuantidade = `${quantidade} ${
      this.props.possuiQuantidadeFracionada ? "g" : "un"
    }`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  thisUpdate(quantidade) {
    this.setState({ quantidade });
  }

  getDescricaoProduto(descricao) {
    let desc = String(descricao);
    let descricaoFormatada = String(
      Utils.capitalize(String(descricao).slice(0, 45))
    );
    if (desc.length > 40) {
      descricaoFormatada = descricaoFormatada.concat("...");
    }

    return descricaoFormatada;
  }

  adicionarItem() {
    this.addQuantidadeItem(
      this.props.possuiQuantidadeFracionada,
      this.props.fatorEmGrama
    );
  }

  removerItem() {
    this.removeQuantidadeItem(
      this.props.possuiQuantidadeFracionada,
      this.props.fatorEmGrama
    );
  }

  render() {
    const {
      precoAtacado,
      possuiQuantidadeFracionada,
      precoVenda,
      precoUnitario,
      descricao,
      codigoBarras,
      fatorEmGrama,
      handlerClick,
      percentualEconomia,
      quantidadeMinimaAtacado,
      disponivel,
      precosConcorrentes,
      background,
    } = this.props;

    let imagem = this.props.image;

    return (
      <Div
        style={{ width: WIDTH, margin: 15 }}
        rounded
        handleMouseEnter={() =>
          this.setState({ show: true }, () =>
            this.consultarProdutoCarrinho(codigoBarras)
          )
        }
        handleMouseLeave={() => this.setState({ show: false })}
      >
        <Div style={{ margin: 5 }} inline="center" padding="2" rounded>
          <If and value1={this.state.quantidade > 0}>
            <Label
              style={{
                position: "absolute",
                transform: `translate(-85px, 95px)`,
                backgroundColor: Color.EMPRESA.TERCIARIA,
                color: "white",
                padding: 2,
                fontSize: 25,
                textAlign: "center",
                borderRadius: 5,
              }}
              family="Light"
              value={this.getQuantidadeLabel()}
            />
          </If>
          <If and value1={!disponivel}>
            {this.renderProdutoIndisponivel()}
          </If>
          <If and value1={this.state.show} value2={disponivel} value3={false}>
            <Div
              className="view-opaca-item-combo"
              style={{
                width: WIDTH,
                borderRadius: 10,
                height: '75%',
              }}
            >
              <Div
                className="view-absolute-label-combo"
                handlerClick={() => handlerClick(this.thisUpdate)}
                pointer
              >
                <Label
                  style={{ fontSize: 25 }}
                  value="Visualizar"
                  colorText={Color.NODE.WHITE}
                  pointer
                  bold
                />
              </Div>
              <Div
                inline="between"
                className="view-absolute-item-combo"
                style={{
                  backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
                  height: 50,
                }}
              >
                <Div style={{ width: "100%" }} inline="between">
                  <I
                    icon={Type.ICON.MINUS}
                    style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 40 }}
                    pointer
                    handlerClick={() => {
                      if (this.state.quantidade > 0) {
                        if (this.usuarioEstaLogado()) {
                          this.removeQuantidadeItem(
                            possuiQuantidadeFracionada,
                            fatorEmGrama
                          );
                        } else {
                          this.renderViewLogin(this.removerItem);
                        }
                      }
                    }}
                  />

                  <Label
                    value={`${this.getQuantidadeLabel()}` || "0"}
                    marginTop="1"
                    colorText={Color.NODE.WHITE}
                    style={{ fontSize: 30 }}
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 40 }}
                    pointer
                    handlerClick={() => {
                      if (this.usuarioEstaLogado()) {
                        if (this.isProdutoValido({ precoVenda })) {
                          this.addQuantidadeItem(
                            possuiQuantidadeFracionada,
                            fatorEmGrama
                          );
                        }
                      } else {
                        this.renderViewLogin(this.adicionarItem);
                      }
                    }}
                  />
                </Div>
              </Div>
            </Div>
          </If>
          <If and value1={Number(percentualEconomia) > 0}>
            <Label
              value={`Economize ${percentualEconomia}%`}
              family="Bold"
              style={styles.containerPercentualEconomia}
            />
          </If>
          <Div>
            <img
              src={this.state.imagemDefault || imagem}
              alt={`Imagem do produto ${descricao}`}
              style={{
                width: WIDTH,
                paddingBottom: 10,
                backgroundColor: Utils.isValueValid(background)
                  ? background
                  : null,
                borderRadius: 5,
              }}
              // className="border-bottom"
              onError={() => {
                this.setState({
                  imagemDefault: ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT,
                });
              }}
            />
          </Div>
        </Div>
        <Div style={{ paddingLeft: 10 }}>
          <If and value1={Utils.isValueValid(descricao)}>
            <Label
              value={this.getDescricaoProduto(descricao)}
              className="descricao-produto d-flex align-self-start"
              style={styles.descricao}
              family="Light"
            />
          </If>
          <Div className="d-flex flex-column align-items-start justify-content-start align-self-start">
            <If and value1={Number(percentualEconomia) > 0}>
              <Label
                value={Mask.formatValueBr(precoUnitario)}
                style={styles.precoUnitario}
                family="SemiBold"
              />
            </If>
            <If and value1={Utils.isValueValid(precoVenda)}>
              <Label
                value={Mask.formatValueBr(precoVenda)}
                style={
                  !disponivel
                    ? { ...styles.precoVenda, ...styles.precoVendaIndisponivel }
                    : styles.precoVenda
                }
                family="SemiBold"
              />
            </If>
          </Div>
          <If
            and
            value1={Utils.isValueValid(precoAtacado)}
            value2={precoAtacado > 0}
          >
            <TooltipText
              //id={Utils.uuidString()}
              direction="bottom"
              title={`Preço aplicado a partir de ${quantidadeMinimaAtacado} unidades`}
            >
              <Label
                value={`${Mask.formatValueBr(
                  precoAtacado
                )} à partir de ${quantidadeMinimaAtacado} unidades`}
                style={styles.precoAtacado}
                family="SemiBold"
              />
            </TooltipText>
          </If>
          {Utils.isValueValid(precosConcorrentes) && (
            <Div
              style={{
                borderRadius: 10,
                backgroundColor: "#f2f2f2",
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 5,
              }}
              inline="start"
            >
              <Div style={{ width: "50%" }}>
                {Array.from(precosConcorrentes).map((concorrente, index) => {
                  return (
                    <Div
                      inline="between"
                      key={index}
                      style={{
                        color: Color.ECOMMERCE.CINZA_ESCURO,
                        padding: 1,
                      }}
                    >
                      <Div inline="start">
                        <Image src={concorrente.urlFoto} width="26px" />
                        <Label
                          value={Mask.formatValueBr(concorrente.precoVenda)}
                          family="SemiBold"
                          marginTop="2"
                          marginLeft="2"
                        />
                      </Div>
                    </Div>
                  );
                })}
              </Div>
              <Div
                style={{
                  color: "#31CDFF",
                  paddingRight: 15,
                  paddingTop: 20,
                  width: "50%",
                }}
              >
                <Div inline="end" style={{ color: "#31CDFF" }}>
                  <Label value="Economize" family="SemiBold" />
                </Div>
                <Div inline="end">
                  <Label
                    value={`${
                      Array.from(precosConcorrentes)[0].percentualEconomia
                    }%`}
                    style={{ fontSize: 20 }}
                    family="Bold"
                  />
                </Div>
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    );
  }
}

export default ItemProdutoCombo;
