import React, { Component } from "react";
import { Div, I, If, Label } from "../../../components";

import ItemProdutoAbaixouPreco from "../ItemProdutoAbaixouPreco";
import ItemProdutoAbaixouPrecoSaccaria from "../ItemProdutoAbaixouPrecoSaccaria";
import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import { connect } from "react-redux";
import ProdutoUtils from "../ProdutoUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";

class ProdutosDestaque extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosSemImage: [],
      carrinho: props.carrinho,
    };
  }

  componentDidMount() {
    //this.listarProdutos();
  }

  listarProdutos() {
    Http.get(EndPoints.PRODUTOS_DESTAQUE)
      .then(({ status, data }) => {
        console.log(data)
        if (status) {
          this.setState({ produtos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutos() {
    const { titulo } = this.props;
    let produtos = Array.from(this.props.produtos.slice(0, 5));
    
    return produtos.map((prod, index) => {
      if (titulo) {
        return (
          <ItemProdutoAbaixouPrecoSaccaria
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerErrorImage={() =>
              this.setState({
                produtosSemImage: [...this.state.produtosSemImage, prod],
              })
            }
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        );
      }
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPreco
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosDestaque() {
    const { titulo } = this.props;
    return (
      <Div style={{ width: 1150 }}>
        <Div inline={titulo ? "center" : "start"} col="12">
          <I
            icon={Type.ICON.STAR}
            style={{
              fontSize: 25,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 15,
              display: titulo ? "none" : "flex",
            }}
          />
          <Label
            style={{
              color: titulo ? Color.EMPRESA.SECUNDARIA : "#878787",
              fontSize: "25px",
              marginLeft: 10,
            }}
            family="Bold"
            value={titulo ? titulo : "PRODUTOS EM OFERTA"}
          />
        </Div>
        <CarouselProdutoGenericoNovo
          timeSlide={7000}
          itens={this.getProdutos()}
          slidesPerPage={4}
          arrows
        />
      </Div>
    );
  }

  render() {
    console.log(this.props)
    return (
      <If and value1={Utils.isValueValid(this.props.produtos)}>
        {this.renderProdutosDestaque()}
      </If>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
    ...store.carrinhoState,
  };
};

export default connect(mapStateToProps)(ProdutosDestaque);
