import React, { Component } from "react";
import { EntregaRetiradaUtils } from "../..";
import {
  Checkbutton,
  Div,
  I,
  If,
  Label,
  ModalNotification,
  Select,
  SelectValue,
} from "../../../components";
import { Color, Type, Utils } from "../../../utilities";
import NovoEnderecoCheckoutMobile from "../../cliente/mobile/NovoEnderecoCheckoutMobile";

const styles = {
  enderecoSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    width: 250,
  },
  enderecoNaoSelecionado: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    width: 250,
  },
  labelAtencao: {
    color: Color.ECOMMERCE.LARANJA,
    opacity: 0.6,
  },
  item: {
    width: Utils.isViewTabletAll() ? window.innerWidth / 1.5 : "100%",
  },
  labelTipoEntregaActive: {
    border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    opacity: 0.9,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    fontSize: 15,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    textAlign: "center",
    height: 50,
  },
  labelTipoEntrega: {
    border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    opacity: 0.9,
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 15,
    borderRadius: 5,
    padding: 10,
    width: "100%",
    textAlign: "center",
    height: 50,
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
    textAlign: "center"
  },
};

const getTipoEntrega = (parametros, cabecalho) => {
  let valorEntrega = Number(parametros.valorMinimoPedidoEntrega).toFixed(2);
  let valorRetirada = Number(parametros.valorMinimoPedidoRetirada).toFixed(2);
  let valorTotal = Number(cabecalho.valorTotal).toFixed(2);

  let statusEntrega = parametros.entrega.situacao;

  let tipos = [];

  if (valorTotal >= valorEntrega && statusEntrega === "SUCESSO") {
    tipos.push(new SelectValue("ENTREGA", "Quero receber em casa"));
  }

  if (valorTotal >= valorRetirada) {
    tipos.push(new SelectValue("RETIRADA", "Quero retirar na loja"));
  }

  return tipos;
};

class TemplateEntregaRetiradaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outroEnderecoEntrega: {},
    };
  }

  componentDidMount() {
    let values = getTipoEntrega(this.props.parametros, this.props.cabecalho);
    if (this.props.pedidoEntrega) {
      let value = values.filter(
        (v) => v.id === EntregaRetiradaUtils.TIPO_ENTREGA
      )[0];
      if (Utils.isValueValid(value)) {
        this.props.handlerChangeTipoEntrega(value.id);
      }
    } else if (this.props.pedidoRetirada) {
      let value = values.filter(
        (v) => v.id === EntregaRetiradaUtils.TIPO_RETIRADA
      )[0];
      if (Utils.isValueValid(value)) {
        this.props.handlerChangeTipoEntrega(value.id);
      }
    } else {
      this.props.handlerChangeTipoEntrega(
        EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
      );
    }
  }

  getTipoEntrega = () => {
    const { pedidoEntrega, pedidoRetirada, pedidoEntregaOutro } = this.props;
    let tipo = null;

    if (pedidoEntrega) tipo = EntregaRetiradaUtils.TIPO_ENTREGA;
    if (pedidoRetirada) tipo = EntregaRetiradaUtils.TIPO_RETIRADA;
    if (pedidoEntregaOutro) tipo = EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO;

    return tipo;
  };

  renderTemplateOutroEndereco() {
    ModalNotification.render(
      "Outro local de entrega",
      <NovoEnderecoCheckoutMobile
        labelBotaoConfirmar="Adicionar endereço"
        corPrimaria={this.props.corPrimaria}
        handlerOutroEndereco={(endereco) => {
          this.setState({ outroEnderecoEntrega: endereco });
        }}
      />
    );
  }

  render() {
    const {
      handlerChangeTipoEntrega,
      handerErrorEntregaRetirada,
      parametros,
      cabecalho,
    } = this.props;
    let values = getTipoEntrega(parametros, cabecalho);
    let tipoEntrega = this.getTipoEntrega();
    let outroEnderecoEntrega = this.state.outroEnderecoEntrega;

    if (!Utils.isValueValid(values)) {
      handerErrorEntregaRetirada();
    }

    return (
      <Div inline="center" marginTop="2" style={styles.item}>
        <Label
          value="ENTREGA / RETIRADA?"
          style={styles.labelInfo}
          family="SemiBold"
          col="12"
        />
        <Div className="d-flex flex-row justify-content-center">
          <Checkbutton
            style={{
              height: 115,
              width: 115,
              margin: "10px 15px 10px 0px"
            }}
            corPrimaria={this.props.corPrimaria}
            checked={tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA}
            title="Receber o pedido"
            onClick={() =>
              handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_ENTREGA)
            }
            Icon={
              <I
                pointer
                icon={Type.ICON.HOME}
                style={{
                  color:
                    tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA
                      ? Color.ECOMMERCE.WHITE
                      : this.props.corPrimaria,
                  fontSize: 20,
                }}
              />
            }
          />

          <If and value1={Utils.isNotNull(parametros.aceitaRetirada)}>
            <Checkbutton
              style={{
                height: 115,
                width: 115,
                margin: "10px 15px 10px 0px",
              }}
              corPrimaria={this.props.corPrimaria}
              checked={tipoEntrega === EntregaRetiradaUtils.TIPO_RETIRADA}
              title="Retirar na loja"
              onClick={() =>
                handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_RETIRADA)
              }
              Icon={
                <I
                  pointer
                  icon={Type.ICON.MAP_MARKER}
                  style={{
                    color:
                      tipoEntrega === EntregaRetiradaUtils.TIPO_RETIRADA
                        ? Color.ECOMMERCE.WHITE
                        : this.props.corPrimaria,
                    fontSize: 20,
                  }}
                />
              }
            />
          </If>


          <Checkbutton
            style={{
              height: 115,
              width: 115,
            }}
            corPrimaria={this.props.corPrimaria}
            checked={tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO}
            title="Adicionar outro"
            onClick={() => {
              handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO);
              this.renderTemplateOutroEndereco();
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.MAP_MARKER}
                style={{
                  color:
                    tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
                      ? Color.ECOMMERCE.WHITE
                      : this.props.corPrimaria,
                  fontSize: 20,
                }}
              />
            }
          />
        </Div>
        <Div inline="between" style={{ width: window.innerWidth - 30 }}>
          <If
            and
            value1={
              tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO &&
              Utils.isValueValid(outroEnderecoEntrega)
            }
          >
            <Div inline="center" handlerClick={() => {}} className="w-100">
              <Div
                className="w-100"
                inline="between"
                padding="1"
                rounded
                pointer
                style={{
                  backgroundColor: Color.ECOMMERCE.LARANJA,
                  border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
                  opacity: 0.9,
                  color: "white",
                }}
              >
                <Div inline="center" className="w-100">
                  <Div
                    className="d-flex flex-column align-items-start justify-content-center"
                    margin="2"
                    pointer
                  >
                    <Div inline="start" className="w-100">
                      <I
                        icon={Type.ICON.MOTORCYCLE}
                        style={{ color: Color.ECOMMERCE.WHITE }}
                        pointer
                      />
                      <Label
                        value={outroEnderecoEntrega.descricao}
                        style={{ height: 15, fontSize: 13, margin: 5 }}
                        family="SemiBold"
                      />
                    </Div>
                    <Label
                      value={`${outroEnderecoEntrega.logradouro} n° ${outroEnderecoEntrega.numero} ${outroEnderecoEntrega.bairro}`}
                      style={{ height: 15, fontSize: 12 }}
                      family="Light"
                    />
                  </Div>
                </Div>
              </Div>
            </Div>
          </If>
        </Div>
      </Div>
    );
  }
}

export default TemplateEntregaRetiradaMobile;
