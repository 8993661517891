import React, { Component } from "react";
import { Div, I, If, Image, Label, ModalNotification } from "../../components";
import { Color, Http, Keys, Mask, Type, Utils } from "../../utilities";

import {ReactComponent as IconValoR} from "../../assets/images/icon-valor.svg";
import {ReactComponent as IconEntrega} from "../../assets/images/icon-entrega.svg";
import {ReactComponent as IconCalendario} from "../../assets/images/icon-calendario.svg";
import {ReactComponent as IconProduto} from "../../assets/images/icon-produto.svg";

import "./carrinho.css";
import LoginCliente from "../cliente/LoginCliente";
import RecuperarSenha from "../cliente/RecuperarSenha";
import { ProdutoUtils } from "..";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

const styles = {
  containerCarrinho: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    lineHeight: "10px",
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
    lineHeight: "10px",
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    lineHeight: "10px",
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 14,
    lineHeight: "10px",
  },
  labelDescricaoItem: {
    fontSize: 14,
    lineHeight: "14px",
    color: "#878787",
    width: 150,
  },
  labelNenhumItemNoCarrinho: {
    fontSize: 14,
    lineHeight: "14px",
    color: "#878787",
  },
  labelPrecoItem: {
    fontSize: 14,
    color: Color.ECOMMERCE.LARANJA,
  },
  labelPrecoItemAtacado: {
    fontSize: 10,
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  image: {
    // border: "1px #878787 solid",
    borderRadius: "10px",
    backgroundColor: "white",
    width: 70,
    height: 70,
  },
  containterPagamento: {
    textAlign: "center",
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
  labelIrPagamento: {
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    width: "100%",
    marginBottom: 0,
  },
  backgroundContainer: {
    backgroundColor: `rgb(242, 242, 242)`,
    width: "100%",
    height: "52vh",
    margin: "0em",
    overflowY: "auto",
  },
  backgroundContainerItem50: {
    backgroundColor: `rgb(242, 242, 242)`,
    width: "100%",
    height: "65vh",
    margin: "0em",
    overflowY: "auto",
  },
};

class Carrinho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carrinho: props.carrinho,
      posicaoCarrinho: 0,
      parametros: {},
    };
  }

  componentDidMount() {
    this.consultarParametrosPedido();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  removerItem(produto) {
    let carrinho = this.props.carrinho;

    let itens = Array.from(carrinho.itens);
    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    itens.splice(itens.indexOf(produtoSelecionado), 1);
    this.props.atualizarCarrinho(carrinho);
  }

  isProdutoValido(produto) {
    return ProdutoUtils.validarPreco(
      produto,
      "Produto indisponível , não possui preço de venda"
    );
  }

  getQuantidadeLabel(item) {
    let quantidade = item.quantidade || 0;

    let labelQuantidade = `${quantidade} ${
      item.possuiQuantidadeFracionada ? "g" : "un"
    }`;

    return quantidade > 0 ? labelQuantidade : "0";
  }

  renderTemplateItensCarrinho = (itens, idsItensCarrinhoParaRolagem, parametrosEmpresa) => {
    return (
      <Div style={styles.backgroundContainer}>
        {Array.from(itens)
          .reverse()
          .map((item, index) => {
            return (
              <Div
                className="border-bottom d-flex align-items-self-start justify-content-between"
                id={idsItensCarrinhoParaRolagem[index]}
              >
                <Div
                  style={{ height: 140, transform: `translateY(-25px)` }}
                  className="mx-auto"
                >
                  <Div
                    key={index}
                    inline="start"
                    paddingTop="1"
                    paddingLeft="3"
                    paddingBottom="1"
                    style={{ height: 100 }}
                  >
                    <Div>
                      <Label
                        style={{
                          transform: `translateY(70px)`,
                          marginLeft: 10,
                          backgroundColor: Color.EMPRESA.TERCIARIA,
                          color: "white",
                          padding: 2,
                          width: 50,
                          fontSize: 12,
                          textAlign: "center",
                          borderRadius: 5,
                        }}
                        family="Light"
                        value={this.getQuantidadeLabel(item)}
                      />
                      <Image
                        src={item.urlImagemPrincipalThumbnail}
                        responsive
                        styleImage={styles.image}
                        border
                      />
                    </Div>
                    <Div
                      className="d-flex flex-column justify-content-start"
                      marginTop="2"
                      marginLeft="2"
                      style={{ transform: `translateY(20px)` }}
                    >
                      <Label
                        value={String(item.descricao).substr(0, 50)}
                        style={styles.labelDescricaoItem}
                      />
                      <Label
                        value={Mask.formatValueBr(item.valorSubTotalItem)}
                        style={{
                          ...styles.labelPrecoItem,
                          color: parametrosEmpresa.corPrimaria
                        }}
                        bold
                      />
                    </Div>
                  </Div>
                  <Div
                    inline="center"
                    style={{
                      color: parametrosEmpresa.corPrimaria,
                      width: 100,
                      padding: 5,
                    }}
                  >
                    <I
                      icon={Type.ICON.MINUS_SQUARE}
                      style={{ fontSize: 30 }}
                      margin="1"
                      pointer
                      handlerClick={() => {
                        this.removeQuantidadeItem(item);
                      }}
                    />
                    <I
                      icon={Type.ICON.PLUS_SQUARE}
                      style={{ fontSize: 30 }}
                      margin="1"
                      pointer
                      handlerClick={() => {
                        if (this.isProdutoValido(item)) {
                          this.addQuantidadeItem(item);
                        }
                      }}
                    />
                  </Div>
                </Div>
                <I
                  icon={Type.ICON.CLOSEX}
                  marginRight="3"
                  marginTop="3"
                  colorText={Color.NODE.DANGER}
                  pointer
                  handlerClick={() => {
                    this.props.handlerRemoveItem(item);
                  }}
                />
              </Div>
            );
          })}
      </Div>
    );
  };

  renderViewRecuperarSenha() {
    ModalNotification.renderModalLogin(
      <Label
        className="modal-title"
        style={{
          color: Color.ECOMMERCE.LARANJA,
          fontSize: 25,
          marginTop: 20,
        }}
        family="Bold"
        value="Recuperar senha"
      />,
      <RecuperarSenha />
    );
  }

  renderViewLogin() {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: Color.ECOMMERCE.LARANJA,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Dados de acesso"
        />
        <Label
          value="Esqueci meus dados!"
          style={{
            color: Color.EMPRESA.TERCIARIA,
          }}
          className="hover-link"
          family="Light"
          handlerClick={() => this.renderViewRecuperarSenha()}
        />
      </>,
      <LoginCliente
        handlerConfirm={() => Utils.refreshPage()}
        empresa={this.props.empresa}
        {...this.props}
      />
    );
  }

  addQuantidadeItem(item) {
    CarrinhoUtils.addQuantidadeItem(item);
    this.props.handlerAddItem(item);
  }

  removeQuantidadeItem(item) {
    CarrinhoUtils.removeQuantidadeItem(item);
    this.props.handlerAddItem(item);
  }

  validarPedidoParaChekout(carrinho) {
    let parametros = this.state.parametros;
    let valorEntrega = Number(parametros.valorMinimoPedidoEntrega);
    let valorRetirada = Number(parametros.valorMinimoPedidoRetirada);
    let valorTotal = Number(carrinho.cabecalho.valorTotal);

    let valorMaximoPedido = Number(parametros.valorMaximoPedido).toFixed(2);
    let valido = valorTotal > valorRetirada || valorTotal > valorEntrega;

    let valorMinimo =
      valorEntrega > valorRetirada ? valorRetirada : valorEntrega;

    if (!valido) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido não possui o valor mínimo necessário"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor mínimo para o pedido: ${Mask.formatValueBr(
              valorMinimo
            )}`}
          />
        </Div>
      );

      return valido;
    }

    valido = valorMaximoPedido >= valorTotal;

    if (!valido) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido ultrapassou o valor limite permitido"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor máximo para o pedido: ${Mask.formatValueBr(
              valorMaximoPedido
            )}`}
          />
        </Div>
      );

      return valido;
    }

    return valido;
  }

  getPrevisaoEntrega(parametros) {
    let previsao = "";

    if (Utils.isValueValid(parametros)) {
      let horarios = Array.from(parametros.horarios);
      previsao = `${horarios[0].dataEntrega} - ${
        horarios[horarios.length - 1].dataEntrega
      }`;
    }

    return previsao;
  }

  render() {
    const { cabecalho, itens } = this.props.carrinho;
    const { parametros } = this.props;

    let entrega = parametros.entrega || {};

    let idsItensCarrinhoParaRolagem = [];

    for (let i = 0; i < Array.from(itens).length; i++) {
      idsItensCarrinhoParaRolagem.push(Utils.uuidString());
    }

    let empresa = this.props.empresa || {};
    let parametrosEmpresa = Utils.isValueValid(empresa)
      ? empresa.parametros
      : {};

    return (
      <Div>
        <Div
          bg={Color.NODE.WHITE}
          className="px-auto"
          style={{ overflow: "hidden" }}
        >
          <Div inline="center">
            <Div className="d-flex flex-column  align-self-start">

              <IconValoR style={{ width:"35px", height:"40px", marginTop:"5"}} />
              <IconEntrega style={{ width:"35px", height:"40px", marginTop:"9"}} />
              <IconEntrega style={{ width:"35px", height:"40px", marginTop:"4"}} />
              <IconValoR style={{ width:"35px", height:"40px", marginTop:"3"}} />
              <IconCalendario style={{ width:"35px", height:"40px", marginTop:"16"}} />
              <IconProduto style={{ width:"35px", height:"40px", marginTop:"3"}} />
            </Div>
            <Div
              className="d-flex flex-column align-self-start"
              marginTop="2"
              marginBottom="2"
            >
              <Div className="d-flex flex-column align-self-start" margin="2">
                <Label
                  value="Valor total"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Label
                  value={Mask.formatValueBr(
                    CarrinhoUtils.getValorTotalPedido(cabecalho, parametros)
                  )}
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
              <Div
                className="d-flex flex-column align-self-start"
                marginLeft="2"
              >
                <Label
                  value="Taxa de entrega"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Label
                  value={Mask.formatValueBr(cabecalho.valorTaxaEntrega || 0)}
                  style={
                    cabecalho.atingiuEntregaGratis
                      ? styles.taxaEntregaGratis
                      : styles.labelBlue
                  }
                  family="Bold"
                />
              </Div>

              <Div
                className="d-flex flex-column align-self-start"
                marginLeft="2"
                marginTop="2"
              >
                <Label
                  value="Taxa de serviço"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Label
                  value={Mask.formatValueBr(parametros.valorTaxaServico || 0)}
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
              <Div
                className="d-flex flex-column align-self-start"
                marginLeft="2"
                marginTop="2"
              >
                <Label
                  value="Desconto"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Label
                  value={Mask.formatValueBr(cabecalho.valorDesconto || 0)}
                  style={styles.labelRed}
                  family="Bold"
                />
              </Div>

              <Div
                className="d-flex flex-column align-self-start"
                marginLeft="2"
                marginTop="3"
              >
                <Label
                  value="Previsão de entrega"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Label
                  value={this.getPrevisaoEntrega(parametros)}
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
              <Label
                marginTop="2"
                marginLeft="2"
                value={`${itens.length} produtos`}
                style={{...styles.labelCinza, lineHeight: "37px",}}
                family="Bold"
              />
            </Div>
          </Div>
        </Div>
        <If and value1={parametros.valorMinimoPedidoFreteGratis > 0}>
          <Div inline="center">
            <Label
              family="Light"
              style={{ color: parametrosEmpresa.corPrimaria }}
              value={`Entrega grátis à partir de: ${Mask.formatValueBr(
                parametros.valorMinimoPedidoFreteGratis
              )}`}
            />
          </Div>
        </If>
        <If and value1={Array.from(itens).length > 0}>
          {this.renderTemplateItensCarrinho(itens, idsItensCarrinhoParaRolagem, parametrosEmpresa)}

          <Div style={styles.containterPagamento}>
            <Div inline="center" className="bg-carrinho">
              <I
                icon={Type.ICON.ARROW_CHEV_DOWN}
                style={{ fontSize: 20, margin: 10, color: "#C4C4C4" }}
                pointer
                handlerClick={() => {
                  let itemCarrinho = document.getElementById(
                    idsItensCarrinhoParaRolagem[this.state.posicaoCarrinho + 1]
                  );
                  if (Utils.isValueValid(itemCarrinho)) {
                    itemCarrinho.scrollIntoView({ behavior: "smooth" });
                    this.setState({
                      posicaoCarrinho: this.state.posicaoCarrinho + 1,
                    });
                  }
                }}
              />

              <I
                icon={Type.ICON.ARROW_CHEV_UP}
                style={{ fontSize: 20, margin: 10, color: "#C4C4C4" }}
                pointer
                handlerClick={() => {
                  let itemCarrinho = document.getElementById(
                    idsItensCarrinhoParaRolagem[this.state.posicaoCarrinho - 1]
                  );
                  if (Utils.isValueValid(itemCarrinho)) {
                    itemCarrinho.scrollIntoView({ behavior: "smooth" });
                    this.setState({
                      posicaoCarrinho: this.state.posicaoCarrinho - 1,
                    });
                  }
                }}
              />
            </Div>

            <Label
              value="Ir para pagamento"
              style={{
                ...styles.labelIrPagamento,
                backgroundColor: parametrosEmpresa.corPrimaria
              }}
              family="SemiBold"
              pointer
              handlerClick={() => {
                let carrinho = CarrinhoUtils.getCarrinho();
                
                TagManagerUtils.tagManagerIrParaPagamento(carrinho.itens)

                // let usuarioLogado = JSON.parse(
                //   localStorage.getItem(Keys.KEY_CLIENTE_LOJA_WEB)
                //  );
                let usuarioLogado = UsuarioUtils.getUsuarioLojaWeb();
                if (
                  Utils.isValueValid(carrinho) &&
                  Utils.isValueValid(usuarioLogado)
                ) {
                  if (this.validarPedidoParaChekout(carrinho)) {
                    this.props.handlerFecharMenu();
                    this.props.history.push("/carrinho", {
                      parametros: this.props.parametros,
                      logoEmpresa: this.props.empresa.parametros.urlFoto,
                      empresa: this.props.empresa,
                    });
                    Utils.ativarScroolTelaPrincipal("auto");
                  }
                } else if (!Utils.isValueValid(usuarioLogado)) {
                  this.renderViewLogin();
                } else {
                  Utils.refreshPage();
                }
              }}
            />
          </Div>
        </If>
        <If and value1={Array.from(itens).length === 0}>
          <Div inline="center" className="border">
            <I icon={Type.ICON.INFO} colorText={Color.NODE.WARNING} />
            <Label
              margin="3"
              value="Nenhum item foi adicionado ao carrinho"
              style={styles.labelNenhumItemNoCarrinho}
              family="Light"
            />
          </Div>
        </If>
      </Div>
    );
  }
}

export default Carrinho;
