import React, { Component } from "react";
import "./App.css";
import { Parent, Header, Center, Div, MenuLeft, CenterTopDashboard } from "./components";
import HeaderDashboard from "./components/containers/HeaderDashboard";
import Id from "./utilities/enuns/Id";

class AppDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: true,
    };

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  }

  render() {
    return (
      <Parent className="bg-container-mobioh">
        <HeaderDashboard
          history={this.props.history}
          handlerClick={(e) => this.toggleNavbar()}
          active={this.state.activeMenu}
          title={this.props.title}
        ></HeaderDashboard>
        {/** <MenuSideBar/>
         *
         */}
        <MenuLeft active={this.state.activeMenu}></MenuLeft>
        <Div active={this.state.activeMenu} className={Id.HTML.MAIN}>
          <CenterTopDashboard {...this.props}></CenterTopDashboard>
          <Center>{this.props.children}</Center>
        </Div>
      </Parent>
    );
  }
}

export default AppDashboard;
