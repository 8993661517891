import React, { Component } from "react";
import {
  Checkbox,
  Div,
  I,
  If,
  Image,
  Label,
  ModalNotification,
} from "../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../utilities";
import IconFiltros from "../../assets/images/icon-filtros.png";
import ItemProdutoAbaixouPrecoMobile from "./ItemProdutoAbaixouPrecoMobile";

import "./produtos.css";
import IconsDepartamento from "../../utilities/enuns/IconsDepartamento";
import ProdutoDetalheMobile from "./mobile/ProdutoDetalheMobile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { atualizarCarrinho, setProdutos } from "../../redux/actions";
import BotaoPaginaInicial from "../utils/BotaoPaginaInicial";
import ContatoWhatsappMobile from "../ContatoWhatsappMobile";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import { EmpresaUtils } from "..";
import { toast } from "react-toastify";

const styles = {
  title: {
    color: "#878787",
  },
  labelFiltros: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    paddingTop: 12,
    paddingLeft: 10,
  },
  labelResultado: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelPrecoActive: {
    border: "2px solid #E94E25",
    width: "100%",
    margin: 3,
  },
  labelPreco: {
    width: "100%",
    margin: 3,
  },
  menuFiltros: {
    position: "absolute",
    zIndex: 1010,
    backgroundColor: "#f2f2f2",
    bottom: 0,
    top: 200,
    left: 0,
    right: 0,
    transform: `translateY(3000px)`,
    transition: "0.5s",
    overflow: "auto",
  },
  menuFiltrosActive: {
    position: "absolute",
    zIndex: 1010,
    backgroundColor: "#f2f2f2",
    bottom: 0,
    top: 200,
    left: 0,
    right: 0,
    transform: `translateY(10px)`,
    transition: "0.5s",
    overflow: "auto",
  },
};

class ProdutosPesquisaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
      produtosFiltrados: [],
      produtosSimilares: [],
      classificacoes: [],
      textoPesquisa: "",
      ...props.location.state,
      open: false,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.toggleMenuFiltros = this.toggleMenuFiltros.bind(this);
  }

  listarProdutosSimilares(produto) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  sortByTerm = (data, term) => {
    let dataSort = Utils.sort(data, "descricao");
    let termString = String(term).toLowerCase().trim();

    return dataSort.sort(function (a, b) {
      return String(a.descricao)
        .split(" ")[0]
        .toLowerCase()
        .trim()
        .startsWith(termString) && a.disponivel
        ? -1
        : 1;
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    let state = props.location.state;

    if (!state) {
      state = this.state;

      let urlPesquisa = this.props.location.pathname.replace("/produto/ean/", "")

      this.realizarPesquisa(urlPesquisa);

    }

    if(state){

      let produtos = state.resultadoPesquisa.listaProdutos;
      let produtosFiltrados = this.sortByTerm(produtos, state.textoPesquisa);
  
      this.setState({
        resultadoPesquisa: state.resultadoPesquisa,
        produtos: produtosFiltrados,
        empresa: state.empresa,
        produtosFiltrados,
        textoPesquisa: state.textoPesquisa,
        classificacoes: state.classificacoes,
      });
    }

  }

  componentDidMount() {
    let produtos = this.state.resultadoPesquisa.listaProdutos;
    let produtosFiltrados = this.sortByTerm(produtos, this.state.textoPesquisa);

    this.setState(
      {
        produtos: produtosFiltrados,
        produtosFiltrados,
      },
      () => this.consultarParametrosPedido()
    );
  }

  realizarPesquisa(texto) {
    Http.get(`/busca/${texto}`)
      .then(({ status, data }) => {
        console.log("pesquisa resultado", data)
        if (status) {
          this.setState(
            {
              resultadoPesquisa: data,
              produtos: data.listaProdutos,
              empresa: this.props.empresa,
              produtosFiltrados: this.sortByTerm(data.listaProdutos, texto),
              textoPesquisa: texto,
              classificacoes: this.state.classificacoes,
            },
            () => {
              if (data.listaProdutos.length === 1) {
                this.visualizarProduto(data.listaProdutos[0])
              }
            }
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({ parametros: data });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  limparFiltros() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    Array.from(resultadoPesquisa.filtros).forEach((filtro) => {
      Array.from(filtro.itens).map((item) => (item.active = false));
    });

    this.setState({ resultadoPesquisa }, () => this.aplicarFiltros());
  }

  aplicarFiltros() {
    let produtos = JSON.parse(
      JSON.stringify(this.props.location.state.resultadoPesquisa.listaProdutos)
    );

    let filtroPreco = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];

    let itensPrecoMarcados = Array.from(filtroPreco.itens).filter(
      (item) => item.active
    );

    let filtroDepartamento = Array.from(
      this.state.resultadoPesquisa.filtros
    ).filter((filtro) => filtro.tipo === "DEPARTAMENTO")[0];

    let itensDepartamentoMarcados = Array.from(filtroDepartamento.itens).filter(
      (item) => item.active
    );

    let filtroSecao = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];

    let itensSecaoMarcados = Array.from(filtroSecao.itens).filter(
      (item) => item.active
    );

    let filtroMarca = Array.from(this.state.resultadoPesquisa.filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    let itensMarcaMarcados = Array.from(filtroMarca.itens).filter(
      (item) => item.active
    );

    let produtosFiltrados = [];

    if (Utils.isValueValid(itensDepartamentoMarcados)) {
      let filtros = itensDepartamentoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.departamento)) {
            produtosFiltrados.push(produto);
          }
        });
      }
    }

    if (Utils.isValueValid(itensSecaoMarcados)) {
      let filtros = itensSecaoMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.secao)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (Utils.isValueValid(itensMarcaMarcados)) {
      let filtros = itensMarcaMarcados.map((item) => item.idFiltro);

      if (Utils.isValueValid(filtros)) {
        produtos.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (filtros.includes(filtroProduto.marca)) {
            let produtoExiste = produtosFiltrados.indexOf(produto);
            if (produtoExiste === -1) {
              produtosFiltrados.push(produto);
            }
          }
        });
      }
    }

    if (!Utils.isValueValid(produtosFiltrados)) {
      produtosFiltrados = produtos;
    }

    if (Utils.isValueValid(itensPrecoMarcados)) {
      let filtros = itensPrecoMarcados.map((item) => item.idFiltro);

      let produtosExcluir = [];

      if (Utils.isValueValid(filtros)) {
        produtosFiltrados.forEach((produto) => {
          let filtroProduto = produto.filtro;
          if (!filtros.includes(filtroProduto.preco)) {
            produtosExcluir.push(produto);
          }
        });

        produtosExcluir.forEach((produto) => {
          produtosFiltrados.splice(produtosFiltrados.indexOf(produto), 1);
        });
      }
    }

    produtosFiltrados = this.sortByTerm(
      produtosFiltrados,
      this.state.textoPesquisa
    );

    this.setState({
      produtosFiltrados,
    });
  }

  getFiltrosMapeados() {
    let resultadoPesquisa = this.state.resultadoPesquisa;
    const { filtros } = resultadoPesquisa;

    let templates = [];

    let filtroDepartamento = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "DEPARTAMENTO"
    )[0];
    let filtroSecao = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "SECAO"
    )[0];
    let filtroPreco = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "PRECO"
    )[0];
    let filtroMarca = Array.from(filtros).filter(
      (filtro) => filtro.tipo === "MARCA"
    )[0];

    if (Utils.isValueValid(filtroPreco)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Preços" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroPreco.itens, "descricao").map((item, index) => {
            return (
              <Label
                key={index}
                value={item.descricao}
                key={index}
                padding="2"
                bg={Color.NODE.LIGHT}
                family="Light"
                style={styles.title}
                pointer
                style={
                  item.active ? styles.labelPrecoActive : styles.labelPreco
                }
                handlerClick={() => {
                  item.active = !item.active;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroDepartamento)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Departamentos" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroDepartamento.itens, "descricao").map(
            (item, index) => {
              return (
                <Checkbox
                  value={item.idFiltro}
                  danger
                  label={item.descricao}
                  classNameLabel="text-ellipsis"
                  styleLabel={{ width: window.innerWidth - 50 }}
                  key={index}
                  handlerChange={({ checked }) => {
                    item.active = checked;
                    this.setState({ resultadoPesquisa });
                    this.aplicarFiltros();
                  }}
                  checked={item.active}
                />
              );
            }
          )}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroSecao)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Categorias" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroSecao.itens, "descricao").map((item, index) => {
            return (
              <Checkbox
                label={item.descricao}
                classNameLabel="text-ellipsis"
                styleLabel={{ width: window.innerWidth - 50 }}
                danger
                key={index}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    if (Utils.isValueValid(filtroMarca)) {
      templates.push(
        <Div className="d-flex flex-column align-items-start justify-content-start">
          <Label value="Marca" family="SemiBold" style={styles.title} />
          {Utils.sort(filtroMarca.itens, "descricao").map((item, index) => {
            return (
              <Checkbox
                label={item.descricao}
                danger
                key={index}
                classNameLabel="text-ellipsis"
                styleLabel={{ width: window.innerWidth - 50 }}
                handlerChange={({ checked }) => {
                  item.active = checked;
                  this.setState({ resultadoPesquisa });
                  this.aplicarFiltros();
                }}
                checked={item.active}
              />
            );
          })}
        </Div>
      );
    }

    return templates;
  }

  getProdutosPorDepartamento = () => {
    let itensParaRenderizar = [];

    for (const item of this.state.produtosFiltrados) {
      let itens = [];

      let departamento = item[0];
      let produtos = Array.from(item[1]);

      let produtosSeparados = Utils.sort(produtos, "descricao");

      itensParaRenderizar.push({ produtos: produtosSeparados, departamento });
    }

    return itensParaRenderizar;
  };

  visualizarProduto = (produto) => {
    ModalNotification.render(
      "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        produtosSimilares={this.state.produtosSimilares}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          toast.info("Produto adicionado no carrinho!", {
            position: "top-right",
          });
        }}
        listasDeCompraCliente={this.props.listasDeCompra}
        history={this.props.history}
        empresa={this.state.empresa}
      />
    );
  };

  renderProdutos() {
    return (
      <Div inline="start">
        {Array.from(this.state.produtosFiltrados).map((prod, index) => {
          return (
            <Div
              className="d-flex justify-content-start align-items-start align-self-start"
              marginTop="2"
              key={index}
            >
              <ItemProdutoAbaixouPrecoMobile
                history={this.props.history}
                image={prod.urlImagemPrincipalThumbnail}
                descricao={prod.descricao}
                precoUnitario={prod.precoUnitario}
                precoVenda={prod.precoVenda}
                precoAtacado={prod.precoAtacado}
                codigoBarras={prod.codigoBarras}
                quantidade={prod.quantidade}
                precosConcorrentes={prod.precosConcorrentes}
                disponivel={prod.disponivel}
                proibidaVenda={prod.proibidaVenda}
                possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                fatorEmGrama={prod.fatorEmGrama}
                handlerClick={() => this.listarProdutosSimilares(prod)}
                handlerAddItemCarrinho={(quantidade, callback) => {
                  prod.quantidade = quantidade;
                  prod.valorSubTotalItem = (
                    Number(quantidade) * Number(prod.precoVenda)
                  ).toFixed(2);
                  prod.selecionado = true;
                  this.adicionarItemNoCarrinho(prod, callback);
                }}
                empresa={this.state.empresa}
              />
            </Div>
          );
        })}
      </Div>
    );
  }

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;

    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.state.parametros
    );
  }

  toggleMenuFiltros = () => {
    this.setState({ open: !this.state.open }, () => {
      Utils.toggleScrollBody(this.state.open ? "hidden" : "auto");
    });
  };

  render() {
    const { textoPesquisa } = this.state;
    const ID_FILTROS = Utils.uuidString();
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <Div style={{ marginTop: 30, width: "100%" }}>
        <Div className="d-flex align-self-start justify-content-start">
          <Div marginLeft="3" marginRight="3" style={{ width: "100%" }}>
            <Div inline="between">
              <BotaoPaginaInicial
                history={this.props.history}
                color={corPrimaria}
              />
              <Div
                inline="center"
                handlerClick={() => this.toggleMenuFiltros()}
                toggle="collapse"
                target={`#${ID_FILTROS}`}
              >
                <If and value1={!this.state.open}>
                  <Image
                    src={IconFiltros}
                    width="30px"
                    marginTop="3"
                    marginLeft="4"
                  />
                </If>
                <If and value1={this.state.open}>
                  <Label
                    value="Fechar filtros"
                    marginTop="4"
                    family="SemiBold"
                    style={{ color: Color.ECOMMERCE.LARANJA }}
                  />
                  <I
                    icon={Type.ICON.CLOSEX}
                    style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 25 }}
                    marginTop="3"
                    marginLeft="2"
                  />
                </If>
              </Div>
            </Div>

            <Div
              id={ID_FILTROS}
              className="collapse"
              style={
                this.state.open ? styles.menuFiltrosActive : styles.menuFiltros
              }
            >
              <Div inline="between" className="w-100 p-1 border-bottom">
                <Div
                  inline="center"
                  pointer
                  handlerClick={() => this.toggleMenuFiltros()}
                  className="bg-light border"
                  rounded
                  padding="1"
                  marginTop="2"
                  toggle="collapse"
                  target={`#${ID_FILTROS}`}
                >
                  <I
                    icon={Type.ICON.SUCCESS}
                    colorText={Color.NODE.SUCCESS}
                    paddingBottom="2"
                    pointer
                  />
                  <Label
                    value="Aplicar filtros"
                    family="Light"
                    marginLeft="2"
                    pointer
                  />
                </Div>
                <Div
                  inline="center"
                  pointer
                  handlerClick={() => this.limparFiltros()}
                  className="bg-light border"
                  rounded
                  padding="1"
                  marginTop="2"
                >
                  <I
                    icon={Type.ICON.CLOSEX}
                    colorText={Color.NODE.DANGER}
                    paddingBottom="2"
                    pointer
                    handlerClick={() => this.limparFiltros()}
                  />
                  <Label
                    value="Limpar filtros"
                    family="Light"
                    marginLeft="2"
                    pointer
                    handlerClick={() => this.limparFiltros()}
                  />
                </Div>
              </Div>
              {this.getFiltrosMapeados().map((item, index) => (
                <Div key={index} padding="1">
                  {item}
                </Div>
              ))}
            </Div>

            <Div inline="between" marginTop="2">
              <Label
                value={`Resultados da busca por: "${textoPesquisa}"`}
                style={styles.labelResultado}
                family="SemiBold"
              />
            </Div>
            {this.renderProdutos()}
            {/**
               * Array.from(this.getProdutosPorDepartamento()).map(
              (data, index) => {
                return (
                  <Div className="d-flex flex-column" key={index}>
                    <Div inline="start">
                      <Image
                        src={IconsDepartamento.getIcon(data.departamento)}
                        style={styles.imageIcon}
                        width="25px"
                      />
                      <Label
                        style={styles.labelDepartamento}
                        value={data.departamento}
                        margin="2"
                      />
                    </Div>
                    <Label className="border-label-departamento" />
                    <Label className="border-bottom border-label-departamento-bottom" />
                    <Div inline="start">
                      {Array.from(data.produtos).map((produtos, subindex) => {
                        let produtosParaRenderizar =
                          Array.from(produtos).length > 1
                            ? produtos
                            : [produtos];

                        return produtosParaRenderizar.map((prod) => {
                          return (
                            <Div className="align-self-start">
                              <ItemProdutoAbaixouPrecoMobile
                                key={subindex}
                                history={this.props.history}
                                image={prod.urlImagemPrincipalThumbnail}
                                descricao={prod.descricao}
                                precoUnitario={prod.precoUnitario}
                                precoVenda={prod.precoVenda}
                                precoAtacado={prod.precoAtacado}
                                codigoBarras={prod.codigoBarras}
                                quantidade={prod.quantidade}
                                precosConcorrentes={prod.precosConcorrentes}
                                disponivel={prod.disponivel}
                                proibidaVenda={prod.proibidaVenda}
                                possuiQuantidadeFracionada={
                                  prod.possuiQuantidadeFracionada
                                }
                                fatorEmGrama={prod.fatorEmGrama}
                                handlerClick={() =>
                                  this.listarProdutosSimilares(prod)
                                }
                                handlerAddItemCarrinho={(
                                  quantidade,
                                  callback
                                ) => {
                                  prod.quantidade = quantidade;
                                  prod.valorSubTotalItem = (
                                    Number(quantidade) * Number(prod.precoVenda)
                                  ).toFixed(2);
                                  prod.selecionado = true;
                                  this.adicionarItemNoCarrinho(prod, callback);
                                }}
                                empresa={this.state.empresa}
                              />
                            </Div>
                          );
                        });
                      })}
                    </Div>
                  </Div>
                );
              }
            )
               */}
          </Div>
        </Div>
        <ContatoWhatsappMobile empresa={this.state.empresa} />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdutosPesquisaMobile);
