import React from "react";
import PropTypes from "prop-types";
import {
  className,
  col,
  float,
  display,
  invisible,
  margin,
  padding,
} from "../../css";
import If from "../../if/If";
import { Color, Type, Utils } from "../../../utilities";
import { Div, I, Label } from "../..";

const getClassDefault = (props) => {
  let classe = "";
  classe = classe.concat(col(props));
  classe = classe.concat(float(props));
  classe = classe.concat(display(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(props.formcontrol ? "form-control " : "");
  classe = classe.concat(className(props));

  return classe;
};

const validateKeyPress = (e) => {
  if (e.key === "Enter" || e.key === "ENTER") {
    e.preventDefault();
  }
};

const Input = (props) => {
  return (
    <>
      <If and value1={Utils.isValueValid(props.error)}>
        <Div style={props.styleError || { position: "absolute" }}>
          <I icon={Type.ICON.EXCLAMATION} colorText="danger" />
          <Label value={props.error} colorText="danger" margin="1" />
        </Div>
      </If>
      <input
        className={getClassDefault(props)}
        type={props.type}
        disabled={props.disabled}
        placeholder={props.placeholder || props.placeHolder}
        onChange={props.handlerChange}
        onFocus={props.handlerFocus}
        onBlur={props.handlerBlur}
        onKeyDown={(e) => validateKeyPress(e)}
        onKeyUp={props.handlerKeyUp}
        onClick={props.handlerClick}
        name={props.name}
        multiple={props.multiple}
        checked={props.checked}
        id={props.id}
        required={props.required}
        pattern={props.pattern}
        title={props.title}
        value={props.value}
        accept={props.accept}
        style={props.style}
        maxLength={props.maxLength}
        data-target={props.target}
        data-toggle={props.toggle}
        autoComplete={props.autoComplete}
      />
    </>
  );
};

Input.propTypes = {
  col: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handlerChange: PropTypes.func,
  handlerBlur: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  col: "12",
  type: "text",
  disabled: false,
};

export default Input;
