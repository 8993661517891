import React, { Component } from "react";

import Div from "../layout/div/Div";
import Sidebar from "react-sidebar";
import { I, Image, Label, ModalNotification } from "..";

import IconWhatsapp from "../../assets/images/icones/icon-whatsapp.png";
import IconMensagem from "../../assets/images/icones/icon-mensagem.png";
import {ReactComponent as IconMenuLeft } from "../../assets/images/icon-menu-esquerdo.svg";
import IconApple from "../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../assets/images/icones/icon-googleplay.png";
import IconFacebook from "../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../assets/images/icones/icon-instagram.png";

import IconMenuCarrinho from "../../assets/images/icon-menu-carrinho.png";
import IconeMinhasLojas from "../../assets/images/icones/icon-minhas-lojas.png";
import IconArrowRight from "../../assets/images/icones/icon-arrow-right.png";

import { Color, Keys, Mask, Type, Utils } from "../../utilities";
import Carrinho from "../../views/carrinho/Carrinho";
import { connect } from "react-redux";
import If from "../if/If";

import "./navbar.css";

const styles = {
  menuCarrinho: {
    background: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    marginTop: 20,
    marginRight: 15,
  },
  labelValorTotalCarrinho: {
    color: Color.ECOMMERCE.AZUL,
  },
  labelSAC: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 20,
  },
  labelServicoAtendimento: {
    color: Color.ECOMMERCE.LARANJA,
    width: 150,
    fontSize: 15,
  },
  labelTelefone: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelEmail: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelQuantidadeTotalItens: {
    position: "fixed",
    top: 45,
    right: 12,
    width: "22px",
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  btnRegistrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: "#878787",
  },
  btnEntrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: Color.ECOMMERCE.LARANJA,
  },
};

class NavbarLojaInstantanea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      isSideBarMenuLeft: true,
      propsSideBar: {},
      carrinho: props.carrinho,
      abrirNossasLojas: false,
      abrirMapa: false,
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      propsSideBar: this.getPropsSideBarMenuLeft(),
    });
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open }, () => {
      if (open) {
        Utils.toggleScrollBody("hidden");
      } else {
        Utils.toggleScrollBody("auto");
      }
    });
  }

  renderMapaLoja() {
    return (
      <Div
        className="scrollhost"
        style={{
          background: "red",
          width: "60%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 50,
        }}
      >
        <Div
          inline="end"
          handlerClick={() => this.setState({ abrirMapa: false })}
        >
          <I
            icon={Type.ICON.CLOSEX}
            sizeIcon="2"
            colorText={Color.NODE.DANGER}
          />
        </Div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.736545449487!2d-49.2560485!3d-16.7399921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef1a8e4ca204b%3A0x9f0eeb9baf8d6bad!2sStockei%20Comercio%20de%20Alimentos%20Importados!5e0!3m2!1spt-BR!2sbr!4v1611152650389!5m2!1spt-BR!2sbr"
          width="100%"
          height="600"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
      </Div>
    );
  }

  renderTemplateNossasLojas = () => {
    let empresa = this.props.empresa || {};
    let filiais = Utils.isValueValid(empresa) ? empresa.filiais : [];

    return (
      <Div
        className="scrollhost"
        style={{
          width: "60%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 50,
        }}
      >
        <I
          icon={Type.ICON.CLOSEX}
          sizeIcon="2"
          colorText={Color.NODE.DANGER}
          handlerClick={() => this.setState({ abrirNossasLojas: false })}
          style={{ position: "absolute", top: 15, right: 15 }}
          pointer
        />
        <Label
          value="Clique em cada endereço para abrir a localização no mapa."
          family="SemiBold"
          style={{ color: Color.EMPRESA.PRIMARIA, fontSize: 20 }}
        />
        <If and value1={Utils.isValueValid(filiais)}>
          {Array.from(filiais).map((filial, index) => {
            return (
              <Div
                className="d-flex flex-column justify-content-start align-items-start"
                handlerClick={() =>
                  this.setState({ abrirMapa: !this.state.abrirMapa })
                }
                pointer
                marginTop="3"
              >
                <Label
                  value={filial.razaoSocial}
                  pointer
                  family="Bold"
                  style={{
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    lineHeight: "15px",
                  }}
                />
                <Label
                  value={Mask.getValueMaskPhone(filial.telefone)}
                  pointer
                  family="SemiBold"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "10px" }}
                />
                <Label
                  value={`${filial.logradouro} n° ${filial.numero}, ${filial.bairro}`}
                  pointer
                  family="Light"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "10px" }}
                />
              </Div>
            );
          })}
        </If>
      </Div>
    );
  };

  renderTemplateMenuLeft = () => {
    let empresa = this.props.empresa || {};
    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    let linkWhatsApp = `https://wa.me/55${String(
      parametros.telefoneWhatsapp
    ).replace(" ", "")}`;

    let horarioFuncionamento = [];
    if (Utils.isValueValid(parametros)) {
      horarioFuncionamento = String(parametros.horarioFuncionamento).split(
        "</br>"
      );
    }

    return (
      <Div className="scrollhost">
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          paddingTop="4"
          inline="start"
        >
          <Image marginLeft="3" src={parametros.urlFoto} width="250px" border />
          <Div
            style={{ marginTop: 50 }}
            col="12"
            className="d-flex flex-column justify-content-stat align-items-start"
          >
            <Label
              value="Sobre a empresa"
              family="SemiBold"
              style={{ color: "#C4C4C4" }}
            />
            <Label
              value="Políticas e regulamentos"
              family="SemiBold"
              style={{ color: "#C4C4C4" }}
            />
            <Label
              value="Ajuda & Suporte"
              family="SemiBold"
              style={{ color: "#C4C4C4" }}
            />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          className="pt-2 pb-2 border-top border-bottom"
          handlerClick={() =>
            this.setState({ abrirNossasLojas: !this.state.abrirNossasLojas, abrirMapa: false })
          }
        >
          <Div
            inline="between"
            col="12"
            style={{ paddingRight: "10%" }}
            pointer
          >
            <Div inline="start" pointer>
              <Image src={IconeMinhasLojas} pointer />
              <Label value="Nossas lojas" margin="3" pointer />
            </Div>
            <Image src={IconArrowRight} pointer />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          inline="between"
          col="12"
        >
          <Div inline="start" col="7">
            <Label value="SAC" style={styles.labelSAC} family="Bold" />
            <Label
              marginLeft="2"
              value="Serviço de atendimento ao consumidor"
              style={styles.labelServicoAtendimento}
              family="Bold"
            />
          </Div>
          <Div className="d-flex flex-column align-items-start" col="5">
            <Label
              value={Mask.getValueMaskPhone(empresa.telefone)}
              style={styles.labelTelefone}
              family="Bold"
            />
            <Label
              value={parametros.emailAtendimento}
              style={styles.labelEmail}
              family="Bold"
            />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          inline="start"
        >
          <Div
            style={{
              color: "white",
              backgroundColor: Color.ECOMMERCE.LARANJA,
              width: 120,
              height: 120,
              margin: 10,
              padding: 10,
              borderRadius: 10,
            }}
            inline="center"
          >
            <Div column>
              <Image src={IconMensagem} width="50px" />
              <Label value="Mensagem" marginTop="3" />
            </Div>
          </Div>
          <Div
            style={{
              color: "white",
              backgroundColor: "#25D366",
              width: 120,
              height: 120,
              margin: 10,
              padding: 10,
              borderRadius: 10,
            }}
            inline="center"
            pointer
            handlerClick={() => {
              window.open(linkWhatsApp, "_blank");
            }}
          >
            <Div column pointer>
              <Image src={IconWhatsapp} width="45px" pointer />
              <Label value="Whatsapp" marginTop="3" pointer />
            </Div>
          </Div>
        </Div>
        <Div col="12">
          <Label
            value={`Atendimento de ${horarioFuncionamento[0]}`}
            style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
            family="Light"
            col="12"
          />
        </Div>
        <Div
          style={{
            backgroundColor: Color.ECOMMERCE.LARANJA,
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "2%",
            paddingBottom: "2%",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
          inline="between"
        >
          <Div>
            <Label
              value="Baixe nosso app"
              style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="start">
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 140,
                  height: 45,
                  padding: 5,
                  color: "white",
                }}
                inline="center"
              >
                <Image src={IconApple} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="App Store"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="iOS"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 140,
                  height: 45,
                  padding: 5,
                  color: "white",
                  marginLeft: 15,
                }}
                inline="center"
              >
                <Image src={IconGooglePlay} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="Google Play"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="Android"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
            </Div>
          </Div>
          <Div>
            <Label
              value="Redes sociais"
              style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="between">
              <Image
                src={IconFacebook}
                width="45px"
                height="45px"
                marginRight="1"
              />
              <Image
                src={IconInstagram}
                width="45px"
                height="45px"
                marginLeft="1"
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  renderTemplateMenuCarrinho = () => {
    return (
      <Carrinho
        {...this.props}
        handlerAddItem={(produto) => this.props.handlerAddItem(produto)}
        handlerRemoveItem={(produto) => this.props.handlerRemoveItem(produto)}
        handlerFecharMenu={() => this.onSetSidebarOpen(false)}
      />
    );
  };

  getPropsSideBarMenuLeft = () => {
    return {
      sidebar: this.renderTemplateMenuLeft(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      styles: {
        sidebar: {
          background: "white",
          width: window.innerWidth < 600 ? "80%" : "40%",
          height: "100%",
          minHeight: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
        },
      },
    };
  };

  getPropsSideBarMenuCarrinho = () => {
    return {
      sidebar: this.renderTemplateMenuCarrinho(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: Utils.isViewMobile() ? "80%" : "25%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: 1011,
        },
      },
    };
  };

  getPropsSideBar() {
    const { isSideBarMenuLeft } = this.state;
    return isSideBarMenuLeft
      ? this.getPropsSideBarMenuLeft()
      : this.getPropsSideBarMenuCarrinho();
  }

  getUsuarioLogado() {
    let usuario = localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB);

    return Utils.isValueValid(usuario) ? JSON.parse(usuario) : usuario;
  }

  getEnderecoPrincipal(usuario) {
    let endereco = {};

    if (Utils.isValueValid(usuario)) {
      endereco = Array.from(usuario.enderecos).filter(
        (endereco) => endereco.principal
      )[0];
    }

    return endereco;
  }

  getQuantidadeProdutos(carrinho) {
    let quantidade = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      quantidade = carrinho.cabecalho.quantidadeProdutos || "0";
    }

    return quantidade;
  }

  getValorTotalPedido(carrinho) {
    let valorTotal = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      valorTotal = carrinho.cabecalho.valorTotal || "0";
    }

    return valorTotal;
  }

  render() {
    const { carrinho, empresa } = this.props;

    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};

    let usuarioLogado = this.getUsuarioLogado() || {};
    let enderecoPrincipal = this.getEnderecoPrincipal(usuarioLogado);

    return (
      <Div inline="between">
        <Sidebar {...this.getPropsSideBar()} className="scrollhost">
          <Div inline="between">
            <Div inline="start" marginBottom="4">
              <IconMenuLeft
                className="gray-svg"
                style={{
                marginLeft: "21px",
                marginTop: "38px",
                width: "25px",
                height: "25px",
                cursor: "pointer",
                }}
                onClick={
                  () => this.setState({ isSideBarMenuLeft: true }, () => {
                    this.onSetSidebarOpen(true);
                  })
                }
              />
              <Image
                src={parametros.urlFoto}
                width="200px"
                className="imagelogo"
                handlerClick={() => this.props.history.replace("/")}
                pointer
              />
            </Div>
            <Div inline="end" margin="1" className="navbar-menu-carrinho">
              <If and value1={!Utils.isValueValid(usuarioLogado)}>
                <Div style={{ position: "absolute", top: 23, right: 70 }}>
                  <Label
                    value="Registar"
                    style={styles.btnRegistrar}
                    marginLeft="1"
                    family="SemiBold"
                    marginRight="1"
                    pointer
                    handlerClick={() =>
                      this.props.history.push("/cadastrarcliente", {
                        logoEmpresa: parametros.urlFoto,
                      })
                    }
                  />
                  <Label
                    value="Entrar"
                    style={styles.btnEntrar}
                    marginLeft="1"
                    marginRight="1"
                    pointer
                    family="SemiBold"
                    handlerClick={() => this.props.handlerLogin()}
                  />
                </Div>
              </If>
              <If and value1={Utils.isValueValid(usuarioLogado)}>
                <Div
                  className="d-flex flex-column justify-content-end align-items-end"
                  style={{ position: "absolute", right: 70 }}
                  handlerClick={() => this.props.handlerMenuCliente()}
                >
                  <Label
                    value={usuarioLogado.nome}
                    family="Bold"
                    style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 13 }}
                  />
                  <Div inline="center">
                    <Label
                      value={enderecoPrincipal.descricao}
                      family="Bold"
                      style={{
                        color: "#878787",
                        fontSize: 11,
                        transform: `translateY(-10px)`,
                      }}
                    />
                    <Label
                      value={`${enderecoPrincipal.logradouro}, ${enderecoPrincipal.bairro}`}
                      family="Light"
                      marginLeft="1"
                      style={{
                        color: "#878787",
                        fontSize: 11,
                        transform: `translateY(-10px)`,
                      }}
                    />
                  </Div>
                </Div>
              </If>
              <Div className="d-flex flex-column align-items-end">
                <Div>
                  <Label
                    value={this.getQuantidadeProdutos(carrinho || {})}
                    style={styles.labelQuantidadeTotalItens}
                    family="Bold"
                  />
                  <Image
                    id="btnMenuDireito"
                    src={IconMenuCarrinho}
                    style={styles.menuCarrinho}
                    handlerClick={() => {
                      this.setState(
                        {
                          isSideBarMenuLeft: false,
                        },
                        () => {
                          this.onSetSidebarOpen(true);
                        }
                      );
                    }}
                    pointer
                    margin="2"
                    width="25px"
                  />
                </Div>
                <Label
                  value={Mask.formatValueBr(
                    this.getValorTotalPedido(carrinho || {})
                  )}
                  style={styles.labelValorTotalCarrinho}
                  family="Bold"
                  margin="2"
                />
              </Div>
            </Div>
          </Div>
        </Sidebar>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={!this.state.abrirMapa}
        >
          {this.renderTemplateNossasLojas()}
        </If>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={this.state.abrirMapa}
        >
          {this.renderMapaLoja()}
        </If>
        <Div
          handlerClick={() => {
            this.forceUpdate();
          }}
          id="forceUpdateGambiarra"
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  let div = document.getElementById("forceUpdateGambiarra");
  if (Utils.isValueValid(div)) div.click();
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
  };
};

export default connect(mapStateToProps)(NavbarLojaInstantanea);
