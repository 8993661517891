import React, { Component } from "react";

import Sidebar from "react-sidebar";
import { I, Image, Label, RadioCustom, Div, If } from "../../../components";

import IconWhatsapp from "../../../assets/images/icones/icon-whatsapp.png";
import IconMensagem from "../../../assets/images/icones/icon-mensagem.png";
import {ReactComponent as IconMenuLeft } from "../../../assets/images/icon-menu-esquerdo.svg";
import IconApple from "../../../assets/images/icones/icon-apple.png";
import IconGooglePlay from "../../../assets/images/icones/icon-googleplay.png";
import IconFacebook from "../../../assets/images/icones/icon-facebook.png";
import IconInstagram from "../../../assets/images/icones/icon-instagram.png";

import IconMenuCarrinho from "../../../assets/images/icon-menu-carrinho.png";
import IconeMinhasLojas from "../../../assets/images/icones/icon-minhas-lojas.png";
import IconArrowRight from "../../../assets/images/icones/icon-arrow-right.png";
import {ReactComponent as IconeLojaHome} from "../../../assets/images/icon-loja-home.svg";

import {
  Color,
  EndPoints,
  Http,
  Keys,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../../utilities";
import { connect } from "react-redux";

import "../header.css";
import CarrinhoMobile from "../../../views/carrinho/CarrinhoMobile";
import UsuarioUtils from "../../../views/utils/UsuarioUtils";
import CarrinhoUtils from "../../../views/utils/CarrinhoUtils";
import { setEnderecoEntrega } from "../../../redux/actions";
import { bindActionCreators } from "redux";
import Zindex from "../../../views/utils/Zindex";
import { EmpresaUtils } from "../..";

const styles = {
  menuCarrinho: {
    background: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    marginTop: 5,
    marginRight: 15,
  },
  labelValorTotalCarrinho: {
    color: Color.EMPRESA.TERCIARIA,
  },
  labelSAC: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  labelServicoAtendimento: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
  },
  labelTelefone: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelEmail: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
  },
  labelQuantidadeTotalItens: {
    position: "fixed",
    top: 35,
    right: 12,
    width: "25px",
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    borderRadius: "50%",
    padding: 5,
    fontSize: 10,
    textAlign: "center",
  },
  btnRegistrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: "#878787",
  },
  btnEntrar: {
    backgroundColor: "#f2f2f2",
    padding: 10,
    borderRadius: 10,
    color: Color.ECOMMERCE.LARANJA,
  },
  containerRodape: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "2%",
    paddingBottom: "2%",
    width: "100%",
    position: window.innerHeight < 600 ? "relative" : "fixed",
    bottom: 0,
  },
  containerRodapeMobileGrande: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "2%",
    paddingBottom: "2%",
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  containterLocaisEntrega: {
    height: 155,
    overflowY: "scroll",
  },
};

class HeaderNavegaoWebMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      isSideBarMenuLeft: true,
      propsSideBar: {},
      carrinho: props.carrinho,
      abrirNossasLojas: false,
      abrirMapa: false,
      abrirSobreEmpresa: false,
      abrirPoliticaRegulamento: false,
      abrirMenuUsuario: false,
      sobreEmpresa: {},
      politicaEmpresa: {},
      filialMapa: {},
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      propsSideBar: this.getPropsSideBarMenuLeft(),
    });
  }

  componentDidMount() {
    this.consultarPoliticaEmpresa();
    this.consultarSobreEmpresa();
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open }, () => {
      if (open) {
        Utils.toggleScrollBody("hidden");
      } else {
        Utils.toggleScrollBody("auto");
      }
    });
  }

  consultarPoliticaEmpresa() {
    Http.get(EndPoints.EMPRESA_POLITICA).then(({ status, data }) => {
      if (status) {
        let politicaEmpresa = data.politica;
        politicaEmpresa = String(politicaEmpresa)
          .replace("font: 20.0px", "font: 12.0px")
          .replace("font: 20.0px", "font: 12.0px");

        this.setState({ politicaEmpresa });
      } else {
        console.log("Falha ao consultar política da empresa");
      }
    });
  }

  consultarSobreEmpresa() {
    Http.get(EndPoints.EMPRESA_SOBRE).then(({ status, data }) => {
      if (status) {
        this.setState({ sobreEmpresa: data.sobre });
      } else {
        console.log("Falha ao consultar sobre da empresa");
      }
    });
  }

  renderTemplateNossasLojas = () => {
    let empresa = this.props.empresa || {};
    let filiais = Utils.isValueValid(empresa) ? empresa.filiais : [];

    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.TEMPLATE_NOSSAS_LOJAS,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
          padding: 10,
        }}
      >
        <I
          icon={Type.ICON.CLOSEX}
          sizeIcon="2"
          colorText={Color.NODE.DANGER}
          handlerClick={() => this.setState({ abrirNossasLojas: false })}
          style={{ position: "absolute", top: 5, right: 5 }}
          pointer
        />
        <Label
          value="Clique em cada endereço para abrir a localização no mapa."
          family="SemiBold"
          style={{ color: Color.EMPRESA.PRIMARIA, fontSize: 15 }}
        />
        <If and value1={Utils.isValueValid(filiais)}>
          {Array.from(filiais).map((filial, index) => {
            return (
              <Div
                key={index}
                className="d-flex flex-column justify-content-start align-items-start border p-1 shadow"
                handlerClick={() =>
                  this.setState({
                    abrirMapa: !this.state.abrirMapa,
                    filialMapa: filial,
                  })
                }
                style={{ borderRadius: 5 }}
                pointer
                marginTop="3"
              >
                <Label
                  value={filial.razaoSocial}
                  pointer
                  family="Bold"
                  style={{
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    fontSize: 13,
                  }}
                />
                <Label
                  value={Mask.getValueMaskPhone(filial.telefone)}
                  pointer
                  family="SemiBold"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "15px" }}
                />
                <Label
                  value={`${filial.enderecoCompleto} - CEP: ${filial.cep}`}
                  pointer
                  family="Light"
                  style={{ color: Color.ECOMMERCE.CINZA, lineHeight: "15px" }}
                />
              </Div>
            );
          })}
        </If>
      </Div>
    );
  };

  renderAtendimento(empresa, parametros, corPrimaria) {
    let linkWhatsApp = `https://wa.me/55${String(
      parametros.telefoneWhatsapp
    ).replace(" ", "")}`;

    return Utils.isViewTabletAll() ? (
      <>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          column
        >
          <Div inline="center">
            <Label
              value="SAC"
              style={{ ...styles.labelSAC, color: corPrimaria }}
              family="Bold"
            />
            <Label
              marginLeft="2"
              value="Serviço de atendimento ao consumidor"
              style={{ ...styles.labelServicoAtendimento, color: corPrimaria }}
              family="Bold"
            />
          </Div>
          <Div className="d-flex flex-column align-items-center">
            <Label
              value={Mask.getValueMaskPhone(empresa.telefone)}
              style={styles.labelTelefone}
              family="Bold"
            />
            <Label
              value={parametros.emailAtendimento}
              style={{ ...styles.labelEmail, color: corPrimaria }}
              family="Bold"
            />
          </Div>
        </Div>
        <Div
          inline="center"
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
        >
          <Div
            style={{
              color: "white",
              backgroundColor: corPrimaria,
              width: 150,
              height: 150,
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
            inline="center"
          >
            <Div column>
              <Image src={IconMensagem} width="50px" />
              <Label value="Mensagem" marginTop="3" />
            </Div>
          </Div>
          <Div
            style={{
              color: "white",
              backgroundColor: "#25D366",
              width: 150,
              height: 150,
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
            inline="center"
            handlerClick={() => {
              window.open(linkWhatsApp, "_blank");
            }}
          >
            <Div column>
              <Image src={IconWhatsapp} width="35px" />
              <Label value="Whatsapp" marginTop="3" />
            </Div>
          </Div>
        </Div>
      </>
    ) : (
      <>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
          inline="center"
        >
          <Div inline="center">
            <Label
              value="SAC"
              style={{ ...styles.labelSAC, color: corPrimaria }}
              family="Bold"
            />
            <Label
              marginLeft="2"
              value="Serviço de atendimento ao consumidor"
              style={{ ...styles.labelServicoAtendimento, color: corPrimaria }}
              family="Bold"
            />
          </Div>
          <Div className="d-flex flex-column align-items-center">
            <Label
              value={Mask.getValueMaskPhone(empresa.telefone)}
              style={styles.labelTelefone}
              family="Bold"
            />
            <Label
              value={parametros.emailAtendimento}
              style={styles.labelEmail}
              family="Bold"
            />
          </Div>
        </Div>
        <Div
          inline="center"
          style={{ paddingLeft: "10%", paddingRight: "10%", marginTop: "2%" }}
        >
          <Div
            style={{
              color: "white",
              backgroundColor: corPrimaria,
              width: 90,
              height: 90,
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
            inline="center"
          >
            <Div column>
              <Image src={IconMensagem} width="50px" />
              <Label value="Mensagem" marginTop="3" />
            </Div>
          </Div>
          <Div
            style={{
              color: "white",
              backgroundColor: "#25D366",
              width: 90,
              height: 90,
              padding: 10,
              borderRadius: 10,
              margin: 5,
            }}
            inline="center"
            handlerClick={() => {
              window.open(linkWhatsApp, "_blank");
            }}
          >
            <Div column>
              <Image src={IconWhatsapp} width="35px" />
              <Label value="Whatsapp" marginTop="3" />
            </Div>
          </Div>
        </Div>
      </>
    );
  }

  renderTemplateMenuLeft = () => {
    let empresa = this.props.empresa || {};

    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};
    let corPrimaria = EmpresaUtils.getCorPrimaria(empresa);
    let corRodape = EmpresaUtils.getCorRodape(empresa);

    return (
      <Div className="scrollhost">
        <I
          icon={Type.ICON.CLOSEX}
          colorText={Color.NODE.DANGER}
          style={{ position: "absolute", right: 10, top: 10 }}
          sizeIcon="2"
          handlerClick={() => this.onSetSidebarOpen(false)}
        />
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          paddingTop="4"
          className="d-flex flex-column"
        >
          <Image marginLeft="2" src={parametros.urlFoto} width="150px" border />
          <Div style={{ marginTop: 20 }} marginLeft="2">
            <Label
              value="Sobre a empresa"
              className="w-100"
              family="SemiBold"
              style={{ color: "#C4C4C4" }}
              handlerClick={() =>
                this.setState({
                  abrirSobreEmpresa: !this.state.abrirSobreEmpresa,
                  abrirPoliticaRegulamento: false,
                  abrirNossasLojas: false,
                })
              }
              pointer
            />
            <Label
              value="Políticas e regulamentos"
              className="w-100"
              pointer
              family="SemiBold"
              style={{ color: "#C4C4C4" }}
              handlerClick={() =>
                this.setState({
                  abrirPoliticaRegulamento:
                    !this.state.abrirPoliticaRegulamento,
                  abrirSobreEmpresa: false,
                  abrirNossasLojas: false,
                })
              }
            />
          </Div>
        </Div>
        <Div
          style={{ paddingLeft: "10%", paddingRight: "10%" }}
          className="pt-2 pb-2 border-top border-bottom"
          handlerClick={() =>
            this.setState({
              abrirNossasLojas: !this.state.abrirNossasLojas,
              abrirMapa: false,
            })
          }
        >
          <Div inline="between" paddingLeft="2" style={{ paddingRight: "10%" }}>
            <Div inline="start">
              <IconeLojaHome />
              <Label value="Nossas lojas" margin="3" />
            </Div>
            <Image src={IconArrowRight} />
          </Div>
        </Div>
        {this.renderAtendimento(empresa, parametros, corPrimaria)}
        <Div
          style={{ ...styles.containerRodape, backgroundColor: corRodape }}
          inline="between"
        >
          <Div>
            <Label
              value="Baixe nosso app"
              style={{ color: corPrimaria, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="start">
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 120,
                  height: 45,
                  padding: 5,
                  color: "white",
                }}
                inline="center"
              >
                <Image src={IconApple} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="App Store"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="iOS"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
              <Div
                style={{
                  backgroundColor: "black",
                  border: "1px solid white",
                  borderRadius: 5,
                  width: 120,
                  height: 45,
                  padding: 5,
                  color: "white",
                  marginLeft: 15,
                }}
                inline="center"
              >
                <Image src={IconGooglePlay} width="20px" paddingBottom="2" />
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  marginLeft="3"
                >
                  <Label
                    value="Google Play"
                    family="Bold"
                    style={{ height: 10, fontSize: 12 }}
                  />
                  <Label
                    value="Android"
                    family="Light"
                    style={{ height: 10, fontSize: 8, marginTop: 1 }}
                  />
                </Div>
              </Div>
            </Div>
          </Div>
          <Div>
            <Label
              value="Redes sociais"
              style={{ color: corPrimaria, fontSize: 13 }}
              family="Bold"
            />
            <Div inline="between">
              <a href={parametros.urlFacebook} target="_blank">
                <Image
                  src={IconFacebook}
                  width="45px"
                  height="45px"
                  marginRight="1"
                />
              </a>
              <a href={parametros.urlInstagram} target="_blank">
                <Image
                  src={IconInstagram}
                  width="45px"
                  height="45px"
                  marginLeft="1"
                />
              </a>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  renderTemplateMenuCarrinho = () => {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    return (
      <CarrinhoMobile
        {...this.props}
        handlerAddItem={(produto) => this.props.handlerAddItem(produto)}
        handlerRemoveItem={(produto) => this.props.handlerRemoveItem(produto)}
        handlerFecharMenu={() => this.onSetSidebarOpen(false)}
        corPrimaria={corPrimaria}
      />
    );
  };

  getPropsSideBarMenuLeft = () => {
    return {
      sidebar: this.renderTemplateMenuLeft(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      styles: {
        sidebar: {
          background: "white",
          width: "100%",
          height: "100%",
          minHeight: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBarMenuCarrinho = () => {
    return {
      sidebar: this.renderTemplateMenuCarrinho(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBarMenuUsuario = () => {
    return {
      sidebar: this.renderMenuUsuario(),
      open: this.state.sidebarOpen,
      onSetOpen: this.onSetSidebarOpen,
      pullRight: true,
      styles: {
        sidebar: {
          background: "white",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MENU,
        },
      },
    };
  };

  getPropsSideBar() {
    const { isSideBarMenuLeft, abrirMenuUsuario } = this.state;

    let propsSideBar = null;
    if (isSideBarMenuLeft) {
      propsSideBar = this.getPropsSideBarMenuLeft();
    } else {
      if (abrirMenuUsuario) {
        propsSideBar = this.getPropsSideBarMenuUsuario();
      } else {
        propsSideBar = this.getPropsSideBarMenuCarrinho();
      }
    }

    return propsSideBar;
  }

  getUsuarioLogado() {
    let usuario = localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB);

    return Utils.isValueValid(usuario) ? JSON.parse(usuario) : usuario;
  }

  getEnderecoPrincipal(usuario) {
    let endereco = {};

    if (Utils.isValueValid(usuario)) {
      endereco = Array.from(usuario.enderecos).filter(
        (endereco) => endereco.principal
      )[0];
    }

    return endereco;
  }

  getQuantidadeProdutos(carrinho) {
    let quantidade = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      quantidade = carrinho.itens.length  || "0";
    }

    return Number(quantidade).toFixed(0);
  }

  getValorTotalPedido(carrinho) {
    let valorTotal = "0";

    if (Utils.isValueValid(carrinho.cabecalho)) {
      valorTotal = carrinho.cabecalho.valorTotal || "0";
    }

    return valorTotal;
  }

  renderMapaLoja() {
    let { urlGoogleMaps } = this.state.filialMapa;
    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.MAPA_LOJA,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
        }}
      >
        <Div
          inline="end"
          handlerClick={() => this.setState({ abrirMapa: false })}
          style={{ position: "absolute", top: 5, left: 5 }}
        >
          <I
            icon={Type.ICON.CLOSEX}
            sizeIcon="2"
            colorText={Color.NODE.DANGER}
          />
        </Div>
        <iframe
          src={urlGoogleMaps}
          width="100%"
          height="600"
          frameborder="0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        />
      </Div>
    );
  }

  renderTemplateSobrePolitica() {
    return (
      <Div
        className="scrollhost"
        style={{
          background: "#f2f2f2",
          width: "100%",
          height: "100%",
          position: "fixed",
          overflowX: "hidden",
          zIndex: Zindex.TEMPLATE_POLITICA,
          top: 0,
          bottom: 0,
          right: 0,
          transition: "1s",
        }}
      >
        <Div
          inline="end"
          handlerClick={() =>
            this.setState({
              abrirMapa: false,
              abrirPoliticaRegulamento: false,
              abrirSobreEmpresa: false,
            })
          }
          pointer
        >
          <I
            icon={Type.ICON.CLOSEX}
            sizeIcon="2"
            colorText={Color.NODE.DANGER}
            pointer
            style={{ position: "fixed", top: 10, right: 10 }}
          />
        </Div>
        {this.state.abrirPoliticaRegulamento ? (
          <div
            style={{ padding: 10 }}
            dangerouslySetInnerHTML={{ __html: this.state.politicaEmpresa }}
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: this.state.sobreEmpresa }} />
        )}
      </Div>
    );
  }

  getEnderecoSelecionado(endereco) {
    let selecionado = false;

    let carrinho = CarrinhoUtils.getCarrinho();

    if (Utils.isValueValid(carrinho)) {
      let enderecoEntrega = carrinho.enderecoEntrega;
      if (Utils.isValueValid(enderecoEntrega)) {
      }

      if (Utils.isValueValid(enderecoEntrega)) {
        selecionado =
          enderecoEntrega.idClienteEndereco === endereco.idClienteEndereco;
      }
    }

    return selecionado;
  }

  setEnderecoSelecionado(endereco) {
    let carrinho = {
      ...this.props.carrinho,
      enderecoEntrega: endereco,
    };

    this.props.setEnderecoEntrega(carrinho);
  }

  renderMeusLocaisDeEntrega(enderecos, corPrimaria) {
    return (
      <Div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 20,
            marginTop: 10,
          }}
          family="Bold"
          value="Meu local de entrega"
        />
        <Div marginTop="2" style={styles.containterLocaisEntrega}>
          {enderecos.map((endereco, index) => {
            let isEnderecoSelecionado = this.getEnderecoSelecionado(endereco);
            return (
              <Div inline="start" key={index}>
                <Div className="d-flex flex-column justify-content-start align-items-start"></Div>
                <RadioCustom
                  checked={isEnderecoSelecionado}
                  handlerChange={(e) => this.setEnderecoSelecionado(endereco)}
                  left
                  value={
                    <>
                      <Label
                        value={endereco.descricao}
                        style={{
                          lineHeight: "15px",
                          fontSize: 13,
                          width: 200,
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                        }}
                        family="Bold"
                        className="text-ellipsis"
                      />
                      <Label
                        value={`${endereco.logradouro}, ${endereco.bairro}`}
                        style={{
                          lineHeight: "13px",
                          fontSize: 13,
                          width: 200,
                          color: Color.ECOMMERCE.CINZA,
                        }}
                        family="Light"
                        className="text-ellipsis"
                      />
                    </>
                  }
                />
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  renderMenuUsuario() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let empresa = this.props.empresa || {};
    let urlLogo = empresa.parametros.urlFoto;

    let parametros = empresa.parametros || {};

    let corPrimaria = EmpresaUtils.getCorPrimaria(empresa);

    return (
      <Div className="scrollhost">
        <I
          icon={Type.ICON.CLOSEX}
          style={{ color: corPrimaria }}
          style={{ position: "absolute", right: 10, top: 10 }}
          sizeIcon="2"
          handlerClick={() => {
            this.setState({ abrirMenuUsuario: false }, () =>
              this.onSetSidebarOpen(false)
            );
          }}
        />
        <Div
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
          paddingTop="4"
          inline="start"
          className="border-bottom"
        >
          <I
            icon={Type.ICON.USER}
            style={{
              fontSize: 50,
              color: corPrimaria,
              margin: 10,
            }}
          />
          <Label
            value={`Olá ${cliente.nome}`}
            family="SemiBold"
            style={{ marginTop: 10, color: Color.ECOMMERCE.CINZA_ESCURO }}
          />
        </Div>
        {this.renderMeusLocaisDeEntrega(cliente.enderecos, corPrimaria)}
        <Div>
          <Div
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
            className="pt-2 pb-2 border-top border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              handlerClick={() => {
                this.onSetSidebarOpen(false);
                this.props.history.push(Routes.CLIENTE, {
                  corPrimaria: corPrimaria,
                  urlLogo: urlLogo,
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.USERR}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Dados pessoais"
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
            className="pt-2 pb-2 border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              handlerClick={() => {
                this.setState({ abrirMenuUsuario: false }, () => {
                  this.onSetSidebarOpen(false);
                  this.props.history.push(Routes.CLIENTE_PEDIDOS);
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.REORDER}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Meus pedidos"
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
            className="pt-2 pb-2 border-bottom"
          >
            <Div
              inline="between"
              paddingLeft="2"
              handlerClick={() => {
                this.onSetSidebarOpen(false);
                this.props.history.push(Routes.LISTAS_COMPRAS, {
                  corPrimaria: parametros.corPrimaria,
                  corSecundaria: parametros.corSecundaria,
                  urlFotoEmpresa: parametros.urlFoto,
                  parametrosPedido: this.props.parametros,
                  empresa: this.props.empresa,
                });
              }}
            >
              <Div inline="start">
                <I
                  icon={Type.ICON.LIST_ALT}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Listas de compra"
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
          <Div
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
            className="pt-2 pb-2 border-bottom"
          >
            <Div inline="between" paddingLeft="2">
              <Div inline="start" handlerClick={() => this.sair()}>
                <I
                  icon={Type.ICON.SIGNOUT}
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 30 }}
                />
                <Label
                  value="Sair"
                  margin="3"
                  style={{ fontSize: 20, color: Color.ECOMMERCE.CINZA_ESCURO }}
                  family="SemiBold"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  sair() {
    UsuarioUtils.removerUsuarioLojaWeb();
    Utils.refreshPage();
  }

  getNomeUsuario(usuario) {
    let nome = "";

    if (Utils.isValueValid(usuario)) {
      nome = String(usuario.nome).split(" ")[0];
    }

    return nome;
  }

  render() {
    const { carrinho, empresa } = this.props;

    let parametros = Utils.isValueValid(empresa) ? empresa.parametros : {};
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    let corPrimaria = EmpresaUtils.getCorPrimaria(empresa);

    return (
      <Div inline="between">
        <Sidebar {...this.getPropsSideBar()} className="scrollhost">
          <Div inline="between">
            <IconMenuLeft
              className="gray-svg"
              style={{
                marginLeft: "12px",
                marginTop: "5px",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({ isSideBarMenuLeft: true }, () => {
                  this.onSetSidebarOpen(true);
                })
              }
            />

            <Image
              src={parametros.urlFoto}
              width="130px"
              className="imagelogo imagelogo-mobile"
              handlerClick={() => this.props.history.replace("/")}
              pointer
              style={{marginTop: 10}}
            />
            <Div inline="end">
              <If and value1={Utils.isValueValid(usuario)}>
                <Div
                  handlerClick={() => {
                    this.setState(
                      {
                        isSideBarMenuLeft: false,
                        abrirMenuUsuario: true,
                      },
                      () => {
                        this.onSetSidebarOpen(true);
                      }
                    );
                  }}
                  column
                  style={{ transform: `translate(-10px, 13px)` }}
                >
                  <I
                    icon={Type.ICON.USER}
                    style={{
                      fontSize: 30,
                      color: corPrimaria,
                    }}
                  />
                  <Label
                    value={this.getNomeUsuario(usuario)}
                    style={{ fontSize: 11 }}
                  />
                </Div>
              </If>

              <Div className="d-flex flex-column align-items-end">
                <Div
                  handlerClick={() => {
                    this.setState(
                      {
                        isSideBarMenuLeft: false,
                        abrirMenuUsuario: false,
                      },
                      () => {
                        this.onSetSidebarOpen(true);
                      }
                    );
                  }}
                >
                  <Label
                    value={this.getQuantidadeProdutos(carrinho || {})}
                    style={styles.labelQuantidadeTotalItens}
                    family="Bold"
                  />
                  <Div>
                    <I
                      icon={Type.ICON.SALE_BASKET}
                      style={{
                        ...styles.menuCarrinho,
                        backgroundColor: corPrimaria,
                        color: "white",
                        padding: 12,
                      }}
                      pointer
                    />
                  </Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </Sidebar>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={!this.state.abrirMapa}
        >
          {this.renderTemplateNossasLojas()}
        </If>
        <If
          and
          value1={this.state.abrirNossasLojas}
          value2={this.state.abrirMapa}
        >
          {this.renderMapaLoja()}
        </If>
        <If
          and
          value1={
            this.state.abrirSobreEmpresa || this.state.abrirPoliticaRegulamento
          }
        >
          {this.renderTemplateSobrePolitica()}
        </If>
        <Div
          handlerClick={() => {
            this.forceUpdate();
          }}
          id="forceUpdateGambiarra"
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  let div = document.getElementById("forceUpdateGambiarra");
  if (Utils.isValueValid(div)) {
    setTimeout(() => {
      div.click();
    }, 100);
  }
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEnderecoEntrega }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavegaoWebMobile);
