import React from "react";
import { Div, MessageSpinner } from "../components";
import { Color } from "../utilities";

const AguardeMobile = (props) => {
  return (
    <Div className="vertical-center" style={{width: window.innerWidth - 20}}>
      <MessageSpinner
        value="Carregando , aguarde"
        style={{
          backgroundColor: Color.ECOMMERCE.CINZA_ESCURO,
          color: "white",
          borderRadius: 10,
          padding: 10,
          fontSize: 20,
        }}
      />
    </Div>
  );
};

export default AguardeMobile;
