import React from "react";
import { Div, If, Label, Title } from "../../components";
import { Color, Utils } from "../../utilities";

const WIDTH = 355;
const HEIGHT = 270;

const ItemCategoriaCombo = ({ image, handlerClick }) => {
  return (
    <Div
      style={{ margin: 5 }}
      inline="center"
      handlerClick={() => handlerClick()}
    >
      <img
        src={image}
        style={{
          width: WIDTH,
          height: HEIGHT,
          borderRadius: 10,
        }}
      />
    </Div>
  );
};

export default ItemCategoriaCombo;
