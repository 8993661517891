import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, ModalNotification } from "../../../components";
import {
  atualizarCarrinho,
  setProdutos,
  setEmpresa,
  setParametrosPedido,
} from "../../../redux/actions";
import { EndPoints, Http, Utils } from "../../../utilities";
import ProdutoDetalheMobile from "../../produtos/mobile/ProdutoDetalheMobile";
import UsuarioUtils from "../../utils/UsuarioUtils";
import {
  ProdutosDestaqueMobile,
} from "../../produtos/mobile";
import CarouselBannerGenericoMobile from "../../../components/carousel/CarouselBannerGenericoMobile";
import ContatoWhatsappMobile from "../../ContatoWhatsappMobile";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import AdicionarProdutoListaCompraMobile from "../../produtos/listascompras/mobile/AdicionarProdutoListaCompraMobile";
import { toast } from "react-toastify";
import EmpresaUtils from "../../utils/EmpresaUtils";
import TagManager from "react-gtm-module";
import TagManagerAdmin from "react-gtm-module";
import BannerProdutos from "../../anuncios/BannerProdutos";
import NovoAnuncioBanner from "../../anuncios/NovoAnuncioBanner";


class PrincipalSaccariaMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      produtos: [],
      menus: [],
      produtosVisualizados: [],
      produtosSimilares: [],
      carrinho: props.carrinho,
      empresa: {},
      zIndexHeader: 1001,
      listaSelecionada: null,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.visualizarProduto = this.visualizarProduto.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  consultarUltimosProdutosVisualizados() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(cliente)) {
      Http.get(`/produto/visualizados/cliente/${cliente.idCliente}`)
        .then(({ status, data }) => {
          if (status) {
            this.setState({ produtosVisualizados: data });
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  componentDidMount() {
    this.consultarUltimosProdutosVisualizados();
    CarrinhoUtils.calcularValorTaxaEntrega(
      this.props.parametros,
      this.props.carrinho,
      this.props.atualizarCarrinho
    );
    this.setTitlePage();
    this.setFavIcon();
    //this.setGoogleTagManager();
  }

  setFavIcon = () => {
    let iconFiveIcon = document.querySelector("link[rel~='icon']");
    let urlFavIcon = EmpresaUtils.getParametro(
      this.props.empresa,
      "urlFavIcon"
    );
    if (!iconFiveIcon) {
      iconFiveIcon = document.createElement("link");
      iconFiveIcon.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(iconFiveIcon);
    }
    iconFiveIcon.href = `${urlFavIcon}?${Utils.uuidString()}`;
  };

  setTitlePage = () => {
    document.title = EmpresaUtils.getParametro(
      this.props.empresa,
      "tituloPagina"
    );
  };

  setGoogleTagManager = () => {
    let gtm = EmpresaUtils.getParametro(this.props.empresa, "googleTagManager");
    if (Utils.isValueValid(gtm)) {
      const tagManagerArgs = {
        gtmId: gtm,
      };
      TagManager.initialize(tagManagerArgs);
    }

    let mobiohGtm = EmpresaUtils.getParametro(
      this.props.empresa,
      "mobiohGoogleTagManager"
    );
    if (Utils.isValueValid(mobiohGtm)) {
      const tagManagerArgs = {
        gtmId: mobiohGtm,
      };
      TagManagerAdmin.initialize(tagManagerArgs);
    }
  };

  listarProdutosSimilares(produto, updateItem) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      produto.combo ? "Informações do combo" : "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }
          toast.info("Produto adicionado no carrinho!", {
            hideProgressBar: true,
            position: "top-right",
          });
        }}
        history={this.props.history}
        empresa={this.props.empresa}
        listasDeCompraCliente={this.props.listasDeCompra}
        handlerAdicionarProdutoLista={() =>
          this.renderAdicionarProdutoLista(produto, updateItem)
        }
      />
    );
  };

  renderAdicionarProdutoLista(produto, updateItem) {
    let listas = this.props.listasDeCompra;
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutoListaCompraMobile
          listas={listas}
          produto={produto}
          empresa={this.props.empresa}
          handlerCancel={() => this.visualizarProduto(produto, updateItem)}
          handlerNovaListaCadastrada={() =>
            this.visualizarProduto(produto, updateItem)
          }
        />
      ),
    });
  }

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.props.empresa,
        possuiProdutos,
      });
    }
  }

  renderVideo(item) {
    ModalNotification.renderMessage({
      title: item.titulo,
      body: (
        <Div inline="center">
          <video src={item.urlVideo} controls style={{ width: "100%" }} />
        </Div>
      ),
      responsive: "6",
      handlerCancel: () => {
        ModalNotification.removeModal();
      },
      labelButtonCancel: "Fechar",
    });
  }

  getItensBannerTopo() {
    const anuncios = this.props.anuncios;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <>
            {item.exibirVideo ? (
              <img
                src={item.urlFotoMobile}
                onClick={() => this.renderVideo(item)}
                width="100%"
                className="cursor-pointer"
              />
            ) : (
              <img
                src={item.urlFotoMobile}
                width="100%"
                className="cursor-pointer"
                onClick={() => this.visualizarProdutosAnuncio(item)}
              />
            )}
          </>
        );
      });
    }

    return itens;
  }

  render() {
    let itensBannerNovos = this.props.anuncios;

    return (
      <>
        <Div className="w-100 overflow-hidden" style={{ marginTop: 20 }}>
          <CarouselBannerGenericoMobile
            timeSlide={7000}
            itens={this.getItensBannerTopo()}
            slidesPerPage={1}
            dots
          />
        </Div>
        <ProdutosDestaqueMobile
          history={this.props.history}
          titulo="DESTAQUE"
          handlerClick={this.listarProdutosSimilares}
          empresa={this.props.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          }
        />

        <Div col="12" inline="center">
          <BannerProdutos
            responsive={true}
            anuncios={itensBannerNovos}
            produtos={
              Utils.isNotUndefined(this.props.produtos)
                ? this.props.produtos
                : []
            }
            history={this.props.history}
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <ProdutosDestaqueMobile
          history={this.props.history}
          titulo="MAIS VISTOS"
          handlerClick={this.listarProdutosSimilares}
          empresa={this.props.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          }
        />

        <Div col="12" inline="center">
          <NovoAnuncioBanner
            responsive={true}
            history={this.props.history}
            anuncios={itensBannerNovos}
            numBanner={2}
          />
        </Div>

        <ProdutosDestaqueMobile
          history={this.props.history}
          titulo="MAIS VISTOS"
          handlerClick={this.listarProdutosSimilares}
          empresa={this.props.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          } 
        />

        <Div col="12" inline="center">
          <BannerProdutos
            responsive={true}
            anuncios={itensBannerNovos}
            produtos={
              Utils.isNotUndefined(this.props.produtos)
                ? this.props.produtos
                : []
            }
            history={this.props.history}
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <ProdutosDestaqueMobile
          history={this.props.history}
          titulo="OFERTAS ESPECIAIS"
          handlerClick={this.listarProdutosSimilares}
          empresa={this.props.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          } 
        />

        <Div col="12" inline="center">
          <NovoAnuncioBanner
            responsive={true}
            anuncios={itensBannerNovos}
            numBanner={3}
          />
        </Div>

        <ProdutosDestaqueMobile
          history={this.props.history}
          titulo="OFERTAS ESPECIAIS"
          handlerClick={this.listarProdutosSimilares}
          empresa={this.props.empresa}
          handlerAddItemCarrinho={(produto) =>
            this.adicionarItemNoCarrinho(produto)
          } 
        />

        <Div
          id="divDepartamentosViewPrincipal"
          handlerClick={(e) => e.preventDefault()}
        />
        <ContatoWhatsappMobile empresa={this.props.empresa} />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
    ...store.empresaState,
    ...store.listasDeCompraState,
    ...store.menusState,
    ...store.anunciosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrincipalSaccariaMobile);
