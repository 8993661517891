import React, { Component } from "react";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";

import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import Aguarde from "./views/Aguarde";
import AguardeMobile from "./views/AguardeMobile";
import { Div } from "./components";
import { Utils } from "./utilities";
import Loading from "./components/spinner/Loading";

class Init extends Component {
  getFallBack() {
    return Utils.isViewMobile() ? <AguardeMobile /> : <Aguarde />;
  }

  render() {
    return (
      <ClearBrowserCacheBoundary
        auto
        fallback={Loading.show()}
        duration={60000}
      >
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ClearBrowserCacheBoundary>
    );
  }
}

export default Init;
