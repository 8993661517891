import React, { Component } from "react";
import {
  CarouselAnuncioOfertaDestaque,
  CarouselAnuncioOfertaVerticalMicroMobile,
  Div,
  If,
  Image,
  Label,
  Row,
} from "../../../components";

import CarouselAnuncioOfertaDestaqueMobile from "../../../components/carousel/CarouselAnuncioOfertaDestaqueMobile";

import "../anuncio.css";

import { Color, Mask, Utils } from "../../../utilities";

const WIDTH_IMAGE = window.innerWidth - 30;

const outrasOfertasItens = (anunciosVerticalMicro) => {
  let anuncios = Array.from(anunciosVerticalMicro).filter(
    (anuncio) => anuncio.tipo === "VERTICAL" || anuncio.tipo === "MICRO"
  );
  let itens = [];

  anuncios.forEach(
    (
      {
        titulo,
        preco,
        urlFoto,
        idAnuncioOferta,
        marcaFiltro,
        tipoDepartamentoSecao,
        tipo,
        idDepartamentoSecaoFiltro,
        precoOferta,
        possuiProdutos,
        exibirTitulo,
      },
      index
    ) => {
      itens.push({
        titulo,
        exibirTitulo,
        precoOferta: Mask.formatValueBr(precoOferta),
        preco: Mask.formatValueBr(preco),
        image: urlFoto,
        idAnuncioOferta: idAnuncioOferta,
        marcaFiltro,
        tipo,
        tipoDepartamentoSecao,
        idDepartamentoSecaoFiltro,
        possuiProdutos,
        active: index === 0 ? true : false,
      });
    }
  );

  return itens;
};

class AnuncioOfertasMobile extends Component {
  
  visualizarProdutosAnuncio(anuncio) {
    if (!anuncio.estatico) {
      this.props.history.push("/anuncio/produtos", {
        ...anuncio,
        empresa: this.props.empresa,
      });
    }
  }

  labelsBanners(anuncio) {
    let titulo = ""
    let preco = ""

    if(Utils.isNotObjectEmpty(anuncio)) {
      titulo = anuncio.titulo
      preco = anuncio.preco
    }

    return (
      <>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "90%",
          height: "100%",
          borderRadius: "11px",
          zIndex: 996,
          padding: "20px 32px",
          fontWeight: "bolder",
          background: "#000",
          opacity: "0.4"
        }}
      ></Div>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "100%",
          height: "100%",
          borderRadius: "11px",
          zIndex: 997,
          padding: "20px 32px",
          fontWeight: "bolder",

        }}
      >
        <Row>
          <Label style={{ fontSize: "26px" }} value={titulo} />
        </Row>

        <If and value1={preco}>
          <Label 
            style={{ 
              fontSize: "26px", 
              color: Color.EMPRESA.PRIMARIA 
            }}
            
            value={preco ? "R$ " + Mask.convertNumberBr(preco) : ""} 
          />
        </If>

      </Div>
      </>
    )
  }

  getOfertasDestaque = (anunciosDestaque) => {
    let anuncios = Array.from(anunciosDestaque).filter(
      (anuncio) => anuncio.tipo === "DESTAQUE"
    );
    let itens = [];

    anuncios.forEach((anuncio) => {
      itens.push(
        <>
          <If and value1={anuncio.exibirTitulo}>
            {this.labelsBanners(anuncio)}
          </If>
          <Image
            src={anuncio.urlFotoMobile}
            width={this.getWidthImageMobile()}
            handlerClick={() => this.visualizarProdutosAnuncio(anuncio)}
            styleImage={{ borderRadius: 10 }}
          />
        </>
      );
    });

    return itens;
  };

  getWidthImageMobile() {
    let isViewMobileGrande = Utils.isViewMobileGrande();
    let isViewTablet = Utils.isViewTablet();
    let isViewTabletGrande = Utils.isViewTabletGrande();
    let width = WIDTH_IMAGE;

    if (isViewMobileGrande) width = 300;
    if (isViewTablet) width = 330;
    if (isViewTabletGrande) width = 400;

    return width;
  }

  render() {
    return (
      <Div
        className={
          Utils.isViewTabletAll() || Utils.isViewMobileGrande()
            ? "d-flex justify-content-start align-items-start align-self-start"
            : ""
        }
        pointer
      >
        <CarouselAnuncioOfertaDestaqueMobile
          itens={this.getOfertasDestaque(this.props.anuncios)}
          history={this.props.history}
          widthContainerBanner={this.getWidthImageMobile()}
        />
        <CarouselAnuncioOfertaVerticalMicroMobile
          history={this.props.history}
          itens={outrasOfertasItens(this.props.anuncios)}
        />
      </Div>
    );
  }
}

export default AnuncioOfertasMobile;
