import React, { Component } from "react";
import { Color, Utils } from "../../utilities";
import AnuncioOfertas from "../../views/anuncios/AnuncioOfertas";
import Div from "../layout/div/Div";

import "./carousel.css";

const styles = {
  labelTopLeft: {
    width: "100%",
    height: "30%",
  },
  labelTitulo: {
    color: Color.ECOMMERCE.WHITE,
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: 350,
    //background: "linear-gradient(270deg, #000000 5%, rgba(0, 0, 0, 0) 80%)",
    //textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  },
  labelTeste: {},
};

class CarouselBannerTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      next: false,
      previos: true,
      itens: props.itens || [],
    };

    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ itens: props.itens });
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    console.log(estatico)
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        possuiProdutos,
      });
    }
  }

  render() {
    const ID_CAROUSEL = Utils.uuidString();

    const { itens } = this.state;

    return (
      <Div>
        <Div className="carousel slide" id={ID_CAROUSEL} dataRide="carousel">
          <ol className="carousel-indicators">
            {Array.from(this.props.itens).map((item, index) => {
              return (
                <li
                  key={index}
                  data-target={`#${ID_CAROUSEL}`}
                  data-slide-to={index}
                  className={`indicator-carousel-banner-top ${
                    index === 0 ? "active" : ""
                  }`}
                ></li>
              );
            })}
          </ol>
          <Div className="carousel-inner">
            {Array.from(itens).map((item, index) => {
              return (
                <Div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                  handlerClick={() => this.visualizarProdutosAnuncio(item)}
                >
                  <Div
                    className="carousel-captiond-md-block container-banner-top"
                    // style={styles.labelTopLeft}
                    pointer
                  >
                    {item.value}
                  </Div>
                </Div>
              );
            })}
          </Div>
        </Div>
      </Div>
    );
  }
}

export default CarouselBannerTop;
