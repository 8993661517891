import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Div, I, Image, Label } from "../../components";
import CarouselProdutoGenericoNovo from "../../components/carousel/CarouselProdutoGenericoNovo";
import { Color, Type } from "../../utilities";
import ItemProdutoAbaixouPrecoMobileSaccaria from "../produtos/ItemProdutoAbaixouPrecoMobileSaccaria";
import ItemProdutoAbaixouPrecoSaccaria from "../produtos/ItemProdutoAbaixouPrecoSaccaria";
import ProdutoUtils from "../produtos/ProdutoUtils";
import "./anuncio.css";

const WIDTH_TELA = 1150;
const WIDTH_IMAGE = 100;

class BannerProdutos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: {},
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.anuncios) {
      this.setState({
        anuncios: this.props.anuncios,
      });
    }
  }

  getProdutos() {
    let responsive = this.props.responsive;

    return this.props.produtos.map((prod, index) => {
      if (responsive) {
        return (
          <Div className="d-flex justify-content-start align-items-start align-self-start">
            <ItemProdutoAbaixouPrecoMobileSaccaria
              history={this.props.history}
              key={index}
              image={prod.urlImagemPrincipalThumbnail}
              descricao={prod.descricao}
              precoUnitario={prod.precoUnitario}
              precoVenda={prod.precoVenda}
              precoAtacado={prod.precoAtacado}
              codigoBarras={prod.codigoBarras}
              handlerClick={(updateItem) =>
                this.props.handlerClick(prod, updateItem)
              }
              quantidade={prod.quantidade}
              disponivel={prod.disponivel}
              empresa={this.props.empresa}
              percentualEconomia={prod.percentualEconomia}
              estoqueDisponivel={prod.estoqueDisponivel}
              quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
              fatorEmGrama={prod.fatorEmGrama}
              possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
              precosConcorrentes={prod.precosConcorrentes || []}
              handlerAddItemCarrinho={(quantidade) => {
                prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                this.props.handlerAddItemCarrinho(prod);
              }}
            />
          </Div>
        );
      }
      return (
        <ItemProdutoAbaixouPrecoSaccaria
          history={this.props.history}
          key={index}
          image={prod.urlImagemPrincipalThumbnail}
          descricao={prod.descricao}
          precoUnitario={prod.precoUnitario}
          precoVenda={prod.precoVenda}
          precoAtacado={prod.precoAtacado}
          codigoBarras={prod.codigoBarras}
          handlerClick={(updateItem) =>
            this.props.handlerClick(prod, updateItem)
          }
          quantidade={prod.quantidade}
          disponivel={prod.disponivel}
          empresa={this.props.empresa}
          percentualEconomia={prod.percentualEconomia}
          estoqueDisponivel={prod.estoqueDisponivel}
          quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
          fatorEmGrama={prod.fatorEmGrama}
          possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
          precosConcorrentes={prod.precosConcorrentes || []}
          handlerErrorImage={() =>
            this.setState({
              produtosSemImage: [...this.state.produtosSemImage, prod],
            })
          }
          handlerAddItemCarrinho={(quantidade) => {
            prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
            this.props.handlerAddItemCarrinho(prod);
          }}
        />
      );
    });
  }

  render() {
    let responsive = this.props.responsive;
    let numeroItens= window.innerWidth < 800 ? 2 : window.innerWidth < 1024 ? 3 : 2;

    let banner = {
      titulo: "Adega",
      tipo: "DESTAQUE",
      urlFoto:
        "https://www.imgs.lojainstantanea.com.br/mercasimples/ba4f8298-30fc-11ed-89a5-0242ac3e0002/anuncios/20220910-principal-cortes-especiais.webp",
      urlFotoMobile:
        "https://www.imgs.lojainstantanea.com.br/mercasimples/ba4f8298-30fc-11ed-89a5-0242ac3e0002/anuncios/mobile-banner-adega.webp",
    };

    return (
      <Div
        col="12"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: WIDTH_TELA,
          flexWrap: "wrap",
          margin: "35px 0px 25px",
        }}
        padding="0"
      >
        <Div
          responsive="5"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Image
            src={banner.urlFoto}
            width={`${WIDTH_IMAGE}%`}
            height={responsive ? "auto" : "436px"}
            alt="Não foi possível carregar a imagem"
            // height={`${HEIGHT_IMAGE}px`}
            // handlerClick={() => this.visualizarProdutosAnuncio(anuncio)}
            // styleImage={{ borderRadius: 10 }}
          />
          <Button
            type="button"
            responsive="12"
            value="Confira"
           // btn={Color.NODE.SUCCESS}
            style={{
              marginTop: "0px",
              backgroundColor: Color.EMPRESA.TERCIARIA,
              color: "#FFF",
              
            }}
            //handlerClick={() => this.modalCancelamento()}
          ></Button>
        </Div>
        <Div responsive="7">
          <Div inline="center" col="12">
            <I
              icon={Type.ICON.STAR}
              style={{
                fontSize: 25,
                color: Color.EMPRESA.PRIMARIA,
                marginBottom: 15,
              }}
            />
            <Label
              style={{
                color: Color.EMPRESA.PRIMARIA,
                fontSize: "25px",
                marginLeft: 10,
              }}
              family="Bold"
              value="LANÇAMENTOS"
            />
          </Div>
          <CarouselProdutoGenericoNovo
            //  timeSlide={7000}
            itens={this.getProdutos()}
            slidesPerPage={numeroItens}
            arrows
          />
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
    ...store.carrinhoState,
  };
};

export default connect(mapStateToProps)(BannerProdutos);
