import React, { Component } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  Image,
  InputDefault,
  Label,
  ModalNotification,
  Notification,
  Title,
} from "../../components";
import {
  Color,
  DateUtils,
  EndPoints,
  Http,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../utilities";

import { ReactComponent as IconValor } from "../../assets/images/icon-valor.svg";
import { ReactComponent as IconEntrega } from "../../assets/images/icon-entrega.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { atualizarCarrinho, setEnderecoEntrega } from "../../redux/actions";
import TemplateItensCarrinho from "./TemplateItensCarrinho";
import moment from "moment";

import TemplatePedidoEntrega from "./TemplatePedidoEntrega";
import TemplateEntregaRetirada from "./TemplateEntregaRetirada";
import TemplatePedidoRetirada from "./TemplatePedidoRetirada";
import TemplatePagamento from "./TemplatePagamento";
import PedidoLojaWeb from "./PedidoLojaWeb";
import RecebimentoPedidoLojaWeb from "./RecebimentoPedidoLojaWeb";
import TooltipText from "../../components/tooltip/TooltipText";
import BotaoPaginaInicial from "../utils/BotaoPaginaInicial";
import UsuarioUtils from "../utils/UsuarioUtils";
import ContatoWhatsapp from "../ContatoWhatsapp";
import Zindex from "../utils/Zindex";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import { EntregaRetiradaUtils } from "..";
import AdicionarProdutosCarrinhoLista from "../produtos/listascompras/web/AdicionarProdutosCarrinhoLista";
import EmpresaService from "../../service/EmpresaService";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

const TEXTO_CONSUMO_CONSCIENTE =
  "(CAIXAS RECICLÁVEIS) Consumo consciente de embalagens é pensar no meio ambiente utilizando embalagens feitas de materiais ambientalmente amigáveis.";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textTransform: "uppercase",
    width: "100%",
    marginLeft: "5px",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: 13,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
  labelExcluirCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
    marginTop: 5,
  },
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containerBotaoCancelar: {
    backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
    color: Color.ECOMMERCE.WHITE,
    padding: 15,
    margin: 10,
    borderRadius: 5,
  },
  containerContinuar: {
    color: "white",
    padding: 12,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerBottom: {
    position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerBottomMostrarTodos: {
    width: "100%",
  },
  containerLogoStockei: {
    padding: 15,
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  },
};

class ItensCarrinho extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarItensCarrinho: true,
      mostrarEntrega: false,
      mostrarPagamento: false,
      mostrarErroEntregaRetirada: false,
      pedidoEntrega: true,
      pedidoRetirada: false,
      pedidoEntregaOutro: false,
      mostrarTodos: false,
      horariosEntrega: [],
      filialSelecionadaRetirada: {},
      erroContinarPagamento: "",
      pagamentoOnline: true,
      pedido: new PedidoLojaWeb(
        props.carrinho.itens,
        props.carrinho.cabecalho.valorTotal,
        props.carrinho.enderecoEntrega
      ),
      horarioRetirada: {},
      horarioEntrega: {},
      recebimentoPedido: new RecebimentoPedidoLojaWeb(),
      ...this.props.location.state,
      windowPagamentoDebito: null,
      corPrimaria: "black",
      novosParamentros: {
        entregaConsciente: false,
      },
    };

    this.handlerPedido = this.handlerPedido.bind(this);
    this.handlerRecebimento = this.handlerRecebimento.bind(this);
  }

  validarContinuarParaPagamento() {
    let erro = "";

    if (this.state.pedidoRetirada) {
      if (!Utils.isValueValid(this.state.filialSelecionadaRetirada)) {
        erro = "Selecione uma loja para realizar a retirada!";
      }

      if (!Utils.isValueValid(this.state.horarioRetirada)) {
        erro = "Selecione um horário para retirada dos produto na loja!";
      }
    }

    if (this.state.pedidoEntrega || this.state.pedidoEntregaOutro) {
      if (this.props.parametros.aceitaAgendamentoEntrega) {
        if (!Utils.isValueValid(this.state.horarioEntrega)) {
          erro = "Selecione um horário para a entrega dos produtos!";
        }
      }

      if (!Utils.isValueValid(this.state.pedido.responsavelRecebimento)) {
        erro = "Informe o responsável pelo recebimento do pedido!";
      }

      if (!Utils.isValueValid(this.state.pedido.idClienteEndereco)) {
        erro = "Selecione um endereço para a entrega!";
      }
    }

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => console.log("CANCELADO"),
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  componentDidUpdate(props) {
    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }
  }

  componentDidMount() {
    const { carrinho } = this.props;

    this.limparCupomDesconto(carrinho);

    if (carrinho.cabecalho.pedidoFinalizado) {
      Utils.refreshPage();
      this.props.history.replace("/");
    }

    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }

    let empresa = this.state.empresa;
    let parametrosEmpresa = Utils.isValueValid(empresa)
      ? empresa.parametros
      : {};
    this.setState({
      corPrimaria: parametrosEmpresa.corPrimaria,
      corSecundaria: parametrosEmpresa.corSecundaria,
    });

    this.getNovosParamentros();
  }

  getNovosParamentros() {
    EmpresaService.getEmpresaParams().then(({ status, data }) => {
      if (status) {
        this.setState({
          novosParamentros: data
        })
      }
    })
  }

  limparCupomDesconto(carrinho) {
    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.props.atualizarCarrinho(carrinho);
  }

  calcularValorTaxaEntrega() {
    let parametros = this.state.parametros;
    let carrinho = this.props.carrinho;
    let isEntrega = this.state.pedidoEntrega || this.state.pedidoEntregaOutro;

    carrinho.tipoEntregaRetirada = isEntrega
      ? EntregaRetiradaUtils.TIPO_ENTREGA
      : EntregaRetiradaUtils.TIPO_RETIRADA;

    CarrinhoUtils.calcularValorTaxaEntrega(
      parametros,
      carrinho,
      this.props.atualizarCarrinho
    );

    this.forceUpdate();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState(
              {
                parametros: data,
              },
              () => this.calcularValorTaxaEntrega()
            );
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  validarDadosEntregaRetirada() {
    let erro = "";

    if (!this.state.novosParamentros.diasPrevisaoEntrega > 0) {
      if (this.state.pedidoEntrega) {
        if (!Utils.isValueValid(this.state.horarioEntrega)) {
          erro = "Selecione o horário de entrega";
        }
      } else {
        if (!Utils.isValueValid(this.state.horarioRetirada)) {
          erro = "Selecione o horário de retirada";
        }
      }
    }

    if (Utils.isValueValid(erro)) {
      this.setState({
        mostrarEntrega: true,
        mostrarItensCarrinho: false,
        mostrarPagamento: false,
      });
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  validarDadosPagamentoInformado() {
    let erro = "";

    let recebimento = this.state.recebimentoPedido;
    if (this.state.pagamentoOnline) {
      if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
        if (
          !Utils.isValueValid(recebimento.anoValidadeCartao) ||
          !Utils.isValueValid(recebimento.mesValidadeCartao)
        ) {
          erro = "Informe a data de validade do cartão!";
        }

        if (String(recebimento.numeroCartao).length < 16) {
          erro =
            "Número de cartão inválido, verifique se todos os números foram inseridos";
        }

        if (
          Utils.isValueValid(recebimento.anoValidadeCartao) &&
          String(recebimento.anoValidadeCartao).length < 4
        ) {
          erro = "Informe o ano da data de validade com 4 dígitos";
        }

        if (!Utils.isValueValid(recebimento.codigoSegurancaCartao)) {
          erro = "Informe o código de seguraça do cartão!";
        }
        if (!Utils.isValueValid(recebimento.titularCartao)) {
          erro = "Informe o titular do cartão!";
        }
        if (!Utils.isValueValid(recebimento.numeroCartao)) {
          erro = "Informe o número do cartão!";
        }
        if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
          erro = "Informe a bandeira do cartão!";
        }
        if (
          recebimento.tipo === "CREDITO" &&
          !Utils.isValueValid(recebimento.quantidadeParcelas)
        ) {
          erro = "Informe a quantidade de parcelas!";
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    } else {
      if (
        recebimento.tipo === "CREDITO" ||
        recebimento.tipo === "DEBITO" ||
        recebimento.tipo === "DINHEIRO"
      ) {
        if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
          if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
            erro = "Selecione a bandeira do cartão!";
          }
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    }

    if (Utils.isValueValid(erro)) {
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  renderHeader(corPrimaria) {
    return (
      <>
        <Div inline="between" style={{ padding: "0 15px" }}>
          <Div inline="center" marginBottom="2">
            <Div
              style={{
                backgroundColor: this.state.mostrarItensCarrinho
                  ? corPrimaria
                  : "#E5E5E5",
                color: "white",
                borderRadius: 5,
                width: 40,
                height: 40,
              }}
              inline="center"
            >
              <Label
                value="1"
                margin="1"
                family="Bold"
                style={{ fontSize: 20 }}
                handlerClick={() =>
                  this.setState({
                    mostrarEntrega: false,
                    mostrarItensCarrinho: true,
                    mostrarPagamento: false,
                  })
                }
              />
            </Div>
            <Label
              marginLeft="3"
              marginTop="2"
              value="Itens do carrinho"
              style={{ fontSize: 20, color: "#878787" }}
              family="Bold"
              handlerClick={() =>
                this.setState({
                  mostrarEntrega: false,
                  mostrarItensCarrinho: true,
                  mostrarPagamento: false,
                })
              }
            />
          </Div>
          <Div inline="center">
            <Div
              style={{
                backgroundColor: this.state.mostrarEntrega
                  ? corPrimaria
                  : "#E5E5E5",
                color: "white",
                borderRadius: 5,
                width: 40,
                height: 40,
              }}
              inline="center"
            >
              <Label
                value="2"
                margin="1"
                family="Bold"
                style={{ fontSize: 20 }}
                handlerClick={() =>
                  this.setState({
                    mostrarEntrega: true,
                    mostrarItensCarrinho: false,
                    mostrarPagamento: false,
                  })
                }
              />
            </Div>
            <Label
              marginLeft="3"
              marginTop="2"
              value="Entrega / Retirada"
              handlerClick={() =>
                this.setState({
                  mostrarEntrega: true,
                  mostrarItensCarrinho: false,
                  mostrarPagamento: false,
                })
              }
              style={{ fontSize: 20, color: "#575757" }}
              family="Bold"
            />
          </Div>
          <Div
            inline="center"
            handlerClick={() => {
              if (this.validarContinuarParaPagamento()) {
                this.setState({
                  mostrarEntrega: false,
                  mostrarItensCarrinho: false,
                  mostrarPagamento: true,
                });
              }
            }}
          >
            <Div
              style={{
                backgroundColor: this.state.mostrarPagamento
                  ? corPrimaria
                  : "#E5E5E5",
                color: "white",
                borderRadius: 5,
                width: 40,
                height: 40,
                textAlign: "center",
              }}
            >
              <Label
                value="3"
                margin="1"
                family="Bold"
                style={{ fontSize: 20 }}
              />
            </Div>
            <Label
              marginLeft="3"
              marginTop="2"
              value="Pagamento"
              style={{ fontSize: 20, color: "#878787" }}
              family="Bold"
            />
          </Div>
        </Div>
      </>
    );
  }

  getEntrega() {
    let parametros = this.state.parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  getRetirada() {
    let { situacao, mensagem } = this.getEntrega();
    return {
      taxaEntrega: 0,
      situacao,
      mensagem,
      ...this.state.filialSelecionadaRetirada,
    };
  }

  removerCupomDesconto() {
    let carrinho = this.props.carrinho;

    if (carrinho.cupomAplicado) {
      carrinho.cabecalho.valorDesconto =
        Number(carrinho.cabecalho.valorDesconto) - carrinho.cupom.valorTotal;
    }

    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.setState({ codigoCupomDesconto: "" }, () => {
      this.props.atualizarCarrinho(carrinho);
    });
  }

  aplicarCupomDesconto(finalizar = false) {
    let pedido = this.getPedidoParaCadastro();
    let carrinho = this.props.carrinho;

    pedido.codigoCupomDesconto =
      this.state.codigoCupomDesconto || carrinho.cupom.codigo;
    Http.post(EndPoints.CUPOM_DESCONTO_VALIDAR, pedido)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            if (!carrinho.cupomAplicado) {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) + data.valorTotal;
              Notification.success("Cupom de desconto aplicado com sucesso!");
            } else {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
            }
          } else {
            Notification.danger(data.mensagem);
            if (carrinho.cupomAplicado) {
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) -
                carrinho.cupom.valorTotal;
            }

            carrinho.cupomAplicado = false;
            carrinho.cupom = null;
            this.setState({ codigoCupomDesconto: "" });
          }

          this.props.atualizarCarrinho(carrinho);
          this.forceUpdate();

          if (finalizar) {
            this.finalizarPedido();
          }
        } else {
          Notification.danger(
            "Falha ao aplicar o cupom de desconto, verifique a conexão com a internet"
          );

          if (finalizar) {
            ModalNotification.confirmarComHandlerCancel(
              "Finalizar pedido",
              "O cupom de desconto aplicado ficou indisponível, deseja finalizar o pedido ?",
              () => this.finalizarPedido(),
              () => { }
            );
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  getValorDescontoVenda() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    let valorDescontoTaxaEntrega = Number(
      cabecalho.valorDescontoTaxaEntrega || 0
    );
    let valorDescontoTaxaServico = Number(
      cabecalho.valorDescontoTaxaServico || 0
    );

    let desconto = valorDescontoTaxaEntrega + valorDescontoTaxaServico;

    if (carrinho.cupomAplicado) {
      desconto = desconto + Number(carrinho.cupom.valorTotal);
    } else {
      desconto = Number(cabecalho.valorDesconto);
    }

    return desconto;
  }

  getDadosCupomDesconto() {
    let { carrinho } = this.props;
    let dados = { valorDescontoCupom: 0 };

    if (carrinho.cupomAplicado) {
      dados.valorDescontoCupom = Number(carrinho.cupom.valorTotal);
      dados.idCupom = carrinho.cupom.idCupom;
    }

    return dados;
  }

  getValorDescontoTaxaEntrega() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    return Number(cabecalho.valorDescontoTaxaEntrega);
  }

  renderResumoDaCompra() {
    const { cabecalho, cupomAplicado, cupom } = this.props.carrinho;

    const { pedido, parametros, corPrimaria, novosParamentros } = this.state;
    let entrega =
      this.state.pedidoEntrega || this.state.pedidoEntregaOutro
        ? this.getEntrega()
        : this.getRetirada();

    return (
      <Div
        inline="center"
        className="align-self-start"
        responsive="4"
        marginTop="3"
        paddingBottom="5"
      >
        <Label
          value="Resumo da compra"
          style={styles.labelResumoCompra}
          family="SemiBold"
        />

        <Div className="w-100">
          <Div
            className="resumo-compra"
            style={{
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "14px",
              padding: "20px",
            }}
          >
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Subtotal do carrinho"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTotal)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconEntrega style={{ width: "30px", margin: 2 }} />
              <Div className="d-flex flex-column justify-content-start">
                <Title
                  value="Taxa de entrega"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTaxaEntrega)}
                  type="h6"
                  style={
                    cabecalho.atingiuEntregaGratis
                      ? styles.taxaEntregaGratis
                      : styles.labelBlue
                  }
                  family="Bold"
                />
              </Div>
            </Div>

            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor taxa de serviço"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(parametros.valorTaxaServico || 0)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Desconto"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(this.getValorDescontoVenda() || 0)}
                  type="h6"
                  style={{ ...styles.labelRed, color: corPrimaria }}
                  family="Bold"
                />
              </Div>
              {Utils.isValueValid(cupom) && cupomAplicado && (
                <TooltipText
                  direction="top"
                  title={`Cupom de desconto aplicado: ${Mask.formatValueBr(
                    cupom.valorTotal
                  )}`}
                  id={Utils.uuidString()}
                >
                  <Label
                    value={`${cupom.codigo} aplicado`}
                    family="Bold"
                    marginLeft="4"
                    style={{
                      fontSize: 12,
                      color: Color.ECOMMERCE.WHITE,
                      backgroundColor: Color.ECOMMERCE.LARANJA,
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                </TooltipText>
              )}
            </Div>
            <Div inline="start" className="border-top ">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor total do pedido"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(
                    Number(cabecalho.valorTotal) +
                    Number(cabecalho.valorTaxaEntrega || 0) +
                    Number(parametros.valorTaxaServico) -
                    this.getValorDescontoVenda()
                  )}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
          </Div>

          <If
            and
            value1={entrega.posicao === "SUCESSO"}
            value2={parametros.valorMinimoPedidoFreteGratis > 0}
          >
            <Label
              family="Bold"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              value={`Entrega grátis à partir de: ${Mask.formatValueBr(
                parametros.valorMinimoPedidoFreteGratis
              )}`}
            />
          </If>
          <If and value1={entrega.situacao === "ERRO"}>
            <Label
              pointer
              value={`${entrega.mensagem} Clique aqui para atualizar o endereço`}
              family="Bold"
              style={{ color: Color.ECOMMERCE.LARANJA }}
              handlerClick={() => this.props.history.push(Routes.CLIENTE)}
            />
          </If>

          <Div className="d-flex flex-column justify-content-start align-items-start">
            <Div
              style={{ width: "100%" }}
              className="d-flex flex-row justify-content-start align-items-start"
            >
              <Checkbutton
                corPrimaria={corPrimaria}
                checked={pedido.incluirCpfCnpjNota}
                title="Incluir CPF/CNPJ na nota"
                onClick={() =>
                  this.setState({
                    pedido: {
                      ...pedido,
                      incluirCpfCnpjNota: !pedido.incluirCpfCnpjNota,
                    },
                  })
                }
                Icon={
                  <I
                    pointer
                    icon={Type.ICON.USERR}
                    style={{
                      color: pedido.incluirCpfCnpjNota
                        ? Color.ECOMMERCE.WHITE
                        : corPrimaria,
                      fontSize: 20,
                    }}
                  />
                }
              />

              <If and value1={Utils.isNotNull(novosParamentros.entregaConsciente)}>
                <Checkbutton
                  corPrimaria={corPrimaria}
                  checked={pedido.entregaConsciente}
                  title="Entrega em caixas recicláveis"
                  onClick={() =>
                    this.setState({
                      pedido: {
                        ...pedido,
                        entregaConsciente: !pedido.entregaConsciente,
                      },
                    })
                  }
                  style={{ margin: "10px 10px 10px 20px" }}
                >
                  <TooltipText title={TEXTO_CONSUMO_CONSCIENTE} direction="right">
                    <I
                      pointer
                      icon={Type.ICON.INFO}
                      style={{
                        color: pedido.entregaConsciente
                          ? Color.ECOMMERCE.WHITE
                          : corPrimaria,
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    />
                  </TooltipText>
                </Checkbutton>
              </If>


              <Checkbutton
                corPrimaria={Color.EMPRESA.TERCIARIA}
                checked={true}
                title="Continuar comprando!"
                onClick={() => this.props.history.push("/")}
                style={{ margin: "10px" }}
                Icon={
                  <I
                    pointer
                    icon={Type.ICON.HOME}
                    style={{
                      color: "#FFF",
                      fontSize: 20,
                    }}
                  />
                }
              />
            </Div>

            <If and value1={this.state.mostrarPagamento}>
              <InputDefault
                handlerChange={(e) => {
                  this.setState({
                    codigoCupomDesconto: e.target.value,
                  });
                }}
                label="Utilizar cupom de desconto"
                className="w-100"
                value={this.state.codigoCupomDesconto}
                placeHolder="Código do cupom"
                handlerBlur={() => {
                  if (Utils.isValueValid(this.state.codigoCupomDesconto)) {
                    this.aplicarCupomDesconto();
                  } else {
                    this.removerCupomDesconto();
                  }
                }}
              />
            </If>
          </Div>
        </Div>
      </Div>
    );
  }

  getItensCarrinho = () => {
    const { itens } = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  renderAdicionarProdutoLista(listas) {
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutosCarrinhoLista
          listas={listas}
          produtos={this.getItensCarrinho()}
          empresa={this.state.empresa}
        //handlerCancel={() => this.visualizarProduto(produto, updateItem)}
        //handlerNovaListaCadastrada={() =>
        //  this.visualizarProduto(produto, updateItem)
        // }
        />
      ),
    });
  }

  renderMensagemFinalizacaoPedido(data) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Div>
          <If and value1={data.statusPedido} value2={data.statusPagamento}>
            <Label
              family="Bold"
              style={{ fontSize: 30, color: "white" }}
              value="Pedido realizado com sucesso!"
            />
          </If>
          <Div>
            <Label
              value={data.statusPedido ? data.mensagem : data.detalhesMensagem}
              family="Bold"
              style={{ fontSize: 30 }}
            />
          </Div>
        </Div>
      ),
      handlerConfirm: null,
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  renderMessage(message) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value={message}
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => { },
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  continuarFinalizacaoPedido() {
    if (
      this.state.mostrarItensCarrinho ||
      (this.state.mostrarEntrega && this.validarContinuarParaPagamento())
    ) {
      this.setState({
        mostrarEntrega: this.state.mostrarItensCarrinho ? true : false,
        mostrarPagamento: this.state.mostrarEntrega ? true : false,
        mostrarItensCarrinho: false,
      });
    }

    if (this.state.mostrarPagamento) {
      if (
        this.validarDadosPagamentoInformado() &&
        this.validarDadosEntregaRetirada()
      ) {
        if (this.props.carrinho.erroPagamento) {
          this.reenviarNovoPagamento();
        } else {
          if (Utils.isValueValid(this.state.codigoCupomDesconto)) {
            this.aplicarCupomDesconto(true);
          } else {
            this.finalizarPedido();
          }
        }
      }
    }
  }

  handlerPedido(pedido) {
    this.setState({ pedido });
  }

  handlerRecebimento(recebimentoPedido) {
    this.setState({ recebimentoPedido });
  }

  voltar() {
    if (this.state.mostrarItensCarrinho) {
      this.props.history.goBack();
    } else if (this.state.mostrarPagamento) {
      this.setState({
        mostrarPagamento: false,
        mostrarEntrega: true,
      });
    } else if (this.state.mostrarEntrega) {
      this.setState({
        mostrarItensCarrinho: true,
        mostrarEntrega: false,
      });
    }
  }

  getDadosEntrega() {
    const {
      pedidoEntrega,
      pedidoRetirada,
      pedidoEntregaOutro,
      horarioEntrega,
      horarioRetirada,
    } = this.state;
    let isEntrega = pedidoEntrega || pedidoEntregaOutro;

    let horario = isEntrega ? horarioEntrega : horarioRetirada;

    let entrega = this.getEntrega();
    let retirada = this.getRetirada();

    if(!Utils.isNotObjectEmpty(horario)) {
      horario = this.state.parametros.horarios[0]
    }

    return {
      dataEntrega: moment(horario.dataEntrega, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      idEmpresaHorarioEntrega: horario.idEmpresaHorarioEntrega,
      tipoEntrega: isEntrega
        ? EntregaRetiradaUtils.TIPO_ENTREGA
        : EntregaRetiradaUtils.TIPO_RETIRADA,
      valorTaxaEntrega: isEntrega ? entrega.taxaEntrega || 0 : 0,
      idFilial: pedidoRetirada ? retirada.idFilial : entrega.idFilial,
    };
  }


  getTipoPagamento() {
    return this.state.pagamentoOnline ? "ON_LINE" : "OFF_LINE";
  }

  getDadosRecebimento() {
    let recebimento = this.state.recebimentoPedido;
    let isOnline = this.state.pagamentoOnline;

    if (
      isOnline &&
      (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO")
    ) {
      return {
        recebimento: {
          ...recebimento,
          dataValidadeCartao: `${recebimento.mesValidadeCartao}/${recebimento.anoValidadeCartao}`,
        },
      };
    } else {
      return { recebimento };
    }
  }

  getPedidoParaCadastro() {
    const {
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
      obs,
      levarTrocoPara,
    } = this.state.pedido;
    let pedido = CarrinhoUtils.getCarrinho();
    let parametros = this.state.parametros;
    let newLevarTrocoPara = levarTrocoPara ? levarTrocoPara : ""
    let newObs = obs ? obs : ""

    return {
      obs: newObs,
      levarTrocoPara: newLevarTrocoPara,
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
      valorTotal: pedido.cabecalho.valorTotal,
      ...pedido,
      ...this.getDadosRecebimento(),
      ...this.getDadosEntrega(),
      ...CarrinhoUtils.getItensParaCadastro(pedido.itens),
      tipoPagamento: this.getTipoPagamento(),
      valorTaxaServico: parametros.valorTaxaServico || 0,
      ...this.getDadosCupomDesconto(),
      valorDescontoTaxaEntrega: this.getValorDescontoTaxaEntrega(),
      valorTaxaCartao: 0,
      valorDescontoTaxaServico: 0,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
    };
  }

  reenviarNovoPagamento() {
    let pedido = this.getPedidoParaCadastro();

    let recebimento = {
      ...this.getDadosRecebimento().recebimento,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
      tipoPagamentoPedido: this.getTipoPagamento(),
    };

    Http.post("/pedido/novopagamento", recebimento)
      .then(({ status, data }) => {
        if (status) {
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              if (
                data.posicao === "AGUARDANDO_PGTO_ON_LINE" &&
                recebimento.tipo === "DEBITO"
              ) {
                this.renderViewAutenticacaoDebito(data);
              } else {
                this.renderMensagemFinalizacaoPedido(data);
                let carrinho = this.props.carrinho;
                carrinho.erroPagamento = true;
                this.props.atualizarCarrinho(carrinho);
              }
            } else {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = false;
              this.props.atualizarCarrinho(carrinho);
              ModalNotification.removeModal();
              this.props.history.push("/finalizacao", {
                pedido,
              });
            }
          } else {
            this.renderMessageError("Erro ao finalizar pedido", data);
          }
        }
      })
      .catch((erro) => {
        console.log(erro);

        this.renderMessageError("Erro ao finalizar pedido", erro);
      });
  }

  renderViewAutenticacaoDebito({ urlAutenticacaoPagamento }) {
    let windowPagamentoDebito = this.state.windowPagamentoDebito;
    windowPagamentoDebito = window.open(
      urlAutenticacaoPagamento,
      "Informe os dados do cartão",
      `height=${window.innerHeight},width=${window.innerWidth}`
    );
    this.setState({ windowPagamentoDebito });
    /**
     * ModalNotification.render(
      "Informe os dados do cartão",
      <Div inline="center" className="w-100">
        <iframe src={urlAutenticacaoPagamento} height="400" width="100%" />
      </Div>
    );
     */
  }

  renderMessageError(title, response) {
    let errors = response;
    let mensagem = Utils.isValueValid(response.mensagem)
      ? response.mensagem
      : "";
    let detalhesMensagem = Utils.isValueValid(response.detalhesMensagem)
      ? response.detalhesMensagem
      : "";

    let msgErro = Utils.isValueValid(mensagem)
      ? `${mensagem} ${detalhesMensagem || ""}`
      : errors.detail;

    ModalNotification.renderMessage({
      title,
      body: <Label value={msgErro} />,
      labelButtonCancel: "Fechar",
      handlerCancel: () => { },
    });
  }

  renderMensagemFalhaFinalizacaoPedido(msg) {
    ModalNotification.renderMessage({
      title: "Falha ao finalizar o pedido",
      body: <Label value={msg} />,
      handlerCancel: () => { },
    });
  }

  consultarSituacaoDoPedidoDebito(pedido) {
    Http.get(
      EndPoints.PEDIDO_SITUACAO.replace("%s", pedido.idLancamentoPedidoApp)
    )
      .then(({ status, data }) => {
        if (status) {
          if (!data.statusPagamento) {
            this.renderViewAutenticacaoDebito(data);
          } else {
            this.setState(
              {
                recebimentoPedido: {
                  ...this.state.recebimentoPedido,
                  ...data,
                },
              },
              () => this.renderViewPedidoFinalizado(pedido)
            );
          }
        } else {
          this.renderMensagemFalhaFinalizacaoPedido(
            "Ocorreu uma falha ao finalizar o pedido, verifique a conexão com a internet!"
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  renderViewPedidoFinalizado(pedido) {
    this.props.history.push("/finalizacao", {
      pedido,
      logoEmpresa: this.state.logoEmpresa,
    });
  }

  sendPedidoTipoCredito(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = false;
              carrinho.pagamentoOnline = data;
              this.props.atualizarCarrinho(carrinho);
              this.renderMessageError("Falha ao processar o pagamento", data);
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        } else {
          this.renderMessageError("Falha ao processar o pedido", data);
        }
      })
      .catch((error) => console.log(error));
  }

  sendPedidoTipoDebito(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              if (data.posicao === "FALHA_PGTO_ON_LINE") {
                this.renderMessageError("Finalização pedido", data);
              } else if (data.posicao === "AGUARDANDO_PGTO_ON_LINE") {
                this.renderViewAutenticacaoDebito(data);
              }
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  sendPedidoOutrosPagamentos(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.renderMensagemFalhaFinalizacaoPedido(data.mensagem);
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () => this.renderViewPedidoFinalizado(pedido)
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  finalizarPedido() {
    let pedido = this.getPedidoParaCadastro();
    let empresa = this.props.empresa.fantasia
    const { tipo } = pedido.recebimento;

    if (tipo === "DEBITO") {
      this.sendPedidoTipoDebito(pedido, empresa);
    } else if (tipo === "CREDITO") {
      this.sendPedidoTipoCredito(pedido, empresa);
    } else {
      this.sendPedidoOutrosPagamentos(pedido, empresa);
    }
  }

  getLabelBotaoVoltar() {
    const { mostrarItensCarrinho, mostrarEntrega, mostrarPagamento } =
      this.state;
    let labelVoltar = "Voltar";

    if (mostrarItensCarrinho) labelVoltar = "Voltar para a página inicial";
    if (mostrarEntrega) labelVoltar = "Voltar para os itens do carrinho";
    if (mostrarPagamento) labelVoltar = "Voltar para os dados entrega";

    if (this.state) return labelVoltar;
  }

  validarItensCheckout() {
    const { carrinho } = this.props;
    const { parametros } = this.state;
    let valorMaximoPedido = Number(parametros.valorMaximoPedido);
    let valorTotal = Number(carrinho.cabecalho.valorTotal);

    let valorEntrega = Number(parametros.valorMinimoPedidoEntrega);
    let valorRetirada = Number(parametros.valorMinimoPedidoRetirada);

    let valorMinimo =
      valorEntrega > valorRetirada ? valorRetirada : valorEntrega;

    if (valorMaximoPedido >= valorTotal && valorMinimo <= valorTotal) {
      this.forceUpdate();
    } else if (valorMaximoPedido < valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido atingiu o valor limite permitido"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor máximo para o pedido: ${Mask.formatValueBr(
              valorMaximoPedido
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    } else if (valorMinimo > valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 30,
          }}
        >
          <Div inline="center">
            <I
              icon={Type.ICON.INFO}
              style={{
                color: Color.ECOMMERCE.CINZA_ESCURO,
                fontSize: 30,
                marginBottom: 10,
                marginRight: 5,
              }}
            />
            <Label
              family="Bold"
              value="O pedido não possui o valor mínimo necessário"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 30 }}
            value={`Valor mínimo para o pedido: ${Mask.formatValueBr(
              valorMinimo
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    }
  }

  render() {
    let corPrimaria = this.state.corPrimaria;
    let corSecundaria = this.state.corSecundaria;
    return (
      <Div column className="w-100">
        <Div
          className="border-bottom w-100"
          inline="between"
          style={{ position: "absolute", top: 0 }}
        >
          <Div
            inline="center"
            handlerClick={() => this.props.history.replace("/")}
          >
            <Image src={this.state.logoEmpresa} width="150px" margin="2" />
          </Div>
          <If
            and
            value1={this.state.mostrarEntrega || this.state.mostrarPagamento}
          >
            <Div
              pointer
              inline="center"
              handlerClick={() => this.voltar()}
              style={{
                ...styles.containerBotaoCancelar,
                backgroundColor: corPrimaria,
              }}
            >
              <Label
                value={this.getLabelBotaoVoltar()}
                marginTop="2"
                style={{ fontSize: 20 }}
                family="SemiBold"
                pointer
              />
              <I
                icon={Type.ICON.ARROW_CIRCLE_LEFT}
                colorText={Color.NODE.WHITE}
                sizeIcon="2"
                marginLeft="3"
                pointer
                marginBottom="1"
              />
            </Div>
          </If>
        </Div>
        <Div responsive="12" style={{ marginBottom: 55, padding: 0 }}>
          {this.renderHeader(corPrimaria)}
          <If and value1={this.state.mostrarItensCarrinho}>
            <Div
              inline="start"
              style={{
                backgroundColor: Color.ECOMMERCE.CINZA_CLARO,
                padding: "0 15px",
              }}
            >
              {this.renderResumoDaCompra()}
              <TemplateItensCarrinho
                {...this.props}
                mostrarTodos={this.state.mostrarTodos}
                parametros={this.state.parametros}
                corPrimaria={corPrimaria}
                handlerAddItem={() => this.validarItensCheckout()}
              />
            </Div>
          </If>
          <If and value1={this.state.mostrarErroEntregaRetirada}>
            <Div column>
              <Div style={{ padding: 20 }} inline="center">
                <I
                  icon={Type.ICON.INFO}
                  style={{
                    color: Color.ECOMMERCE.CINZA_ESCURO,
                    fontSize: 30,
                    marginBottom: 10,
                    marginRight: 5,
                  }}
                />
                <Label
                  family="Bold"
                  style={{ color: Color.ECOMMERCE.CINZA_ESCURO, fontSize: 25 }}
                  value="Pedido não possui valor suficiente para entrega / retirada"
                />
              </Div>
              <Label
                family="Bold"
                style={{ color: Color.ECOMMERCE.LARANJA_CLARO, fontSize: 20 }}
                value={`Valor mínimo para RETIRADA: ${Mask.formatValueBr(
                  this.state.parametros.valorMinimoPedidoRetirada
                )}`}
              />
              <Label
                family="Bold"
                style={{ color: Color.ECOMMERCE.LARANJA_CLARO, fontSize: 20 }}
                value={`Valor mínimo para ENTREGA: ${Mask.formatValueBr(
                  this.state.parametros.valorMinimoPedidoEntrega
                )}`}
              />
              <BotaoPaginaInicial
                label="Retornar a página inicial"
                history={this.props.history}
              />
            </Div>
          </If>
          <If and value1={this.state.mostrarEntrega}>
            <Div
              inline="start"
              style={{
                backgroundColor: Color.RGBA.CINZA_MEDIO,
                padding: "0 15px",
              }}
            >
              {this.renderResumoDaCompra()}
              <Div
                responsive="8"
                className="d-flex flex-column justify-content-start align-items-start align-self-start"
              >
                <TemplateEntregaRetirada
                  cabecalho={this.props.carrinho.cabecalho}
                  pedidoEntrega={this.state.pedidoEntrega}
                  pedidoRetirada={this.state.pedidoRetirada}
                  pedidoEntregaOutro={this.state.pedidoEntregaOutro}
                  parametros={this.state.parametros}
                  novosParamentros={this.state.novosParamentros}
                  handlerChangeTipoEntrega={(tipo) => {
                    this.setState(
                      {
                        pedidoEntrega:
                          tipo === EntregaRetiradaUtils.TIPO_ENTREGA,
                        pedidoRetirada:
                          tipo === EntregaRetiradaUtils.TIPO_RETIRADA,
                        pedidoEntregaOutro:
                          tipo === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO,
                      },
                      () => this.calcularValorTaxaEntrega()
                    );
                  }}
                  handerErrorEntregaRetirada={() =>
                    this.setState({
                      mostrarErroEntregaRetirada: true,
                      mostrarEntrega: false,
                    })
                  }
                  corPrimaria={corPrimaria}
                  corSecundaria={corSecundaria}
                />
                <If
                  and
                  value1={
                    this.state.pedidoEntrega || this.state.pedidoEntregaOutro
                  }
                >
                  <TemplatePedidoEntrega
                    pedido={this.state.pedido}
                    handlerPedido={this.handlerPedido}
                    parametros={this.state.parametros}
                    horarioEntrega={this.state.horarioEntrega}
                    empresa={this.state.empresa}
                    carrinho={this.props.carrinho}
                    listasDeCompra={this.props.listasDeCompra}
                    handlerEnderecoSelecionado={(endereco) => {
                      this.setState(
                        {
                          pedido: {
                            ...this.state.pedido,
                            idClienteEndereco: endereco.idClienteEndereco,
                          },
                        },
                        () => {
                          let carrinho = {
                            ...this.props.carrinho,
                            enderecoEntrega: endereco,
                          };

                          this.props.setEnderecoEntrega(carrinho);
                        }
                      );
                    }}
                    handlerHorarioSelecionado={(horario) =>
                      this.setState({ horarioEntrega: horario })
                    }
                    corPrimaria={corPrimaria}
                    corSecundaria={corSecundaria}
                  />
                </If>
                <If and value1={this.state.pedidoRetirada}>
                  <TemplatePedidoRetirada
                    filialSelecionadaRetirada={
                      this.state.filialSelecionadaRetirada
                    }
                    pedido={this.state.pedido}
                    handlerPedido={this.handlerPedido}
                    handlerFilialSelecionada={(filial) =>
                      this.setState({
                        filialSelecionadaRetirada: filial,
                      })
                    }
                    parametros={this.state.parametros}
                    handlerHorarioSelecionado={(horario) =>
                      this.setState({ horarioRetirada: horario })
                    }
                    corPrimaria={corPrimaria}
                    corSecundaria={corSecundaria}
                  />
                </If>
              </Div>
            </Div>
          </If>
          <If and value1={this.state.mostrarPagamento}>
            <Div className="d-flex align-self-start" style={{
              backgroundColor: Color.RGBA.CINZA_MEDIO,
              padding: "0 15px",
            }}>
              {this.renderResumoDaCompra()}
              <TemplatePagamento
                recebimentoPedido={this.state.recebimentoPedido}
                pedido={this.state.pedido}
                carrinho={this.props.carrinho}
                handlerRecebimento={this.handlerRecebimento}
                handlerPedido={this.handlerPedido}
                parametros={this.state.parametros}
                novosParamentros={this.state.novosParamentros}
                empresa={this.state.empresa}
                windowPagamentoDebito={this.state.windowPagamentoDebito}
                handlerSucessoPagamento={(data) => {
                  let pedido = this.getPedidoParaCadastro();
                  ModalNotification.removeModal();
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  });
                }}
                handlerErrorPagamento={(data) => {
                  this.renderMessageError("Falha ao realizar pagamento", data);
                }}
                dadosEntregaRetirada={
                  this.state.pedidoEntrega
                    ? this.getEntrega()
                    : this.getRetirada()
                }
                handlerInitPagamento={() =>
                  this.setState({
                    recebimentoPedido: new RecebimentoPedidoLojaWeb(),
                  })
                }
                handlerTipoPagamento={(pagamentoOnline) =>
                  this.setState({ pagamentoOnline })
                }
              />
            </Div>
          </If>
        </Div>
        <Div style={styles.containerBottom}>
          <If
            and
            value1={!this.state.mostrarTodos && this.state.mostrarItensCarrinho}
          >
            <Div
              responsive="12"
              inline="center"
              style={styles.containterMostrarTodos}
              pointer
              handlerClick={() => this.setState({ mostrarTodos: true })}
            >
              <Label
                value="Mostrar todos"
                family="Light"
                style={{ color: "#878787", fontSize: 15 }}
              />
              <I
                icon={Type.ICON.ARROW_CHEV_DOWN}
                marginLeft="2"
                marginBottom="2"
              />
            </Div>
          </If>
          <Div
            responsive="12"
            inline="center"
            style={{
              ...styles.containerContinuar,
              backgroundColor: corPrimaria,
            }}
            pointer
            handlerClick={() => {
              this.continuarFinalizacaoPedido();
            }}
          >
            <Label
              value={
                this.state.mostrarPagamento ? "Finalizar o pedido" : "Continuar"
              }
              family="Light"
            />
            <I
              icon={Type.ICON.ARROW_CHEV_RIGHT}
              marginLeft="2"
              marginBottom="2"
            />
          </Div>
        </Div>
        <ContatoWhatsapp
          empresa={this.state.empresa}
          zIndex={Zindex.MIL_E_DOIS}
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
    ...store.listasDeCompraState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setEnderecoEntrega }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ItensCarrinho);
