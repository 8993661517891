import React, { Component } from "react";
import { toast } from "react-toastify";
import { EmpresaUtils, ProdutosListaGenerico, ProdutoUtils } from "../..";

import {
  Div,
  I,
  If,
  Image,
  Label,
  ModalNotification,
  NavbarTab,
  Select,
  SelectValue,
} from "../../../components";
import CarouselImagens from "../../../components/carousel/CarouselImagens";
import { Color, Http, Mask, Type, Utils } from "../../../utilities";
import LoginCliente from "../../cliente/LoginCliente";
import RecuperarSenha from "../../cliente/RecuperarSenha";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import UsuarioUtils from "../../utils/UsuarioUtils";
import ProdutoTabsDetalhes from "../web/ProdutoTabsDetalhes";

const styles = {
  descricao: {
    fontSize: "20px",
    lineHeight: "20px",
    color: "#878787",
    fontFamily: "MetropolisBold",
    marginTop: 5,
  },
  codigoReferencia: {
    fontSize: 11,
    color: "#878787",
    lineHeight: "7px",
    width: "100%",
  },
  containterPreco: {
    height: window.innerHeight / 3,
  },
  precoVenda: {
    color: Color.ECOMMERCE.LARANJA,
    fontFamily: "MetropolisBold",
    fontSize: 20,
  },
  precoAtacado: {
    color: "#46160E",
    fontSize: 15,
  },
  containerQuantidade: {
    borderRadius: "5px",
  },
  botaoAdicionar: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  botaoDiminuir: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  containerBotaoComprar: {
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    fontFamily: "MetropolisSemiBold",
    fontSize: 20,
    borderRadius: "5px",
    textAlign: "center",
    paddingTop: 10,
  },
  containerBotaoColorCarrinho: {
    backgroundColor: Color.EMPRESA.PRIMARIA,
    color: "white",
    fontFamily: "MetropolisLight",
    fontSize: 20,
    borderRadius: "5px",
    paddingTop: 10,
  },
  containerAdicionarMinhaLista: {
    backgroundColor: Color.EMPRESA.SECUNDARIA,
    color: "white",
    fontFamily: "MetropolisSemiBold",
    fontSize: 15,
    borderRadius: "5px",
    paddingTop: 10,
  },
  labelPeso: {
    textAlign: "center",
    backgroundColor: "#f2f2f2",
    width: "50%",
    padding: 10,
    borderRightTop: 10,
  },
  labelPesoActive: {
    textAlign: "center",
    width: "50%",
    padding: 10,
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
    borderRightTop: 10,
  },
  labelUnidade: {
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    padding: 10,
    width: "50%",
  },
  labelUnidadeActive: {
    textAlign: "center",
    padding: 10,
    width: "50%",
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
  },
};

const getImagensProduto = (produto) => {
  let imagens = [];

  if (Utils.isValueValid(produto.imagens)) {
    let imagensProduto = Array.from(produto.imagens);

    imagensProduto.forEach((img, index) => {
      img.active = index === 0;
      img.width = "280px";
      img.height = "280px";
    });

    imagens = imagensProduto;
  } else {
    imagens = [{ url: produto.urlImagemPrincipal }];
  }

  return imagens;
};

class ProdutoDetalheMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantidade: props.produto.quantidade || "0",
      quantidadePeso: this.getQuantidadePeso(props.produto),
      quantidadeUnidade: this.getQuantidadeUnidade(props.produto),
      haderInfoProdutoCombo: "PRODUTOS_COMBO",
    };

    this.atualizarProdutoDetalhe = this.atualizarProdutoDetalhe.bind(this);

    this.sincronizarVisualizacaoProduto();
  }

  getQuantidadePeso(produto) {
    return (
      produto.quantidadePeso ||
      (produto.possuiQuantidadeFracionada && !produto.quantidadeUnidade)
    );
  }

  getQuantidadeUnidade(produto) {
    return (
      produto.quantidadeUnidade ||
      (!produto.possuiQuantidadeFracionada && produto.quantidadeUnidade)
    );
  }

  visualizarProduto = (produto) => {
    ProdutoUtils.visualizarProduto(
      "Informações do produto",
      <ProdutoDetalheMobile
        produto={produto}
        produtosSimilares={this.state.produtosSimilares}
        handlerAddItem={this.adicionarItemNoCarrinho}
      />
    );
  };

  getHeaderInfoProdutoCombo = () => {
    return [
      {
        target: "PRODUTOS_COMBO",
        value: "ITENS DO COMBO",
        active: "PRODUTOS_COMBO" === this.state.haderInfoProdutoCombo,
      },
      {
        target: "INFORMACOES",
        value: "INFORMAÇÕES",
        active: "INFORMACOES" === this.state.haderInfoProdutoCombo,
      },
    ];
  };

  sincronizarVisualizacaoProduto() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      Http.post("/produto/visualizado/sincronizar", {
        idCliente: cliente.idCliente,
        idProdutoWeb: this.props.produto.idProdutoWeb,
      })
        .then(({ status, data }) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }

  componentDidMount() {
    let produtoNoCarrinho = this.getProdutoNoCarrinho(
      this.props.produto.codigoBarras
    );
    let produto = Utils.isValueValid(produtoNoCarrinho)
      ? produtoNoCarrinho
      : this.props.produto;

    let quantidadeProduto = Number(
      this.consultarQuantidadeProdutoCarrinho(produto.codigoBarras)
    );
    
    if (produto.possuiQuantidadeFracionada && produto.quantidadeUnidade) {
      quantidadeProduto =
        Number(quantidadeProduto) / Number(produto.fatorEmGrama);
    }

    if (quantidadeProduto > 0) {
      this.setState({
        quantidade: quantidadeProduto,
        quantidadePeso: this.getQuantidadePeso(produto),
        quantidadeUnidade: this.getQuantidadeUnidade(produto),
      });
    } else {
      // this.setState({
      //   quantidade: quantidadeProduto,
      // });
      this.addQuantidadeItemState(produto);
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let produtoNoCarrinho = this.getProdutoNoCarrinho(
      props.produto.codigoBarras
    );
    let produto = Utils.isValueValid(produtoNoCarrinho)
      ? produtoNoCarrinho
      : props.produto;

    let quantidadeProduto = Number(
      this.consultarQuantidadeProdutoCarrinho(produto.codigoBarras)
    );

    if (produto.possuiQuantidadeFracionada && produto.quantidadeUnidade) {
      quantidadeProduto =
        Number(quantidadeProduto) / Number(produto.fatorEmGrama);
    }

    this.setState(
      {
        imagemProduto: produto.urlImagemPrincipal,
        quantidade: quantidadeProduto,
        quantidadePeso: this.getQuantidadePeso(produto),
        quantidadeUnidade: this.getQuantidadeUnidade(produto),
      },
      () => {
        this.sincronizarVisualizacaoProduto();
        if (quantidadeProduto === 0) {
          this.addQuantidadeItemState(produto);
        }
      }
    );
  }

  consultarProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let quantidade = 0;

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        quantidade = produtoNoCarrinho.quantidade;
      }
    }

    return quantidade;
  }

  atualizarProdutoDetalhe() {
    this.forceUpdate();
  }

  usuarioEstaLogado() {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    return Utils.isValueValid(usuario);
  }

  renderViewRecuperarSenha(corPrimaria) {
    ModalNotification.renderModalLogin(
      <Label
        className="modal-title"
        style={{
          color: corPrimaria,
          fontSize: 25,
          marginTop: 20,
        }}
        family="Bold"
        value="Recuperar senha"
      />,
      <RecuperarSenha corPrimaria={corPrimaria} />
    );
  }

  renderViewLogin(corPrimaria) {
    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Dados de acesso"
        />
        <Label
          value="Esqueci meus dados!"
          style={{
            color: Color.EMPRESA.TERCIARIA,
          }}
          className="hover-link"
          family="Light"
          handlerClick={() => this.renderViewRecuperarSenha(corPrimaria)}
        />
      </>,
      <LoginCliente
        handlerConfirm={() => Utils.refreshPage()}
        empresa={this.props.empresa}
        {...this.props}
        corPrimaria={corPrimaria}
      />
    );
  }

  addQuantidadeItemState(item) {
    let quantidade = Number(this.state.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade + Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade + 1;
      }
    } else {
      quantidade = quantidade + 1;
    }

    this.setState({ quantidade });
  }

  addQuantidadeItemBotaoAdicionar(item) {
    let quantidade = 0;

    if (item.possuiQuantidadeFracionada && this.state.quantidadeUnidade) {
      quantidade = Number(item.fatorEmGrama) * Number(this.state.quantidade);
    } else {
      quantidade = Number(this.state.quantidade);
    }

    item.quantidadePeso = this.state.quantidadePeso;
    item.quantidadeUnidade = this.state.quantidadeUnidade;

    item = ProdutoUtils.calcularValorProduto(item, quantidade);
    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  addQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }
  removeQuantidadeItemState(item) {
    let quantidade = Number(this.state.quantidade || 0);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      if (this.state.quantidadePeso) {
        quantidade = quantidade - Number(item.fatorEmGrama);
      } else {
        quantidade = quantidade - 1;
      }
    } else {
      quantidade = quantidade - 1;
    }

    this.setState({ quantidade });
  }

  removeQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  consultarUnidade(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();
    let labelUnidade = "";

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        if (produtoNoCarrinho.possuiQuantidadeFracionada) {
          labelUnidade = this.state.quantidadePeso ? "g" : "un";
        } else {
          labelUnidade = "un";
        }
      } else {
        if (this.props.produto.possuiQuantidadeFracionada) {
          labelUnidade = this.state.quantidadePeso ? "g" : "un";
        } else {
          labelUnidade = "un";
        }
      }
    }

    return labelUnidade;
  }

  getLabelAdicionarCarrinho = () => {
    let quantidadeCarrinho = Number(
      this.consultarQuantidadeProdutoCarrinho(this.props.produto.codigoBarras)
    );

    return quantidadeCarrinho > 0
      ? "Atualizar item no carrinho"
      : "Adicionar item ao carrinho";
  };

  renderPrecos(corPrimaria) {
    const { produto } = this.props;
    let aceitaKG = Number(produto.fatorEmGrama) > 0;
    let precoPorFator =
      (Number(produto.precoVenda) * Number(produto.fatorEmGrama)) / 1000;

    return (
      <Div paddingTop="5" className="d-flex flex-column">
        <If and value1={produto.possuiQuantidadeFracionada}>
          <Label
            value={`${Mask.formatValueBr(produto.precoVenda)} / KG`}
            style={{ ...styles.precoVenda, color: corPrimaria }}
          />
          <If and value1={aceitaKG}>
            <Label
              value={`${Mask.formatValueBr(
                precoPorFator
              )} cada unidade de ${Number(produto.fatorEmGrama)}g`}
              style={{ ...styles.precoVenda, color: corPrimaria }}
            />
          </If>
        </If>
        <If and value1={!produto.possuiQuantidadeFracionada}>
          <Label
            value={Mask.formatValueBr(produto.precoVenda)}
            style={{ ...styles.precoVenda, color: corPrimaria }}
          />
        </If>
        <If
          and
          value1={Utils.isValueValid(produto.precoAtacado)}
          value2={produto.precoAtacado > 0}
        >
          <Label
            value={`${Mask.formatValueBr(produto.precoAtacado)} à partir de ${
              produto.quantidadeMinimaAtacado
            } unidades`}
            family="SemiBold"
            style={styles.precoAtacado}
          />
        </If>
      </Div>
    );
  }

  consultarQuantidadeProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let quantidade = 0;

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        quantidade = produtoNoCarrinho.quantidade;
      }
    }

    return quantidade;
  }

  getProdutoNoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let produto = {};

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produto = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      return produto;
    }

    return produto;
  }

  renderLabelsPesoUnidade() {
    let { produto, quantidadePeso, quantidadeUnidade } = this.state;
    return (
      <Div style={styles.containerQuantidade}>
        <Label
          value="Peso"
          style={quantidadePeso ? styles.labelPesoActive : styles.labelPeso}
          pointer
          handlerClick={() => {
            if (this.state.quantidadeUnidade) {
              let quantidadeConvertida =
                this.getQuantidadeProdutoConvertida(true);
              this.setState({
                quantidadePeso: true,
                quantidadeUnidade: false,
                quantidade: quantidadeConvertida,
              });
            }
          }}
        />
        <Label
          value="Unidade"
          style={
            quantidadeUnidade ? styles.labelUnidadeActive : styles.labelUnidade
          }
          pointer
          handlerClick={() => {
            if (this.state.quantidadePeso) {
              let quantidadeConvertida =
                this.getQuantidadeProdutoConvertida(false);
              this.setState({
                quantidadePeso: false,
                quantidadeUnidade: true,
                quantidade: quantidadeConvertida,
              });
            }
          }}
        />
      </Div>
    );
  }

  renderProdutosCombo = (produtos) => {
    let idParent = Utils.uuidString();
    return (
      <Div id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          let unidade = prod.possuiQuantidadeFracionada ? "KG" : "UN";
          return (
            <Div className="d-flex flex-column" key={index}>
              <Div
                inline="start"
                padding="2"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Image
                  border
                  src={prod.urlImagemPrincipalThumbnail}
                  width="40px"
                />
                <Label
                  className="text-ellipsis"
                  value={`${prod.quantidade} ${unidade} ${Utils.capitalize(
                    prod.descricao
                  )}`}
                  marginLeft="2"
                  family="Light"
                  style={{ width: "70%" }}
                />
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  getQuantidadeProdutoConvertida(converterParaPeso = false) {
    const { quantidade } = this.state;
    let produto = this.props.produto;
    let quantidadeConvertida = 0;

    if (converterParaPeso) {
      quantidadeConvertida = Number(produto.fatorEmGrama) * Number(quantidade);
    } else {
      quantidadeConvertida = Number(quantidade) / Number(produto.fatorEmGrama);
    }

    return quantidadeConvertida;
  }

  getListaCompraAtualizacao() {
    let { listaSelecionada } = this.state;
    let { produto } = this.props;
    let itensLista = Array.from(listaSelecionada.itens);

    let itemNaLista = itensLista.filter(
      (i) => i.codigoBarras === produto.codigoBarras
    )[0];

    if (Utils.isValueValid(itemNaLista)) {
      itemNaLista.quantidade = this.state.quantidade;
    } else {
      itensLista.push({
        idListaCompraProduto: Utils.uuidv4(),
        idListaCompra: listaSelecionada.idListaCompra,
        idProdutoWeb: produto.idProdutoWeb,
        preco: produto.precoVenda,
        quantidade: this.state.quantidade,
        ordem: 1,
      });
    }

    listaSelecionada.itens = itensLista;

    return listaSelecionada;
  }

  atualizarListaCompra() {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            toast.info("Produto adicionado na lista de compra!", {
              hideProgressBar: true,
              position: "top-right",
            });
          } else {
            toast.error("Falha ao adicionar produto na lista de compras", {
              hideProgressBar: true,
              position: "top-right",
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  getListasSelect = () => {
    let listas = Array.from(this.props.listasDeCompraCliente);
    let listasSelect = [
      new SelectValue(null, "Selecione"),
      new SelectValue("NOVA_LISTA", "+ Cadastrar Nova Lista"),
    ];

    listas.forEach((lista) => {
      listasSelect.push(
        new SelectValue(lista.idListaCompra, Utils.capitalize(lista.descricao))
      );
    });

    return listasSelect;
  };

  setListaSelecionada(idListaCompra) {
    let listas = Array.from(this.props.listasDeCompraCliente);
    let listaSelecionada = listas.filter(
      (lista) => lista.idListaCompra === idListaCompra
    )[0];
    this.setState({ listaSelecionada }, () => {
      if (idListaCompra === "NOVA_LISTA") {
        this.props.handlerAdicionarProdutoLista();
      }
    });
  }

  render() {
    let { produto, produtosSimilares } = this.props;

    const imagens = getImagensProduto(produto);

    let quantidadeProduto = Number(this.state.quantidade || 0);
    let labelUnidade = this.consultarUnidade(produto.codigoBarras);

    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.props.empresa);

    let usuarioLogado = UsuarioUtils.getUsuarioLojaWeb();

    return (
      <Div inline="center" martinTop="5" marginBottom="5">
        <If and value1={Utils.isValueValid(imagens) && imagens.length === 1}>
          <Div responsive="3" inline="center">
            <Image src={produto.urlImagemPrincipal} width="280px" responsive />
          </Div>
        </If>
        <If and value1={Utils.isValueValid(imagens) && imagens.length > 1}>
          <Div responsive="5" inline="center">
            <CarouselImagens itens={imagens} inline />
          </Div>
        </If>
        <Div responsive="3">
          <Label
            value={Utils.capitalize(produto.descricao)}
            paddingBottom="2"
            style={styles.descricao}
          />
          <Div inline="start">
            <Label
              value={`Cód. Referência: ${produto.codigoBarras}`}
              style={styles.codigoReferencia}
              family="Light"
            />
            <If and value1={Utils.isValueValid(produto.marca)}>
              <Label
                value={`Marca: ${produto.marca}`}
                family="Light"
                style={styles.codigoReferencia}
              />
            </If>
          </Div>
          <If and value1={Utils.isValueValid(produto.informacoes)}>
            <Label
              value={produto.informacoes}
              style={{ textAlign: "justify" }}
            />
          </If>
          <If and value1={!produto.proibidaVenda}>
            {this.renderPrecos(corPrimaria)}
          </If>
          {Utils.isValueValid(produto.precosConcorrentes) && (
            <Div
              style={{
                borderRadius: 10,
                backgroundColor: "#f2f2f2",
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 5,
              }}
              inline="start"
            >
              <Div style={{ width: "50%" }}>
                {Array.from(produto.precosConcorrentes).map(
                  (concorrente, index) => {
                    return (
                      <Div
                        inline="between"
                        key={index}
                        style={{
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                          padding: 1,
                        }}
                      >
                        <Div inline="start">
                          <Image src={concorrente.urlFoto} width="26px" />
                          <Label
                            value={Mask.formatValueBr(concorrente.precoVenda)}
                            family="SemiBold"
                            marginTop="2"
                            marginLeft="2"
                          />
                        </Div>
                      </Div>
                    );
                  }
                )}
              </Div>
              <Div
                style={{
                  color: "#31CDFF",
                  paddingRight: 15,
                  paddingTop: 20,
                  width: "50%",
                }}
              >
                <Div inline="end" style={{ color: "#31CDFF" }}>
                  <Label value="Economize" family="SemiBold" />
                </Div>
                <Div inline="end">
                  <Label
                    value={`${
                      Array.from(produto.precosConcorrentes)[0]
                        .percentualEconomia
                    }%`}
                    style={{ fontSize: 20 }}
                    family="Bold"
                  />
                </Div>
              </Div>
            </Div>
          )}
        </Div>
        <If and value1={!produto.proibidaVenda}>
          <Div responsive="3" marginTop="2">
            <If and value1={produto.possuiQuantidadeFracionada}>
              {this.renderLabelsPesoUnidade()}
            </If>
            <Div
              bg={Color.NODE.LIGHT}
              inline="between"
              padding="2"
              style={styles.containerQuantidade}
            >
              <I
                icon={Type.ICON.MINUS}
                sizeIcon="2"
                style={{ color: corPrimaria }}
                pointer
                handlerClick={() => {
                  if (quantidadeProduto > 0) {
                    this.removeQuantidadeItemState(produto);
                    // this.setState({ quantidade: produto.quantidade });
                  }
                }}
              />
              <Label
                value={`${quantidadeProduto} ${labelUnidade}` || "0"}
                family="Light"
                style={{ fontSize: 20 }}
              />{" "}
              <I
                icon={Type.ICON.PLUS}
                sizeIcon="2"
                style={{ color: corPrimaria }}
                pointer
                handlerClick={() => {
                  if (this.usuarioEstaLogado()) {
                    this.addQuantidadeItemState(produto);
                    // this.setState({ quantidade: produto.quantidade });
                  } else {
                    this.renderViewLogin(corPrimaria);
                  }
                }}
              />
            </Div>

            <Div
              style={{
                ...styles.containerBotaoColorCarrinho,
                backgroundColor: corPrimaria,
              }}
              marginTop="2"
              inline="center"
              pointer
              handlerClick={() => {
                this.addQuantidadeItemBotaoAdicionar(produto);
                ModalNotification.removeModal();
              }}
            >
              <Label
                pointer
                value={this.getLabelAdicionarCarrinho()}
                style={{ fontSize: 15 }}
              />
            </Div>
            <If and value1={Utils.isValueValid(usuarioLogado)}>
              <Select
                marginTop="2"
                col="12"
                styleContainerSelect={{ padding: 0 }}
                label="Minhas listas de compra"
                handlerChange={(e) => this.setListaSelecionada(e.target.value)}
                values={this.getListasSelect()}
              />
              <Div
                style={{
                  ...styles.containerAdicionarMinhaLista,
                }}
                marginTop="2"
                inline="center"
                pointer
                handlerClick={() => {
                  if (Utils.isValueValid(this.state.listaSelecionada)) {
                    this.atualizarListaCompra();
                  } else {
                    toast.error(
                      "Selecione uma lista de compra para adicionar o produto",
                      { position: "top-right" }
                    );
                  }
                }}
              >
                <Label
                  pointer
                  value="Adicionar à minha lista"
                  className="label-adicionar-carrinho"
                />
              </Div>
            </If>
          </Div>
        </If>
        {Utils.isValueValid(produto.filtros) && (
          <ProdutoTabsDetalhes detalhes={produto.filtros} info={false} infoText="" />
        )}

        {produto.combo && (
          <Div
            className="d-flex flex-column"
            style={{ width: "100%" }}
            marginTop="3"
          >
            {
              <NavbarTab
                itens={this.getHeaderInfoProdutoCombo()}
                styleItem={{
                  fontSize: 13,
                  color: "#c8c8c8",
                  fontWeight: "bold",
                }}
                handlerClick={(item) =>
                  this.setState({ haderInfoProdutoCombo: item.target })
                }
              />
            }
            {this.state.haderInfoProdutoCombo === "PRODUTOS_COMBO" &&
              this.renderProdutosCombo(produto.itensCombo)}
            {this.state.haderInfoProdutoCombo === "INFORMACOES" && (
              <Label value={produto.informacoes || "Produto sem informação"} />
            )}
          </Div>
        )}

        <If and value1={Utils.isValueValid(produtosSimilares)}>
          <Div
            marginTop="2"
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: 5,
              padding: 10,
              width: "100%",
            }}
            inline="center"
          >
            <ProdutosListaGenerico
              titulo="Produtos similares"
              produtos={produtosSimilares}
              history={this.props.history}
              handlerClick={(produto) => this.visualizarProduto(produto)}
            />
          </Div>
        </If>
      </Div>
    );
  }
}

export default ProdutoDetalheMobile;
