import React, { Component } from "react";
import { Label, Div, Ul, Li, Span, I, Input } from "../..";
import "./checkbox.css";
import { className, margin, padding } from "../../css";

class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        value: props.value,
        checked: props.checked || false,
        label: props.label
      }
    };

    this.toogleCheck = this.toogleCheck.bind(this);
  }

  getClassDefault() {
    let classe = "";
    classe = classe.concat(this.props.col ? `col-${this.props.col} ` : "");
    classe = classe.concat(padding(this.props));
    classe = classe.concat(margin(this.props));
    classe = classe.concat(className(this.props));

    return classe;
  }

  getClassCheck() {
    let classe = "cb-checkbox ";
    classe = classe.concat(this.state.data.checked ? "checked " : "");
    classe = classe.concat(this.props.success ? "cb-checkbox-success " : "");
    classe = classe.concat(this.props.danger ? "cb-checkbox-danger " : "");
    classe = classe.concat(
      this.props.colorLabel ? `text-${this.props.colorLabel} ` : ""
    );
    return classe;
  }

  toogleCheck() {
    let data = this.state.data;
    data.checked = !data.checked;
    this.setState({
      data
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: {
        value: props.value,
        checked: props.checked || false,
        label: props.label
      }
    });
  }

  render() {
    return (
      <Div id="cbb" className={this.getClassDefault()} style={this.props.style}>
        <Ul className="list-unstyled">
          <Li>
            <Label className={this.getClassCheck()} inline="start" style={this.props.styleLabel}>
              <Span className="cb-inner">
                <I>
                  <Input
                    type="checkbox"
                    name={this.props.name}
                    disabled={this.props.disabled}
                    checked={this.state.data.checked}
                    handlerChange={e => {
                      this.toogleCheck(e);
                      this.props.handlerChange(this.state.data, e);
                    }}
                  />
                </I>
              </Span>
              <Span value={this.props.label} style={this.props.styleLabel} className={this.props.classNameLabel} />
            </Label>
          </Li>
        </Ul>
      </Div>
    );
  }
}

export default Checkbox;
