import React from "react";
import { Utils } from "../../utilities";
import "./buttonsandwich.css";
import { Button, Span } from "..";
import I from "../i/I";

const ID = Utils.uuidString();

const toggle = () => {
  let btn = document.getElementById(ID);
  if (btn !== null && btn !== undefined) {
    btn.classList.toggle("is-active");
  }
};

const ButtonSandwichToggle = (props) => {
  return (
    <I
      pointer
      className={`hamburger--slider ${props.className}`}
      type="button"
      id={ID}
      handlerClick={(e) => {
        toggle();
        props.handlerClick(e);
      }}
    >
      <Span className="hamburger-box">
        <Span className="hamburger-inner"></Span>
      </Span>
    </I>
  );
};

export default ButtonSandwichToggle;
