import React, { Component } from "react";
import {
  Div,
  Table,
  Title,
  Notification,
  ModalNotification,
  SelectValue,
} from "../../components";
import { Color, PathRoute, Type } from "../../utilities";
import TemplateFilterProdutosLista from "./template/TemplateFilterProdutosLista";
import InserirEditarProdutos from "./InserirEditarProdutos";
import ConcorrentesService from "../../service/ConcorrentesService";
import EditarProdutoPreco from "./EditarProdutoPreco";

class ListarProdutosCadastrados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectConcorrente: null,
      selectDataExpiracao: null,
      selectDataIncial: null,
      dataExpiracao: "",
      dataInicial: "",
      concorrentes: [
        new SelectValue(null, "SELECIONE", true)
      ],
      pesquisa: {},
      produtoPreco: {},
      closeModal: false,
      closeModalEditarProduto: false,
      produtos: [],
      loadingProdutosPreco: false,
      dataPadraoEditar: null,
      precoVendaEditar: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.getProdutosPreco(100, {});
    this.getDataConcorrentes();
  }

  componentDidUpdate() {
    if (this.state.loadingProdutosPreco) {
      this.getProdutosPreco(100, {});
    }
  }

  setLoadingProdutosPreco = boolean => {
    this.setState({
      loadingProdutosPreco: boolean,
    })
  }

  setCloseModal = (boolean) => {
    this.setState({
      closeModal: boolean,
    });
  };

  setCloseModalProduto = (boolean) => {
    this.setState({
      closeModalEditarProduto: boolean,
    });
  };

  async getProdutosPreco(paginacao, obj) {
    ConcorrentesService.getConcorrentesPreco(paginacao, obj).then(data => {
      this.setState({
        produtos: data,
        loadingProdutosPreco: false,
      })
    })
  }

  getDataConcorrentes() {
    ConcorrentesService.getConcorrentes().then(({ status, data }) => {
      if (status) {
        this.setState({ concorrentes: data });
      }
    })
  }

  valuesConcorrentesSelect() {
    let selectValue = [new SelectValue("", "SELECIONE", true)];

    this.state.concorrentes.forEach((item) => {
      selectValue.push(
        new SelectValue(item.idConcorrente, item.descricao, false)
      );
    });
    return selectValue;
  }

  editarPesquisa(pesquisa) {
    this.setState({
      closeModalEditarProduto: true,
      produtoPreco: pesquisa,
      precoVendaEditar: pesquisa.precoVenda,
      dataExpiracaoEditar: pesquisa.dataExpiracao
    })
  }

  deletarPesquisa(pesquisa) {

    ModalNotification.confirm(
      "Deseja desativar esta Pesquisa de preço? ",
      "Esta pesquisa será removida no sistema!",
      () => this.delete(pesquisa),
      () => { }
    );
  }

  async delete(pesquisa) {
    let obj = {
      idConcorrentePreco: pesquisa.idConcorrentePreco
    }
    await ConcorrentesService.removerConcorrentePreco(obj)
      .then(({ status, data }) => {
        if (data.status) {
          Notification.success(data.mensagem);
          this.getProdutosPreco(100, {});
        } else {
          Notification.danger(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  mudarFiltros(value, type) {
    const { selectConcorrente, selectDataExpiracao, selectDataIncial } = this.state;
    let search = {}

    if (type === "concorrentes") {
      search = {
        idConcorrente: value,
        dataExpiracaoInicial: selectDataIncial ?  selectDataIncial + " 00:00:00.0" : null, 
        dataExpiracaoFinal: selectDataExpiracao ?  selectDataExpiracao + " 00:00:00.0" : null
      }
      this.setState(
        {
          selectConcorrente: value === "SELECIONE" ? null : value,
        },
        () => this.getProdutosPreco(100, search)
      );
    }
    if (type === "dataExpiracao") {
      search = { 
        idConcorrente: selectConcorrente, 
        dataExpiracaoInicial: selectDataIncial ? selectDataIncial + " 00:00:00.0" : null, 
        dataExpiracaoFinal: value ? value + " 00:00:00.0" : null
      };
      this.setState(
        {
          selectDataExpiracao: value,
          dataExpiracao: value
        },
        () => this.getProdutosPreco(100, search)
      );
    }

    if (type === "dataInicial") {
      search = { 
        idConcorrente: selectConcorrente, 
        dataExpiracaoInicial: value ? value + " 00:00:00.0" : null, 
        dataExpiracaoFinal: selectDataExpiracao ? selectDataExpiracao + " 00:00:00.0" : null,
      };
      this.setState(
        {
          selectDataIncial: value,
          dataInicial: value
        },
        () => this.getProdutosPreco(100, search)
      );
    }
  }


  renderFiltros() {
    const { dataExpiracao, dataInicial } = this.state;

    return (
      <TemplateFilterProdutosLista
        concorrentes={this.valuesConcorrentesSelect()}
        dataInicial={dataInicial}
        dataExpiracao={dataExpiracao}
        handler={(value, type) => this.mudarFiltros(value, type)}
      />
    );
  }

  render() {

    return (
      <>
        <InserirEditarProdutos
          setLoadingProdutosPreco={this.setLoadingProdutosPreco}
          closeModal={this.state.closeModal}
          setCloseModal={this.setCloseModal}
        />
        <EditarProdutoPreco
          setLoadingProdutosPreco={this.setLoadingProdutosPreco}
          closeModal={this.state.closeModalEditarProduto}
          setCloseModal={this.setCloseModalProduto}
          produtoPreco={this.state.produtoPreco}
        />
        <Div
          margin="3"
          shadow
          bg={Color.NODE.WHITE}
          borderLeft={Color.NODE.PRIMARY}
        >
          <Title
            value="Pesquisa de Preços por Concorrente"
            type="h4"
            paddingLeft="4"
            paddingRight="4"
            paddingTop="3"
          />

          <Div padding="2">
            <Table
              columns={[
                { value: "Código de Barras", align: "center" },
                { value: "Descricao", align: "left" },
                { value: "Concorrente", align: "center" },
                { value: "Preço de Venda", align: "center" },
                { value: "Data de Expiração", align: "center" },
              ]}
              fields={[
                {
                  value: "codigoBarrasProduto",
                  align: "center",
                  type: Type.DADO.STRING,
                },
                {
                  value: "descricaoProduto",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "descricaoConcorrente",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "precoVenda",
                  align: "left",
                  type: Type.DADO.CURRENCY,
                },
                {
                  value: "dataExpiracao",
                  align: "left",
                  type: Type.DADO.DATE,
                },
              ]}
              bordered
              search
              orientation="center"
              pagination
              edit
              delete
              sizeEdit="2"
              sizeDelete="2"
              filter={this.renderFiltros()}
              handlerEdit={(rede) => this.editarPesquisa(rede)}
              handlerDelete={(rede) => this.deletarPesquisa(rede)}
              data={this.state.produtos}
              rota="#"
              handlerRota={() => this.setCloseModal(true)}
              error="Nenhuma produto encontrado!"
            ></Table>
          </Div>
        </Div>
      </>
    );
  }
}



export default ListarProdutosCadastrados;
