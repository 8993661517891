import React from "react";
import { Component } from "react";
import {
  Div,
  I,
  If,
  Image,
  Label,
  MessageSpinner,
} from "../../../components";
import {
  Color,
  EndPoints,
  Http,
  Mask,
  StatusPedidoLojaWeb,
  Type,
  Utils,
} from "../../../utilities";

import UsuarioUtils from "../../utils/UsuarioUtils";
import TimeLinePedidoLojaWebMobile from "../../../components/timeline/horizontal/TimeLinePedidoLojaWebMobile";
import BotaoPaginaInicial from "../../utils/BotaoPaginaInicial";

import {ReactComponent as IconValor} from "../../../assets/images/icon-valor.svg";
import {ReactComponent as IconEntrega} from "../../../assets/images/icon-entrega.svg";

const styles = {
  containerPedidos: {
    backgroundColor: "#E5E5E5",
    borderRadius: 10,
    padding: 10,
  },
  containterHeaderPedido: {
    backgroundColor: Color.ECOMMERCE.WHITE,
    width: window.innerWidth - 30,
    height: 300,
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
  },
  labelDescricao: { fontSize: 13, color: "#878787", lineHeight: "10px" },
  labelValor: {
    color: "#C4C4C4",
    fontSize: 15,
  },
  btnDetalhesCompra: {
    backgroundColor: Color.ECOMMERCE.CINZA,
    color: Color.ECOMMERCE.WHITE,
    padding: 10,
    width: "100%",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    position: "absolute",
    textAlign: "center",
    left: 0,
    bottom: -8,
  },
  containterTopBody: {
    borderBottom: "1px dashed #C4C4C4",
    width: "100%",
  },
  topBody: {
    lineHeight: "10px",
  },
  labelPedido: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    textAlign: "center",
    fontSize: 12,
    lineHeight: "8px",
  },
  labelPedidoCancelado: {
    color: "red",
    textAlign: "center",
    textDecoration: "line-through",
    fontSize: 12,
    lineHeight: "8px",
  },
  labelPagamentoConfirmado: {
    color: "#31CDFF",
    fontSize: 15,
    width: "100%",
  },
  labelPagamentoAguardar: {
    color: Color.EMPRESA.PRIMARIA,
    fontSize: 15,
    width: "100%",
  },
  labelTitleHeader: {
    fontSize: 13,
    color: "#878787",
  },
  labelNaoTemPedidos: {
    fontSize: 30,
    color: Color.EMPRESA.PRIMARIA,
  },
};

class MeusPedidosMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedidos: [],
      pesquisando: false,
    };
  }

  componentDidMount() {
    this.setState({ pesquisando: true }, () => this.listarPedidos());
  }

  listarPedidos() {
    //let cliente = JSON.parse(localStorage.getItem(Keys.KEY_CLIENTE_LOJA_WEB));
    let cliente = UsuarioUtils.getUsuarioLojaWeb();

    if (Utils.isValueValid(cliente)) {
      Http.get(EndPoints.CLIENTE_PEDIDOS.concat(cliente.idCliente))
        .then(({ status, data }) => {
          if (status) {
            this.setState({ pedidos: data, pesquisando: false });
          } else {
            this.setState({ pesquisando: false });
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  getElemententosTimeLine(pedido) {
    let elementos = [];

    elementos.push({
      elemento: StatusPedidoLojaWeb.EM_ABERTO,
      active: Utils.isValueValid(pedido.dataSituacaoEmAberto),
    });
    elementos.push({
      elemento: StatusPedidoLojaWeb.EM_SEPARACAO,
      active: Utils.isValueValid(pedido.dataSituacaoEmSeparacao),
    });

    if (pedido.tipoEntrega === "ENTREGA") {
      elementos.push({
        elemento: StatusPedidoLojaWeb.SAIU_ENTREGA,
        active: Utils.isValueValid(pedido.dataSituacaoSaiuEntrega),
      });
    } else {
      elementos.push({
        elemento: StatusPedidoLojaWeb.AGUARDANDO_RETIRADA,
        active: Utils.isValueValid(pedido.dataSituacaoAguardandoRetirada),
      });
    }

    elementos.push({
      elemento: StatusPedidoLojaWeb.CONCLUIDO,
      active: Utils.isValueValid(pedido.dataSituacaoConcluido),
    });

    return elementos;
  }

  setStatusPedido(pedido) {
    let status = "ABERTO";
    if (Utils.isValueValid(pedido.dataSituacaoEmAberto))
      status = StatusPedidoLojaWeb.EM_ABERTO;
    if (Utils.isValueValid(pedido.dataSituacaoEmSeparacao))
      status = StatusPedidoLojaWeb.EM_SEPARACAO;
    if (Utils.isValueValid(pedido.dataSituacaoAguardandoRetirada))
      status = StatusPedidoLojaWeb.AGUARDANDO_RETIRADA;
    if (Utils.isValueValid(pedido.dataSituacaoSaiuEntrega))
      status = StatusPedidoLojaWeb.SAIU_ENTREGA;
    if (Utils.isValueValid(pedido.dataSituacaoProblemasEntrega))
      status = StatusPedidoLojaWeb.PROBLEMAS_ENTREGA;
    if (Utils.isValueValid(pedido.dataSituacaoConcluido))
      status = StatusPedidoLojaWeb.CONCLUIDO;
    if (Utils.isValueValid(pedido.dataSituacaoCancelado))
      status = StatusPedidoLojaWeb.CANCELADO;

    return status;
  }

  getStyleLabelPedido(pedido) {
    let style = styles.labelPedido;

    if (pedido.status === StatusPedidoLojaWeb.CANCELADO) {
      style = styles.labelPedidoCancelado;
    }

    return style;
  }

  renderTemplatePedidos() {
    let pedidos = this.state.pedidos;

    return (
      <Div inline="center" className="w-100" padding="1">
        <Div style={styles.containerPedidos} responsive="12">
          {Array.from(pedidos).map((pedido, index) => {
            pedido.status = this.setStatusPedido(pedido);
            if (pedido.status === StatusPedidoLojaWeb.CANCELADO) {
              pedido.cancelado = true;
            } else if (
              pedido.status === StatusPedidoLojaWeb.PROBLEMAS_ENTREGA
            ) {
              pedido.problemasEntrega = true;
            }

            if (pedido.status === StatusPedidoLojaWeb.PAGAMENTO_CONFIRMADO) {
              pedido.pago = true;
            }

            let styleLabelPedido = this.getStyleLabelPedido(pedido);

            return (
              <Div inline="start" key={index} marginTop="2">
                <Div style={styles.containterHeaderPedido} responsive="3">
                  <Label
                    value={`Pedido ${Utils.capitalize(pedido.status)}`}
                    style={styleLabelPedido}
                    family="SemiBold"
                    col="12"
                  />
                  <Label
                    col="12"
                    value={`Referência: ${pedido.referencia}`}
                    style={{
                      color: Color.ECOMMERCE.MARRON_ESCURO,
                      textAlign: "center",
                      fontSize: 12,
                    }}
                    family="Light"
                  />
                  <Label
                    value=""
                    style={{ width: "100%", border: "1px dashed #E5E5E5" }}
                  />

                  <Div inline="between">
                    <Div inline="center">                
                      <IconValor style={{ width:"20px", marginBottom: 15, marginRight: 5 }} />
                      <Div className="d-flex flex-column align-items-start">
                        <Label
                          value="Valor total"
                          style={styles.labelDescricao}
                          family="Light"
                        />
                        <Label
                          value={Mask.formatValueBr(pedido.valorTotalSeparacao)}
                          style={styles.labelValor}
                          family="SemiBold"
                        />
                      </Div>
                    </Div>
                    <Div inline="center">
                      <IconEntrega style={{ width:"35px", marginBottom: 15, marginRight: 5 }} />
                      <Div className="d-flex flex-column align-items-start">
                        <Label
                          value="Taxa de entrega"
                          style={styles.labelDescricao}
                        />
                        <Label
                          value={Mask.formatValueBr(pedido.valorTaxaEntrega)}
                          style={styles.labelValor}
                          family="SemiBold"
                        />
                      </Div>
                    </Div>
                  </Div>
                  <Div inline="start">
                    <IconValor style={{ width:"20px", marginBottom: 15, marginRight: 5 }} />
                    <Div className="d-flex flex-column align-items-start">
                      <Label
                        value="Status pagamento"
                        style={styles.labelDescricao}
                        family="Light"
                      />
                      <Label
                        value={
                          pedido.pago ? "Confirmado" : "Aguardando pagamento"
                        }
                        style={
                          pedido.pago
                            ? styles.labelPagamentoConfirmado
                            : styles.labelPagamentoAguardar
                        }
                        className="text-ellipsis"
                        family="SemiBold"
                      />
                    </Div>
                  </Div>
                  <Div marginTop="1">
                    <TimeLinePedidoLojaWebMobile
                      elements={this.getElemententosTimeLine(pedido)}
                    />
                  </Div>
                  <Label
                    value="Detalhes da compra"
                    style={styles.btnDetalhesCompra}
                    family="SemiBold"
                    handlerClick={() =>
                      this.props.history.push("/cliente/pedidos/detalhe", {
                        pedido,
                      })
                    }
                  />
                </Div>
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  renderTemplateNaoTemPedido() {
    return (
      <Div column className="w-100" padding="2">
        <Div style={styles.labelNaoTemPedidos} responsive="12" inline="center">
          <I icon={Type.ICON.INFO} col="2" style={{fontSize: 35}} />
          <Label
            col="11"
            family="SemiBold"
            value="Nenhum pedido encontrado, verifique se algum pedido foi realizado"
            style={{ fontSize: 20 }}
          />
        </Div>
      </Div>
    );
  }

  render() {
    let pedidos = this.state.pedidos;
    return (
      <>
        <Div inline="start" className="w-100 m-2">
          <I
            icon={Type.ICON.HISTORY}
            style={{
              fontSize: 20,
              marginBottom: 8,
              marginRight: 5,
              color: Color.ECOMMERCE.CINZA_ESCURO,
            }}
          />
          <Label
            value="HISTÓRIO DE PEDIDOS"
            style={{ color: Color.ECOMMERCE.CINZA_ESCURO }}
            family="Bold"
          />
        </Div>
        <Div className="w-100 p-2">
          <BotaoPaginaInicial history={this.props.history} label="Continuar comprando"/>
        </Div>

        <If and value1={Utils.isValueValid(pedidos)}>
          {this.renderTemplatePedidos()}
        </If>
        <If and value1={this.state.pesquisando}>
          <Div inline="center" className="w-100" marginTop="5">
            <MessageSpinner
              style={{ backgroundColor: Color.EMPRESA.PRIMARIA }}
              colorText={Color.NODE.WHITE}
            >
              <Label
                value="Consultando pedidos, aguarde"
                family="Light"
                style={{ fontSize: 30 }}
                margin="2"
              />
            </MessageSpinner>
          </Div>
        </If>
        <If
          and
          value1={!Utils.isValueValid(pedidos)}
          value2={!this.state.pesquisando}
        >
          {this.renderTemplateNaoTemPedido()}
        </If>
      </>
    );
  }
}

export default MeusPedidosMobile;
