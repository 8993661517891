import React, { Component } from 'react'
import {NavbarTop} from '../../components'
import Id from '../../utilities/enuns/Id'
import Div from '../layout/div/Div'

class Footer extends Component {

    render() {
        return (
            <Div />
        )
    }

}

export default Footer