import React, { Component } from "react";
import { EntregaRetiradaUtils } from "..";
import {
  Checkbutton,
  Div,
  I,
  If,
  Label,
  ModalNotification,
  Select,
  SelectValue,
} from "../../components";
import { Color, Type, Utils } from "../../utilities";
import NovoEnderecoCheckoutMobile from "../cliente/mobile/NovoEnderecoCheckoutMobile";

const getTipoEntrega = (parametros, cabecalho) => {
  let valorEntrega = Number(parametros.valorMinimoPedidoEntrega).toFixed(2);
  let valorRetirada = Number(parametros.valorMinimoPedidoRetirada).toFixed(2);
  let valorTotal = Number(cabecalho.valorTotal).toFixed(2);

  let statusEntrega = parametros.entrega.situacao;

  let tipos = [];

  if (valorTotal >= valorEntrega && statusEntrega === "SUCESSO") {
    tipos.push(new SelectValue("ENTREGA", "Quero receber em casa"));
  }

  if (valorTotal >= valorRetirada) {
    tipos.push(new SelectValue("RETIRADA", "Quero retirar na loja"));
  }

  return tipos;
};

const styles = {
  botoesEntrega: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "center !important"
  },
  labelTipoEntregaActive: {
    border: `3px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    opacity: 0.9,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    fontSize: 17,
    borderRadius: 5,
    padding: 10,
    width: "33%",
    height: 80,
    textAlign: "center",
  },
  labelTipoEntrega: {
    border: `3px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 17,
    borderRadius: 5,
    padding: 10,
    height: 80,
    width: "33%",
    textAlign: "center",
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
  },
};

class TemplateEntregaRetirada extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outroEnderecoEntrega: {},
    };
  }

  getTipoEntrega = () => {
    const { pedidoEntrega, pedidoRetirada, pedidoEntregaOutro } = this.props;
    let tipo = null;

    if (pedidoEntrega) tipo = EntregaRetiradaUtils.TIPO_ENTREGA;
    if (pedidoRetirada) tipo = EntregaRetiradaUtils.TIPO_RETIRADA;
    if (pedidoEntregaOutro) tipo = EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO;

    return tipo;
  };

  componentDidMount() {
    let values = getTipoEntrega(this.props.parametros, this.props.cabecalho);
    if (this.props.pedidoEntrega) {
      let value = values.filter(
        (v) => v.id === EntregaRetiradaUtils.TIPO_ENTREGA
      )[0];
      if (Utils.isValueValid(value)) {
        this.props.handlerChangeTipoEntrega(value.id);
      }
    } else if (this.props.pedidoRetirada) {
      let value = values.filter(
        (v) => v.id === EntregaRetiradaUtils.TIPO_RETIRADA
      )[0];
      if (Utils.isValueValid(value)) {
        this.props.handlerChangeTipoEntrega(value.id);
      }
    } else {
      this.props.handlerChangeTipoEntrega(
        EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
      );
    }
  }

  renderTemplateOutroEndereco() {
    ModalNotification.render(
      "Outro local de entrega",
      <NovoEnderecoCheckoutMobile
        labelBotaoConfirmar="Adicionar endereço"
        handlerOutroEndereco={(endereco) => {
          this.setState({ outroEnderecoEntrega: endereco });
        }}
        corPrimaria={this.props.corPrimaria}
        renderAgain={() => this.renderTemplateOutroEndereco()}
        handlerUpdate={() =>
          this.props.handlerChangeTipoEntrega(
            EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
          )
        }
      />
    );
  }

  render() {
    const {
      handlerChangeTipoEntrega,
      handerErrorEntregaRetirada,
      parametros,
      cabecalho,
      corPrimaria,
      corSecundaria,
    } = this.props;
    let values = getTipoEntrega(parametros, cabecalho);
    let tipoEntrega = this.getTipoEntrega();

    if (!Utils.isValueValid(values)) {
      handerErrorEntregaRetirada();
    }

    return (
      <Div marginTop="2" col="6" padding="0" >
        <Label
          value="ENTREGA / RETIRADA"
          style={styles.labelInfo}
          family="SemiBold"
        />
        <Div className="d-flex flex-row justify-content-center">
          <Checkbutton
            corPrimaria={corPrimaria}
            checked={tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA}
            title="Receber o pedido"
            onClick={() =>
              handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_ENTREGA)
            }
            style={{  margin: "9px 15px 9px 0"  }}
            Icon={
              <I
                pointer
                icon={Type.ICON.HOME}
                style={{
                  color:
                    tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA
                      ? Color.ECOMMERCE.WHITE
                      : corPrimaria,
                  fontSize: 20,
                }}
              />
            }
          />

          <If and value1={Utils.isNotNull(parametros.aceitaRetirada)}>

            <Checkbutton
              corPrimaria={corPrimaria}
              checked={tipoEntrega === EntregaRetiradaUtils.TIPO_RETIRADA}
              title="Retirar na loja"
              onClick={() =>
                handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_RETIRADA)
              }
              style={{ margin: "10px 15px 10px 0" }}
              Icon={
                <I
                  pointer
                  icon={Type.ICON.MAP_MARKER}
                  style={{
                    color:
                      tipoEntrega === EntregaRetiradaUtils.TIPO_RETIRADA
                        ? Color.ECOMMERCE.WHITE
                        : corPrimaria,
                    fontSize: 20,
                  }}
                />
              }
              
            />
          </If>

          <Checkbutton
            corPrimaria={corPrimaria}
            checked={tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO}
            title="Adicionar outro"
            onClick={() => {
              handlerChangeTipoEntrega(EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO);
              this.renderTemplateOutroEndereco();
            }}
            Icon={
              <I
                pointer
                icon={Type.ICON.MAP_MARKER}
                style={{
                  color:
                    tipoEntrega === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO
                      ? Color.ECOMMERCE.WHITE
                      : corPrimaria,
                  fontSize: 20,
                }}
              />
            }
          />
        </Div>
      </Div>
    );
  }
}

export default TemplateEntregaRetirada;
