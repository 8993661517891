import React, { Component } from "react";
import { Type, Utils } from "../../utilities";

import "./carousel.css";
import "./carouselprodutogenerico.css";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { I, If } from "..";

class CarouselProdutoGenericoNovo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itens: props.itens || [],
      index: 0,
      infinite: false,
    };
  }

  componentWillReceiveProps({ itens }) {
    this.setState({ itens }, () => {
      this.setInfinite();
    });
  }

  componentDidMount() {
    this.setInfinite();
  }

  getDots() {
    let dots = [];
    Array.from(this.props.itens).forEach((item) => {
      dots.push(<li className={`indicator-carousel-banner-top`}></li>);
    });
    return dots;
  }

  getArrowLeft() {
    return this.props.arrows || this.props.arrowLeft ? (
      <I
        style={{
          fontSize: this.props.isMobile ? 40 : 60,
          color: "#C4C4C4",
          margin: 5,
          transform: `translateY(${this.props.isMobile ? "-45px" : "-40px"})`,
        }}
        className={Type.ICON.ARROW_ANGLE_LEFT}
        pointer
        handlerClick={() =>
          this.setState({
            index: this.state.index - this.props.slidesPerPage - 1,
          })
        }
      />
    ) : null;
  }

  getArrowRight() {
    let itens = Array.from(this.state.itens);
    return this.props.arrows || this.props.arrowRight ? (
      <I
        style={{
          fontSize: this.props.isMobile ? 40 : 60,
          color: "#C4C4C4",
          margin: 5,
          transform: `translateY(${this.props.isMobile ? "-45px" : "-40px"})`,
        }}
        className={Type.ICON.ARROW_ANGLE_RIGHT}
        pointer
        handlerClick={() => {
          this.setState({
            index: this.state.index + this.props.slidesPerPage - 1,
          });
        }}
      />
    ) : null;
  }

  setInfinite() {
    setTimeout(() => {
      this.setState({ infinite: true });
    }, 600);
  }

  render() {
    let itens = Array.from(this.state.itens).filter((p) => !p.produtoSemImage);
    return (
      <>
        <Carousel
          infinite={this.state.infinite}
          slidesPerPage={this.props.slidesPerPage}
          value={this.state.index}
          onChange={(index) => this.setState({ index })}
          stopAutoPlayOnHover
          autoPlay={this.props.timeSlide || 5000}
          arrowLeft={this.getArrowLeft()}
          arrowRight={this.getArrowRight()}
          offset={this.props.offset}
          animationSpeed={1000}
        >
          {Array.from(itens).filter((item) => !item.produtoSemImagem)}
        </Carousel>
        <If and value1={Utils.isValueValid(this.props.dots)}>
          <Dots
            value={this.state.index}
            onChange={(index) => this.setState({ index })}
            thumbnails={this.getDots()}
          />
        </If>
      </>
    );
  }
}

export default CarouselProdutoGenericoNovo;
