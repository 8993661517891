import React, { Component } from "react";
import { Div, I, If, Image, Label, TooltipText } from "../../components";
import { Color, Mask, Type, Utils } from "../../utilities";

const styles = {
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
  },
  labelSubTotalItem: {
    fontSize: 25,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
  },
  labelTitleObs: {
    fontSize: 20,
    color: Color.ECOMMERCE.CINZA,
  },
  containterObs: {
    backgroundColor: "#f2f2f2",
    padding: 5,
    borderRadius: 5,
    width: "100%",
  },
  containterCliente: {
    border: "1px solid #f2f2f2",
    width: "100%",
  },
  labelSubTotalIndisponivel: {
    fontSize: 25,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
    textDecoration: "line-through",
  },
};

class TemplateItensPedidoDetalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarTodos: props.mostrarTodos || false,
      pedido: props.pedido,
    };
  }

  setAlteradoNaSeparacao(pedido) {
    pedido.alteradoNaSeparacao =
      Number(pedido.valorTotal).toFixed(2) !==
      Number(pedido.valorTotalSeparacao).toFixed(2);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let pedido = props.pedido;
    this.setAlteradoNaSeparacao(pedido);
    this.setState({ pedido, parametros: props.parametros });
  }

  getProdutosPedido = () => {
    let itensFiltrados = [];
    if (Utils.isValueValid(this.state.pedido)) {
      const { itens } = this.state.pedido;

      if (Utils.isValueValid(itens)) {
        itensFiltrados = itens;
      }
    }

    return itensFiltrados;
  };

  getValorItem(produto) {
    return produto.indisponivel
      ? 0
      : produto.valorTotalSeparacao || produto.valorTotal || 0;
  }

  getQuantidade(produto) {
    return produto.quantidadeSeparacao || produto.quantidade || 0;
  }

  renderProdutosLista = (produtos) => {
    let idParent = Utils.uuidString();

    return (
      <Div className="w-100" id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          prod.indisponivel = prod.posicao === "INDISPONIVEL";
          return (
            <Div key={index}>
              <Div
                inline="between"
                col="12"
                padding="1"
                key={index}
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start" col="6">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="70px"
                  />
                  <Label
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={{ whiteSspace: "normal" }}
                  />
                </Div>

                <Div inline="center" col="2" style={styles.containerAddItem}>
                  <If and value1={!prod.indisponivel}>
                    <Label
                      value={`${this.getQuantidade(prod)}x ${Number(
                        prod.precoUnitario
                      ).toLocaleString()}`}
                      family="Light"
                      margin="3"
                      className="mx-auto"
                    />
                  </If>
                  <If and value1={prod.indisponivel}>
                    <Label value="CANCELADO" family="Light" margin="3" />
                  </If>
                </Div>

                <Div inline="end" col="2">
                  <If and value1={Utils.isValueValid(prod.obs)}>
                    <TooltipText diretion="top" title={prod.obs}>
                      <I
                        icon={Type.ICON.INFO}
                        sizeIcon="2"
                        style={{ width: 30, marginRight: 15 }}
                      />
                    </TooltipText>
                  </If>
                  <Label
                    value={Mask.formatValueBr(this.getValorItem(prod))}
                    family="SemiBold"
                    style={
                      prod.indisponivel
                        ? styles.labelSubTotalIndisponivel
                        : styles.labelSubTotalItem
                    }
                  />
                </Div>
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  renderDadosPessoaisCliente() {
    let pedido = this.state.pedido;
    let cliente = {};
    if (Utils.isValueValid(pedido)) cliente = pedido.cliente;

    return (
      <Div style={styles.containterCliente} inline="center">
        <Div
          responsive="6"
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Label
            value="Dados pessoais"
            family="SemiBold"
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 20,
              marginLeft: 10,
            }}
          />
          <Div className="w-100">
            <Div className="w-100" inline="between">
              <Label
                value={`Nome: ${cliente.nome}`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10 }}
              />
              <Label
                value={`CPF/CNPJ: ${Mask.getValueMaskCpfCnpj(cliente.cpfcnpj)}`}
                family="Bold"
                style={{ color: "#c4c4c4" }}
              />
            </Div>
            <Div inline="between" className="w-100">
              <Label
                value={`Telefone: ${Mask.getValueMaskPhone(cliente.telefone)}`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10 }}
              />
              <Label
                value={`Email: ${cliente.email}`}
                family="Bold"
                style={{ color: "#c4c4c4" }}
              />
            </Div>
          </Div>
        </Div>
        <Div
          responsive="6"
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Label
            value="Endereço"
            family="SemiBold"
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 20,
              marginLeft: 10,
            }}
          />
          <Div>
            <Div col="12">
              <Label
                value={`Logradouro: ${cliente.logradouro} n° ${cliente.numero}, ${cliente.bairro} - ${cliente.nomeCidade}`}
                family="Bold"
                style={{ color: "#c4c4c4" }}
              />
              <Label
                marginLeft="2"
                value={Mask.getValueMaskCep(cliente.cep)}
                family="Bold"
                style={{ color: "#c4c4c4" }}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  renderDadosPagamento() {
    let pedido = this.state.pedido;
    let recebimento = {};
    if (Utils.isValueValid(pedido)) recebimento = pedido.recebimento;

    return (
      <Div style={styles.containterCliente} inline="center">
        <Div
          responsive="12"
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Div className="w-100">
            <Div className="w-100" inline="between">
              <Label
                value={`Tipo: ${recebimento.tipo}`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10 }}
              />
              <If
                and
                value1={
                  recebimento.tipo === "CREDITO" ||
                  recebimento.tipo === "DEBITO"
                }
              >
                <Label
                  value={`Bandeira: ${recebimento.bandeiraCartao}`}
                  family="Bold"
                  style={{ color: "#c4c4c4", marginLeft: 10 }}
                />
              </If>
            </Div>
            <Div inline="between" className="w-100">
              <Label
                value={`Status: ${
                  recebimento.pedigoPago ? "CONFIRMADO" : "AGUARDANDO PAGAMENTO"
                }`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10 }}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  render() {
    let itens = this.getProdutosPedido();
    let pedido = this.state.pedido;

    let idDadosCliente = Utils.uuidString();
    let idDadosPagamento = Utils.uuidString();

    return (
      <>
        <Div
          className="d-flex flex-column align-self-start align-items-start justify-content-between"
          responsive="9"
        >
          <If and value1={Utils.isValueValid(pedido.obs)}>
            <Div style={styles.containterObs}>
              <Label
                value="Observações:"
                style={styles.labelTitleObs}
                family="SemiBold"
              />
              <If and value1={!pedido.alteradoNaSeparacao}>
                <Label value={pedido.obs} family="Light" marginLeft="2" />
              </If>
              <If
                and
                value1={
                  pedido.alteradoNaSeparacao &&
                  pedido.recebimento.tipo === "CREDITO" &&
                  pedido.tipoPagamento === "ON_LINE"
                }
              >
                <Div className="d-flex flex-column" marginLeft="2">
                  <Label value={pedido.obs} family="Light" />
                  <Label
                    value={
                      "INFORMAÇÕES SOBRE O CARTÃO DE CRÉDITO: O prazo para o valor da compra mudar na fatura é de 2 dias."
                    }
                  />
                </Div>
              </If>
            </Div>
          </If>
          <Div
            marginTop="2"
            inline="between"
            className="w-100"
            style={styles.containterObs}
            pointer
            target={`#${idDadosCliente}`}
            toggle="collapse"
          >
            <Label
              value="Dados do cliente"
              style={styles.labelTitleObs}
              family="SemiBold"
              pointer
            />
            <I
              icon={Type.ICON.ARROW_CHEV_DOWN}
              style={{ color: Color.ECOMMERCE.CINZA }}
              pointer
            />
          </Div>
          <Div className="collapse w-100" id={idDadosCliente}>
            {this.renderDadosPessoaisCliente()}
          </Div>
          <Div
            marginTop="2"
            inline="between"
            className="w-100"
            style={styles.containterObs}
            pointer
            target={`#${idDadosPagamento}`}
            toggle="collapse"
          >
            <Label
              value="Dados do pagamento"
              style={styles.labelTitleObs}
              family="SemiBold"
              pointer
            />
            <I
              icon={Type.ICON.ARROW_CHEV_DOWN}
              style={{ color: Color.ECOMMERCE.CINZA }}
              pointer
            />
          </Div>
          <Div className="collapse w-100" id={idDadosPagamento}>
            {this.renderDadosPagamento()}
          </Div>

          <Div marginTop="2" className="w-100">
            <Label
              value="Produtos da compra:"
              style={styles.labelTitleObs}
              family="SemiBold"
            />
            <Div className="w-100">{this.renderProdutosLista(itens || [])}</Div>
          </Div>
        </Div>
      </>
    );
  }
}

export default TemplateItensPedidoDetalhe;
