import { Color, Keys, Mask, Type, Utils } from "../../utilities";
import CryptoJS from "crypto-js";
import { EntregaRetiradaUtils, ProdutoUtils } from "..";
import { Div, I, If, Label, ModalNotification } from "../../components";
import React from "react";
import TagManagerUtils from "../../utilities/utils/TagManagerUtils";

export default class CarrinhoUtils {
  static getCarrinho() {
    let carrinho = localStorage.getItem(Keys.KEY_CARRINHO_LOJA_WEB);
    let carrinhoEncrypt = null;
    if (Utils.isValueValid(carrinho)) {
      try {
        carrinhoEncrypt = CryptoJS.AES.decrypt(
          localStorage.getItem(Keys.KEY_CARRINHO_LOJA_WEB),
          Keys.KEY_CARRINHO_SECRET_LOJA_WEB
        ).toString(CryptoJS.enc.Utf8);
      } catch (error) {
        if (String(error).includes("Malformed UTF-8 data")) {
          localStorage.setItem(
            "error",
            "Erro ao realizar parse do carrinho de compras, provavelmente houve alteração na estrutura do carrinho"
          );
          localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
          Utils.refreshPage();
        }
      }

      return JSON.parse(carrinhoEncrypt);
    }
  }

  static addQuantidadeItem(item) {
    let quantidade = item.quantidade;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);
  }

  static removeQuantidadeItem(item) {
    let quantidade = item.quantidade;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);
  }

  static adicionarItemNoCarrinho(
    produto,
    carrinho,
    produtos,
    atualizarCarrinho,
    setProdutos,
    atualizarProdutoDetalhe,
    parametros
  ) {
    let itens = Array.from(carrinho.itens);

    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    // SE A QUANTIDADE FOR ZERO REMOVE DO CARRINHO
    if (Utils.isValueValid(produtoSelecionado)) {
      if (produtoSelecionado.quantidade > 0) {
        produtoSelecionado.quantidade = produto.quantidade;
        produtoSelecionado.valorSubTotalItem = produto.valorSubTotalItem;
        produtoSelecionado.quantidadePeso = produto.quantidadePeso;
        produtoSelecionado.quantidadeUnidade = produto.quantidadeUnidade;
      } else {
        itens.splice(itens.indexOf(produtoSelecionado), 1);
        TagManagerUtils.tagManagerRemoverDoCarrinho(produto)
      }
    } else {
      itens.push(produto);
      TagManagerUtils.tagManagerAdicionarNoCarrinho(produto)
    }

    carrinho.itens = itens;

    atualizarCarrinho(carrinho);
    setProdutos(produtos);

    if (Utils.isFunction(atualizarProdutoDetalhe)) {
      atualizarProdutoDetalhe();
    }

    CarrinhoUtils.notificarProdutoAlterado(produto);

    CarrinhoUtils.calcularValorTaxaEntrega(
      parametros,
      carrinho,
      atualizarCarrinho
    );

    setTimeout(() => {
      CarrinhoUtils.validarValorMaximoPedido(
        carrinho,
        parametros,
        produto,
        atualizarCarrinho
      );
    }, 100);
  }

  static adicionarItemNoCarrinhoDeCompras(
    produto,
    carrinho,
    atualizarCarrinho,
    atualizarProdutoDetalhe,
    parametros
  ) {

    let itens = Array.from(carrinho.itens);

    let produtoSelecionado = itens.filter(
      (item) => item.codigoBarras === produto.codigoBarras
    )[0];

    // SE A QUANTIDADE FOR ZERO REMOVE DO CARRINHO
    if (Utils.isValueValid(produtoSelecionado)) {
      if (produtoSelecionado.quantidade > 0) {
        produtoSelecionado.quantidade = produto.quantidade;
        produtoSelecionado.valorSubTotalItem = produto.valorSubTotalItem;
        produtoSelecionado.quantidadePeso = produto.quantidadePeso;
        produtoSelecionado.quantidadeUnidade = produto.quantidadeUnidade;
      } else {
        itens.splice(itens.indexOf(produtoSelecionado), 1);
      }
    } else {
      itens.push(produto);
    }

    carrinho.itens = itens;

    atualizarCarrinho(carrinho);

    if (Utils.isFunction(atualizarProdutoDetalhe)) {
      atualizarProdutoDetalhe();
    }

    CarrinhoUtils.notificarProdutoAlterado(produto);

    CarrinhoUtils.calcularValorTaxaEntrega(
      parametros,
      carrinho,
      atualizarCarrinho
    );

    setTimeout(() => {
      CarrinhoUtils.validarValorMaximoPedido(
        carrinho,
        parametros,
        produto,
        atualizarCarrinho
      );
    }, 100);
  }

  static notificarProdutoAlterado(produto) {
    document.dispatchEvent(
      ProdutoUtils.eventChangeProduto({
        quantidade: produto.quantidade,
        codigoBarras: produto.codigoBarras,
      })
    );
  }

  static validarValorMaximoPedido(
    carrinho,
    { valorMaximoPedido },
    produto,
    atualizarCarrinho
  ) {
    let valorTotal = Number(carrinho.cabecalho.valorTotal);
    let valorMaximo = Number(valorMaximoPedido);

    if (valorTotal > valorMaximo) {
      let itens = Array.from(carrinho.itens);
      let itemAdicionado = itens.filter(
        (i) => i.codigoBarras === produto.codigoBarras
      )[0];
      CarrinhoUtils.removeQuantidadeItem(itemAdicionado);
      if (itemAdicionado.quantidade === 0) {
        itens.splice(itens.indexOf(itemAdicionado), 1);
      }

      carrinho.itens = itens;
      atualizarCarrinho(carrinho);
      CarrinhoUtils.notificarProdutoAlterado(produto);
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: Utils.isViewMobile() ? 20 : 30,
          }}
        >
          <Div inline="center">
            <If and value1={!Utils.isViewMobile()}>
              <I
                icon={Type.ICON.INFO}
                style={{
                  color: Color.ECOMMERCE.CINZA_ESCURO,
                  fontSize: 30,
                  marginBottom: 10,
                  marginRight: 5,
                }}
              />
            </If>
            <Label
              family="Bold"
              value="O pedido atingiu o valor máximo permitido"
            />
          </Div>
          <Label
            family="Bold"
            style={{
              color: Color.ECOMMERCE.LARANJA,
              fontSize: Utils.isViewMobile() ? 20 : 30,
            }}
            value={`Valor máximo para o pedido: ${Mask.formatValueBr(
              valorMaximo
            )}`}
          />
        </Div>
      );
    }
  }

  static calcularValorTaxaEntrega(parametros, carrinho, atualizarCarrinho) {
    let entrega = this.getEntrega(parametros);
    let cabecalho = carrinho.cabecalho;

    let valorTaxaEntrega = entrega.taxaEntrega;

    if (carrinho.tipoEntregaRetirada === EntregaRetiradaUtils.TIPO_ENTREGA) {
      if (
        Number(valorTaxaEntrega) > 0 &&
        Number(cabecalho.valorTotal) >=
          Number(parametros.valorMinimoPedidoFreteGratis)
      ) {
        cabecalho.valorDesconto = valorTaxaEntrega;
        cabecalho.valorDescontoTaxaEntrega = valorTaxaEntrega;
        cabecalho.atingiuEntregaGratis = true;
      } else {
        cabecalho.atingiuEntregaGratis = false;
        cabecalho.valorDesconto = 0;
        cabecalho.valorDescontoTaxaEntrega = 0;
      }
      cabecalho.valorTaxaEntrega = valorTaxaEntrega;
    } else {
      cabecalho.atingiuEntregaGratis = false;
      cabecalho.valorDesconto = 0;
      cabecalho.valorTaxaEntrega = 0;
      cabecalho.valorDescontoTaxaEntrega = 0;
    }

    atualizarCarrinho(carrinho);
  }

  static getEntrega(parametros) {
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  static getValorTotalPedido(cabecalho, parametros) {
    let { valorTotal, valorTaxaEntrega, valorDescontoCupom } = cabecalho;
    let { valorTaxaServico } = parametros;
    return (
      Number(valorTotal || 0) +
      Number(valorTaxaEntrega || 0) +
      Number(valorTaxaServico || 0) -
      Number(valorDescontoCupom || 0)
    );
  }

  static setCarrinhoNavegador(carrinho) {
    let carrinhoEncrypt = CryptoJS.AES.encrypt(
      JSON.stringify({
        ...carrinho,
      }),
      Keys.KEY_CARRINHO_SECRET_LOJA_WEB
    );

    localStorage.setItem(Keys.KEY_CARRINHO_LOJA_WEB, carrinhoEncrypt);
  }

  static removeCarrinhoNavegador() {
    localStorage.removeItem(Keys.KEY_CARRINHO_LOJA_WEB);
  }

  static getEntrega(parametros) {
    let parametrosEntrega = parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametrosEntrega)) {
      entrega = parametrosEntrega.entrega;
    }

    return entrega;
  }

  static getObsItem(item) {
    let obs = Utils.isValueValid(item.obs) ? String(item.obs) : "";
    let novaObs = "";

    if (item.possuiQuantidadeFracionada && item.quantidadeUnidade) {
      let quantidadeEmUnidades =
        Number(item.quantidade) / Number(item.fatorEmGrama);
      novaObs = `Enviar ${quantidadeEmUnidades} unidades aproximadamente ${item.quantidade} gramas`;
      obs = Utils.isValueValid(obs) ? obs.concat(novaObs) : novaObs;
    }

    return obs;
  }

  static getItensParaCadastro(itens) {

    let itensParaCadastro = [];
    Array.from(itens).forEach((item) => {
      itensParaCadastro.push({
        idProdutoWeb: item.idProdutoWeb,
        quantidade: item.possuiQuantidadeFracionada
          ? Number(item.quantidade) / Number(1000)
          : Number(item.quantidade),
        valorDesconto: item.valorDesconto || 0,
        precoUnitario: item.precoVenda,
        obs: this.getObsItem(item),
        idListaCompra: Utils.isValueValid(item.idListaCompra)
          ? item.idListaCompra
          : null,
      });
    });

    return { itens: itensParaCadastro };
  }

  static addQuantidadeItemJaNoCarrinhoLista(carrinho, item) {
    let itensCarrinho = Array.from(carrinho.itens);

    if (Utils.isValueValid(itensCarrinho)) {
      let itemNoCarrinho = itensCarrinho.filter(
        (i) => i.codigoBarras === item.codigoBarras
      )[0];
      if (Utils.isValueValid(itemNoCarrinho)) {
        if (item.possuiQuantidadeFracionada) {
          let precoUnitario = 
            (Number(item.precoVenda) * Number(item.fatorEmGrama)) / 1000;
          let gramasQuantidade = 
            (Number(item.quantidade) + Number(itemNoCarrinho.quantidade)) / Number(item.fatorEmGrama);
          let valorTotal = precoUnitario * gramasQuantidade;
          
          item.quantidade = Number(item.quantidade) + Number(itemNoCarrinho.quantidade);
          item.valorSubTotalItem = Number(valorTotal);

        } else {
          item.quantidade =
            Number(item.quantidade) + Number(itemNoCarrinho.quantidade);
          item.valorSubTotalItem =
            Number(item.quantidade) * Number(item.precoVenda);
        }
      }

    }
  }
}
