import React, { Component } from "react";
import { Div, I, If, Label } from "../../../components";

import { Color, Type, Utils } from "../../../utilities";

import "../produtos.css";
import ItemProdutoAbaixouPrecoMobile from "../ItemProdutoAbaixouPrecoMobile";
import CarouselProdutosGenericoMobile from "../../../components/carousel/CarouselProdutosGenericoMobile";
import ProdutoUtils from "../ProdutoUtils";
import SpaceUtils from "../../utils/SpaceUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";

const styles = {
  title: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 12,
    marginLeft: 5,
  },
};

const WIDTH_CONTAINER = SpaceUtils.MOBILE_CONTAINER_ATUAL;

class ProdutosVisualizadosMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: props.produtos || [],
    };
  }

  UNSAFE_componentWillReceiveProps({ produtos }) {
    this.setState({ produtos });
  }

  getUltimosProdutosVisualizados = () => {
    let itens = [];

    let produtosSeparados = Utils.isValueValid(this.state.produtos)
      ? Utils.quebrarArray(
          this.state.produtos,
          SpaceUtils.getQuantidadeItensView()
        )
      : [];

    Array.from(produtosSeparados).forEach((produtos, index) => {
      itens.push({
        value: (
          <Div inline="start" key={index}>
            {Array.from(produtos).map((prod, index) => {
              return (
                <Div className="d-flex justify-content-start align-items-start align-self-start">
                  <ItemProdutoAbaixouPrecoMobile
                    history={this.props.history}
                    key={index}
                    image={prod.urlImagemPrincipalThumbnail}
                    descricao={prod.descricao}
                    precoUnitario={prod.precoUnitario}
                    precoVenda={prod.precoVenda}
                    precoAtacado={prod.precoAtacado}
                    codigoBarras={prod.codigoBarras}
                    handlerClick={(updateItem) =>
                      this.props.handlerClick(prod, updateItem)
                    }
                    quantidade={prod.quantidade}
                    empresa={this.props.empresa}
                    disponivel={prod.disponivel}
                    estoqueDisponivel={prod.estoqueDisponivel}
                    quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
                    fatorEmGrama={prod.fatorEmGrama}
                    possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
                    percentualEconomia={prod.percentualEconomia}
                    handlerAddItemCarrinho={(quantidade) => {
                      prod = ProdutoUtils.calcularValorProduto(
                        prod,
                        quantidade
                      );
                      this.props.handlerAddItemCarrinho(prod);
                    }}
                  />
                </Div>
              );
            })}
          </Div>
        ),
        active: index === 0,
      });
    });

    return itens;
  };

  getProdutos() {
    return Array.from(this.state.produtos).map((prod, index) => {
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPrecoMobile
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosVisualizados() {
    return (
      <Div style={{ width: WIDTH_CONTAINER, marginTop: 20, paddingLeft: 10 }}>
        <Div inline="start" col="12">
          <I
            icon={Type.ICON.STACKOVERFLOW}
            style={{
              fontSize: 15,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 10,
            }}
          />
          <Label
            value="ÚLTIMOS VISUALIZADOS"
            style={styles.title}
            family="Bold"
          />
        </Div>
        <CarouselProdutoGenericoNovo
          timeSlide={7000}
          isMobile
          itens={this.getProdutos()}
          slidesPerPage={SpaceUtils.getQuantidadeItensView()}
          offset={20}
          isMobile
          arrows
        />
      </Div>
    );
  }

  render() {
    return (
      <If and value1={Utils.isValueValid(this.state.produtos)}>
        {this.renderProdutosVisualizados()}
      </If>
    );
  }
}

export default ProdutosVisualizadosMobile;
