import React from "react";
import { Component } from "react";

import { Div, Label } from "../../components";

class CadastroAnuncio extends Component {
  constructor(props) {
    super(props);
  }

  getSelectTipoAnuncio() {
      return [
          
      ]
  }

  render() {
    return (
      <Div margin="5">
        <Label value="Cadastro de anuncios" style={{ fontSize: 30 }} />
        <Div>
            
        </Div>
      </Div>
    );
  }
}

export default CadastroAnuncio;
