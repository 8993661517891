import React from "react";
import { Component } from "react";
import {
  Checkbox,
  ComboBox,
  Div,
  I,
  If,
  Image,
  Input,
  InputCep,
  InputCpfCnpj,
  InputDefault,
  InputInteger,
  Label,
  ModalNotification,
  Select,
  SelectValue,
} from "../../../components";
import { Color, EndPoints, Http, Mask, Type, Utils } from "../../../utilities";

import "../cliente.css";

import LogoStockei from "../../../assets/images/logo-stockei.png";
import axios from "axios";
import UsuarioUtils from "../../utils/UsuarioUtils";

const PATH_VIA_CEP = "https://viacep.com.br/ws/%s/json";

const styles = {
  containerMenu: {
    borderRadius: 10,
    height: "100%",
  },
  meusDados: {
    color: Color.ECOMMERCE.LARANJA,
    backgroundColor: Color.ECOMMERCE.WHITE,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  meusDadosActive: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  btnAddEndereco: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.EMPRESA.TERCIARIA,
    borderRadius: 5,
    padding: 5,
    fontSize: 20,
    width: "100%",
    borderBottom: "1px solid #F2F2F2",
  },
  input: {
    width: "100%",
    border: "none",
    fontSize: 25,
    textAlign: "center",
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  input50: {
    width: "50%",
    height: 30,
    border: "none",
    fontSize: 25,
    textAlign: "center",
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  input50Disabled: {
    width: "50%",
    height: 30,
    border: "none",
    fontSize: 25,
    textAlign: "center",
    borderBottom: "1px solid #C4C4C4",
    cursor: "no-drop",
  },
  label: {
    fontSize: 20,
    color: Color.EMPRESA.PRIMARIA,
    marginTop: 20,
    textAlign: "center",
  },
  btnAlterarDadosPessoais: {
    fontSize: 20,
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.ECOMMERCE.LARANJA,
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    width: "100%",
    textAlign: "center",
  },
  enderecoSelecionado: {
    borderBottom: "1px solid #f2f2f2",
    color: Color.EMPRESA.TERCIARIA,
    height: 70,
  },
  labelTitle: {
    width: "100%",
    fontSize: 20,
    color: Color.EMPRESA.TERCIARIA,
  },
  labelPrincipal: {
    color: Color.ECOMMERCE.WHITE,
    backgroundColor: Color.ECOMMERCE.CINZA_ESCURO,
    padding: 5,
    fontSize: 13,
    borderRadius: 5,
  },
  containerBotaoCancelar: {
    color: "#878787",
    padding: 15,
    margin: 10,
  },
};

class DadosPessoaisClienteMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarEndereco: false,
      mostrarDadosPessoais: true,
      mostrarAlteracaoSenha: false,
      cliente: this.getCliente(),
      enderecoAlterado: { descricao: "Casa" },
      adicionarEndereco: false,
      labelMensagemCepNaoEncontrado: "",
      ...props.location.state,
    };

    this.handlerChange = this.handlerChange.bind(this);
    Utils.toggleScrollBody("auto");
  }

  consultarCep(endereco) {
    axios
      .get(PATH_VIA_CEP.replace("%s", Mask.clearMask(endereco.cep)))
      .then(({ data }) => {
        if (data) {
          if (!Utils.isValueValid(data.erro)) {
            this.setState({
              enderecoAlterado: {
                ...this.state.enderecoAlterado,
                idCidadeIbge: data.ibge,
                bairro: data.bairro,
                complemento: data.complemento,
                cidade: data.localidade,
                logradouro: data.logradouro,
              },
              labelMensagemCepNaoEncontrado: "",
            });
          } else {
            this.setState({
              enderecoAlterado: {
                ...this.state.enderecoAlterado,
                idCidadeIbge: "",
                bairro: "",
                complemento: "",
                cidade: "",
                logradouro: "",
              },
              labelMensagemCepNaoEncontrado:
                "Endereço não encontrado para o CEP informado",
            });
          }
        } else {
          this.setState({
            enderecoAlterado: {
              ...this.state.enderecoAlterado,
              idCidadeIbge: "",
              bairro: "",
              complemento: "",
              cidade: "",
              logradouro: "",
            },
            labelMensagemCepNaoEncontrado:
              "Endereço não encontrado para o CEP informado",
          });
        }

        this.state.adicionarEndereco
          ? this.renderAdicionarEndereco()
          : this.renderTrocarEndereco({ ...endereco, ...data });
      })
      .catch((error) => console.log(error));
  }

  getCliente() {
    // let cliente =
    //   JSON.parse(localStorage.getItem(Keys.KEY_CLIENTE_LOJA_WEB)) || {};
    let cliente = UsuarioUtils.getUsuarioLojaWeb() || {};
    cliente.cpfCnpj = Mask.getValueMaskCpfCnpj(cliente.cpfCnpj);
    cliente.cep = Mask.getValueMaskCep(cliente.cep);
    cliente.telefone = Mask.getValueMaskPhone(cliente.telefone);
    return cliente;
  }

  getEnderecos() {
    let enderecoAlterado = this.state.enderecoAlterado;
    enderecoAlterado.cep = Mask.clearMask(enderecoAlterado.cep);
    return [enderecoAlterado];
  }

  getDataCliente(cliente) {
    let enderecos = Array.from(cliente.enderecos);
    enderecos.map((end) => {
      end.cep = Mask.clearMask(end.cep);
      return true;
    });
    return {
      ...cliente,
      cpfCnpj: Mask.clearMask(cliente.cpfCnpj),
      telefone: Mask.clearMask(cliente.telefone)
    };
  }

  getDataNovoEndereco() {
    let endereco = this.state.enderecoAlterado;
    return {
      ...endereco,
      idCliente: this.state.cliente.idCliente,
      cep: Mask.clearMask(endereco.cep),
    };
  }

  renderTrocarEndereco(endereco) {
    ModalNotification.render(
      <Div style={{ color: this.state.corPrimaria }}>
        <I icon={Type.ICON.MOTORCYCLE} style={{ fontSize: 15 }} margin="2" />
        <Label
          value="Alterar endereço de entrega"
          family="Light"
          style={{ fontSize: 13 }}
        />
      </Div>,
      this.renderEnderecoEntrega(endereco)
    );
  }

  renderAdicionarEndereco() {
    ModalNotification.render(
      <Div style={{ color: this.state.corPrimaria }}>
        <I icon={Type.ICON.MOTORCYCLE} sizeIcon="2" margin="2" />
        <Label value="Adicionar um novo endereço" family="Light" />
      </Div>,
      this.renderEnderecoEntrega(this.state.enderecoAlterado)
    );
  }

  adicionarNovoEndereco() {
    let cliente = this.getDataNovoEndereco();
    this.cadastrar(cliente);
  }

  atualizar(cliente) {
    Http.put(EndPoints.CLIENTE, cliente)
      .then(({ status, data }) => {
        if (status) {
          // localStorage.setItem(
          //    Keys.KEY_CLIENTE_LOJA_WEB,
          //   JSON.stringify(cliente)
          //  );
          UsuarioUtils.setUsuarioLojaWeb(cliente);
          this.renderSucesso("Dados atualizados com sucesso!");
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  }

  adicionarNovoEnderecoMemoria(endereco) {
    let cliente = this.state.cliente;
    cliente.enderecos = [...cliente.enderecos, endereco];
    UsuarioUtils.setUsuarioLojaWeb(cliente);
    //localStorage.setItem(Keys.KEY_CLIENTE_LOJA_WEB, JSON.stringify(cliente));
  }

  cadastrar(endereco) {
    Http.post(EndPoints.CLIENTE_ENDERECO, endereco)
      .then(({ status, data }) => {
        if (status) {
          this.renderSucesso("Endereço cadastrado com sucesso!");
          this.setState({ adicionarEndereco: false }, () =>
            this.adicionarNovoEnderecoMemoria(endereco)
          );

          //Enquanto não tem endpoint que retorne o ID do cliente ou um GET nesse sentido para pegar o id do endereço cadastrado
          UsuarioUtils.removerDadosAcessoUsuario();
          UsuarioUtils.removerUsuarioLojaWeb();
          this.props.history.replace("/");
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarDados() {
    let enderecos = this.getEnderecos();
    Http.put(EndPoints.CLIENTE_ENDERECOS, enderecos)
      .then(({ status, data }) => {
        if (status) {
          this.atualizarCliente();
        }
      })
      .catch((error) => console.log(error));
  }

  atualizarCliente() {
    let cliente = this.getDataCliente(this.state.cliente);
    this.atualizar(cliente);
  }

  renderSucesso(msg) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value={msg}
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => Utils.refreshPage(),
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  renderErro(msg) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value={msg}
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => {},
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  validarCadastro() {
    let endereco = this.state.enderecoAlterado || {};
    let erro = "";

    if (!Utils.isValueValid(endereco.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(endereco.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(endereco.logradouro))
      erro = "Preencha o campo lougradouro do endereço de casa!";
    if (!Mask.validarCep(endereco.cep)) erro = "Informe um CEP válido!";
    if (!Utils.isValueValid(endereco.descricao))
      erro = "Preencha o campo tipo do endereço!";
    if (!Utils.isValueValid(endereco.idCidadeIbge))
      erro = "Selecione a cidade!";

    let cliente = this.state.cliente;
    let enderecos = Array.from(cliente.enderecos);
    let enderecoPrincipal = enderecos.filter((end) => end.principal)[0];

    if (!Utils.isValueValid(enderecoPrincipal) && !endereco.principal) {
      erro = "Nenhum endereço está marcado como principal";
    }

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => {
          this.state.adicionarEndereco
            ? this.renderAdicionarEndereco()
            : this.renderTrocarEndereco(endereco);
        },
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  renderTemplateNovamente(endereco) {
    this.state.adicionarEndereco
      ? this.renderAdicionarEndereco()
      : this.renderTrocarEndereco(endereco);
  }

  getNomesEndereco = () => {
    return [
      new SelectValue("Casa", "Casa"),
      new SelectValue("Trabalho", "Trabalho"),
      new SelectValue("Outro", "Outro"),
    ];
  };

  renderEnderecoEntrega(end) {
    let endereco = end || {};
    return (
      <Div column rounded style={{ paddingBottom: 70 }}>
        <Label
          value="Informe os seguintes dados para atualizar o endereço de entrega"
          family="Light"
          style={{ fontSize: 15 }}
          col="12"
        />
        <Div inline="start">
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <Label
              col="12"
              value={this.state.labelMensagemCepNaoEncontrado}
              style={{ color: Color.ECOMMERCE.LARANJA }}
            />
          </If>
          <Select
            values={this.getNomesEndereco()}
            label="Tipo de endereço"
            valueSelected={endereco.descricao}
            handlerChange={(e) => {
              endereco.descricao = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            responsive="4"
          />
          <InputCep
            handlerChange={(e) => {
              endereco.cep = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            handlerBlur={() => {
              if (Mask.validarCep(endereco.cep)) {
                this.setState(
                  {
                    labelMensagemCepNaoEncontrado: "",
                  },
                  () => this.consultarCep(endereco)
                );
              } else {
                let enderecoAlterado = {
                  ...endereco,
                  logradouro: "",
                  bairro: "",
                };
                this.setState(
                  {
                    enderecoAlterado,
                  },
                  () => this.renderTemplateNovamente(enderecoAlterado)
                );
              }
            }}
            label="CEP"
            responsive="3"
            name="cep"
            value={endereco.cep || ""}
          />
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <ComboBox
              label="Selecione uma cidade"
              responsive="3"
              labelPrimaryItem="Selecione"
              url="/cidade/estado/GO"
              name="idCidadeIbge"
              text="nomeCidade"
              valueSelected={endereco.idCidadeIbge}
              handlerChange={(e) => {
                endereco.idCidadeIbge = e.target.value;
                this.setState({ enderecoAlterado: endereco }, () =>
                  this.renderTemplateNovamente(endereco)
                );
              }}
            />
          </If>
          <InputDefault
            handlerChange={(e) => {
              endereco.logradouro = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            label="Logradouro"
            responsive="4"
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            name="logradouro"
            value={endereco.logradouro || ""}
          />
          <InputInteger
            handlerChange={(e) => {
              endereco.numero = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            label="Número"
            responsive="2"
            name="numero"
            value={endereco.numero || ""}
          />
          <InputDefault
            handlerChange={(e) => {
              endereco.bairro = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            label="Bairro"
            responsive="3"
            name="bairro"
            value={endereco.bairro || ""}
          />
          <InputDefault
            handlerChange={(e) => {
              endereco.complemento = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            label="Complemento"
            responsive="3"
            name="complemento"
            value={endereco.complemento || ""}
          />

          <InputDefault
            handlerChange={(e) => {
              endereco.referencia = e.target.value;
              this.setState({ enderecoAlterado: endereco }, () =>
                this.renderTemplateNovamente(endereco)
              );
            }}
            label="Referência"
            responsive="3"
            name="referencia"
            value={endereco.referencia || ""}
          />
          <Div col="12" inline="start">
            <Checkbox
              label="Endereço principal"
              checked={endereco.principal}
              handlerChange={({ checked }) => {
                let cliente = this.state.cliente;
                let enderecos = Array.from(cliente.enderecos);
                enderecos.map((end) => (end.principal = false));

                endereco.principal = checked;
                this.setState({ enderecoAlterado: endereco, cliente }, () =>
                  this.renderTemplateNovamente(endereco)
                );
              }}
              danger
            />
          </Div>
        </Div>
        <Label
          style={{
            backgroundColor: this.state.corPrimaria,
            color: "white",
            padding: 15,
            fontSize: 15,
            width: 230,
            borderRadius: 10,
            textAlign: "center",
          }}
          pointer
          family="Light"
          value={
            this.state.adicionarEndereco
              ? "Adicionar endereço"
              : "Atualizar endereço"
          }
          icon={Type.ICON.REFRESH}
          handlerClick={() => {
            if (this.validarCadastro()) {
              this.state.adicionarEndereco
                ? this.adicionarNovoEndereco()
                : this.atualizarDados();
            }
          }}
        />
      </Div>
    );
  }

  renderEndereco() {
    const { cliente } = this.state;

    return (
      <>
        <Label value="Endereços" style={styles.labelTitle} family="Bold" />
        {Array.from(cliente.enderecos).map((endereco, index) => {
          endereco.cep = Mask.getValueMaskCep(endereco.cep);
          return (
            <Div
              key={index}
              inline="between"
              style={styles.enderecoSelecionado}
            >
              <Div inline="start">
                <Div inline="center" col="1">
                  <I
                    icon={Type.ICON.MOTORCYCLE}
                    style={{ color: this.state.corPrimaria, fontSize: 25 }}
                    pointer
                  />
                </Div>
                <Div
                  className="d-flex flex-column align-items-start justify-content-center"
                  pointer
                  col="8"
                >
                  <Label
                    value={String(endereco.descricao).toUpperCase()}
                    style={{ height: 25, fontSize: 13 }}
                    family="SemiBold"
                  />
                  <Label
                    value={`${endereco.logradouro} n° ${endereco.numero} ${endereco.bairro}`}
                    style={{ height: 25, fontSize: 13 }}
                    family="Light"
                  />
                </Div>
                <Div inline="center">
                  <If and value1={endereco.principal}>
                    <I
                      icon={Type.ICON.SUCCESS}
                      colorText={Color.NODE.SUCCESS}
                    />
                  </If>
                  <I
                    icon={Type.ICON.FLOPPY}
                    sizeIcon="2"
                    margin="2"
                    pointer
                    style={{ fontSize: 30 }}
                    handlerClick={() => {
                      this.setState(
                        {
                          enderecoAlterado: endereco,
                        },
                        () => this.renderTrocarEndereco(endereco)
                      );
                    }}
                  />
                </Div>
              </Div>
            </Div>
          );
        })}
      </>
    );
  }

  renderDadosParaAtualizarSenha() {
    return (
      <Div>
        <Label
          value="Informe um email para alteração da senha"
          style={styles.label}
          family="Bold"
          className="py-2"
        />
        <Input
          handlerChange={(e) =>
            this.setState({ emailAlteracaoSenha: e.target.value })
          }
          name="emailAlteracaoSenha"
          value={this.state.emailAlteracaoSenha}
          placeholder="Email"
          style={styles.input}
        />
        <Label
          family="SemiBold"
          value="Reenviar a senha para o email"
          style={{
            backgroundColor: "#c4c4c4",
            color: "white",
            fontSize: 25,
            padding: 10,
            borderRadius: 10,
            marginTop: 15,
            width: "100%",
            textAlign: "center",
          }}
          pointer
          handlerClick={(e) => {
            if (Utils.isValueValid(this.state.emailAlteracaoSenha)) {
              this.verificarEmailEReenviarSenha();
            } else {
              this.setState({
                mensagem: "Preencher o campo de email para o reenvio da senha!",
              });
            }
          }}
        />
      </Div>
    );
  }

  verificarEmailEReenviarSenha() {
    Http.get(
      EndPoints.VERIFICAR_EMAIL.replace("%s", this.state.emailAlteracaoSenha)
    )
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.reenviarSenha();
          } else {
            this.renderErro(data.mensagem);
          }
        } else {
          this.renderErro(
            "Falha ao reenviar a senhar, verifique a conexão com a internet"
          );
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  reenviarSenha() {
    Http.put(EndPoints.USUARIO_RECUPERAR_SENHA, {
      email: this.state.emailAlteracaoSenha,
    })
      .then(({ status, data }) => {
        if (status) {
          this.renderSucesso(data.mensagem);
        }
      })
      .catch((error) => console.log(error));
  }

  renderDadosPessoais() {
    const { cliente } = this.state;

    return (
      <Div>
        <Label value="Dados pessoais" style={styles.labelTitle} family="Bold" />
        <Label value="Email" style={styles.label} family="Light" />
        <Input
          handlerChange={this.handlerChange}
          name="email"
          value={cliente.email}
          placeholder="Email"
          disabled
          style={styles.input}
        />
        <Label value="Nome" style={styles.label} family="Light" />
        <Input
          id="inputNomeCompletoCadastroCliente"
          handlerChange={this.handlerChange}
          name="nome"
          value={cliente.nome}
          placeholder="Nome completo"
          style={styles.input}
        />
        <Label value="CPF/CNPJ" style={styles.label} family="Light" />
        <InputCpfCnpj
          handlerChange={this.handlerChange}
          name="cpfCnpj"
          value={cliente.cpfCnpj}
          placeholder="CPF / CNPJ"
          disabled
          style={styles.input}
        />

        <Label value="Telefone" style={styles.label} family="Light" />
        <Input
          handlerChange={(e) => {
            Mask.maskPhone(e);
            this.handlerChange(e);
          }}
          name="telefone"
          value={cliente.telefone}
          placeholder="Telefone"
          style={styles.input}
        />
        <Div inline="center" handlerClick={() => this.atualizarCliente()}>
          <Label
            value="Alterar dados pessoais"
            style={{
              ...styles.btnAlterarDadosPessoais,
              backgroundColor: this.state.corPrimaria,
            }}
            pointer
          />
        </Div>
      </Div>
    );
  }

  handlerChange(event) {
    const { name, value } = event.target;
    this.setState({
      cliente: {
        ...this.state.cliente,
        [name]: value,
      },
    });
  }

  render() {
    let corPrimaria = this.state.corPrimaria;
    return (
      <Div inline="center" className="w-100">
        <Div className="w-100 border-bottom" inline="between">
          <Image
            src={this.state.urlLogo}
            width="150px"
            handlerClick={() => this.props.history.replace("/")}
          />
          <Div
            pointer
            inline="center"
            handlerClick={() => this.props.history.replace("/")}
            style={styles.containerBotaoCancelar}
          >
            <I
              icon={Type.ICON.CLOSEX}
              style={{ fontSize: 20, color: corPrimaria }}
            />
          </Div>
        </Div>
        <Div
          className="d-flex flex-column justify-content-start align-items-start align-self-start w-100"
          padding="2"
          style={styles.containerMenu}
        >
          <Div
            style={
              this.state.mostrarDadosPessoais
                ? {
                    ...styles.meusDadosActive,
                    backgroundColor: corPrimaria,
                  }
                : { ...styles.meusDados, color: corPrimaria }
            }
            pointer
            handlerClick={() =>
              this.setState({
                mostrarDadosPessoais: true,
                mostrarEndereco: false,
                mostrarAlteracaoSenha: false,
              })
            }
          >
            <I icon={Type.ICON.USER} margin="1" pointer />
            <Label value="Meus dados" family="SemiBold" margin="2" pointer />
          </Div>
          <Div
            style={
              this.state.mostrarAlteracaoSenha
                ? {
                    ...styles.meusDadosActive,
                    backgroundColor: corPrimaria,
                  }
                : { ...styles.meusDados, color: corPrimaria }
            }
            pointer
            handlerClick={() =>
              this.setState({
                mostrarAlteracaoSenha: true,
                mostrarDadosPessoais: false,
                mostrarEndereco: false,
              })
            }
          >
            <I icon={Type.ICON.KEY} margin="1" pointer />
            <Label value="Alterar senha" family="SemiBold" margin="2" pointer />
          </Div>
          <Div
            style={
              this.state.mostrarEndereco
                ? {
                    ...styles.meusDadosActive,
                    backgroundColor: this.state.corPrimaria,
                  }
                : { ...styles.meusDados, color: corPrimaria }
            }
            pointer
            handlerClick={() =>
              this.setState({
                mostrarDadosPessoais: false,
                mostrarEndereco: true,
                mostrarAlteracaoSenha: false,
              })
            }
          >
            <I icon={Type.ICON.MAP_MARKER} margin="2" pointer />
            <Label value="Endereços" family="SemiBold" margin="2" pointer />
          </Div>

          <If and value1={this.state.mostrarEndereco}>
            <Div
              style={styles.btnAddEndereco}
              pointer
              marginTop="2"
              handlerClick={() => {
                this.setState(
                  {
                    enderecoAlterado: { descricao: "Casa" },
                    adicionarEndereco: true,
                  },
                  () => this.renderAdicionarEndereco()
                );
              }}
            >
              <I icon={Type.ICON.PLUS} margin="2" pointer />
              <Label
                value="Adicionar endereço"
                family="SemiBold"
                margin="2"
                pointer
              />
            </Div>
          </If>
        </Div>
        <Div>
          <Div col="12">
            <If and value1={this.state.mostrarDadosPessoais}>
              <Div marginTop="2">{this.renderDadosPessoais()}</Div>
            </If>
            <If and value1={this.state.mostrarEndereco}>
              <Div marginTop="2">{this.renderEndereco()}</Div>
            </If>
            <If and value1={this.state.mostrarAlteracaoSenha}>
              <Div marginTop="2" col="12">
                {this.renderDadosParaAtualizarSenha()}
              </Div>
            </If>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default DadosPessoaisClienteMobile;
