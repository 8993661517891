import React from "react";
import { Div, I } from "..";
import { Type } from "../../utilities";
import "./image.css";
import {
  float,
  className,
  col,
  sizeIcon,
  margin,
  padding,
  shadow,
  pointer,
} from "../css";

const getClasseDeafult = (props) => {
  let classe = "";
  classe = classe.concat(props.responsive ? "img-fluid " : "");
  classe = classe.concat(props.border ? "img-thumbnail " : "");
  classe = classe.concat(col(props));
  classe = classe.concat(props.rounded ? "rounded-circle " : "");
  classe = classe.concat(float(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(shadow(props));
  classe = classe.concat(className(props));
  return classe;
};

const getClassContainer = (props) => {
  let classe = "";
  classe = classe.concat(props.center ? "text-center " : "");
  classe = classe.concat(className(props));

  return classe;
};

const Image = (props) => {
  return (
    <Div
      className={getClassContainer(props)}
      handlerClick={props.handlerClick}
      style={props.style}
      handleMouseEnter={props.handleMouseEnter}
    >
      <img
        className={getClasseDeafult(props)}
        src={props.src}
        width={props.width}
        height={props.height}
        alt={props.alt}
        id={props.id}
        data-toggle={props.toggle}
        data-target={props.target}
        data-slide-to={props.dataSlideTo}
        style={props.styleImage}
        onError={props.onError}
      />
      {props.close && (
        <I
          icon={Type.ICON.CLOSE}
          size={sizeIcon(props)}
          className={`${props.classNameIconClose} position-absolute translatex10 pointer`}
          handlerClick={(e) => props.handlerClose(props.data)}
        ></I>
      )}
    </Div>
  );
};

export default Image;
