import React, { Component } from "react";
import { Div, Table, InputSearch, Title, Notification, ModalNotification } from "../../components";
import { connect } from "react-redux";
import { Color, PathRoute, Http, Type } from "../../utilities";
import { withRouter } from "react-router-dom";
import ConcorrentesService from "../../service/ConcorrentesService";


class ConcorrenteListar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      dialogRede: false,
      empresas: [],
      concorrentes: [],
      concorrente: {},
      selectedConcorrente: {},
      globalFilter: null,
      //...this.props.location.state,
    };

  }

  UNSAFE_componentWillMount() {
    this.getDataConcorrentes();
  }

  getDataConcorrentes() {
    ConcorrentesService.getConcorrentes().then(({ status, data }) => {

      if (status) {
        this.setState({ concorrentes: data });
      }
    })
      .catch((error) => console.log(error));
  }

  editarConcorrente(concorrente) {
    this.props.history.push({
      pathname: PathRoute.PATH.PESQUISA_CONCORRENTE_CADASTRAR,
      state: { concorrente: concorrente, editar: true },
    });
  }


  deletarConcorrente(concorrente) {
    console.log(concorrente)
    ModalNotification.confirm(
      "Deseja desativar este Concorrente? " + concorrente.descricao,
      "Esta concorrente será desativada no sistema!",
      () => this.delete(concorrente),
      () => true
    );
  }

  async delete(concorrente) {
    let obj = {
      idConcorrente: concorrente.idConcorrente
    }

    await ConcorrentesService.removerConcorrente(obj).then(({ status, data }) => {
      if (status) {
        Notification.success(data.mensagem);
        this.getDataConcorrentes();
      } else {
        Notification.danger(data.mensagem);
      }
    })
      .catch((error) => console.log(error));
  }

  render() {
    console.log(this.state.concorrentes)

    return (
      <Div margin="3" shadow bg={Color.NODE.WHITE} borderLeft={Color.NODE.PRIMARY}>

        <Title value="Concorrentes" type="h4" paddingLeft="4" paddingRight="4" paddingTop="3" />

        <Div padding="2">
          <Table
            columns={[
              { value: "Descrição", align: "center" },
              { value: "Data de Cadastro", align: "center" },
              { value: "Status", align: "center"}
            ]}
            fields={[
              { value: "descricao", type: Type.DADO.STRING },
              { value: "dataCadastro", type: Type.DADO.DATE },
              { value: "ativo", type: Type.DADO.BOOLEAN, valueTrue: "ATIVO", valueFalse: "DESATIVADO" },
            ]}
            bordered
            search
            orientation="center"
            pagination
            rota={PathRoute.PATH.PESQUISA_CONCORRENTE_CADASTRAR}
            edit
            delete
            handlerEdit={(concorrente) => this.editarConcorrente(concorrente)}
            handlerDelete={(concorrente) => this.deletarConcorrente(concorrente)}
            data={this.state.concorrentes}
            error="Nenhum concorrente encontrado"
          ></Table>
        </Div>
      </Div>
    );
  }
}

const mapStateToProps = (store) => ({
  idUsuario: store.loginState.user.idUsuario,
});

export default withRouter(connect(mapStateToProps)(ConcorrenteListar));
