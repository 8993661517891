import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  margin,
  padding,
  className,
  rounded,
  pointer,
  col,
  float,
  responsive,
  invisible,
  textAlign,
  colorText,
  backGround,
  font,
  underline,
  inline,
  family,
} from "../../css";
import { Utils } from "../../../utilities";

const LabelHover = (props) => {
  
  const [hover, setHover] = useState(false);
  const [styleLabel, setStyleHover] = useState(props.style);

  useEffect(() => {
    setStyleHover(hover ? props.styleHover : props.style);
  });

  return (
    <label
      className={getClassLabel(props)}
      id={props.id}
      htmlFor={props.for}
      onClick={props.handlerClick}
      data-target={props.target}
      data-toggle={props.toggle}
      style={{ ...styleLabel, ...getStyleFontFamily(props) }}
      onMouseEnter={() => {
        if (Utils.isValueValid(props.styleHover)) {
          setStyleHover(props.styleHover);
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (Utils.isValueValid(props.styleHover)) {
          setStyleHover(props.style);
          setHover(false);
        }
      }}
    >
      {getValue(props.value, props.uppercase, props.lowercase) ||
        props.children}
    </label>
  );
};

const getValue = (value, uppercase, lowercase) => {
  if (uppercase) {
    value = value.toUpperCase();
  } else if (lowercase) {
    value = value.toLowerCase();
  }
  return value;
};

const getStyleFontFamily = (props) => {
  let style = {
    // ...props.style,
    ...family(props),
  };
  return style;
};

const getClassLabel = (props) => {
  let classe = "";
  classe = classe.concat(textAlign(props));
  classe = classe.concat(colorText(props));
  classe = classe.concat(backGround(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(margin(props));
  classe = classe.concat(pointer(props));
  classe = classe.concat(rounded(props));
  classe = classe.concat(col(props));
  classe = classe.concat(underline(props));
  classe = classe.concat(float(props));
  classe = classe.concat(inline(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(props.bold ? `font-weight-bold ` : "");
  classe = classe.concat(invisible(props));
  classe = classe.concat(font(props));
  classe = classe.concat(className(props));

  return classe;
};

LabelHover.propTypes = {
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
};

export default LabelHover;
