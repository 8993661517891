import React from "react";

import { Div, Label, Input } from "../../../components";
import { Type } from "../../../utilities";
import InputDefaultValue from "./InputDefaultValue";

import { responsive, className, col, display, invisible, padding,margin } from "../../css";

const getType = (props) => {
  if (props.number) return Type.INPUT.NUMBER;
  else if (props.date) return Type.INPUT.DATE;
  else if (props.password) return Type.INPUT.PASSWORD;
  else if (props.phone) return Type.INPUT.PHONE;
  else if (props.file) return Type.INPUT.FILE;
  else if (props.checkbox) return Type.INPUT.CHECKBOX;
  else if (props.radio) return Type.INPUT.RADIO;
  else if (props.color) return Type.INPUT.COLOR;
  else if (props.range) return Type.INPUT.RANGE;
  else return Type.INPUT.TEXT;
};

const getInput = (props) => {
  let input;
  input = props.defaultValue ? (
    <InputDefaultValue {...props} formcontrol type={getType(props)} />
  ) : (
    <Input {...props} formcontrol type={getType(props)} />
  );
  return input;
};

const getClassNameDefault = (props) => {
  let classe = "form-group ";
  classe = classe.concat(col(props));
  classe = classe.concat(responsive(props));
  classe = classe.concat(display(props));
  classe = classe.concat(invisible(props));
  classe = classe.concat(className(props));
  classe = classe.concat(padding(props));
  classe = classe.concat(margin(props));

  return classe;
};

const InputDefault = (props) => {
  return (
    <Div className={getClassNameDefault(props)} none={props.none} padding={props.padd}>
      <Label family="Light" value={props.label} style={props.styleLabel} />
      {getInput(props)}
    </Div>
  );
};

export default InputDefault;
