import React from "react";
import { Div, I, If, Image, Label } from "../../components";
import { Color, Type } from "../../utilities";

const BotaoPaginaInicial = (props) => {
  return (
    <Div
      className="d-flex justify-content-center align-items-center align-self-center"
      style={{
        padding: 3,
        border: `3px solid ${Color.EMPRESA.SECUNDARIA}`,
        borderRadius: 10,
        color: Color.EMPRESA.SECUNDARIA,
        opacity: 0.8,
        lineHeight: "10px",
        ...props.style,
      }}
      pointer
      handlerClick={() => props.history.push("/")}
    >
      <I
        pointer
        icon={Type.ICON.HOME}
        style={{
          color: Color.EMPRESA.SECUNDARIA,
          fontSize: 20,
        }}
      />
      <If and value1={!props.hideLabel}>
        <Label
          value={props.label || "página inicial"}
          marginLeft="1"
          style={{ lineHeight: "10px", paddingTop: 11 }}
          pointer
          family="Light"
        />
      </If>
    </Div>
  );
};

export default BotaoPaginaInicial;
