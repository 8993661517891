import React, { Component } from "react";
import { toast } from "react-toastify";
import { EmpresaUtils, ProdutosListaGenerico, ProdutoUtils } from "../../..";

import {
  Div,
  I,
  If,
  Image,
  Label,
  ModalNotification,
  TooltipText,
} from "../../../../components";
import CarouselImagens from "../../../../components/carousel/CarouselImagens";
import ImageWithZoom from "../../../../components/image/ImageWithZoom";
import AssinaturaService from "../../../../service/AssinaturaService";
import { Color, Http, Mask, Type, Utils } from "../../../../utilities";
import LoginCliente from "../../../cliente/LoginCliente";
import RecuperarSenha from "../../../cliente/RecuperarSenha";
import CarrinhoUtils from "../../../utils/CarrinhoUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import ContainerAssinatura from "../ContainerAssinaturas";

const styles = {
  descricao: {
    fontSize: "30px",
    lineHeight: "30px",
    margin: 10,
    color: "#878787",
    fontFamily: "MetropolisBold",
  },
  containerCompartilhar: {
    opacity: 0.9,
    color: Color.EMPRESA.SECUNDARIA,
    fontFamily: "MetropolisLight",
    fontSize: 20,
    paddingTop: 10,
    width: "100%",
    border: `1px solid ${Color.EMPRESA.SECUNDARIA}`,
    borderRadius: "5px",
  },
  containterPreco: {
    height: window.innerHeight / 3,
  },
  precoVenda: {
    color: Color.ECOMMERCE.LARANJA,
    fontFamily: "MetropolisBold",
    fontSize: 25,
  },
  precoAtacado: {
    color: "#46160E",
    fontSize: 15,
  },
  containerQuantidade: {
    borderRadius: "5px",
    width: "100%",
  },
  botaoAdicionar: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  botaoDiminuir: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 30,
    fontWeight: "bold",
  },
  containerBotaoComprar: {
    backgroundColor: Color.EMPRESA.TERCIARIA,
    color: "white",
    fontFamily: "MetropolisSemiBold",
    fontSize: 20,
    borderRadius: "5px",
    textAlign: "center",
    paddingTop: 10,
  },
  containerBotaoColorCarrinho: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    opacity: 0.9,
    color: "white",
    fontSize: 25,
    borderRadius: "5px",
    padding: 10,
    width: "100%",
  },
  containerAdicionarMinhaLista: {
    backgroundColor: "#46160E",
    color: "white",
    fontSize: 25,
    borderRadius: "5px",
    paddingTop: 10,
    width: "100%",
  },
  labelPeso: {
    textAlign: "center",
    backgroundColor: "#f2f2f2",
    width: "50%",
    padding: 10,
    borderRightTop: 10,
  },
  labelPesoActive: {
    textAlign: "center",
    width: "50%",
    padding: 10,
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
    borderRightTop: 10,
  },
  labelUnidade: {
    backgroundColor: "#f2f2f2",
    textAlign: "center",
    padding: 10,
    width: "50%",
  },
  labelUnidadeActive: {
    textAlign: "center",
    padding: 10,
    width: "50%",
    backgroundColor: Color.ECOMMERCE.AZUL,
    color: Color.ECOMMERCE.WHITE,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
    marginTop: 5,
    width: "100%",
  },
  labelSubTotalItem: {
    fontSize: 15,
    color: Color.ECOMMERCE.LARANJA,
    position: "absolute",
    transform: `translate(58px, 17px)`,
  },
  textoRiscado: {
    textDecoration: "line-through",
  },
};

const getImagensProduto = (produto) => {
  let imagens = [];

  if (Utils.isValueValid(produto.imagens)) {
    let imagensProduto = Array.from(produto.imagens);

    imagensProduto.forEach((img, index) => {
      img.active = index === 0;
      img.width = "400px";
      img.height = "400px";
    });

    imagens = imagensProduto;
  } else {
    imagens = [{ url: produto.urlImagemPrincipal }];
  }

  return imagens;
};

class ListaCompraSugeridaDetalheMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imagemProduto: props.produto.urlFoto,
      itens: props.produto.itens,
      assinaturaBox: false,
      paramentrosAssinatura: {
        valorMinimoPedidoEntrega: 0,
        valorMinimoPedidoRetirada: 0,
        valorMinimoPedidoFreteGratis: 0,
        valorMaximoPedido: 2000,
        quantidadeMaximaParcelas: 5,
        aceitaPagamentoOnline: true,
        aceitaRetirada: true,
        aceitaAgendamentoEntrega: true,
        diasPrevisaoEntrega: 0,
        diasPreparacaoPedido: 0,
        diasPreparacaoPedidoRetirada: 5,
        aceitaAssinaturas: false,
        horarios: [],
        filiais: [],
        formasPagamentos: [],
        cartoes: []
      },
      listaCompra: {},
    };

    this.atualizarProdutoDetalhe = this.atualizarProdutoDetalhe.bind(this);

    this.adicionarItem = this.adicionarItem.bind(this);
    this.removerItem = this.removerItem.bind(this);
  }

  getQuantidadeLabel(produto) {
    let preco = produto.precoVenda;
    let quantidade = produto.quantidade || 0;
    return produto.possuiQuantidadeFracionada
      ? `${Number(produto.quantidade)}g`
      : `${quantidade}un x ${Mask.formatValueBr(preco)}`;
  }

  renderProdutosCombo = (produtos) => {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    let idParent = Utils.uuidString();
    return (
      <Div id={idParent} shadow rounded style={{ width: "100%" }}>
        <Div inline="between" style={{ width: "100%" }}>
          <Label
            value="Produtos da lista"
            style={{ fontSize: 25, color: Color.ECOMMERCE.CINZA_ESCURO }}
            family="SemiBold"
          />
          <TooltipText
            direction="top"
            title="As quantidades dos itens são sugestões, você pode adicionar mais quantidades caso deseje."
          >
            <I
              icon={Type.ICON.INFO}
              style={{ fontSize: 25, color: corPrimaria }}
            />
          </TooltipText>
        </Div>
        {Array.from(produtos).map((prod, index) => {
          return (
            <Div key={index}>
              <Div
                inline="between"
                padding="2"
                style={{ borderBottom: "1px solid #f2f2f2" }}
              >
                <Div inline="start">
                  <Image
                    border
                    src={prod.urlImagemPrincipalThumbnail}
                    width="50px"
                  />
                  <Label
                    className="text-ellipsis descricao-item"
                    value={prod.descricao}
                    marginLeft="2"
                    family="Light"
                    style={{ width: 150, fontSize: 15 }}
                  />
                  <Label
                    value={Mask.formatValueBr(prod.valorSubTotalItem)}
                    family="SemiBold"
                    style={{
                      ...styles.labelSubTotalItem,
                      color: corPrimaria,
                    }}
                  />
                </Div>
                <Div inline="center" style={styles.containerAddItem}>
                  <I
                    icon={Type.ICON.MINUS}
                    pointer
                    style={{
                      color: corPrimaria,
                      fontSize: 18,
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                    handlerClick={() => {
                      if (prod.quantidade > 0) {
                        this.removeQuantidadeItemState(prod);
                      }
                    }}
                  />
                  <Label
                    value={this.getQuantidadeLabel(prod)}
                    family="Light"
                    margin="3"
                    className="mx-auto"
                  />
                  <I
                    icon={Type.ICON.PLUS}
                    pointer
                    style={{
                      color: corPrimaria,
                      fontSize: 18,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    handlerClick={() => {
                      this.addQuantidadeItemState(prod);
                    }}
                  />
                </Div>
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      imagemProduto: props.produto.urlFoto,
      itens: props.produto.itens,
    });
  }

  componentDidMount() {
    this.consultarAssinaturaListaCompra()
    this.getParamentros()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.produto !== this.props.produto) {
      this.consultarAssinaturaListaCompra()
    }
  }

  getParamentros() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if(cliente) {
      AssinaturaService.getParamentros(cliente.idCliente).then(({ data, status }) => {
        if (status) {
          console.log(data)
          this.setState({
            paramentrosAssinatura: data
          })
        }
      })
    }
  }

  consultarAssinaturaListaCompra() {
    let idListaCompra = this.props.produto.idListaCompra

    AssinaturaService.getListaDeCompra(idListaCompra).then((data) => {

      if (Utils.isNotObjectEmpty(data)) {
        this.setState({
          listaCompra: {
            ...this.props.produto,
            assinatura: data
          }
        })
      }
    })
  }

  consultarQuantidadeProdutoCarrinho(codigoBarras) {
    let carrinho = CarrinhoUtils.getCarrinho();

    let quantidade = 0;

    if (Utils.isValueValid(carrinho)) {
      let itens = Array.from(carrinho.itens || []);
      let produtoNoCarrinho = itens.filter(
        (item) => item.codigoBarras === codigoBarras
      )[0];

      if (Utils.isValueValid(produtoNoCarrinho)) {
        quantidade = produtoNoCarrinho.quantidade;
      }
    }

    return quantidade;
  }

  consultarUnidade() {
    let labelUnidade = "";

    if (this.props.produto.possuiQuantidadeFracionada) {
      labelUnidade = this.state.quantidadePeso ? "g" : "un";
    } else {
      labelUnidade = "un";
    }

    return labelUnidade;
  }

  atualizarProdutoDetalhe() {
    this.forceUpdate();
  }

  usuarioEstaLogado() {
    //let usuario = JSON.parse(localStorage.getItem(Keys.KEY_USUARIO_LOJA_WEB));
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    return Utils.isValueValid(usuario);
  }

  renderViewRecuperarSenha() {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    ModalNotification.renderModalLogin(
      <Label
        className="modal-title"
        style={{
          color: corPrimaria,
          fontSize: 25,
          marginTop: 20,
        }}
        family="Bold"
        value="Recuperar senha"
      />,
      <RecuperarSenha corPrimaria={corPrimaria} />
    );
  }

  renderViewLogin(callback) {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);

    ModalNotification.renderModalLogin(
      <>
        <Label
          className="modal-title"
          style={{
            color: corPrimaria,
            fontSize: 25,
            marginTop: 20,
          }}
          family="Bold"
          value="Dados de acesso"
        />
        <Label
          value="Esqueci meus dados!"
          style={{
            color: Color.EMPRESA.TERCIARIA,
          }}
          className="hover-link"
          family="Light"
          handlerClick={() => this.renderViewRecuperarSenha()}
        />
      </>,
      <LoginCliente
        handlerConfirm={() => {
          callback();
          Utils.refreshPage();
        }}
        empresa={this.props.empresa}
        corPrimaria={corPrimaria}
        {...this.props}
      />
    );
  }

  addQuantidadeItemState(item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    let itens = Array.from(this.state.itens);
    let itemAlterado = itens.filter(
      (i) => i.codigoBarras === item.codigoBarras
    )[0];
    itemAlterado.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ itens });
  }

  addQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade + Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade + 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  removeQuantidadeItemState(item) {
    let quantidade = Number(item.quantidade);

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    let itens = Array.from(this.state.itens);
    let itemAlterado = itens.filter(
      (i) => i.codigoBarras === item.codigoBarras
    )[0];
    itemAlterado.quantidade = quantidade;
    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.setState({ itens });
  }

  removeQuantidadeItem(item) {
    let quantidade = item.quantidade || 0;

    if (
      item.possuiQuantidadeFracionada &&
      Utils.isValueValid(item.fatorEmGrama)
    ) {
      quantidade = quantidade - Number(item.fatorEmGrama);
    } else {
      quantidade = quantidade - 1;
    }

    item = ProdutoUtils.calcularValorProduto(item, quantidade);

    this.props.handlerAddItem(item, this.atualizarProdutoDetalhe);
  }

  adicionarItem() {
    let carrinho = CarrinhoUtils.getCarrinho();
    Array.from(this.state.itens).forEach((item) => {
      if (Utils.isValueValid(carrinho)) {
        CarrinhoUtils.addQuantidadeItemJaNoCarrinhoLista(carrinho, item);
      }
      this.props.handlerAddItem(item);
    });
  }

  removerItem() {
    this.removeQuantidadeItem(this.props.produto);
  }

  getLabelAdicionarCarrinho = () => {
    return "Adicionar no carrinho";
  };

  renderPrecos(corPrimaria) {
    const { produto } = this.props;
    let totalItens = Array.from(this.state.itens)
      .map((i) => Number(i.valorSubTotalItem))
      .reduce((a, b) => a + b, 0);
    return (
      <Div paddingTop="5" className="d-flex flex-column">
        <Label
          value={Mask.formatValueBr(totalItens)}
          style={{ ...styles.precoVenda, color: corPrimaria }}
        />
      </Div>
    );
  }

  getQuantidadeProdutoConvertida(converterParaPeso = false) {
    const { quantidade } = this.state;
    let produto = this.props.produto;
    let quantidadeConvertida = 0;

    if (converterParaPeso) {
      quantidadeConvertida = Number(produto.fatorEmGrama) * Number(quantidade);
    } else {
      quantidadeConvertida = Number(quantidade) / Number(produto.fatorEmGrama);
    }

    return quantidadeConvertida;
  }

  render() {
    let { produto } = this.props;
    let valorTotal = this.props.produto.itens.map((p) => Number(p.precoVenda)).reduce((a, b) => a + b, 0)

    const imagens = getImagensProduto(produto);

    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.props.empresa);

    return (
      <Div inline="between" martinTop="5" marginBottom="5">
        <If
          and
          value1={
            Utils.isValueValid(imagens) &&
            imagens.length === 1 &&
            Utils.isValueValid(this.state.imagemProduto)
          }
        >
          <Div>
            <ImageWithZoom
              width="250px"
              height="250px"
              src={this.state.imagemProduto}
              className="img-thumbnail"
              onError={() =>
                this.setState({
                  imagemProduto: ProdutoUtils.URL_IMAGEM_PRODUTO_DEFAULT,
                })
              }
            />
          </Div>
        </If>
        <If and value1={Utils.isValueValid(imagens) && imagens.length > 1}>
          <Div responsive="5">
            <CarouselImagens itens={imagens} />
          </Div>
        </If>
        <Div responsive="4">
          <Label
            value={Utils.capitalize(produto.descricao)}
            style={styles.descricao}
          />
          <If and value1={Utils.isValueValid(produto.informacoes)}>
            <Label
              value={produto.informacoes}
              padding="2"
              style={{ textAlign: "justify" }}
            />
          </If>
          <If and value1={!produto.proibidaVenda}>
            {this.renderPrecos(corPrimaria)}
          </If>

          {Utils.isValueValid(produto.precosConcorrentes) && (
            <Div
              style={{
                borderRadius: 10,
                backgroundColor: "#f2f2f2",
                paddingLeft: 5,
                paddingRight: 5,
                marginTop: 5,
              }}
              inline="start"
            >
              <Div style={{ width: "50%" }}>
                {Array.from(produto.precosConcorrentes).map(
                  (concorrente, index) => {
                    return (
                      <Div
                        inline="between"
                        key={index}
                        style={{
                          color: Color.ECOMMERCE.CINZA_ESCURO,
                          padding: 1,
                        }}
                      >
                        <Div inline="start">
                          <Image src={concorrente.urlFoto} width="26px" />
                          <Label
                            value={Mask.formatValueBr(concorrente.precoVenda)}
                            family="SemiBold"
                            marginTop="2"
                            marginLeft="2"
                          />
                        </Div>
                      </Div>
                    );
                  }
                )}
              </Div>
              <Div
                style={{
                  color: "#31CDFF",
                  paddingRight: 15,
                  paddingTop: 20,
                  width: "50%",
                }}
              >
                <Div inline="end" style={{ color: "#31CDFF" }}>
                  <Label value="Economize" family="SemiBold" />
                </Div>
                <Div inline="end">
                  <Label
                    value={`${Array.from(produto.precosConcorrentes)[0]
                      .percentualEconomia
                      }%`}
                    style={{ fontSize: 20 }}
                    family="Bold"
                  />
                </Div>
              </Div>
            </Div>
          )}
        </Div>
        <If and value1={!produto.proibidaVenda}>
          <Div responsive="3" column marginBottom="3">
            <Div
              style={{
                ...styles.containerBotaoColorCarrinho,
                backgroundColor: corPrimaria,
              }}
              marginTop="2"
              inline="center"
              pointer
              handlerClick={() => {
                if (this.usuarioEstaLogado()) {
                  let carrinho = CarrinhoUtils.getCarrinho();
                  Array.from(this.state.itens).forEach((item) => {
                    if (Utils.isValueValid(carrinho)) {
                      CarrinhoUtils.addQuantidadeItemJaNoCarrinhoLista(carrinho, item);
                    }
                    this.props.handlerAddItem(item);
                  });

                  toast.info("Produtos da lista adicionados no carrinho!");
                  ModalNotification.removeModal();
                } else {
                  this.renderViewLogin(this.adicionarItem);
                }
              }}
            >
              <Label
                pointer
                value={this.getLabelAdicionarCarrinho()}
                className="label-adicionar-carrinho"
                family="Light"
                style={{ fontSize: 20 }}
              />
            </Div>

            <Div
              style={{
                ...styles.containerCompartilhar,
                backgroundColor: this.state.assinaturaBox ? Color.EMPRESA.SECUNDARIA : "#FFF",
                color: !this.state.assinaturaBox ? Color.EMPRESA.SECUNDARIA : "#FFF"
              }}
              marginTop="2"
              pointer
              inline="center"
              handlerClick={() => {
                if (this.usuarioEstaLogado()) {
                  this.setState({
                    assinaturaBox: true
                  })
                } else {
                  this.renderViewLogin(this.adicionarItem);
                }
              }}

            >
              <Label
                pointer
                value="Assinar Lista de Compras"
                className="label-adicionar-carrinho"
              />
            </Div>
            {/**
                *  <Div
              style={{
                ...styles.containerAdicionarMinhaLista,
              }}
              marginTop="2"
              inline="center"
              pointer
              handlerClick={() => alert("EM DESENVOLVIMENTO")}
            >
              <Label
                pointer
                value="Assinar lista"
                style={{ fontSize: 20 }}
                className="label-adicionar-carrinho"
              />
              </Div>
                */}
          </Div>
        </If>
        {Utils.isValueValid(produto.filtros) && (
          <Div className="d-flex flex-column">
            <Label
              value="Especificações do produto"
              family="SemiBold"
              style={{ fontSize: 20, marginTop: 15 }}
            />
            <Div
              style={{
                border: `1px solid ${corSecundaria}`,
                borderRadius: 10,
                padding: 10,
                marginTop: 15,
              }}
            >
              {Array.from(produto.filtros).map((filtro, index) => {
                return (
                  <Div inline="start">
                    <Label
                      value={filtro.nome}
                      style={{
                        width: "20%",
                        backgroundColor: corSecundaria,
                        color: "white",
                        padding: 5,
                        fontSize: 20,
                        borderRadius: 10,
                      }}
                      family="SemiBold"
                    />
                    <Label
                      value={filtro.valor}
                      style={{
                        width: "80%",
                        padding: 5,
                      }}
                      family="Light"
                    />
                  </Div>
                );
              })}
            </Div>
          </Div>
        )}
        <If and value1={!this.state.assinaturaBox}>
          {this.renderProdutosCombo(this.state.itens)}
        </If>
        <If and value1={this.state.assinaturaBox}>
          <ContainerAssinatura
            lista={this.state.listaCompra}
            modal={true}
            listaSugerida={true}
            empresa={this.props.empresa}
            paramentrosAssinatura={this.state.paramentrosAssinatura}
            valorTotal={valorTotal}
          />
        </If>
      </Div>
    );
  }
}

export default ListaCompraSugeridaDetalheMobile;
