import React, { Component } from "react";
import { Button, Div, Image, Label } from "../../components";
import CarouselProdutoGenericoNovo from "../../components/carousel/CarouselProdutoGenericoNovo";
import { Color, Utils } from "../../utilities";
import "./anuncio.css";

const WIDTH_TELA = 1150;

const WIDTH_IMAGE = 337;
const HEIGHT_IMAGE = 284;

const WIDTH_IMAGE_2 = 100;
const HEIGHT_IMAGE_2 = 444;

const styles = {
  bgOpacity: {
    position: "absolute",
    zIndex: "998",
    background: "#000",
    color: "#FFF",
    minHeight: "90px",
    opacity: "0.5",
    bottom: 0,
  },
  boxLabelBanner: {
    position: "absolute",
    zIndex: "999",
    // background: "#000",
    color: "#FFF",
    minHeight: "90px",
    bottom: 8,
  },
  fontDuplo: {
    margin: "40px 15px 0px",
    fontSize: "21px",
    fontWeight: "bolder",
  },
  fontTriplo: {
    margin: "30px 15px",
    fontWeight: "bolder",
  },
  bgOpacityB2: {
    position: "absolute",
    zIndex: "998",
    background: "#FFF",
    color: "#000",
    width: "50%",
    minHeight: "100%",
    opacity: "0.5",
    bottom: 8,
  },
  boxLabelBanner2: {
    position: "absolute",
    zIndex: "999",
    // background: "#000",
    color: "#000",
    width: "50%",
    minHeight: "100%",
    bottom: 10,
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    justifyContent: "space-between",
  },
};

class NovoAnuncioBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: {},
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.anuncios) {
      this.setState({
        anuncios: this.props.anuncios,
      });
    }
  }

  bannerTriplo() {
    let bannerItens = [];
    let responsive = this.props.responsive;
    let widthOp = window.innerWidth <= 1024 ? "100%" : WIDTH_IMAGE;

    if (Utils.isNotObjectEmpty(this.props.anuncios)) {
      bannerItens = this.props.anuncios.topo;
    }

    return bannerItens.map((banner) => {
      return (
        <Div style={{ width: WIDTH_IMAGE, height: HEIGHT_IMAGE }}>
          <Image
            src={banner.urlFoto}
            width={!responsive ? WIDTH_IMAGE : `100%`}
            alt="Não foi Possível Carregar Imagem"
            height={HEIGHT_IMAGE}
            id="triplo-banner"
            // handlerClick={() => this.visualizarProdutosAnuncio(anuncio)}
            // styleImage={{ borderRadius: 10 }}
          />
          <Div style={{ ...styles.bgOpacity, width: widthOp }}></Div>
          <Div style={{ ...styles.boxLabelBanner, width: widthOp }}>
            <Label bolder value={banner.titulo} style={styles.fontTriplo} />
            <Button
              type="button"
              responsive="12"
              value="Confira"
              //btn="warning"
              float="right"
              marginRight="3"
              marginBottom="3"
              style={{
                marginTop: "25px",
                backgroundColor: Color.EMPRESA.TERCIARIA,
                color: "#FFF"
              }}
              //handlerClick={() => this.modalCancelamento()}
            ></Button>
          </Div>
        </Div>
      );
    });
  }

  bannerDuplo() {
    let bannerItens = [];
    let responsive = this.props.responsive;

    if (Utils.isNotObjectEmpty(this.props.anuncios)) {
      bannerItens = this.props.anuncios.topo;
    }

    return bannerItens.map((banner) => {
      return (
        <Div padding={responsive ? "3" : "2"}>
          <Image
            src={bannerItens[0].urlFoto}
            width={`${WIDTH_IMAGE_2}%`}
            alt="Não foi Possível Carregar Imagem"
            //height={`${HEIGHT_IMAGE_2}px`}
            // handlerClick={() => this.visualizarProdutosAnuncio(anuncio)}
            // styleImage={{ borderRadius: 10 }}
          />
          <Div
            style={
              responsive
                ? { ...styles.bgOpacityB2, bottom: 0 }
                : styles.bgOpacityB2
            }
          ></Div>
          <Div style={styles.boxLabelBanner2}>
            <Label value={banner.titulo} style={styles.fontDuplo} />
            <Button
              type="button"
              responsive="12"
              value="Confira"
             // btn="warning"
              float="right"
              marginRight="3"
              marginBottom="3"
              style={{
                marginTop: "25px",
                backgroundColor: Color.EMPRESA.TERCIARIA,
                color: "#FFF",
                border: "none"
              }}
              //handlerClick={() => this.modalCancelamento()}
            ></Button>
          </Div>
        </Div>
      );
    });
  }

  render() {
    let numeroBanner =
      window.innerWidth < 800
        ? 1
        : window.innerWidth < 1024
        ? 2
        : this.props.numBanner
        ? this.props.numBanner
        : 3;

    let responsive = this.props.responsive;

    return (
      <Div
        col="12"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: WIDTH_TELA,
          flexWrap: "wrap",
          margin: "25px 0px 35px",
        }}
        padding="0"
      >
        <CarouselProdutoGenericoNovo
          timeSlide={7000000}
          itens={
            this.props.numBanner === 2
              ? this.bannerDuplo()
              : this.bannerTriplo()
          }
          slidesPerPage={numeroBanner}
          arrows
          offset={responsive ? 10 : null}
        />
      </Div>
    );
  }
}

export default NovoAnuncioBanner;
