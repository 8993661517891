import React, { Component } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  InputCep,
  InputDefault,
  InputInteger,
  Label,
  ModalNotification,
  Select,
  SelectValue,
  TextArea,
} from "../../components";
import { Color, DateUtils, Http, Mask, Type, Utils } from "../../utilities";
import ClienteLogin from "../cliente/ClienteLogin";
import AdicionarProdutosCarrinhoListaMobile from "../produtos/listascompras/mobile/AdicionarProdutosCarrinhoListaMobile";
import CarrinhoUtils from "../utils/CarrinhoUtils";
import UsuarioUtils from "../utils/UsuarioUtils";
import Zindex from "../utils/Zindex";
import ContatoWhatsappGenerico from "../whatsapp/ContatoWhatsappGenerico";

import "./carrinho.css";

const styles = {
  enderecoSelecionado: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "white",
    marginBottom: 10,
  },
  enderecoNaoSelecionado: {
    backgroundColor: "#F2F2F2",
    color: Color.ECOMMERCE.CINZA_ESCURO,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    marginBottom: 10,
  },
  labelAtencao: {
    color: Color.ECOMMERCE.LARANJA,
    fontSize: 12,
    textAlign: "center",
  },
  containterHorario: {
    backgroundColor: "#F2F2F2",
    padding: 10,
    borderRadius: 10,
    margin: 5,
    fontSize: 13,
  },
  containterHorarioSelecionado: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    padding: 10,
    borderRadius: 10,
    margin: 5,
    fontSize: 13,
  },
  selecioneHorario: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FFF",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    color: "#878787",
    borderRadius: 5,
    padding: 10,
    cursor: "pointer",
    width: "95%",
    margin: "auto",
    lineHeight: 0.9,
  },
  item: {
    width: Utils.isViewTabletAll() ? window.innerWidth / 1.5 : "100%",
  },
  labelInfo: {
    color: "#575757",
    fontSize: 18,
    margin: "35px 0 0",
    textAlign: "center",
    width: "100%",
  },
};

class TemplatePedidoEntregaMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      enderecoEntregaSelecionado: false,
      enderecoCasaSelecionado: true,
      horarios: props.parametros.horarios || [],
      pedido: props.pedido,
      cliente: UsuarioUtils.getUsuarioLojaWeb() || new ClienteLogin(),
    };
  }

  UNSAFE_componentWillReceiveProps({ parametros }) {
    this.setState({ horarios: parametros.horarios });
  }

  componentDidMount() {
    let cliente = this.state.cliente;
    let enderecos = cliente.enderecos;

    let enderecoEntrega = this.getEnderecoSelecionadoParaEntrega();

    if (Utils.isValueValid(enderecoEntrega)) {
      this.setState(
        {
          pedido: {
            ...this.state.pedido,
            enderecoEntrega,
          },
        },
        () => this.props.handlerEnderecoSelecionado(enderecoEntrega)
      );
    }
  }

  getHorariosEntrega() {
    let horarios = this.state.horarios;

    let horariosEntrega = [];

    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    return horariosEntrega;
  }

  getDataCliente(cliente) {
    return {
      ...cliente,
      cep: Mask.clearMask(cliente.cep),
    };
  }

  sincronizarCliente() {
    let cliente = this.getDataCliente(this.state.cliente);
    Http.post("cliente", cliente)
      .then(({ status, data }) => {
        if (status) {
          UsuarioUtils.setUsuarioLojaWeb(cliente);

          this.renderSucesso();
          this.forceUpdate();
        }
      })
      .catch((error) => console.log(error));
  }

  handlerChange({ name, value }) {
    this.setState(
      {
        cliente: {
          ...this.state.cliente,
          [name]: value,
        },
      },
      () => this.renderTrocarEndereco()
    );
  }

  handlerChangeEndereco({ name, value }) {
    this.setState(
      {
        cliente: {
          ...this.state.cliente,
          [name]: value,
        },
      },
      () => this.renderTrocarEndereco()
    );
  }

  getItensCarrinho = () => {
    const { itens } = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  renderAdicionarProdutoLista(listas) {
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutosCarrinhoListaMobile
          listas={listas}
          produtos={this.getItensCarrinho()}
          empresa={this.props.empresa}
          //handlerCancel={() => this.visualizarProduto(produto, updateItem)}
          //handlerNovaListaCadastrada={() =>
          //  this.visualizarProduto(produto, updateItem)
          // }
        />
      ),
    });
  }

  renderEnderecoEntrega() {
    let cliente = this.state.cliente;

    let enderecos = cliente.enderecos;

    let enderecoPrincipal = Array.from(enderecos).filter(
      (end) => end.principal
    )[0];

    return (
      <Div col="12" rounded column>
        <Label
          value="Informe os seguintes dados para atualizar o endereço de entrega"
          family="Light"
          style={{ fontSize: 30 }}
        />
        <Div inline="start">
          <InputDefault
            handlerChange={(e) => {
              enderecoPrincipal.logradouro = e.target.value;
              this.setState({ cliente }, () => this.renderTrocarEndereco());
            }}
            label="Logradouro"
            responsive="4"
            name="logradouro"
            value={enderecoPrincipal.logradouro}
          />
          <InputInteger
            handlerChange={(e) => {
              enderecoPrincipal.numero = e.target.value;
              this.setState({ cliente }, () => this.renderTrocarEndereco());
            }}
            label="Número"
            responsive="2"
            name="numero"
            value={enderecoPrincipal.numero}
          />
          <InputDefault
            handlerChange={(e) => {
              enderecoPrincipal.complemento = e.target.value;
              this.setState({ cliente }, () => this.renderTrocarEndereco());
            }}
            label="Complemento"
            responsive="3"
            name="complemento"
            value={enderecoPrincipal.complemento}
          />
          <InputDefault
            handlerChange={(e) => {
              enderecoPrincipal.bairro = e.target.value;
              this.setState({ cliente }, () => this.renderTrocarEndereco());
            }}
            label="Bairro"
            responsive="3"
            name="bairro"
            value={enderecoPrincipal.bairro}
          />
          <InputCep
            handlerChange={(e) => {
              enderecoPrincipal.cep = e.target.value;
              this.setState({ cliente }, () => this.renderTrocarEndereco());
            }}
            label="CEP"
            responsive="4"
            name="cep"
            value={enderecoPrincipal.cep}
          />
        </Div>
        <Label
          style={{
            backgroundColor: Color.ECOMMERCE.LARANJA,
            color: "white",
            padding: 20,
            fontSize: 20,
            width: 250,
            borderRadius: 10,
            textAlign: "center",
          }}
          pointer
          family="Light"
          value="Atualizar endereço"
          icon={Type.ICON.REFRESH}
          handlerClick={() => this.sincronizarCliente()}
        />
      </Div>
    );
  }

  renderTrocarEndereco() {
    ModalNotification.render(
      <Div style={{ color: Color.ECOMMERCE.LARANJA }}>
        <I icon={Type.ICON.MOTORCYCLE} sizeIcon="2" margin="2" />
        <Label value="Trocar endereço de entrega" family="Light" />
      </Div>,
      this.renderEnderecoEntrega()
    );
  }

  renderSucesso() {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Label
          value="Endereço atualizado com sucesso!"
          style={{ fontSize: 25, textAlign: "center" }}
          family="Light"
        />
      ),
      handlerConfirm: null,
      handlerCancel: () => {},
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  getHorariosEntregaSelect() {
    let horarios = this.state.horarios;

    let horariosEntrega = [];

    if (Utils.isValueValid(horarios)) {
      horariosEntrega = Array.from(horarios).filter(
        (h) => h.tipoEntrega === "ENTREGA"
      );
    }

    let horariosSelect = [new SelectValue("", "Selecione um horário")];

    horariosEntrega.forEach((horario) => {
      horariosSelect.push(
        new SelectValue(
          horario.idEmpresaHorarioEntrega,
          `${horario.dataEntrega} ${horario.descricao}`
        )
      );
    });

    return horariosSelect;
  }

  renderDiasPrevisaoEntrega() {
    let diasPrevisaoEntrega = this.props.parametros ? this.props.parametros.diasPrevisaoEntrega : 0;
    let dataHoje = this.state.horarios[0].dataEntrega; 

    return (
        <Div inline="start" className="w-100" style={{cursor: "pointer"}}>
          <Label
              value="PREVISÃO DE ENTREGA"
              style={styles.labelInfo}
              family="SemiBold"
          />
          <Div
            row
            style={styles.selecioneHorario}
          >
            <Div column>
              <Label 
                style={{ 
                  margin: 0,
                  padding: "10px",
                  color: Color.EMPRESA.SECUNDARIA
                }} 
                value={`Data: ${dataHoje}`} 
                family="Bold"
              />
            </Div>
          </Div>
        </Div>
    );
  }

  renderHorariosEntrega() {
    let todosHorarios = this.getHorariosEntrega();

    let horarioSelecionado = todosHorarios.filter((h) => h.active)[0] || {};

    return (
      <Div inline="center" style={{ ...styles.item }}>
        <Select
          responsive="12"
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
            marginBottom: 0,
          }}
          valueSelected={horarioSelecionado.idEmpresaHorarioEntrega}
          handlerChange={(e) => {
            let idEmpresaHorarioEntregaSelecionado = e.target.value;
            Array.from(todosHorarios).map(
              (horario) => (horario.active = false)
            );

            let horarioSelecionado = todosHorarios.filter(
              (h) =>
                h.idEmpresaHorarioEntrega === idEmpresaHorarioEntregaSelecionado
            )[0];
            if (Utils.isValueValid(horarioSelecionado)) {
              horarioSelecionado.active = !horarioSelecionado.active;
            }

            this.setState(
              {
                horarios: [...this.state.horarios],
              },
              () => this.props.handlerHorarioSelecionado(horarioSelecionado)
            );
          }}
          values={this.getHorariosEntregaSelect()}
        />
      </Div>
    );
  }

  getEnderecoSelecionadoParaEntrega() {
    let carrinho = CarrinhoUtils.getCarrinho();

    if (Utils.isValueValid(carrinho.enderecoEntrega)) {
      return carrinho.enderecoEntrega;
    } else {
      let usuario = UsuarioUtils.getUsuarioLojaWeb();
      console.log(usuario);
      if (
        Utils.isValueValid(usuario) &&
        Utils.isValueValid(usuario.enderecos)
      ) {
        let enderecoPrincipal = Array.from(usuario.enderecos).filter(
          (e) => e.principal
        )[0];
        return enderecoPrincipal;
      } else {
        return {};
      }
    }
  }

  render() {
    let usuario = UsuarioUtils.getUsuarioLojaWeb();
    let enderecos = Array.from(usuario.enderecos);
    const { aceitaEntregaExpress } = this.props.parametros;

    let pedido = this.state.pedido || {};

    let diasPrevisaoEntrega = this.props.parametros ? this.props.parametros.diasPrevisaoEntrega : 0

    return (
      <Div style={{ marginBottom: 100, ...styles.item }}>
        <Div>
          <Label
            value="LOCAL DA ENTREGA"
            style={styles.labelInfo}
            col="12"
            family="SemiBold"
          />
          {enderecos.map((end, index) => {
            let enderecoEntrega = this.getEnderecoSelecionadoParaEntrega();

            let isSelected = false;

            if (Utils.isValueValid(enderecoEntrega)) {
              isSelected =
                enderecoEntrega.idClienteEndereco === end.idClienteEndereco;
            }

            return (
              <Div
                inline="center"
                key={index}
                col="12"
                handlerClick={() => {
                  pedido = {
                    ...pedido,
                    enderecoEntrega: { ...end },
                  };

                  this.setState(
                    {
                      enderecoCasaSelecionado: true,
                      enderecoEntregaSelecionado: false,
                      pedido,
                    },
                    () => this.props.handlerEnderecoSelecionado(end)
                  );
                }}
              >
                <Div
                  className="w-100"
                  inline="between"
                  padding="1"
                  rounded
                  pointer
                  style={
                    isSelected
                      ? {
                          ...styles.enderecoSelecionado,
                          backgroundColor: this.props.corPrimaria,
                        }
                      : styles.enderecoNaoSelecionado
                  }
                >
                  <Div inline="center">
                    <Div
                      className="d-flex flex-column align-items-start justify-content-center"
                      margin="2"
                      pointer
                    >
                      <Div inline="start">
                        <I
                          icon={Type.ICON.MOTORCYCLE}
                          style={{ color: Color.ECOMMERCE.WHITE }}
                          pointer
                        />
                        <Label
                          value={end.descricao}
                          style={{ height: 15, fontSize: 13, margin: 5 }}
                          family="SemiBold"
                        />
                      </Div>
                      <Label
                        value={`${end.logradouro} n° ${end.numero} ${end.bairro}`}
                        style={{ height: 15, fontSize: 12 }}
                        family="Light"
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
            );
          })}
          <Label
            value="ATENÇÃO! Trocar o endereço pode alterar o valor do frete"
            style={{ ...styles.labelAtencao }}
            family="Bold"
            col="12"
            marginTop="1"
          />

          <If and value1={diasPrevisaoEntrega === 0}>
            <Label
              value="AGENDE SEU HORÁRIO"
              style={styles.labelInfo}
              col="12"
              family="SemiBold"
            />

            {this.renderHorariosEntrega()}
          </If>

          <If and value1={diasPrevisaoEntrega > 0}>
            {this.renderDiasPrevisaoEntrega()}
          </If>

          <Div
            inline="start"
            marginTop="3"
            style={{ color: "#25D366", marginBottom: 20, padding: "0 10px" }}
          >
            <If and value1={aceitaEntregaExpress}>
              <ContatoWhatsappGenerico
                width="100%"
                height="50px"
                empresa={this.props.empresa}
                corPrimaria={
                  this.props.corPrimaria
                    ? this.props.corPrimaria
                    : Color.ECOMMERCE.LARANJA
                }
                zIndex={Zindex.MIL_E_DOIS}
                title="Entre em contato com um de nossos atendentes para antecipar um pedido"
                value={{ mensagem: "Olá, desejo antecipar minha entrega!" }}
              />

              <Label
                value="Deseja antecipar sua entrega? Entre em contato via Whatsapp"
                family="Bold"
                style={{
                  ...styles.labelAtencao,
                  width: "100%",
                  textAlign: "center",
                }}
              />
            </If>
          </Div>

          <Div
            inline="start"
            marginTop="3"
            style={{ padding: "0 10px 15px" }}
          >
            <Checkbutton
              corPrimaria={Color.EMPRESA.TERCIARIA}
              style={{
                width: "100%",
                height: "50px",
                borderRadius: "5px",
                color: Color.EMPRESA.PRIMARIA,
                textTransform: "uppercase",
                fontSize: 16,
              }}
              checked={true}
              title="Salve aqui a sua lista de compra!"
              onClick={() => {
                let listas = JSON.parse(
                  JSON.stringify(this.props.listasDeCompra)
                );
                Array.from(listas).forEach((lista) => {
                  Array.from(lista.itens).forEach((p) => {
                    p.valorSubTotalItem =
                      Number(p.precoVenda) * Number(p.quantidade);
                  });
                });

                this.renderAdicionarProdutoLista(listas);
              }}
            />
          </Div>

          <Div inline="center" style={styles.item}>
            <InputDefault
              responsive="12"
              fontSizeLabel={13}
              value={pedido.responsavelRecebimento}
              label="Responsável pelo recebimento"
              handlerChange={(e) => {
                pedido = {
                  ...pedido,
                  responsavelRecebimento: e.target.value,
                };
                this.props.handlerPedido(pedido);
              }}
            />
            <TextArea
              responsive="12"
              label="Observações"
              fontSizeLabel={13}
              handlerChange={(e) => {
                pedido = {
                  ...pedido,
                  obs: e.target.value,
                };
                this.props.handlerPedido(pedido);
              }}
            />
          </Div>
        </Div>
      </Div>
    );
  }
}

export default TemplatePedidoEntregaMobile;
