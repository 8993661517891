import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonIconOutLine, CheckboxToggle, Div, Image, InputDefault, Label, Notification, Row, Select, SelectValue, TextAreaCustom, Title, } from "../../components";
import { Color, Mask, Utils } from "../../utilities";
import { bindActionCreators } from "redux";
import { setEmpresa } from "../../redux/actions";
import FileUpload from "../../components/forms/input/FileUpload";
import ImagemIndisponivel from "../../assets/images/imgindisponivel_thumb.webp";
import EmpresaService from "../../service/EmpresaService";


const styles = {
	boxInfo: {
		marginBottom: "50px",
		padding: "10px 0 20px 0",
		backgroundColor: "#FFF",
		alignItems: "end"
	},
	labelCinza: {
		color: "#878787",
		fontSize: "16px",
		marginBottom: "35px",
	},
	imageIcon: {
		marginBottom: 15,
		marginLeft: 5,
		marginRight: 5,
	},
	checkedBox: {
		margin: "8px 5px"
	},
	colorInput: {
		color: "#c5c5c5",
	}
}

class ConfigGerais extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailAtendimento: "",
			horarioFuncionamento: "",
			tituloPagina: "",
			empresaParams: {},
			empresa: {},
			aceitaPagamentoOnline: false,
			pagamentoOnlineTipo: null,
			pagamentoOnlineTipoOptions: [
				new SelectValue(null, "SELECIONE", true),
				new SelectValue("CIELO", "CIELO", true),
			],
			urlFoto: "",
			urlFavIcon: "",
			urlLogoEmail: "",
			urlBackground: "",
			obsRodape: "",
			sobre: "",
			politica: "",
			telefoneAtendimento: "",
			telefoneWhatsapp: "",
			urlAppStore: "",
			urlFacebook: "",
			urlInstagram: "",
			urlPlayStore: "",

			chavePix: "",

		}
	}

	componentDidMount() {
		if (Object.keys(this.props.empresa).length !== 0) {
			this.setState({
				empresa: { ...this.props.empresa }
			})
		}

		this.getParametrosEmpresa();
		this.getEmpresaPolitica();
		this.getEmpresaSobre();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.empresa !== this.props.empresa) {
			this.setState({
				empresa: { ...this.props.empresa }
			})
		}
	}

	async getParametrosEmpresa() {
		await EmpresaService.getEmpresaParams().then( ({ data }) => {
			this.setState({
				...data
			})
		})
	}

	async getEmpresaSobre() {
		await EmpresaService.getSobre().then( ({ data }) => {
			this.setState({
				sobre: data.sobre
			})
		})
	}

	async getEmpresaPolitica() {
		await EmpresaService.getPolitica().then( ({ data }) => {
			this.setState({
				politica: data.politica
			})
		})
	}


	handleChange = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleFileUpload = ({ name, value }) => {
		this.setState({ [name]: value });
	};

	handleChanceTextArea = (name, value) => {
		this.setState({
			[name]: value,
		})
	}

	submitParametros = () => {
		const {
			empresa,
			horarioFuncionamento,
			tituloPagina,
			emailAtendimento,
			urlBackground,
			urlFavIcon,
			urlFoto,
			urlLogoEmail,
			obsRodape,
			sobre,
			politica,
			urlAppStore,
			urlFacebook,
			urlInstagram,
			urlPlayStore,
			chavePix,
		} = this.state

		let newParams = {
			idEmpresa: empresa.idEmpresa,
			horarioFuncionamento,
			tituloPagina,
			emailAtendimento,
			arquivoLogoEmpresa: urlFoto,
			arquivoLogoEmpresaEmail: urlLogoEmail,
			arquivoFavIcon: urlFavIcon,
			arquivoBackground: urlBackground,
			obsRodape,
			sobre,
			politica,
			urlAppStore,
			urlFacebook,
			urlInstagram,
			urlPlayStore,
			chavePix,
		}

		EmpresaService.putEmpresa(newParams).then(({data, status}) => {
			if (status) {
				Notification.success(data.mensagem);
				this.props.setLoadingProdutosPreco(true);
			} else {
				Notification.danger(data.mensagem);
			}
		})
	}


	handleChangeCheckedPagamento = e => {
		const { name, checked } = e;
		this.setState({ [name]: checked, });
	};

	getParametros = (empresa) => {
		let parametros = {};
		if (Utils.isValueValid(empresa)) {
			parametros = { ...empresa.parametros };
		}

		return parametros;
	};

	render() {

		return (
			<Div>
				<Div col="12">
					<Row col="12" shadow className="border-left-primary" style={styles.boxInfo}>
						<Title
							value="Informações da Loja"
							type="h6"
							className="label-valor-total-carrinho"
							style={styles.labelCinza}
							family="Bold"
							col="12"
						/>

						<InputDefault
							responsive="6"
							label="Email de Atendimento"
							name="emailAtendimento"
							handlerChange={this.handleChange}
							value={this.state.emailAtendimento}
							style={styles.colorInput}
						></InputDefault>

						<InputDefault
							responsive="6"
							label="Chave PIX"
							name="chavePix"
							handlerChange={this.handleChange}
							value={this.state.chavePix}
							style={styles.colorInput}
						></InputDefault>

						<InputDefault
							responsive="4"
							label="Titulo da Pagina"
							name="tituloPagina"
							handlerChange={this.handleChange}
							value={this.state.tituloPagina}
							style={styles.colorInput}
						></InputDefault>

						<InputDefault
							responsive="4"
							label="Telefone"
							name="telefoneAtendimento"
							handlerChange={this.handleChange}
							value={Mask.maskValuePhone(this.state.telefoneAtendimento)}
							style={styles.colorInput}
						></InputDefault>

						<InputDefault
							responsive="4"
							label="Whatsapp"
							name="telefoneWhatsapp"
							handlerChange={this.handleChange}
							value={Mask.maskValuePhone(this.state.telefoneWhatsapp)}
							style={styles.colorInput}
						></InputDefault>

						<InputDefault
							responsive="12"
							label="Horário de funcionamento"
							name="horarioFuncionamento"
							handlerChange={this.handleChange}
							value={this.state.horarioFuncionamento}
							style={styles.colorInput}
						></InputDefault>

					</Row>

					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Title
							value="Redes Sociais"
							type="h6"
							className="label-valor-total-carrinho"
							style={styles.labelCinza}
							family="Bold"
							col="12"
						/>
						<InputDefault
							responsive="6"
							label="Url Facebook"
							name="urlFacebook"
							handlerChange={this.handleChange}
							value={this.state.urlFacebook}
							style={styles.colorInput}
						></InputDefault>
						<InputDefault
							responsive="6"
							label="Url Instagram"
							name="urlInstagram"
							handlerChange={this.handleChange}
							value={this.state.urlInstagram}
							style={styles.colorInput}
						></InputDefault>
						<InputDefault
							responsive="6"
							label="Url Google Play Store"
							name="urlPlayStore"
							handlerChange={this.handleChange}
							value={this.state.urlPlayStore}
							style={styles.colorInput}
						></InputDefault>
						<InputDefault
							responsive="6"
							label="Url Apple App Store"
							name="urlAppStore"
							handlerChange={this.handleChange}
							value={this.state.urlAppStore}
							style={styles.colorInput}
						></InputDefault>
					</Row>

					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Title
							value="Upload de Imagens"
							type="h6"
							className="label-valor-total-carrinho"
							style={styles.labelCinza}
							family="Bold"
							col="12"
						/>
						<Div col="3">
							<Div col="12" style={{ textAlign: "center" }}>
								<Image
									src={this.state.urlFoto ? this.state.urlFoto : ImagemIndisponivel}
									style={styles.imageIcon}
									//className="color-icon-menu"
									pointer
									width="100%"
								/>
							</Div>
							<FileUpload col="12"
								label="Logo Empresa"
								handlerChange={e => this.handleFileUpload({ name: "urlFoto", value: e.fileUpload })}
								isIcon
								classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
								name="urlFoto"
								tipos={["png"]}>

							</FileUpload>
						</Div>

						<Div col="3" >
							<Div col="12" style={{ textAlign: "center" }} >
								<Image
									src={this.state.urlLogoEmail ? this.state.urlLogoEmail : ImagemIndisponivel}
									style={styles.imageIcon}
									//className="color-icon-menu"
									pointer
									width="100%"
								/>
							</Div>
							<FileUpload col="12"
								label="Logo Email"
								handlerChange={e => this.handleFileUpload({ name: "urlLogoEmail", value: e.fileUpload })}
								isIcon
								classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
								name="urlLogoEmail"
								tipos={["png"]}>

							</FileUpload>
						</Div>

						<Div col="3" >
							<Div col="12" style={{ textAlign: "center" }} >
								<Image
									src={this.state.urlFavIcon ? this.state.urlFavIcon : ImagemIndisponivel}
									style={styles.imageIcon}
									//className="color-icon-menu"
									pointer
									width="50px"
								/>
							</Div>
							<FileUpload col="12"
								label="Favicon"
								handlerChange={e => this.handleFileUpload({ name: "urlFavIcon", value: e.fileUpload })}
								isIcon
								classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
								name="urlFavIcon"
								tipos={["png"]}>

							</FileUpload>
						</Div>

						<Div col="3" >
							<Div col="12" style={{ textAlign: "center" }} >
								<Image
									src={this.state.urlBackground ? this.state.urlBackground : ImagemIndisponivel}
									style={styles.imageIcon}
									//className="color-icon-menu"
									pointer
									width="50px"
								/>
							</Div>
							<FileUpload col="12"
								label="Background"
								handlerChange={e => this.handleFileUpload({ name: "urlBackground", value: e.fileUpload })}
								isIcon
								classNameIcon="col-12 btn bg-primary text-white rounded pt-3"
								name="urlBackground"
								tipos={["png"]}>

							</FileUpload>
						</Div>
					</Row>


					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Row style={{ padding: "0 15px" }}>
							<Title
								value="Pagamentos Online"
								type="h6"
								className="label-valor-total-carrinho"
								style={styles.labelCinza}
								family="Bold"

							/>
							<Div style={styles.checkedBox}>
								<CheckboxToggle
									inline="center"
									name="aceitaPagamentoOnline"
									value={this.state.aceitaPagamentoOnline}
									checked={this.state.aceitaPagamentoOnline}
									handlerChange={(e) => this.handleChangeCheckedPagamento(e)}
								></CheckboxToggle>
							</Div>
						</Row>
						<Row col="12" style={{ padding: "0" }}>
							<InputDefault
								responsive="6"
								label="Identificador (ID)"
								name="pagamentoOnlineId"
								disabled={!this.state.aceitaPagamentoOnline}
								handlerChange={this.handleChange}
								value={this.state.pagamentoOnlineId}
							></InputDefault>
							<InputDefault
								responsive="6"
								label="Token"
								name="pagamentoOnlineToken"
								handlerChange={this.handleChange}
								disabled={!this.state.aceitaPagamentoOnline}
								value={this.state.pagamentoOnlineToken}
							></InputDefault>
						</Row>

						<Row col="12" style={{ padding: "0", alignItems: "center" }}>
							<Select
								required
								responsive="5"
								label="Tipo de Pagamento"
								disabled={!this.state.aceitaPagamentoOnline}
								valueSelected={this.state.pagamentoOnlineTipo}
								handlerChange={(e) => {
									this.setState({ pagamentoOnlineTipo: e.target.value });
								}}
								values={this.state.pagamentoOnlineTipoOptions}
							/>

							<Div style={{ margin: "26px 5px 0 19px" }}>
								<CheckboxToggle
									inline="center"
									name="pagamentoOnlinePreAutorizacao"
									value={this.state.pagamentoOnlinePreAutorizacao}
									checked={this.state.pagamentoOnlinePreAutorizacao}
									handlerChange={(e) => this.handleChangeCheckedPagamento(e)}
									disabled={!this.state.aceitaPagamentoOnline}
								></CheckboxToggle>
							</Div>

							<Title
								value="Pré Autorização de Pagamento Online"
								type="h6"
								className="label-valor-total-carrinho"
								style={{ ...styles.labelCinza, marginBottom: "0" }}
								family="Bold"
							/>
						</Row>
					</Row>

					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Row col="12">
							<Title
								value="Sobre"
								type="h6"
								className="label-valor-total-carrinho"
								style={{ ...styles.labelCinza }}
								family="Bold"
							/>
						</Row>
						<Div col="12">
							<TextAreaCustom col="12" value={this.state.sobre} handleChance={e => this.handleChanceTextArea("sobre", e)} />
						</Div>
					</Row>

					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Row col="12">
							<Title
								value="Política de Privacidade"
								type="h6"
								className="label-valor-total-carrinho"
								style={{ ...styles.labelCinza }}
								family="Bold"
							/>
						</Row>
						<Div col="12">
							<TextAreaCustom col="12" value={this.state.politica} handleChance={e => this.handleChanceTextArea("politica", e)} />
						</Div>
					</Row>

					<Row shadow col="12" className="border-left-primary" style={styles.boxInfo}>
						<Row col="12" style={{ marginBottom: "35px" }}>
							<Title
								value="Informações e Observações no Rodapé"
								type="h6"
								className="label-valor-total-carrinho"
								style={{ ...styles.labelCinza, marginBottom: "0" }}
								family="Bold"
							/>
						</Row>
						<Div col="12">
							<TextAreaCustom col="12" value={this.state.obsRodape} handleChance={e => this.handleChanceTextArea("obsRodape", e)} />
						</Div>
					</Row>

					<Div
						col="12"
						style={{
							textAlign: "right",
							alignItems: "center",
							display: "flex",
							justifyContent: "flex-end ",
							margin: "-25px 0 35px 0",
							padding: "0",
						}}
					>
						<ButtonIconOutLine
							value="Salvar"
							//icon={Type.ICON.PLUS}
							btn={Color.NODE.PRIMARY}
							handlerClick={() => this.submitParametros()}
						/>
					</Div>

				</Div>

			</Div>
		)
	}
}

const mapStateToProps = (store) => ({
	user: store.loginState.user,
	...store.empresaState,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ setEmpresa },
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigGerais);