import React, { Component } from "react";
import { Div, Notification, Table, Title } from "../../components";
import { Color, Type } from "../../utilities";


class ListaCompraListar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      produtos: [],
    };
  }

  UNSAFE_componentWillMount() {

  }

  componentDidUpdate() {

  }

  render() {

    return (

        <Div
          margin="3"
          shadow
          bg={Color.NODE.WHITE}
          borderLeft={Color.NODE.PRIMARY}
        >
          <Title
            value="Listas de Compras Cadastradas"
            type="h4"
            paddingLeft="4"
            paddingRight="4"
            paddingTop="3"
          />

          <Div padding="2">
            <Table
              columns={[
                { value: "Descricao", align: "left" },
                { value: "Valor Total", align: "center" },
              ]}
              fields={[
                {
                  value: "descricaoProduto",
                  align: "left",
                  type: Type.DADO.STRING,
                },
                {
                  value: "precoVenda",
                  align: "left",
                  type: Type.DADO.CURRENCY,
                },

              ]}
              bordered
              search
              orientation="center"
              pagination
              edit
              delete
              sizeEdit="2"
              sizeDelete="2"
              //filter={this.renderFiltros()}
              //handlerEdit={(rede) => this.editarPesquisa(rede)}
             // handlerDelete={(rede) => this.deletarPesquisa(rede)}
              data={this.state.produtos}
              rota="#"
              handlerRota={() => this.setCloseModal(true)}
              error="Nenhuma lista de compra encontrado!"
            ></Table>
          </Div>
        </Div>

    );
  }
}



export default ListaCompraListar;
