import React from "react";
import { Div, Image, Label } from "../../components";
import { Component } from "react";
import { EmpresaUtils } from "..";
import { staticPix } from "pix-qrcode";
import {Mask} from "../../utilities";

class PixQrCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qrcode: null,
    };
  }

  async generateQrCode(chavePix) {
    let empresa = this.props.empresa.fantasia;
    let nomeCidade = this.props.empresa.nomeCidade;
    let idTransacaoPedido = this.props.idTransacaoPedido;
    let descricao = "Pedido:" + String(idTransacaoPedido).substr(0,8) + "..., Valor: " + Mask.formatValueBrSemCifrao(this.props.valor);
    const pixCode = await staticPix({
      pixKey: chavePix,
      description: descricao,
      merchant: empresa,
      merchantCity: nomeCidade,
      transactionId: idTransacaoPedido,
      amount: String(this.props.valor),
    });

    return pixCode;
  }

  async componentDidMount() {
    let chavePix =  EmpresaUtils.getChavePix(this.props.empresa);
    let pix = await this.generateQrCode(chavePix);
    this.setState({ qrcode: pix.qrcode });
  }

  async componentWillReceiveProps(props) {
    let chavePix =  EmpresaUtils.getChavePix(this.props.empresa);
    let pix = await this.generateQrCode(chavePix);
    this.setState({ qrcode: pix.qrcode });
  }

  render() {
    return (
      <Div column>
        <Label
          value="Utize o aplicativo do seu banco para escanear o QRCODE"
          family="Light"
        />
        <Label
          value="Verifique se o valor do pix corresponde ao da compra"
          family="Light"
          style={{ fontSize: 12 }}
        />
        <Image src={this.state.qrcode} />
      </Div>
    );
  }
}

export default PixQrCode;
