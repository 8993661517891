import React from "react";
import { Div, If, Label, Title } from "../../components";
import { Color, Utils } from "../../utilities";
import "./anuncio.css";

const WIDTH_IMAGE_OFERTA = 240;
const HEIGHT_IMAGE_OFERTA_1 = 402;
const HEIGHT_IMAGE_OFERTA_2 = 197;

const styles = {
  title: {
    position: "absolute",
    padding: 10,
    zIndex: 1001,
    color: "white",
    fontStyle: "normal",
    fontSize: 25,
    lineHeight: "25px",
  },
  subTitle: {
    position: "absolute",
    padding: 10,
    marginTop: 60,
    zIndex: 1001,
    color: Color.ECOMMERCE.LARANJA,
    width: 300,
    fontSize: 20,
    textDecoration: "line-through",
  },
  label: {
    position: "absolute",
    padding: 10,
    fontSize: 30,
    zIndex: 1001,
    marginTop: 80,
    color: Color.ECOMMERCE.LARANJA,
    width: 300,
  },
  containerImage: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    width: 300,
    height: 300,
    background: "rgba(0,0,0,0.6)",
    transition: "all 0.5s",
  },
};

const ItemOfertaPrioridade = ({
  image,
  handlerClick,
  widthImage,
  heightImage,
  labelsBanners,
  pagina
}) => {
  return (
    <Div
      style={{ marginLeft: 5, marginBottom: 5 }}
      handlerClick={() => handlerClick()}
    >
      { labelsBanners && labelsBanners(pagina) }
      <img
        src={image}
        alt="Imagem da oferta"
        style={{
          width: widthImage || WIDTH_IMAGE_OFERTA,
          height: heightImage,
          borderRadius: 10,
        }}
      />
    </Div>
  );
};

export default ItemOfertaPrioridade;
