import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Div, If, Image, ModalNotification, Row } from "../../../components";
import {
  atualizarCarrinho,
  setEmpresa,
  setParametrosPedido,
  setProdutos,
} from "../../../redux/actions";
import { EndPoints, Http, Utils } from "../../../utilities";
import ProdutoDetalhe from "../../produtos/web/ProdutoDetalhe";
import ProdutosDestaque from "../../produtos/web/ProdutosDestaque";
import CarouselBannerGenerico from "../../../components/carousel/CarouselBannerGenerico";
import ContatoWhatsapp from "../../ContatoWhatsapp";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import EmpresaUtils from "../../utils/EmpresaUtils";
import AdicionarProdutoListaCompra from "../../produtos/listascompras/web/AdicionarProdutoListaCompra";
import { toast } from "react-toastify";
import TagManager from "react-gtm-module";
import TagManagerAdmin from "react-gtm-module";
import NovoAnuncioBanner from "../../anuncios/NovoAnuncioBanner";
import BannerProdutos from "../../anuncios/BannerProdutos";

const initContatoWhatsapp = {
  telefone: "",
  mensagem: "",
};

class PrincipalSaccaria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anuncios: [],
      produtos: [],
      menus: [],
      produtosVisualizados: [],
      produtosSimilares: [],
      carrinho: props.carrinho,
      empresa: {},
      zIndexHeader: 1001,
      contatoWhatsapp: initContatoWhatsapp,
    };

    this.adicionarItemNoCarrinho = this.adicionarItemNoCarrinho.bind(this);
    this.visualizarProduto = this.visualizarProduto.bind(this);
    this.listarProdutosSimilares = this.listarProdutosSimilares.bind(this);
    this.visualizarProdutosAnuncio = this.visualizarProdutosAnuncio.bind(this);
  }

  componentDidMount() {
    this.setTitlePage();
    this.setFavIcon();
    //this.setGoogleTagManager();
  }

  setFavIcon = () => {
    let iconFiveIcon = document.querySelector("link[rel~='icon']");
    let urlFavIcon = EmpresaUtils.getParametro(
      this.props.empresa,
      "urlFavIcon"
    );
    if (!iconFiveIcon) {
      iconFiveIcon = document.createElement("link");
      iconFiveIcon.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(iconFiveIcon);
    }
    iconFiveIcon.href = `${urlFavIcon}?${Utils.uuidString()}`;
  };

  setTitlePage = () => {
    document.title = EmpresaUtils.getParametro(
      this.props.empresa,
      "tituloPagina"
    );
  };

  setGoogleTagManager = () => {
    let gtm = EmpresaUtils.getParametro(this.props.empresa, "googleTagManager");
    if (Utils.isValueValid(gtm)) {
      const tagManagerArgs = {
        gtmId: gtm,
      };
      TagManager.initialize(tagManagerArgs);
    }

    let mobiohGtm = EmpresaUtils.getParametro(
      this.props.empresa,
      "mobiohGoogleTagManager"
    );
    if (Utils.isValueValid(mobiohGtm)) {
      const tagManagerArgs = {
        gtmId: mobiohGtm,
      };
      TagManagerAdmin.initialize(tagManagerArgs);
    }
  };

  listarProdutosSimilares(produto, updateItem) {
    Http.get(EndPoints.PRODUTOS_SIMILARES.replace("%s", produto.idProdutoWeb))
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtosSimilares: data }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        } else {
          this.setState({ produtosSimilares: [] }, () =>
            this.visualizarProduto(produto, updateItem)
          );
        }
      })
      .catch((error) => console.log(error));
  }

  visualizarProduto = (produto, updateItem) => {
    ModalNotification.render(
      produto.combo ? "Informações do combo" : "Informações do produto",
      <ProdutoDetalhe
        produto={produto}
        listasDeCompraCliente={this.props.listasDeCompra}
        handlerAddItem={(produto, atualizarProdutoDetalhe) => {
          this.adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe);
          if (Utils.isFunction(updateItem)) {
            updateItem(produto.quantidade);
          }
          toast.info("Produto adicionado no carrinho com sucesso!");
        }}
        history={this.props.history}
        empresa={this.props.empresa}
        handlerAdicionarProdutoLista={() =>
          this.renderAdicionarProdutoLista(produto, updateItem)
        }
      />
    );
  };

  renderAdicionarProdutoLista(produto, updateItem) {
    let listas = this.props.listasDeCompra;
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutoListaCompra
          listas={listas}
          produto={produto}
          empresa={this.props.empresa}
          handlerCancel={() => this.visualizarProduto(produto, updateItem)}
          handlerNovaListaCadastrada={() =>
            this.visualizarProduto(produto, updateItem)
          }
        />
      ),
    });
  }

  adicionarItemNoCarrinho(produto, atualizarProdutoDetalhe) {
    let { carrinho, produtos } = this.props;
    CarrinhoUtils.adicionarItemNoCarrinho(
      produto,
      carrinho,
      produtos,
      this.props.atualizarCarrinho,
      this.props.setProdutos,
      atualizarProdutoDetalhe,
      this.props.parametros
    );
  }

  visualizarProdutosAnuncio({
    titulo,
    idAnuncioOferta,
    idDepartamentoSecaoFiltro,
    tipoDepartamentoSecao,
    marcaFiltro,
    possuiProdutos,
    estatico,
  }) {
    if (!estatico) {
      this.props.history.push("/anuncio/produtos", {
        titulo,
        idAnuncioOferta,
        idDepartamentoSecaoFiltro,
        tipoDepartamentoSecao,
        marcaFiltro,
        empresa: this.props.empresa,
        possuiProdutos,
      });
    }
  }

  getItensBanner() {
    let { anuncios } = this.state || {};
    let especiais = Array.from(anuncios.especial || []);

    if (Utils.isValueValid(especiais)) {
      especiais = Utils.sort(especiais, "ordem");
    }

    let especiaisPagina = Utils.quebrarArray(especiais, 3);

    let itens = [];

    especiaisPagina.forEach((itemPagina) => {
      itens.push(
        <Div inline="end" style={{ height: 300 }}>
          <Div
            margin="2"
            handlerClick={() => this.visualizarProdutosAnuncio(itemPagina[0])}
          >
            <Image
              src={
                Utils.isValueValid(itemPagina[0]) ? itemPagina[0].urlFoto : ""
              }
              width="330px"
              styleImage={{ height: "300px" }}
            />
          </Div>
          <Div column margin="2">
            <If and value1={Utils.isValueValid(itemPagina[1])}>
              <Div
                marginTop="1"
                marginBottom="1"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[1])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[1])
                      ? itemPagina[1].urlFoto
                      : ""
                  }
                  width="320px"
                  styleImage={{
                    height: itemPagina[1].tipo === "DESTAQUE" ? "300px" : "",
                  }}
                />
              </Div>
            </If>
            <If and value1={Utils.isValueValid(itemPagina[2])}>
              <Div
                marginTop="1"
                marginBottom="1"
                handlerClick={() =>
                  this.visualizarProdutosAnuncio(itemPagina[2])
                }
              >
                <Image
                  src={
                    Utils.isValueValid(itemPagina[2])
                      ? itemPagina[2].urlFoto
                      : ""
                  }
                  width="320px"
                  styleImage={{
                    height: itemPagina[1].tipo === "DESTAQUE" ? "300px" : "",
                  }}
                />
              </Div>
            </If>
          </Div>
        </Div>
      );
    });

    return [...itens];
  }

  renderVideo(item) {
    ModalNotification.renderMessage({
      title: item.titulo,
      body: (
        <Div inline="center">
          <video src={item.urlVideo} controls style={{ width: "100%" }} />
        </Div>
      ),
      responsive: "6",
      handlerCancel: () => {
        ModalNotification.removeModal();
      },
      labelButtonCancel: "Fechar",
    });
  }

  getItensBannerTopo() {
    const anuncios = this.props.anuncios;
    let itens = [];

    let itensTopo = Array.from(anuncios.banner || []);

    if (Utils.isValueValid(itensTopo)) {
      itensTopo.forEach((item) => {
        itens.push(
          <>
            {item.exibirVideo ? (
              <img
                src={item.urlFoto}
                onClick={() => this.renderVideo(item)}
                width="100%"
                className="cursor-pointer"
              />
            ) : (
              <img
                src={item.urlFoto}
                width="100%"
                className="cursor-pointer"
                onClick={() => this.visualizarProdutosAnuncio(item)}
              />
            )}
          </>
        );
      });
    }

    return itens;
  }

  render() {
    let itensBannerTopo = this.getItensBannerTopo();
    let itensBannerNovos = this.props.anuncios;

    return (
      <>
        <If and value1={Utils.isValueValid(itensBannerTopo)}>
          <Div className="w-100 overflow-hidden" marginBottom="3">
            <CarouselBannerGenerico
              timeSlide={7000}
              itens={itensBannerTopo}
              slidesPerPage={1}
              dots
            />
          </Div>
        </If>

        <Div column>
          <ProdutosDestaque   
            history={this.props.history}
            titulo="DESTAQUE"
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <Div col="12" inline="center">
          <BannerProdutos 
            anuncios={itensBannerNovos} 
            produtos={ Utils.isNotUndefined(this.props.produtos) ? this.props.produtos : [] }
            handlerClick={this.listarProdutosSimilares}
            history={this.props.history}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <Div column>
          <ProdutosDestaque
            history={this.props.history}
            titulo="MAS VISTOS"
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}

            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <Div col="12" inline="center">
          <NovoAnuncioBanner anuncios={itensBannerNovos} numBanner={2} />
        </Div>


        <Div column>
          <ProdutosDestaque
            history={this.props.history}
            titulo="MAS VISTOS"
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <Div col="12" inline="center">
          <BannerProdutos 
            anuncios={itensBannerNovos} 
            produtos={ Utils.isNotUndefined(this.props.produtos) ? this.props.produtos : [] } 
          />
        </Div>
        

        <Div column>
          <ProdutosDestaque
            history={this.props.history}
            titulo="OFERTAS ESPECIAIS"
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>


        {/* 
        <Div inline="center" column marginBottom="3">
          <Div style={{ marginTop: 20 }}>
            <ListasComprasSugeridas
              handlerAddItemCarrinho={(produto) =>
                this.adicionarItemNoCarrinho(produto)
              }
              empresa={this.props.empresa}
              history={this.props.history}
            />
            <ProdutosCombo
              handlerClick={(produto) => this.visualizarProduto(produto)}
              handlerAddItemCarrinho={(produto) =>
                this.adicionarItemNoCarrinho(produto)
              }
            />
          </Div>
        </Div> */}

        <Div col="12" inline="center">
          <NovoAnuncioBanner anuncios={itensBannerNovos} numBanner={3} />
        </Div>

        <Div column>
          <ProdutosDestaque
            history={this.props.history}
            titulo="OFERTAS ESPECIAIS"
            handlerClick={this.listarProdutosSimilares}
            empresa={this.props.empresa}
            handlerAddItemCarrinho={(produto) =>
              this.adicionarItemNoCarrinho(produto)
            }
          />
        </Div>

        <Div id="divDepartamentosViewPrincipal" />
        <ContatoWhatsapp empresa={this.props.empresa} />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.parametrosPedidoState,
    ...store.listasDeCompraState,
    ...store.empresaState,
    ...store.anunciosState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { atualizarCarrinho, setProdutos, setEmpresa, setParametrosPedido },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PrincipalSaccaria);
