import React, { Component } from "react";
import { Div, I, If, Label } from "../../../components";

import { Color, EndPoints, Http, Type, Utils } from "../../../utilities";

import "../produtos.css";
import { connect } from "react-redux";
import ProdutoUtils from "../ProdutoUtils";
import ItemProdutoAbaixouPrecoMobile from "../ItemProdutoAbaixouPrecoMobile";
import SpaceUtils from "../../utils/SpaceUtils";
import CarouselProdutoGenericoNovo from "../../../components/carousel/CarouselProdutoGenericoNovo";
import ItemProdutoAbaixouPrecoMobileSaccaria from "../ItemProdutoAbaixouPrecoMobileSaccaria";

const styles = {
  title: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    fontSize: 12,
    marginLeft: 5,
  },
};

const WIDTH_CONTAINER = SpaceUtils.MOBILE_CONTAINER_ATUAL;

class ProdutosDestaqueMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {  
      produtos: [],
    };
  }

  componentDidMount() {
   // this.listarProdutos();

  }

  listarProdutos() {
    Http.get(EndPoints.PRODUTOS_DESTAQUE)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ produtos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  getProdutos() {
    let titulo = Utils.isNotNull(this.props.titulo)

    if(titulo) {
      return Array.from(this.props.produtos.slice(0, 5)).map((prod, index) => {
        return (
          <Div className="d-flex justify-content-start align-items-start align-self-start">
            <ItemProdutoAbaixouPrecoMobileSaccaria
              history={this.props.history}
              key={index}
              image={prod.urlImagemPrincipalThumbnail}
              descricao={prod.descricao}
              precoUnitario={prod.precoUnitario}
              precoVenda={prod.precoVenda}
              precoAtacado={prod.precoAtacado}
              codigoBarras={prod.codigoBarras}
              handlerClick={(updateItem) =>
                this.props.handlerClick(prod, updateItem)
              }
              quantidade={prod.quantidade}
              disponivel={prod.disponivel}
              empresa={this.props.empresa}
              percentualEconomia={prod.percentualEconomia}
              estoqueDisponivel={prod.estoqueDisponivel}
              quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
              fatorEmGrama={prod.fatorEmGrama}
              possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
              precosConcorrentes={prod.precosConcorrentes || []}
              handlerAddItemCarrinho={(quantidade) => {
                prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
                this.props.handlerAddItemCarrinho(prod);
              }}
            />
          </Div>
        );
      });
    }

    return Array.from(this.props.produtos.slice(0, 5)).map((prod, index) => {
      return (
        <Div className="d-flex justify-content-start align-items-start align-self-start">
          <ItemProdutoAbaixouPrecoMobile
            history={this.props.history}
            key={index}
            image={prod.urlImagemPrincipalThumbnail}
            descricao={prod.descricao}
            precoUnitario={prod.precoUnitario}
            precoVenda={prod.precoVenda}
            precoAtacado={prod.precoAtacado}
            codigoBarras={prod.codigoBarras}
            handlerClick={(updateItem) =>
              this.props.handlerClick(prod, updateItem)
            }
            quantidade={prod.quantidade}
            disponivel={prod.disponivel}
            empresa={this.props.empresa}
            percentualEconomia={prod.percentualEconomia}
            estoqueDisponivel={prod.estoqueDisponivel}
            quantidadeMinimaAtacado={prod.quantidadeMinimaAtacado}
            fatorEmGrama={prod.fatorEmGrama}
            possuiQuantidadeFracionada={prod.possuiQuantidadeFracionada}
            precosConcorrentes={prod.precosConcorrentes || []}
            handlerAddItemCarrinho={(quantidade) => {
              prod = ProdutoUtils.calcularValorProduto(prod, quantidade);
              this.props.handlerAddItemCarrinho(prod);
            }}
          />
        </Div>
      );
    });
  }

  renderProdutosDestaqueMobile() {
    const { titulo } = this.props;
    return (
      <Div style={{ width: WIDTH_CONTAINER }}>
        <Div inline={titulo ? "center" : "start"} col="12">
          <I
            icon={Type.ICON.STAR}
            style={{
              fontSize: 15,
              color: Color.ECOMMERCE.CINZA_ESCURO,
              marginBottom: 10,
              display: titulo ? "none" : "flex",
            }}
          />
          <Label
            value={titulo ? titulo : "PRODUTOS EM OFERTA"}
            style={{
              color: titulo ? Color.EMPRESA.SECUNDARIA : Color.ECOMMERCE.CINZA_ESCURO,
              fontSize: 22,
              marginLeft: 5,
            }}
            family="Bold"
          />
        </Div>
        <CarouselProdutoGenericoNovo
          timeSlide={7000}
          isMobile
          itens={this.getProdutos()}
          slidesPerPage={SpaceUtils.getQuantidadeItensView()}
          offset={30}
          arrows
        />
      </Div>
    );
  }

  render() {
    return (
      <If and value1={Utils.isValueValid(this.props.produtos)}>
        {this.renderProdutosDestaqueMobile()}
      </If>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.produtosState,
  };
};

export default connect(mapStateToProps)(ProdutosDestaqueMobile);
