import React, { Component } from "react";
import { Color } from "../../../utilities";
import { Link } from "react-router-dom";
import { colorText, className, padding, margin, sizeIcon } from "../../css";
import I from "../../i/I";
import Label from "../../texts/label/Label";
import Div from "../../layout/div/Div";
import Span from "../../texts/span/Span";

class NavbarItemMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  getClassIconLeft() {
    let classe = "";
    classe = classe.concat(sizeIcon(this.props));
    return classe;
  }
  
  getClasseContainer() {
    let classe = "collapsed pl-1 cursor-pointer ";
    classe = classe.concat(padding(this.props));
    classe = classe.concat(margin(this.props));
    classe = classe.concat(colorText(this.props));
    classe = classe.concat(className(this.props));
    return classe;
  }

  toggleActive() {
    this.setState({
      active: !this.state.active
    });
  }

  render() {
    const { rota, target, value, sizeIcon, iconLeft, iconRight, classIconLeft } = this.props;
    return (
      <Link to={rota}>
        <li
          className={this.getClasseContainer()}
          data-target={target}
          data-toggle="collapse"
          onClick={e => this.toggleActive()}
          style={{ width: "100%"}}
        >
          <Div inline="between">
            <Div inline="center">
              <I className={classIconLeft} sizeIcon={sizeIcon} icon={iconLeft} padding="1" />
              <Span className="cursor-pointer" value={value}/>
            </Div>
            <I sizeIcon={sizeIcon} icon={iconRight} paddingRight="3" />
          </Div>
        </li>
      </Link>
    );
  }
}

NavbarItemMenu.defaultProps = {
  colorText: Color.NODE.WHITE,
  sizeIcon: "1"
};

export default NavbarItemMenu;
