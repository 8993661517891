import React from "react";
import { Div, Label } from "../..";
import { Color } from "../../../utilities";
import If from "../../if/If";

const colors = ["#31CDFF", "#05EB00", "#FEB3A0", "#7a5d55"];

const getStyleTimeLine = (color) => {
  return {
    width: `100%`,
    borderBottom: `8px solid ${color}`,
  };
};

const getElementsWithColor = (elements) => {
  let elementos = [];
  Array.from(elements).forEach((e, index) => {
    elementos.push({
      ...e,
      color: colors[index],
    });
  });

  return elementos;
};

const TimeLinePedidoLojaWeb = ({ elements }) => {
  let elementos = getElementsWithColor(elements);
  let width = (Number(100) / Number(elementos.length)).toFixed(2);

  return (
    <Div inline="start">
      {elementos.map((elemento, index) => {
        let isLast = index === elementos.length - 1;
        let isFirst = index === 0;

        let styleFirst = isFirst
          ? { borderBottomLeftRadius: 5, borderTopLeftRadius: 5 }
          : {};
        let styleLast = isLast
          ? { borderBottomRightRadius: 5, borderTopRightRadius: 5 }
          : {};

        let colorLine = elemento.active ? elemento.color : "#F2F2F2";
        let colorCircle = elemento.active ? elemento.color : "#C4C4C4";

        return (
          <Div style={{ width: `${width}%` }} key={index} inline="start">
            <Div
              style={{ width: "100%" }}
              className="d-flex flex-column align-items-start"
            >
              <Div
                style={{
                  ...getStyleTimeLine(colorLine),
                  ...styleFirst,
                  ...styleLast,
                }}
              ></Div>
              <If
                and
                value1={index < elementos.length - 1}
                value2={!elemento.active}
              >
                <Label
                  value=""
                  style={{
                    borderRadius: "50%",
                    border: `4px solid ${colorCircle}`,
                    position: "absolute",
                    left: `${(index + 1) * width}%`,
                  }}
                />
              </If>
              <Label
                marginTop="2"
                family="Bold"
                style={{ color: "#878787", width: "100%", textAlign: "center" }}
                className="text-ellipsis"
              >
                {elemento.elemento}
              </Label>
            </Div>
          </Div>
        );
      })}
    </Div>
  );
};

export default TimeLinePedidoLojaWeb;
