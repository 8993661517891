import React, { Component } from "react";

import {
  Div,
  Label,
  If,
  InputDefault,
  Image,
  I,
  ModalNotification,
  NavbarTab,
  Row,
  Input,
} from "../../../../components";
import { Color, Http, Mask, Type, Utils } from "../../../../utilities";
import EmpresaUtils from "../../../utils/EmpresaUtils";
import UsuarioUtils from "../../../utils/UsuarioUtils";
import ListaCompraUtils from "./ListaCompraUtils";
import "./listaCompra.css";

const styles = {
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
  },
  lista: {
    height: 50,
    border: `1px solid ${Color.ECOMMERCE.CINZA}`,
    margin: 5,
    color: Color.ECOMMERCE.CINZA,
    width: "100%",
  },
  menuDrop: {
    width: "100%",
  },
  menuDropAtivo: {
    backgroundColor: "var(--cor-primaria)",
    color: "#fff",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "16px",
    paddingLeft: "5px",
    marginBottom: "10px",
  },
  labelTitulo: {
    color: "#878787",
    fontSize: "18px",
    paddingLeft: "5px",
    marginBottom: "10px",
    fontWeight: "bolder"
  },

};

const TAB_MINHAS_LISTAS = "MINHAS_LISTAS";

class AdicionarProdutosCarrinhoLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: {
        target: TAB_MINHAS_LISTAS,
        value: "Minhas listas",
        active: true,
      },
      nomeNovaLista: "",
      listaSelecionada: {},
      listas: [],
      listasPesquisa: [],
      mensagem: "",
      produtos: JSON.parse(JSON.stringify(props.produtos)),
      assinaturaLista: "Assinar lista",
      assinaturaAtiva: false,
      isNovaLista: false,
      isCriarLista: true,
      pesquisa: "",

    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    let produtos = JSON.parse(JSON.stringify(props.produtos));

    this.setState({
      produtos,
      listaSelecionada: {},
    });
  }

  componentDidMount() {
    this.setState({
      listas: this.props.listas,
      listasPesquisa: this.props.listas
    });
  }

  getItensNovaLista(idListaCompra) {
    let itens = [];
    let produtos = Array.from(this.state.produtos);
    produtos.forEach((produto) => {
      itens.push({
        idListaCompraProduto: Utils.uuidv4(),
        idListaCompra: idListaCompra,
        idProdutoWeb: produto.idProdutoWeb,
        preco: produto.precoVenda,
        quantidade: produto.possuiQuantidadeFracionada
          ? Number(produto.quantidade) / Number(1000)
          : Number(produto.quantidade),
        ativo: true,
        ordem: 1,
      });
    });
    return itens;
  }

  cadastrarNovaListaCompra() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    let idListaCompra = Utils.uuidv4();

    let listaParam = {
      idListaCompra: idListaCompra,
      tipo: "CLIENTE",
      descricao: this.state.nomeNovaLista,
      idCliente: cliente.idCliente,
      inicioVigencia: new Date().getTime(),
      fimVigencia: new Date().getTime(),
      ativo: true,
      ordem: 1,
      preco: 0,
      precoOferta: 0,
      itens: this.getItensNovaLista(idListaCompra),
    };

    Http.post("/listacompra", listaParam)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.setState({ mensagem: "" }, () =>
              ModalNotification.renderMessage({
                title: "Mensagem",
                body: "Produtos da compra adicionado à lista com sucesso!",
                handlerConfirm: () => ModalNotification.removeModal(),
                labelButtonConfirm: "Fechar",
              })
            );
          } else {
            this.setState({ mensagem: data.mensagem });
          }
        } else {
          console.log("Falha ao cadastrar lista de compra");
        }
      })
      .catch((erro) => console.log(erro));
  }

  getListaCompraAtualizacao() {
    let { listaSelecionada, produtos } = this.state;
    let itensLista = Array.from(listaSelecionada.itens);

    Array.from(produtos).forEach((produto) => {
      let itemNaLista = itensLista.filter(
        (i) => i.codigoBarras === produto.codigoBarras
      )[0];

      if (Utils.isValueValid(itemNaLista)) {
        itemNaLista.quantidade = produto.possuiQuantidadeFracionada
          ? Number(produto.quantidade) / Number(1000)
          : Number(produto.quantidade)
      } else {
        itensLista.push({
          idListaCompraProduto: Utils.uuidv4(),
          idListaCompra: listaSelecionada.idListaCompra,
          idProdutoWeb: produto.idProdutoWeb,
          preco: produto.precoVenda,
          quantidade: produto.possuiQuantidadeFracionada
            ? Number(produto.quantidade) / Number(1000)
            : Number(produto.quantidade),
          ordem: 1,
        });
      }
    });

    listaSelecionada.itens = itensLista;

    return listaSelecionada;
  }

  checkedMenuActive(assinatura) {
    const { assinaturaAtiva, assinaturaLista } = this.state;

    return assinatura === assinaturaLista && assinaturaAtiva ? styles.menuDropAtivo : styles.menuDrop
  }

  iconCheckedMenuActive(assinatura) {
    const { assinaturaAtiva, assinaturaLista } = this.state;

    return assinatura === assinaturaLista && assinaturaAtiva ? "#FFF" : "var(--cor-primaria)"
  }

  atualizarListaCompra() {
    Http.put("/listacompra", this.getListaCompraAtualizacao())
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            ModalNotification.renderMessage({
              title: "Mensagem",
              body: "Produtos da compra adicionado à lista com sucesso!",
              handlerConfirm: () => ModalNotification.removeModal(),
              labelButtonConfirm: "Fechar",
            });
          } else {
            ModalNotification.renderMessage({
              title: "Falha ao atualizar lista de compras",
              body: <Label value={data.mensagem} />,
              handlerCancel: () => { },
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    let { nomeNovaLista, mensagem, listaSelecionada, tab } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.props.empresa);
    return (
      <Div style={{ width: "100%" }}>
        <If and value1={Utils.isValueValid(this.props.listas) || !this.state.isCriarLista}>
          <If and value1={Utils.isValueValid(mensagem)}>
            <Label
              value={mensagem}
              style={{ fontSize: 20, color: corPrimaria, width: "100%" }}
              family="Light"
            />
          </If>
          <Row style={{ padding: "5px" }}>
            <Div
              className="rounded"
              style={{
                padding: "10px",
                background: Color.EMPRESA.PRIMARIA,
                color: "#FFF",
                fontWeight: "bolder",
                textAlign: "center",
                width: "200px",
                marginBottom: "20px"
              }}
              pointer
              handlerClick={() => this.setState({ isNovaLista: !this.state.isNovaLista })}
            >
              <I
                icon={Type.ICON.PLUS_SQUARE}
                pointer
                style={{ fontSize: 25, color: "#FFF" }}
              />
              <Label
                family="Light"
                value="Criar Nova Lista"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div>
            <Div
              style={{
                margin: "16px 20px",
                width: "300px"
              }}
            >
              <I
                pointer
                handlerClick={(e) => { }}
                icon={Type.ICON.SEARCH}
                width="25px"
                style={{
                  position: "absolute",
                  color: Color.EMPRESA.PRIMARIA,
                  margin: "2px 0px 0px 3px",
                  fontSize: "20px",
                  transform: `rotate(90deg)`,
                }}
              />
              <Input
                className="input-search-lojainstantanea pl-5"
                placeholder="Pesquisar Lista de compras"
                style={{
                  fontFamily: "MetropolisLight",
                  borderBottom: `1px solid ${Color.EMPRESA.PRIMARIA}`,
                  paddingLeft: "44px !important",
                  paddingBottom: "4px",
                }}
                handlerChange={(e) => {
                  let { listas, listasPesquisa } = this.state;
                  let search = e.target.value
                  let newLista = []

                  if (search !== "") {
                    newLista = listas.filter(item =>
                      (item.descricao !== null && item.descricao.toLowerCase().indexOf(search.toLowerCase()) > -1)
                    )
                    this.setState({
                      listas: newLista,
                      pesquisa: search
                    })
                  } else if (search === "") {
                    this.setState({
                      listas: listasPesquisa,
                      pesquisa: search
                    })
                  }
                }}
                value={this.state.pesquisa}
              />
            </Div>
          </Row>

          <If and value1={this.state.isNovaLista || !this.state.isCriarLista}>
            <Div shadow className="border" marginBottom="3" rounded padding="2" margin="2">
              <InputDefault
                responsive="12"
                handlerChange={(e) => {
                  this.setState({
                    nomeNovaLista: e.target.value,
                    mensagem: "",
                  });
                }}
                label="Nome da lista"
                value={nomeNovaLista}
              />
            </Div>
            <Div inline="between">
              <Label
                handlerClick={
                  (e) => {
                    if (!this.state.isCriarLista) {
                      ModalNotification.removeModal()
                    } else {
                      this.setState({ isNovaLista: false })
                    }

                  }
                }
                family="SemiBold"
                style={{
                  backgroundColor: Color.ECOMMERCE.CINZA,
                  color: "white",
                  width: "50%",
                  padding: 10,
                  textAlign: "center",
                }}
                className="d-flex justify-content-center align-items-center"
                value={!this.state.isCriarLista ? "Fechar" : "Cancelar"}
                pointer
              />
              <Label
                handlerClick={() => {
                  if (Utils.isValueValid(nomeNovaLista)) {
                    this.cadastrarNovaListaCompra();
                  } else {
                    this.setState({ mensagem: "O nome da lista é obrigatório" });
                  }
                }}
                family="SemiBold"
                style={{
                  backgroundColor: corPrimaria,
                  color: "white",
                  padding: 10,
                  width: "50%",
                }}
                className="d-flex justify-content-center align-items-center"
                value="Confirmar"
                pointer
              />
            </Div>
          </If>

          <If and value1={!this.state.isNovaLista && Utils.isValueValid(this.props.listas)}>
            <Label
              value="Os produtos da compra serão adicionados na lista selecionada"
              family="Light"
              style={styles.labelTitulo}
            />
            <Label
              value="Selecione uma lista para adicionar o produto: "
              style={styles.labelCinza}
              family="Light"
            />

            <Div
              className="flex-column"
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                paddingRight: "10px",
                width: "100%",
                maxHeight: "220px"
              }}
            >
              {this.state.listas && Array.from(this.state.listas).map((lista, index) => {
                return (
                  <Div
                    pointer
                    key={index}
                    style={
                      listaSelecionada.idListaCompra === lista.idListaCompra
                        ? {
                          ...styles.lista,
                          backgroundColor: corPrimaria,
                          color: "white",
                          opacity: 0.9,
                        }
                        : styles.lista
                    }
                    rounded
                    inline="start"
                    handlerClick={() =>
                      this.setState({
                        listaSelecionada: lista,
                      })
                    }
                  >
                    <Label
                      pointer
                      value={lista.descricao}
                      marginLeft="4"
                      marginTop="2"
                      family="SemiBold"
                    />
                  </Div>
                );
              })}

            </Div>
            <Div inline="between" marginTop="5">
              <Label
                handlerClick={(e) => ModalNotification.removeModal()}
                family="SemiBold"
                style={{
                  backgroundColor: Color.ECOMMERCE.CINZA,
                  color: "white",
                  width: "50%",
                  padding: 10,
                  textAlign: "center",
                }}
                className="d-flex justify-content-center align-items-center"
                value="Fechar"
                pointer
              />
              <Label
                handlerClick={() => {
                  if (Utils.isValueValid(listaSelecionada)) {
                    this.atualizarListaCompra();
                  } else {
                    this.setState({ mensagem: "Nenhuma lista foi selecionada" });
                  }
                }}
                family="SemiBold"
                style={{
                  backgroundColor: corPrimaria,
                  color: "white",
                  padding: 10,
                  width: "50%",
                }}
                className="d-flex justify-content-center align-items-center"
                value="Confirmar"
                pointer
              />
            </Div>
          </If>
        </If>
        <If and value1={!Utils.isValueValid(this.props.listas) && this.state.isCriarLista}>
          <Div column inline="center" col="12">
            <Div
              className="rounded"
              style={{
                padding: "10px",
                marginTop: 20,
                border: `1px solid ${Color.EMPRESA.PRIMARIA}`,
                background: "#FFF",
                color: Color.EMPRESA.PRIMARIA,
                fontWeight: "bolder",
                textAlign: "center",
                fontSize: "18px",
                width: "400px"
              }}
              pointer
              handlerClick={() => this.setState({ isCriarLista: false })}
            >
              <I
                icon={Type.ICON.PLUS_SQUARE}
                pointer
                style={{ fontSize: 25, color: Color.EMPRESA.PRIMARIA, }}
              />
              <Label
                family="Light"
                value="Criar Uma nova Lista de Compra"
                pointer
                marginTop="2"
                marginLeft="2"
              />
            </Div>
            <Div>
              <Label
                handlerClick={
                  (e) => {
                    ModalNotification.removeModal()
                  }
                }
                family="SemiBold"
                style={{
                  backgroundColor: Color.ECOMMERCE.CINZA,
                  color: "white",
                  width: "200px",
                  padding: 10,
                  marginTop: "43px",
                  borderRadius: "3px",
                  textAlign: "center",
                }}
                className="d-flex justify-content-center align-items-center"
                value="Fechar"
                pointer
              />
            </Div>
          </Div>
        </If>
      </Div>
    );
  }
}

export default AdicionarProdutosCarrinhoLista;
