import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import App from "../App";
import ItensCarrinho from "../views/pagamento/ItensCarrinho";
import ProdutoDetalhe from "../views/produtos/web/ProdutoDetalhe";
import {
  DadosPessoaisCliente,
  DadosPessoaisClienteMobile,
  ErrorApplication,
  FinalizacaoPedido,
  MeusPedidos,
  MeusPedidosMobile,
  PedidoDetalheCliente,
  PedidoDetalheClienteMobile,
  ProdutosPesquisa,
  ProdutosPorAnuncioOferta,
  ProdutosPorDepartamento,
  ResetarSenha,
  ListaCompraCliente,
  ListaCompraClienteMobile,
  PrincipalColorauMobile,
  PrincipalColorauWeb,
  PrincipalMobile,
  Principal,
  EmpresaUtils,
  LoginAdmin,
  Dashboard,
  InformacoesGerais,
  ConfigColors,
  ListarProdutosCadastrados,
  ConcorrenteCadastrar,
  ConcorrenteListar,
  HorarioEntregas,
  HorarioRetiradas,
  HorarioTaxas,
  AssinaturasListar,
  AssinaturasEditar,
  PrincipalSaccaria,
  PrincipalSaccariaMobile
} from "../views";
import {
  ListaCompraDashboard,
  ListaCompraListar
} from "../view_dashboard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { atualizarCarrinho, setProdutos, setEmpresa } from "../redux/actions";
import CadastroCliente from "../views/cliente/CadastroCliente";
import CadastrarClienteMobile from "../views/cliente/mobile/CadastrarClienteMobile";
import { Http, Keys, Utils } from "../utilities";
import ItensCarrinhoMobile from "../views/pagamento/mobile/ItensCarrinhoMobile";
import FinalizacaoPedidoMobile from "../views/pedido/FinalizacaoPedidoMobile";
import ProdutosPesquisaMobile from "../views/produtos/ProdutosPesquisaMobile";
import ProdutosPorDepartamentoMobile from "../views/produtos/mobile/ProdutosPorDepartamentoMobile";
import ProdutosPorAnuncioMobile from "../views/produtos/mobile/ProdutosPorAnuncioMobile";
import CadastroAnuncio from "../views/cadastro/CadastroAnuncio";
import AppMobile from "../AppMobile";
import PagamentoProcessado from "../views/pagamento/PagamentoProcessado";
import Aguarde from "../views/Aguarde";
import AguardeMobile from "../views/AguardeMobile";
import ErrorPaginaPrincipalNaoEncontrada from "../views/error/ErrorPaginaPrincipalNaoEncontrada";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

const initMap = () => {
  let map = new Map();
  map.set(
    Keys.VIEW_PRINCIPAL_COLORAU,
    Utils.isViewMobile() ? PrincipalColorauMobile : PrincipalColorauWeb
  );
  map.set(
    Keys.VIEW_PRINCIPAL,
    Utils.isViewMobile() ? PrincipalMobile : Principal
  );

  map.set(
    Keys.VIEW_PRINCIPAL_SACCARIA,
    Utils.isViewMobile() ? PrincipalSaccariaMobile : PrincipalSaccaria
  );

  return map;
};

class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: {},
      views: initMap(),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    Utils.scrollTop();
  }

  getRoute(path, component) {
    return (
      <Route
        path={path}
        render={(props) => {
          return Utils.isViewMobile() ? (
            <AppMobile {...props}>{component} </AppMobile>
          ) : (
            <App {...props}>{component}</App>
          );
        }}
      />
    );
  }

  consultarEmpresa() {
    Http.get("empresa")
      .then(({ status, data }) => {
        if (status) {
          this.props.setEmpresa(data);
        }
      })
      .catch((erro) => console.log(erro));
  }

  componentDidMount() {
    this.consultarEmpresa();
  }

  getPrincipal() {
    if (Utils.isValueValid(this.props.empresa)) {
      let paginaPrincipal = EmpresaUtils.getPaginaPrincipal(this.props.empresa);

      let tituloPagina = EmpresaUtils.getTituloPagina(this.props.empresa)

      if(tituloPagina === "Empório Saccaria") {
        paginaPrincipal = Keys.VIEW_PRINCIPAL_SACCARIA
      }

      if (Utils.isValueValid(paginaPrincipal)) {
        return React.createElement(this.state.views.get(paginaPrincipal), {
          showRodape: true,
          showHeader: true,
          ...this.props,
        });
      } else {
        return (
          <ErrorPaginaPrincipalNaoEncontrada
            {...this.props}
            showHeader={false}
            showRodape={false}
          />
        );
      }

      //return  <Principal showHeader={true} showRodape={true} {...this.props} />;
    } else {
      return Utils.isViewMobile() ? <AguardeMobile /> : <Aguarde />;
    }
  }


  render() {
    return (
      <Switch>

        <Redirect from="/admin/horarios" exact to="/admin/horarios/entregas" />
        <Redirect from="/admin/personalizacao" exact to="/admin/personalizacao/geral" />
        <Redirect from="/admin/pesquisas" exact to="/admin/pesquisas/listar" />
        <Redirect from="/admin" exact to="/admin/dashboard" />

        <PublicRoute restricted={true} component={LoginAdmin} path="/admin/login" exact />

        <PrivateRoute Component={Dashboard} path="/admin/dashboard" exact />

        <PrivateRoute Component={HorarioEntregas} path="/admin/horarios/entregas" exact />
        <PrivateRoute Component={HorarioRetiradas} path="/admin/horarios/retiradas" exact />
        <PrivateRoute Component={HorarioTaxas} path="/admin/horarios/taxas" exact />

        <PrivateRoute Component={InformacoesGerais} path="/admin/personalizacao/geral" exact />
        <PrivateRoute Component={ConfigColors} path="/admin/personalizacao/cores" exact />

        <PrivateRoute Component={ListarProdutosCadastrados} path="/admin/pesquisas/listar" exact history={this.props.history} />
        <PrivateRoute Component={ConcorrenteListar} path="/admin/pesquisas/concorrentes" exact />
        <PrivateRoute Component={ConcorrenteCadastrar} path="/admin/pesquisas/concorrentes/cadastrar" exact />

        <PrivateRoute Component={AssinaturasListar} path="/admin/assinaturas" exact />
        <PrivateRoute Component={AssinaturasEditar} path="/admin/assinaturas/editar" exact />

        {/* <PrivateRoute Component={ListaCompraListar} path="/admin/listacompras/" exact />
        <PrivateRoute Component={ListaCompraDashboard} path="/admin/listacompras/editar" exact /> */}

        {this.getRoute(
          "/anuncio/cadastro/admin",
          <CadastroAnuncio
            {...this.props}
            showHeader={true}
            showRodape={true}
          />
        )}
        {this.getRoute(
          "/carrinho",
          Utils.isViewMobile() ? (
            <ItensCarrinhoMobile
              {...this.props}
              showRodape={false}
              showHeader={false}
            />
          ) : (
            <ItensCarrinho
              {...this.props}
              showRodape={false}
              showHeader={false}
            />
          )
        )}
        {this.getRoute(
          "/anuncio/produtos",
          Utils.isViewMobile() ? (
            <ProdutosPorAnuncioMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorAnuncioOferta
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/produto/departamento/:descricao",
          Utils.isViewMobile() ? (
            <ProdutosPorDepartamentoMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorDepartamento
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/produto/secao/:descricao",
          Utils.isViewMobile() ? (
            <ProdutosPorDepartamentoMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorDepartamento
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/produto/marca/:descricao",
          Utils.isViewMobile() ? (
            <ProdutosPorDepartamentoMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPorDepartamento
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/produto/ean/:codigobarras",
          Utils.isViewMobile() ? (
            <ProdutosPesquisaMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPesquisa
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        
        {this.getRoute(
          "/produto/:codigoBarras",
          <ProdutoDetalhe {...this.props} showHeader={true} showRodape={true} />
        )}

        {this.getRoute(
          "/pedido/cielo/confirmarpagamento/debito",
          <PagamentoProcessado
            {...this.props}
            showHeader={false}
            showRodape={false}
          />
        )}

        {this.getRoute(
          "/resultadopesquisa",
          Utils.isViewMobile() ? (
            <ProdutosPesquisaMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ProdutosPesquisa
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}

        
        {this.getRoute(
          "/finalizacao",
          Utils.isViewMobile() ? (
            <FinalizacaoPedidoMobile
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <FinalizacaoPedido
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/cadastrarcliente",
          Utils.isViewMobile() ? (
            <CadastrarClienteMobile
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <CadastroCliente
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/cliente/pedidos/detalhe",
          Utils.isViewMobile() ? (
            <PedidoDetalheClienteMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <PedidoDetalheCliente
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/cliente/pedidos",
          Utils.isViewMobile() ? (
            <MeusPedidosMobile
              showHeader={true}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <MeusPedidos showHeader={true} showRodape={false} {...this.props} />
          )
        )}
        {this.getRoute(
          "/cliente/listascompras",
          Utils.isViewMobile() ? (
            <ListaCompraClienteMobile
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <ListaCompraCliente
              showHeader={true}
              showRodape={false}
              showDepart={true}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          "/cliente",
          Utils.isViewMobile() ? (
            <DadosPessoaisClienteMobile
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          ) : (
            <DadosPessoaisCliente
              showHeader={false}
              showRodape={false}
              {...this.props}
            />
          )
        )}
        {this.getRoute(
          ["/resetarsenha", "/resetarsenha/:id"],
          <ResetarSenha showHeader={false} showRodape={false} {...this.props} />
        )}
        {this.getRoute("/", this.getPrincipal())}
        {this.getRoute("/*", this.getPrincipal())}
        {this.getRoute("/error", <ErrorApplication {...this.props} />)}
      </Switch>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.produtosState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setProdutos, setEmpresa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
