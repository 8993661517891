import React, { Component } from "react";
import {
  CarouselAnuncioOfertaDestaque,
  CarouselAnuncioOfertaVerticalMicro,
  Div,
  If,
  Image,
  Label,
  Row,
} from "../../components";

import "./anuncio.css";
import { Color, Mask, Utils } from "../../utilities";

const WIDTH_IMAGE = 540;
const HEIGHT_IMAGE = 490;

const outrasOfertasItens = (anunciosVerticalMicro) => {
  let anuncios = Array.from(anunciosVerticalMicro).filter(
    (anuncio) => anuncio.tipo === "VERTICAL" || anuncio.tipo === "MICRO"
  );
  let itens = [];

  anuncios.forEach(
    (
      {
        titulo,
        preco,
        urlFoto,
        idAnuncioOferta,
        marcaFiltro,
        tipo,
        tipoDepartamentoSecao,
        idDepartamentoSecaoFiltro,
        precoOferta,
        possuiProdutos,
        ordem,
        exibirTitulo
      },
      index
    ) => {
      itens.push({
        titulo,
        tipo,
        precoOferta: Mask.formatValueBr(precoOferta),
        preco: Mask.formatValueBr(preco),
        image: urlFoto,
        idAnuncioOferta: idAnuncioOferta,
        marcaFiltro,
        tipoDepartamentoSecao,
        idDepartamentoSecaoFiltro,
        possuiProdutos,
        ordem,
        exibirTitulo,
        active: index === 0 ? true : false,
      });
    }
  );

  return itens;
};

class AnuncioOfertas extends Component {
  constructor(props) {
    super(props);

    this.setState({
      empresa: props.empresa,
    });
  }

  labelsBanners(anuncio) {
    let titulo = ""
    let preco = ""

    if(Utils.isNotObjectEmpty(anuncio)) {
      titulo = anuncio.titulo
      preco = anuncio.preco
    }

    return (
      <>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "100%",
          height: "100%",
          borderRadius: "11px",
          zIndex: 996,
          padding: "20px 32px",
          fontWeight: "bolder",
          background: "#000",
          opacity: "0.4"
        }}
      ></Div>
      <Div
        style={{
          position: "absolute",
          color: "#FFF",
          width: "100%",
          height: "100%",
          borderRadius: "11px",
          zIndex: 997,
          padding: "20px 32px",
          fontWeight: "bolder",

        }}
      >
        <Row>
          <Label style={{ fontSize: "26px" }} value={titulo} />
        </Row>

        <If and value1={preco}>
          <Label 
            style={{ 
              fontSize: "26px", 
              color: Color.EMPRESA.PRIMARIA 
            }}
            
            value={preco ? "R$ " + Mask.convertNumberBr(preco) : ""} 
          />
        </If>

      </Div>
      </>
    )
  }

  visualizarProdutosAnuncio(anuncio) {
    if (!anuncio.estatico) {
      this.props.history.push("/anuncio/produtos", {
        ...anuncio,
        empresa: this.props.empresa,
      });
    }
  }

  ofertasEmDestaque = (anunciosDestaque) => {
    let anuncios = Array.from(anunciosDestaque).filter(
      (anuncio) => anuncio.tipo === "DESTAQUE"
    );
    let itens = [];

    anuncios.forEach((anuncio) => {
      itens.push(
        <>
          <If and value1={anuncio.exibirTitulo}>
            {this.labelsBanners(anuncio)}
          </If>
          <Image
            src={Utils.isViewMobile() ? anuncio.urlFotoMobile : anuncio.urlFoto}
            width={`${WIDTH_IMAGE}px`}
            height={`${HEIGHT_IMAGE}px`}
            handlerClick={() => this.visualizarProdutosAnuncio(anuncio)}
            styleImage={{ borderRadius: 10 }}
          />
        </>
      );
    });

    return itens;
  };

  render() {  
    return (
      <Div inline="center" pointer>
        <CarouselAnuncioOfertaDestaque
          itens={this.ofertasEmDestaque(this.props.anuncios)}
          history={this.props.history}
          widthContainerBanner={WIDTH_IMAGE}
        />
        <CarouselAnuncioOfertaVerticalMicro
          history={this.props.history}
          empresa={this.props.empresa}
          itens={outrasOfertasItens(this.props.anuncios)}
        />
      </Div>
    );
  }
}

export default AnuncioOfertas;
