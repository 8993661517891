import React, { Component } from "react";
import { Div, I, If, Image, Label } from "../../../components";
import { Color, Mask, Type, Utils } from "../../../utilities";

const styles = {
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerAddItem: {
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    lineHeight: "10px",
    width: 100,
    fontSize: 13,
  },
  labelSubTotalItem: {
    fontSize: 15,
    color: Color.ECOMMERCE.LARANJA,
    marginTop: 10,
  },
  labelTitleObs: {
    fontSize: 15,
    color: Color.ECOMMERCE.CINZA,
  },
  containterObs: {
    backgroundColor: "#f2f2f2",
    padding: 5,
    borderRadius: 5,
    width: "100%",
  },
  containterCliente: {
    border: "1px solid #f2f2f2",
    width: "100%",
  },
};

class TemplateItensPedidoDetalheMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarTodos: props.mostrarTodos || false,
      pedido: props.pedido,
    };
  }

  setAlteradoNaSeparacao(pedido) {
    pedido.alteradoNaSeparacao =
      Number(pedido.valorTotal).toFixed(2) !==
      Number(pedido.valorTotalSeparacao).toFixed(2);
  }

  UNSAFE_componentWillReceiveProps(props) {
    let pedido = props.pedido;
    this.setAlteradoNaSeparacao(pedido);
    this.setState({ pedido, parametros: props.parametros });
  }

  getProdutosPedido = () => {
    let itensFiltrados = [];
    if (Utils.isValueValid(this.state.pedido)) {
      const { itens } = this.state.pedido;

      if (Utils.isValueValid(itens)) {
        itensFiltrados = itens;
      }
    }

    return itensFiltrados;
  };

  getValorItem(produto) {
    return produto.indisponivel
      ? 0
      : produto.valorTotalSeparacao || produto.valorTotal || 0;
  }

  getQuantidade(produto) {
    return produto.quantidadeSeparacao || produto.quantidade || 0;
  }

  renderProdutosLista = (produtos) => {
    let idParent = Utils.uuidString();
    return (
      <Div className="w-100" id={idParent}>
        {Array.from(produtos).map((prod, index) => {
          prod.indisponivel = prod.posicao === "INDISPONIVEL";
          return (
            <Div key={index}>
              <Div
                inline="between"
                key={index}
                style={{ borderBottom: "1px solid #f2f2f2", padding: 5 }}
              >
                <Div inline="between" className="w-100">
                  <Div inline="center">
                    <Image
                      border
                      src={prod.urlImagemPrincipalThumbnail}
                      width="50px"
                    />
                    <Label
                      value={prod.descricao}
                      marginLeft="2"
                      family="Light"
                      style={{
                        whiteSspace: "normal",
                        fontSize: 13,
                        width: Utils.isViewTabletAll() ? 250 : 120,
                      }}
                    />
                  </Div>
                  <If and value1={prod.indisponivel}>
                    <Label
                      value="CANCELADO"
                      family="Light"
                      style={{
                        backgroundColor: Color.ECOMMERCE.LARANJA_CLARO,
                        color: "white",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    />
                  </If>
                  <If and value1={!prod.indisponivel}>
                    <Div>
                      <Div inline="center" style={styles.containerAddItem}>
                        <Label
                          value={`${this.getQuantidade(prod)}x ${Number(
                            prod.precoUnitario
                          ).toLocaleString()}`}
                          family="Light"
                          margin="2"
                        />
                        <Label
                          value={Mask.formatValueBr(this.getValorItem(prod))}
                          family="SemiBold"
                          style={styles.labelSubTotalItem}
                        />
                      </Div>
                    </Div>
                  </If>
                </Div>
                <If and value1={Utils.isValueValid(prod.obs)}>
                  <Label value={`Obs: ${prod.obs}`} />
                </If>
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  };

  renderDadosPessoaisCliente() {
    let pedido = this.state.pedido;
    let cliente = {};
    if (Utils.isValueValid(pedido)) cliente = pedido.cliente;

    return (
      <Div style={styles.containterCliente} inline="center">
        <Div
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Label
            value="Dados pessoais"
            family="SemiBold"
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 15,
              marginLeft: 10,
              marginTop: 10,
            }}
          />
          <Div className="w-100">
            <Label
              value={`Nome: ${cliente.nome}`}
              family="Bold"
              style={{ color: "#c4c4c4", marginLeft: 10, fontSize: 13 }}
            />
            <Label
              value={`CPF/CNPJ: ${Mask.getValueMaskCpfCnpj(cliente.cpfcnpj)}`}
              family="Bold"
              style={{ color: "#c4c4c4", fontSize: 13, marginLeft: 10 }}
            />
            <Label
              value={`Telefone: ${Mask.getValueMaskPhone(cliente.telefone)}`}
              family="Bold"
              style={{ color: "#c4c4c4", fontSize: 13, marginLeft: 10 }}
            />
            <Label
              value={`Email: ${cliente.email}`}
              family="Bold"
              style={{ color: "#c4c4c4", fontSize: 13, marginLeft: 10 }}
            />
          </Div>
        </Div>
        <Div
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Label
            value="Endereço"
            family="SemiBold"
            style={{
              color: Color.ECOMMERCE.CINZA,
              fontSize: 15,
              marginLeft: 10,
            }}
          />
          <Div>
            <Div col="12">
              <Label
                value={`Logradouro: ${cliente.logradouro} n° ${cliente.numero}, ${cliente.bairro} - ${cliente.nomeCidade}`}
                family="Bold"
                style={{ color: "#c4c4c4", fontSize: 13 }}
              />
              <Label
                marginLeft="2"
                value={Mask.getValueMaskCep(cliente.cep)}
                family="Bold"
                style={{ color: "#c4c4c4", fontSize: 13 }}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  renderDadosPagamento() {
    let pedido = this.state.pedido;
    let recebimento = {};
    if (Utils.isValueValid(pedido)) recebimento = pedido.recebimento;

    return (
      <Div style={styles.containterCliente} inline="center">
        <Div
          responsive="12"
          className="d-flex flex-column align-items-start justify-content-center align-self-start"
          style={{ border: "1px solid #f2f2f2", height: 150 }}
        >
          <Div className="w-100">
            <Div className="w-100" inline="between">
              <Label
                value={`Tipo: ${recebimento.tipo}`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10, fontSize: 13 }}
              />
              <If
                and
                value1={
                  recebimento.tipo === "CREDITO" ||
                  recebimento.tipo === "DEBITO"
                }
              >
                <Label
                  value={`Bandeira: ${recebimento.bandeiraCartao}`}
                  family="Bold"
                  style={{ color: "#c4c4c4", marginLeft: 10, fontSize: 13 }}
                />
              </If>
            </Div>
            <Div inline="between" className="w-100">
              <Label
                value={`Status: ${
                  recebimento.pedigoPago ? "CONFIRMADO" : "AGUARDANDO PAGAMENTO"
                }`}
                family="Bold"
                style={{ color: "#c4c4c4", marginLeft: 10, fontSize: 13 }}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }

  render() {
    let itens = this.getProdutosPedido();
    let pedido = this.state.pedido;

    let idDadosCliente = Utils.uuidString();
    let idDadosPagamento = Utils.uuidString();

    return (
      <>
        <Div
          className="d-flex flex-column align-self-start align-items-start justify-content-between"
          className="w-100"
          marginTop="2"
        >
          <If and value1={Utils.isValueValid(pedido.obs)}>
            <Div style={styles.containterObs}>
              <Label
                value="Observações:"
                style={styles.labelTitleObs}
                family="SemiBold"
              />
              <If and value1={!pedido.alteradoNaSeparacao}>
                <Label value={pedido.obs} family="Light" marginLeft="2" />
              </If>
              <If
                and
                value1={
                  pedido.alteradoNaSeparacao &&
                  pedido.recebimento.tipo === "CREDITO" &&
                  pedido.tipoPagamento === "ON_LINE"
                }
              >
                <Div className="d-flex flex-column" marginLeft="2">
                  <Label value={pedido.obs} family="Light" />
                  <Label
                    value={
                      "INFORMAÇÕES SOBRE O CARTÃO DE CRÉDITO: O prazo para o valor da compra mudar na fatura é de 2 dias."
                    }
                  />
                </Div>
              </If>
            </Div>
          </If>
          <Div
            marginTop="2"
            inline="between"
            className="w-100"
            style={styles.containterObs}
            pointer
            target={`#${idDadosCliente}`}
            toggle="collapse"
          >
            <Label
              value="Dados do cliente"
              style={styles.labelTitleObs}
              family="SemiBold"
              pointer
            />
            <I
              icon={Type.ICON.ARROW_CHEV_DOWN}
              style={{ color: Color.ECOMMERCE.CINZA }}
              pointer
            />
          </Div>
          <Div className="collapse w-100" id={idDadosCliente}>
            {this.renderDadosPessoaisCliente()}
          </Div>
          <Div
            marginTop="2"
            inline="between"
            className="w-100"
            style={styles.containterObs}
            pointer
            target={`#${idDadosPagamento}`}
            toggle="collapse"
          >
            <Label
              value="Dados do pagamento"
              style={styles.labelTitleObs}
              family="SemiBold"
              pointer
            />
            <I
              icon={Type.ICON.ARROW_CHEV_DOWN}
              style={{ color: Color.ECOMMERCE.CINZA }}
              pointer
            />
          </Div>
          <Div className="collapse w-100" id={idDadosPagamento}>
            {this.renderDadosPagamento()}
          </Div>

          <Div marginTop="2" className="w-100">
            <Label
              value="Produtos da compra:"
              style={styles.labelTitleObs}
              family="SemiBold"
            />
            <Div className="w-100">{this.renderProdutosLista(itens || [])}</Div>
          </Div>
        </Div>
      </>
    );
  }
}

export default TemplateItensPedidoDetalheMobile;
