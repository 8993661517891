import React, { Component } from "react";

import {
  Div,
  Row,
  Image,
  InputGroup,
  Button,
  Notification,
} from "../../components";

import { Color, Type, Auth, Utils } from "../../utilities";

import logo from "./../../assets/images/logo1.svg";
import { bindActionCreators } from "redux";
import { logar } from "../../redux/actions";
import { connect } from "react-redux";

import "./login.css";

class LoginAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      senha: "",
    };
  }

  handlerChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  login() {

    Auth.login(this.state).then(({ data, status }) => {
      if (data.status) {
        this.props.logar(true, data);
        sessionStorage.setItem("auth", JSON.stringify(data));
        this.props.history.push("/admin/dashboard");
      } else {
        Notification.danger(
          "Falha na autenticação! Usuário ou senha inválidos"
        );
      }
    })
      .catch((error) => {
        if (error.message === "Network Error") {
          Notification.danger("Falha na conexão com o servidor");
        }
      });
  }

  render() {
    return (
      <Row className="vertical-center login-mobioh">
        <Div responsive="6" offsetXl="3">
          <Div bg="dark" className="card mx-auto" col="8" center>
            <Div marginTop="4" inline="center">
              <Image src={logo} responsive></Image>
            </Div>
            <Div className="card-body">
              <InputGroup
                bgIcon={Color.NODE.BEGE}
                icon={Type.ICON.USER}
                colorIcon={Color.NODE.WHITE}
                name="login"
                placeholder="Login"
                handlerChange={this.handlerChange}
              />
              <InputGroup
                bgIcon={Color.NODE.BEGE}
                icon={Type.ICON.KEY}
                colorIcon={Color.NODE.WHITE}
                name="senha"
                type="password"
                placeholder="Senha"
                handlerChange={this.handlerChange}
              />
              <Button
                className="col"
                marginBottom="3"
                btn="bege"
                colorText={Color.NODE.WHITE}
                value="Entrar"
                handlerClick={(e) => this.login()}
              />
            </Div>
          </Div>
        </Div>
      </Row>
    );
  }
}

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  user: store.loginState.user,
  
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdmin);
