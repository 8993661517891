import React, { Component } from "react";
import {
  Checkbutton,
  Div,
  I,
  If,
  Image,
  InputDefault,
  Label,
  ModalNotification,
  Notification,
  Title,
  TooltipText,
} from "../../../components";
import {
  Color,
  EndPoints,
  Http,
  Mask,
  Routes,
  Type,
  Utils,
} from "../../../utilities";

import { ReactComponent as IconValor } from "../../../assets/images/icon-valor.svg";
import { ReactComponent as IconEntrega } from "../../../assets/images/icon-entrega.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { atualizarCarrinho, setEnderecoEntrega } from "../../../redux/actions";
import moment from "moment";

import PedidoLojaWeb from "../PedidoLojaWeb";
import RecebimentoPedidoLojaWeb from "../RecebimentoPedidoLojaWeb";
import TemplateItensCarrinhoMobile from "../TemplateItensCarrinhoMobile";
import TemplatePedidoEntregaMobile from "../TemplatePedidoEntregaMobile";
import TemplatePedidoRetiradaMobile from "../TemplatePedidoRetiradaMobile";
import TemplatePagamentoMobile from "../TemplatePagamentoMobile";
import UsuarioUtils from "../../utils/UsuarioUtils";
import TemplateEntregaRetiradaMobile from "./TemplateEntregaRetiradaMobile";
import ContatoWhatsappMobile from "../../ContatoWhatsappMobile";
import Zindex from "../../utils/Zindex";
import CarrinhoUtils from "../../utils/CarrinhoUtils";
import { EmpresaUtils, EntregaRetiradaUtils } from "../..";
import AdicionarProdutosCarrinhoListaMobile from "../../produtos/listascompras/mobile/AdicionarProdutosCarrinhoListaMobile";
import EmpresaService from "../../../service/EmpresaService";
import TagManagerUtils from "../../../utilities/utils/TagManagerUtils";

const styles = {
  labelResumoCompra: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    width: "100%",
    textAlign: "center",
  },
  labelBlue: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
  },
  labelRed: {
    color: "#EA4E25",
    fontSize: 16,
  },
  taxaEntregaGratis: {
    color: Color.EMPRESA.TERCIARIA,
    fontSize: 16,
    textDecoration: "line-through",
  },
  labelCinza: {
    color: "#878787",
    fontSize: "13px",
  },
  labelExcluirCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelCupom: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    fontSize: 15,
  },
  labelBorda: {
    color: Color.ECOMMERCE.MARRON_ESCURO,
    borderBottom: "1px dotted #E5E5E5",
  },
  labelAdicionarLista: {
    backgroundColor: Color.ECOMMERCE.MARRON_ESCURO,
    color: "white",
    borderRadius: 10,
    fontSize: 12,
  },
  labelLoja: {
    color: "#878787",
    fontSize: 13,
  },
  containerBotaoCancelar: {
    color: "#878787",
    padding: 10,
    margin: 10,
  },
  containerContinuar: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: "white",
    padding: 12,
  },
  containterMostrarTodos: {
    backgroundColor: "#F2F2F2",
    color: "#878787",
    padding: 15,
  },
  containerBottom: {
    position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerBottomMostrarTodos: {
    width: "100%",
  },
  containerLogoStockei: {
    padding: 15,
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  },
};

const ID_RESUMO_COLLAPSE = Utils.uuidString();

class ItensCarrinhoMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mostrarItensCarrinho: true,
      mostrarEntrega: false,
      mostrarPagamento: false,
      pedidoEntrega: true,
      pedidoRetirada: false,
      pedidoEntregaOutro: false,
      mostrarTodos: false,
      horariosEntrega: [],
      filialSelecionadaRetirada: {},
      erroContinarPagamento: "",
      pagamentoOnline: true,
      pedido: new PedidoLojaWeb(
        props.carrinho.itens,
        props.carrinho.cabecalho.valorTotal,
        props.carrinho.enderecoEntrega
      ),
      horarioRetirada: {},
      horarioEntrega: {},
      recebimentoPedido: new RecebimentoPedidoLojaWeb(),
      ...this.props.location.state,
      windowPagamentoDebito: null,
      novosParamentros: {
        entregaConsciente: false,
      },
    };

    this.handlerPedido = this.handlerPedido.bind(this);
    this.handlerRecebimento = this.handlerRecebimento.bind(this);

    Utils.scrollTop();
  }

  validarContinuarParaPagamento() {
    let erro = "";

    if (this.state.pedidoRetirada) {
      if (!Utils.isValueValid(this.state.filialSelecionadaRetirada)) {
        erro = "Selecione uma loja para realizar a retirada!";
      }

      if (!Utils.isValueValid(this.state.horarioRetirada)) {
        erro = "Selecione um horário para retirada dos produto na loja!";
      }
    }

    if (this.state.pedidoEntrega || this.state.pedidoEntregaOutro) {

      if (!this.state.novosParamentros.diasPrevisaoEntrega > 0) {
        if (!Utils.isValueValid(this.state.horarioEntrega)) {
          erro = "Selecione um horário para a entrega dos produtos!";
        }
      }

      if (!Utils.isValueValid(this.state.pedido.responsavelRecebimento)) {
        erro = "Informe o responsável pelo recebimento do pedido!";
      }

      if (!Utils.isValueValid(this.state.pedido.idClienteEndereco)) {
        erro = "Selecione um endereço para a entrega!";
      }
    }

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => { },
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  componentDidMount() {
    const { carrinho } = this.props;

    this.limparCupomDesconto(carrinho);

    if (carrinho.cabecalho.pedidoFinalizado) {
      Utils.refreshPage();
      this.props.history.replace("/");
    }

    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }

    this.getNovosParamentros();
  }

  getNovosParamentros() {
    EmpresaService.getEmpresaParams().then(({ status, data }) => {
      if (status) {
        this.setState({
          novosParamentros: data
        })
      }
    })
  }

  limparCupomDesconto(carrinho) {
    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.props.atualizarCarrinho(carrinho);
  }

  calcularValorTaxaEntrega() {
    let parametros = this.state.parametros;
    let carrinho = this.props.carrinho;
    let isEntrega = this.state.pedidoEntrega || this.state.pedidoEntregaOutro;

    carrinho.tipoEntregaRetirada = isEntrega
      ? EntregaRetiradaUtils.TIPO_ENTREGA
      : EntregaRetiradaUtils.TIPO_RETIRADA;

    CarrinhoUtils.calcularValorTaxaEntrega(
      parametros,
      carrinho,
      this.props.atualizarCarrinho
    );

    this.forceUpdate();
  }

  consultarParametrosPedido() {
    let cliente = UsuarioUtils.getUsuarioLojaWeb();
    if (Utils.isValueValid(cliente)) {
      let enderecoPrincipal = Array.from(cliente.enderecos).filter(
        (e) => e.principal
      )[0];

      if (Utils.isValueValid(cliente)) {
        Http.get(
          `pedido/parametros/cliente/${cliente.idCliente}/endereco/entrega/${enderecoPrincipal.idClienteEndereco}`
        ).then(({ status, data }) => {
          if (status) {
            this.setState({
              parametros: data,
            });
          }
        });
      }
    } else {
      console.log(
        "Cliente não autenticado, não foi possível consultar os parâmetros"
      );
    }
  }

  componentDidUpdate(props) {
    if (!Utils.isValueValid(this.state.parametros)) {
      this.consultarParametrosPedido();
    }
  }

  validarDadosEntregaRetirada() {
    let erro = "";

    if (!this.state.novosParamentros.diasPrevisaoEntrega > 0) {
      if (this.state.pedidoEntrega) {
        if (!Utils.isValueValid(this.state.horarioEntrega)) {
          erro = "Selecione o horário de entrega";
        }
      } else {
        if (!Utils.isValueValid(this.state.horarioRetirada)) {
          erro = "Selecione o horário de retirada";
        }
      }
    }

    if (Utils.isValueValid(erro)) {
      this.setState({
        mostrarEntrega: true,
        mostrarItensCarrinho: false,
        mostrarPagamento: false,
      });
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  validarDadosPagamentoInformado() {
    let erro = "";

    let recebimento = this.state.recebimentoPedido;
    if (this.state.pagamentoOnline) {
      if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
        if (
          !Utils.isValueValid(recebimento.anoValidadeCartao) ||
          !Utils.isValueValid(recebimento.mesValidadeCartao)
        ) {
          erro = "Informe a data de validade do cartão!";
        }

        if (String(recebimento.numeroCartao).length < 16) {
          erro =
            "Número de cartão inválido, verifique se todos os números foram inseridos";
        }

        if (
          Utils.isValueValid(recebimento.anoValidadeCartao) &&
          String(recebimento.anoValidadeCartao).length < 4
        ) {
          erro = "Informe o ano da data de validade com 4 dígitos";
        }

        if (!Utils.isValueValid(recebimento.codigoSegurancaCartao)) {
          erro = "Informe o código de seguraça do cartão!";
        }
        if (!Utils.isValueValid(recebimento.titularCartao)) {
          erro = "Informe o titular do cartão!";
        }
        if (!Utils.isValueValid(recebimento.numeroCartao)) {
          erro = "Informe o número do cartão!";
        }
        if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
          erro = "Informe a bandeira do cartão!";
        }
        if (
          recebimento.tipo === "CREDITO" &&
          !Utils.isValueValid(recebimento.quantidadeParcelas)
        ) {
          erro = "Informe a quantidade de parcelas!";
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    } else {
      if (
        recebimento.tipo === "CREDITO" ||
        recebimento.tipo === "DEBITO" ||
        recebimento.tipo === "DINHEIRO"
      ) {
        if (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO") {
          if (!Utils.isValueValid(recebimento.bandeiraCartao)) {
            erro = "Selecione a bandeira do cartão!";
          }
        }
      } else {
        erro = "Forma de pagamento indisponível!";
      }
    }

    if (Utils.isValueValid(erro)) {
      this.renderMessage(erro);
    }

    return !Utils.isValueValid(erro);
  }

  renderHeader(corPrimaria) {
    return (
      <>
        <Div inline="between" style={{ position: "absolute", top: 75 }}>
          <If and value1={this.state.mostrarItensCarrinho}>
            <Div inline="start" margin="2">
              <Div
                style={{
                  backgroundColor: this.state.mostrarItensCarrinho
                    ? corPrimaria
                    : "#E5E5E5",
                  color: "white",
                  borderRadius: 5,
                  width: 30,
                  height: 30,
                }}
                inline="center"
              >
                <Label
                  value="1"
                  margin="1"
                  family="Bold"
                  style={{ fontSize: 15 }}
                  handlerClick={() =>
                    this.setState({
                      mostrarEntrega: false,
                      mostrarItensCarrinho: true,
                      mostrarPagamento: false,
                    })
                  }
                />
              </Div>
              <If and value1={this.state.mostrarItensCarrinho}>
                <Label
                  margin="3"
                  value="Itens do carrinho"
                  style={{ fontSize: 13, color: "#878787" }}
                  family="Bold"
                  handlerClick={() =>
                    this.setState({
                      mostrarEntrega: false,
                      mostrarItensCarrinho: true,
                      mostrarPagamento: false,
                    })
                  }
                />
              </If>
            </Div>
          </If>
          <If
            and
            value1={
              this.state.mostrarItensCarrinho || this.state.mostrarEntrega
            }
          >
            <Div inline="start" margin="2">
              <Div
                style={{
                  backgroundColor: this.state.mostrarEntrega
                    ? corPrimaria
                    : "#E5E5E5",
                  color: "white",
                  borderRadius: 5,
                  width: 30,
                  height: 30,
                }}
                inline="center"
              >
                <Label
                  value="2"
                  margin="1"
                  family="Bold"
                  style={{ fontSize: 15 }}
                  handlerClick={() =>
                    this.setState({
                      mostrarEntrega: true,
                      mostrarItensCarrinho: false,
                      mostrarPagamento: false,
                    })
                  }
                />
              </Div>
              <If and value1={this.state.mostrarEntrega}>
                <Label
                  margin="3"
                  value="Entrega / Retirada"
                  handlerClick={() =>
                    this.setState({
                      mostrarEntrega: true,
                      mostrarItensCarrinho: false,
                      mostrarPagamento: false,
                    })
                  }
                  style={{ fontSize: 13, color: "#575757" }}
                  family="Bold"
                />
              </If>
            </Div>
          </If>

          <If
            and
            value1={this.state.mostrarEntrega || this.state.mostrarPagamento}
          >
            <Div
              inline="start"
              handlerClick={() => {
                if (this.validarContinuarParaPagamento()) {
                  this.setState({
                    mostrarEntrega: false,
                    mostrarItensCarrinho: false,
                    mostrarPagamento: true,
                  });
                }
              }}
            >
              <Div
                style={{
                  backgroundColor: this.state.mostrarPagamento
                    ? corPrimaria
                    : "#E5E5E5",
                  color: "white",
                  borderRadius: 5,
                  width: 30,
                  height: 30,
                  textAlign: "center",
                }}
              >
                <Label
                  value="3"
                  margin="1"
                  family="Bold"
                  style={{ fontSize: 15 }}
                />
              </Div>
              <If and value1={this.state.mostrarPagamento}>
                <Label
                  margin="3"
                  value="Pagamento"
                  style={{ fontSize: 13, color: "#878787" }}
                  family="Bold"
                />
              </If>
            </Div>
          </If>
          <If
            and
            value1={this.state.mostrarPagamento || this.state.mostrarEntrega}
          >
            <Div
              style={{
                borderRadius: 5,
                marginLeft: 30,
                width: 30,
                height: 30,
              }}
              inline="center"
            >
              <I
                icon={Type.ICON.ARROW_ANGLE_LEFT}
                style={{ fontSize: 30, color: Color.ECOMMERCE.CINZA_ESCURO }}
                handlerClick={() => this.voltar()}
              />
            </Div>
          </If>
        </Div>
      </>
    );
  }

  getEntrega() {
    let parametros = this.state.parametros || {};
    let entrega = {};
    if (Utils.isValueValid(parametros)) {
      entrega = parametros.entrega;
    }

    return entrega;
  }

  getRetirada() {
    let { situacao, mensagem } = this.getEntrega();
    return {
      taxaEntrega: 0,
      situacao,
      mensagem,
      ...this.state.filialSelecionadaRetirada,
    };
  }

  getValorDescontoVenda() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    let valorDescontoTaxaEntrega = Number(
      cabecalho.valorDescontoTaxaEntrega || 0
    );
    let valorDescontoTaxaServico = Number(
      cabecalho.valorDescontoTaxaServico || 0
    );

    let desconto = valorDescontoTaxaEntrega + valorDescontoTaxaServico;

    if (carrinho.cupomAplicado) {
      desconto = desconto + Number(carrinho.cupom.valorTotal);
    } else {
      desconto = Number(cabecalho.valorDesconto);
    }

    return desconto;
  }

  renderResumoCompraEscondido() {
    return (
      <Div
        inline="center"
        style={{
          border: `2px solid ${Color.ECOMMERCE.CINZA_ESCURO}`,
          borderRadius: 5,
          padding: 5,
          width: window.innerWidth - 30,
        }}
        target={`#${ID_RESUMO_COLLAPSE}`}
        toggle="collapse"
      >
        <I
          icon={Type.ICON.SALE_BASKET}
          style={{
            color: Color.ECOMMERCE.CINZA_ESCURO,
            marginBottom: 12,
            marginRight: 10,
          }}
        />
        <Label
          value="RESUMO DA COMPRA"
          family="Bold"
          style={{
            fontSize: 15,
            color: Color.ECOMMERCE.CINZA_ESCURO,
          }}
        />
        {this.renderResumoDaCompra(true)}
      </Div>
    );
  }

  getItensCarrinho = () => {
    const { itens } = this.props.carrinho;
    let itensFiltrados = [];

    if (Array.from(itens).length > 6 && this.state.mostrarTodos) {
      itensFiltrados = itens;
    } else {
      itensFiltrados = Array.from(itens).slice(0, 6);
    }

    return itensFiltrados;
  };

  renderAdicionarProdutoLista(listas) {
    ModalNotification.renderMessage({
      title: "Listas de compra",
      body: (
        <AdicionarProdutosCarrinhoListaMobile
          listas={listas}
          produtos={this.getItensCarrinho()}
          empresa={this.props.empresa}
        //handlerCancel={() => this.visualizarProduto(produto, updateItem)}
        //handlerNovaListaCadastrada={() =>
        //  this.visualizarProduto(produto, updateItem)
        // }
        />
      ),
    });
  }

  renderResumoDaCompra(collapse = false) {
    const { cabecalho, cupomAplicado, cupom } = this.props.carrinho;
    const { pedido, parametros, novosParamentros } = this.state;

    let entrega =
      this.state.pedidoEntrega || this.state.pedidoEntregaOutro
        ? this.getEntrega()
        : this.getRetirada();
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);

    return (
      <Div
        className={`align-self-start ${collapse ? "collapse" : ""}`}
        responsive="12"
        id={ID_RESUMO_COLLAPSE}
      >
        <If and value1={!collapse}>
          <Label
            value="Resumo da compra"
            style={styles.labelResumoCompra}
            family="SemiBold"
          />
        </If>
        <Label style={styles.labelBorda} col="12" />

        <Div>
          <Div
            style={{
              backgroundColor: "#FFF",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              borderRadius: "14px",
              padding: "20px",
              margin: "0 0 25px"
            }}
          >
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Subtotal do carrinho"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTotal)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconEntrega style={{ width: "30px", margin: 2 }} />
              <Div className="d-flex flex-column justify-content-start">
                <Title
                  value="Taxa de entrega"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTaxaEntrega || 0)}
                  type="h6"
                  style={
                    cabecalho.atingiuEntregaGratis
                      ? styles.taxaEntregaGratis
                      : styles.labelBlue
                  }
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconEntrega style={{ width: "32px", margin: 2 }} />
              <Div className="d-flex flex-column justify-content-start">
                <Title
                  value="Taxa de serviço"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(cabecalho.valorTaxaServico || 0)}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Div inline="start">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Desconto"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(this.getValorDescontoVenda() || 0)}
                  type="h6"
                  style={styles.labelRed}
                  family="Bold"
                />
              </Div>
              {Utils.isValueValid(cupom) && cupomAplicado && (
                <TooltipText
                  direction="top"
                  title={`Cupom de desconto aplicado: ${Mask.formatValueBr(
                    cupom.valorTotal
                  )}`}
                  id={Utils.uuidString()}
                >
                  <Label
                    value={`${cupom.codigo} aplicado`}
                    family="Bold"
                    marginLeft="4"
                    style={{
                      fontSize: 12,
                      color: Color.ECOMMERCE.WHITE,
                      backgroundColor: Color.ECOMMERCE.LARANJA,
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                </TooltipText>
              )}
            </Div>
            <Div inline="start" className="border-top ">
              <IconValor style={{ width: "22px", margin: 2 }} />
              <Div
                className="d-flex flex-column justify-content-start"
                marginLeft="2"
              >
                <Title
                  value="Valor total do pedido"
                  type="h6"
                  className="label-valor-total-carrinho"
                  style={styles.labelCinza}
                  family="Bold"
                />
                <Title
                  value={Mask.formatValueBr(
                    Number(cabecalho.valorTotal) +
                    Number(cabecalho.valorTaxaEntrega || 0) +
                    Number(parametros.valorTaxaServico) -
                    Number(cabecalho.valorDesconto)
                  )}
                  type="h6"
                  style={styles.labelBlue}
                  family="Bold"
                />
              </Div>
            </Div>
            <Label style={styles.labelBorda} col="12" />
            <If
              and
              value1={entrega.posicao === "SUCESSO"}
              value2={parametros.valorMinimoPedidoFreteGratis > 0}
            >
              <Label
                family="Bold"
                style={{ color: corPrimaria }}
                value={`Entrega grátis à partir de: ${Mask.formatValueBr(
                  parametros.valorMinimoPedidoFreteGratis
                )}`}
              />
            </If>
            <If and value1={entrega.situacao === "ERRO"}>
              <Label
                pointer
                value={`${entrega.mensagem} Clique aqui para atualizar o endereço`}
                family="Bold"
                style={{ color: corPrimaria }}
                handlerClick={() => this.props.history.push(Routes.CLIENTE)}
              />
            </If>
          </Div>

          <Div
            style={{ width: "100%" }}
            className="d-flex flex-row justify-content-center align-items-center"
          >
            <Checkbutton
              style={{
                height: 115,
                width: 115,
              }}
              corPrimaria={corPrimaria}
              checked={pedido.incluirCpfCnpjNota}
              title="Incluir CPF/CNPJ na nota"
              onClick={() =>
                this.setState({
                  pedido: {
                    ...pedido,
                    incluirCpfCnpjNota: !pedido.incluirCpfCnpjNota,
                  },
                })
              }
              Icon={
                <I
                  pointer
                  icon={Type.ICON.USERR}
                  style={{
                    color: pedido.incluirCpfCnpjNota
                      ? Color.ECOMMERCE.WHITE
                      : corPrimaria,
                    fontSize: 20,
                  }}
                />
              }
            />

            <If and value1={Utils.isNotNull(novosParamentros.entregaConsciente)}>

              <Checkbutton
                style={{
                  height: 115,
                  width: 115,
                  margin: "10px 10px 10px 20px",
                }}
                corPrimaria={corPrimaria}
                checked={pedido.entregaConsciente}
                title="Entrega em caixas recicláveis"
                onClick={() =>
                  this.setState({
                    pedido: {
                      ...pedido,
                      entregaConsciente: !pedido.entregaConsciente,
                    },
                  })
                }
              >
                <TooltipText direction="right">
                  <I
                    pointer
                    icon={Type.ICON.INFO}
                    style={{
                      color: pedido.entregaConsciente
                        ? Color.ECOMMERCE.WHITE
                        : corPrimaria,
                      marginLeft: 5,
                      fontSize: 20,
                    }}
                  />
                </TooltipText>
              </Checkbutton>

            </If>

            <Checkbutton
              style={{
                height: 115,
                width: 115,
                margin: "10px"
              }}
              corPrimaria={Color.EMPRESA.TERCIARIA}
              checked={true}
              title="Continuar comprando!"
              onClick={() => this.props.history.push("/")}
              Icon={
                <I
                  pointer
                  icon={Type.ICON.HOME}
                  style={{
                    color: "#FFF",
                    fontSize: 20,
                  }}
                />
              }
            />
          </Div>
        </Div>
      </Div>
    );
  }

  renderMensagemFinalizacaoPedido(data) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: (
        <Div column>
          <Label
            value={data.statusPedido ? data.mensagem : data.detalhesMensagem}
            family="Light"
            style={{ fontSize: 30, padding: 30 }}
          />
        </Div>
      ),
      handlerConfirm: null,
      handlerCancel: () => console.log("CANCELADO"),
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  renderMessage(message) {
    ModalNotification.renderMessage({
      title: "Mensagem",
      body: <Label value={message} style={{ fontSize: 25 }} family="Light" />,
      handlerConfirm: null,
      handlerCancel: () => console.log("CANCELADO"),
      labelButtonCancel: "Fechar",
      responsive: "5",
    });
  }

  continuarFinalizacaoPedido() {
    if (
      this.state.mostrarItensCarrinho ||
      (this.state.mostrarEntrega && this.validarContinuarParaPagamento())
    ) {
      this.setState(
        {
          mostrarEntrega: this.state.mostrarItensCarrinho ? true : false,
          mostrarPagamento: this.state.mostrarEntrega ? true : false,
          mostrarItensCarrinho: false,
        },
        () => Utils.scrollTop()
      );
    }

    if (this.state.mostrarPagamento) {
      if (
        this.validarDadosPagamentoInformado() &&
        this.validarDadosEntregaRetirada()
      ) {
        if (this.state.recebimentoPedido.erroPagamento) {
          this.reenviarNovoPagamento();
        } else {
          if (Utils.isValueValid(this.state.codigoCupomDesconto)) {
            this.aplicarCupomDesconto(true);
          } else {
            this.finalizarPedido();
          }
        }
      }
    }
  }

  handlerPedido(pedido) {
    this.setState({ pedido });
  }

  handlerRecebimento(recebimentoPedido) {
    this.setState({ recebimentoPedido });
  }

  voltar() {
    if (this.state.mostrarItensCarrinho) {
      this.props.history.goBack();
    } else if (this.state.mostrarPagamento) {
      this.setState({
        mostrarPagamento: false,
        mostrarEntrega: true,
      });
    } else if (this.state.mostrarEntrega) {
      this.setState({
        mostrarItensCarrinho: true,
        mostrarEntrega: false,
      });
    }
  }

  getDadosEntrega() {
    const {
      pedidoEntrega,
      pedidoRetirada,
      pedidoEntregaOutro,
      horarioEntrega,
      horarioRetirada,
    } = this.state;
    let isEntrega = pedidoEntrega || pedidoEntregaOutro;

    let horario = isEntrega ? horarioEntrega : horarioRetirada;

    let entrega = this.getEntrega();
    let retirada = this.getRetirada();

    if(!Utils.isNotObjectEmpty(horario)) {
      horario = this.state.parametros.horarios[0]
    }

    return {
      dataEntrega: moment(horario.dataEntrega, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      ),
      idEmpresaHorarioEntrega: horario.idEmpresaHorarioEntrega,
      tipoEntrega: isEntrega
        ? EntregaRetiradaUtils.TIPO_ENTREGA
        : EntregaRetiradaUtils.TIPO_RETIRADA,
      valorTaxaEntrega: isEntrega ? entrega.taxaEntrega : 0,
      idFilial: pedidoRetirada ? retirada.idFilial : entrega.idFilial,
    };
  }

  getTipoPagamento() {
    return this.state.pagamentoOnline ? "ON_LINE" : "OFF_LINE";
  }

  getDadosRecebimento() {
    let recebimento = this.state.recebimentoPedido;
    let isOnline = this.state.pagamentoOnline;

    if (
      isOnline &&
      (recebimento.tipo === "CREDITO" || recebimento.tipo === "DEBITO")
    ) {
      return {
        recebimento: {
          ...recebimento,
          dataValidadeCartao: `${recebimento.mesValidadeCartao}/${recebimento.anoValidadeCartao}`,
        },
      };
    } else {
      return { recebimento };
    }
  }

  getDadosCupomDesconto() {
    let { carrinho } = this.props;
    let dados = { valorDescontoCupom: 0, idCupom: "" };

    if (carrinho.cupomAplicado) {
      dados.valorDescontoCupom = Number(carrinho.cupom.valorTotal);
      dados.idCupom = carrinho.cupom.idCupom;
    }

    return dados;
  }

  getValorDescontoTaxaEntrega() {
    let { carrinho } = this.props;
    let cabecalho = carrinho.cabecalho;

    return Number(cabecalho.valorDescontoTaxaEntrega);
  }

  getPedidoParaCadastro() {
    const {
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
    } = this.state.pedido;
    let pedido = CarrinhoUtils.getCarrinho();
    let parametros = this.state.parametros;

    return {
      idCliente,
      idClienteEndereco,
      incluirCpfCnpjNota,
      responsavelRecebimento,
      entregaConsciente,
      valorTotal: pedido.cabecalho.valorTotal,
      ...pedido,
      ...this.getDadosRecebimento(),
      ...this.getDadosEntrega(),
      ...CarrinhoUtils.getItensParaCadastro(pedido.itens),
      tipoPagamento: this.getTipoPagamento(),
      valorTaxaServico: parametros.valorTaxaServico || 0,
      ...this.getDadosCupomDesconto(),
      valorDescontoTaxaEntrega: this.getValorDescontoTaxaEntrega(),
      valorTaxaCartao: 0,
      valorDescontoTaxaServico: 0,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
    };
  }

  reenviarNovoPagamento() {
    let pedido = this.getPedidoParaCadastro();

    let recebimento = {
      ...this.getDadosRecebimento().recebimento,
      idLancamentoPedidoApp:
        this.props.carrinho.cabecalho.idLancamentoPedidoApp,
      tipoPagamentoPedido: this.getTipoPagamento(),
    };

    Http.post("/pedido/novopagamento", recebimento)
      .then(({ status, data }) => {
        if (status) {
          if (!data.statusPagamento) {
            this.renderMensagemFinalizacaoPedido(data);
            this.setState({
              recebimentoPedido: {
                ...this.state.recebimentoPedido,
                erroPagamento: true,
              },
            });
          } else {
            this.setState(
              {
                recebimentoPedido: {
                  ...this.state.recebimentoPedido,
                  erroPagamento: false,
                  ...data,
                },
              },
              () =>
                this.props.history.push("/finalizacao", {
                  pedido,
                })
            );
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  sendPedidoTipoCredito(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    erroPagamento: true,
                  },
                },
                () => {
                  let carrinho = CarrinhoUtils.getCarrinho();
                  carrinho.pagamentoOnline = data;
                  this.renderMessageError(
                    "Falha ao processar o pagamento",
                    data
                  );
                }
              );
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    erroPagamento: true,
                  },
                },
                () => this.reenviarNovoPagamento()
              );
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        } else {
          this.renderMessageError("Falha ao processar o pedido", data);
        }
      })
      .catch((error) => console.log(error));
  }

  renderViewAutenticacaoDebito({ urlAutenticacaoPagamento }) {
    let windowPagamentoDebito = this.state.windowPagamentoDebito;
    // windowPagamentoDebito = window.open();
    //urlAutenticacaoPagamento,
    //"Informe os dados do cartão",
    //`height=${window.innerHeight},width=${window.innerWidth}`
    //);
    windowPagamentoDebito.location = urlAutenticacaoPagamento;
    this.setState({ windowPagamentoDebito });
    /**
     * ModalNotification.render(
      "Informe os dados do cartão",
      <Div inline="center" className="w-100">
        <iframe src={urlAutenticacaoPagamento} height="400" width="100%" />
      </Div>
    );
     */
  }

  sendPedidoTipoDebito(pedido, empresa) {
    let windowPagamentoDebito = this.state.windowPagamentoDebito;
    windowPagamentoDebito = window.open();
    this.setState({ windowPagamentoDebito });

    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              if (data.posicao === "FALHA_PGTO_ON_LINE") {
                this.renderMessageError("Finalização pedido", data);
              } else if (data.posicao === "AGUARDANDO_PGTO_ON_LINE") {
                this.renderViewAutenticacaoDebito(data);
              }
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () =>
                  this.props.history.push("/finalizacao", {
                    pedido,
                    logoEmpresa: this.state.logoEmpresa,
                  })
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              let carrinho = this.props.carrinho;
              carrinho.erroPagamento = true;
              this.props.atualizarCarrinho(carrinho);
              this.reenviarNovoPagamento();
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  }

  renderViewPedidoFinalizado(pedido) {
    this.props.history.push("/finalizacao", {
      pedido,
      logoEmpresa: this.state.logoEmpresa,
    });
  }

  sendPedidoOutrosPagamentos(pedido, empresa) {
    Http.post("pedido", pedido)
      .then(({ status, data }) => {
        if (status) {
          TagManagerUtils.tagManagerConcluiuCompra(pedido, empresa)
          if (data.statusPedido) {
            if (!data.statusPagamento) {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    erroPagamento: true,
                  },
                },
                () => this.renderMensagemFalhaFinalizacaoPedido(data.mensagem)
              );
            } else {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    ...data,
                  },
                },
                () => this.renderViewPedidoFinalizado(pedido)
              );
            }
          } else {
            if (data.posicao === "FALHA_PROCESSAMENTO") {
              this.setState(
                {
                  recebimentoPedido: {
                    ...this.state.recebimentoPedido,
                    erroPagamento: true,
                  },
                },
                () => this.reenviarNovoPagamento()
              );
            } else {
              this.renderMessageError("Falha ao processar o pedido", data);
            }
          }
        }
      })
      .catch((error) =>  {
        console.log(error);

        this.renderMessageError("Erro ao finalizar pedido", error);
      });
  }

  finalizarPedido() {
    let pedido = this.getPedidoParaCadastro();
    let empresa = this.props.empresa.fantasia
    const { tipo } = pedido.recebimento;

    if (tipo === "DEBITO") {
      this.sendPedidoTipoDebito(pedido, empresa);
    } else if (tipo === "CREDITO") {
      this.sendPedidoTipoCredito(pedido, empresa);
    } else {
      this.sendPedidoOutrosPagamentos(pedido, empresa);
    }
  }

  renderMessageError(title, response) {
    let errors = response;
    let mensagem = Utils.isValueValid(response.mensagem)
      ? response.mensagem
      : "";
    let detalhesMensagem = Utils.isValueValid(response.detalhesMensagem)
      ? response.detalhesMensagem
      : "";

    let msgErro = Utils.isValueValid(mensagem)
      ? `${mensagem} ${detalhesMensagem || ""}`
      : errors.detail;

    ModalNotification.renderMessage({
      title,
      body: <Label value={msgErro} />,
      labelButtonCancel: "Fechar",
      handlerCancel: () => { },
    });
  }

  renderMensagemFalhaFinalizacaoPedido(msg) {
    ModalNotification.renderMessage({
      title: "Falha ao finalizar o pedido",
      body: <Label value={msg} />,
      handlerCancel: () => { },
    });
  }

  validarItensCheckout() {
    const { carrinho } = this.props;
    const { parametros } = this.state;
    let valorMaximoPedido = Number(parametros.valorMaximoPedido);
    let valorTotal = Number(carrinho.cabecalho.valorTotal);

    let valorEntrega = Number(parametros.valorMinimoPedidoEntrega);
    let valorRetirada = Number(parametros.valorMinimoPedidoRetirada);

    let valorMinimo =
      valorEntrega > valorRetirada ? valorRetirada : valorEntrega;

    if (valorMaximoPedido >= valorTotal && valorMinimo <= valorTotal) {
      this.forceUpdate();
    } else if (valorMaximoPedido < valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 20,
          }}
        >
          <Div inline="center">
            <Label
              family="Bold"
              value="O pedido atingiu o valor limite permitido"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 20 }}
            value={`Valor máximo para o pedido: ${Mask.formatValueBr(
              valorMaximoPedido
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    } else if (valorMinimo > valorTotal) {
      ModalNotification.render(
        "Informações do carrinho",
        <Div
          column
          className="justify-content-center"
          style={{
            height: 200,
            color: Color.ECOMMERCE.CINZA_ESCURO,
            fontSize: 20,
          }}
        >
          <Div inline="center">
            <Label
              family="Bold"
              value="O pedido não possui o valor mínimo necessário"
            />
          </Div>
          <Label
            family="Bold"
            style={{ color: Color.ECOMMERCE.LARANJA, fontSize: 20 }}
            value={`Valor mínimo para o pedido: ${Mask.formatValueBr(
              valorMinimo
            )}`}
          />
        </Div>
      );
      this.props.history.replace("/");
    }
  }

  removerCupomDesconto() {
    let carrinho = this.props.carrinho;

    if (carrinho.cupomAplicado) {
      carrinho.cabecalho.valorDesconto =
        Number(carrinho.cabecalho.valorDesconto) - carrinho.cupom.valorTotal;
    }

    carrinho.cupomAplicado = false;
    carrinho.cupom = null;

    this.setState({ codigoCupomDesconto: "" }, () => {
      this.props.atualizarCarrinho(carrinho);
    });
  }

  aplicarCupomDesconto(finalizar = false) {
    let pedido = this.getPedidoParaCadastro();
    let carrinho = this.props.carrinho;

    pedido.codigoCupomDesconto =
      this.state.codigoCupomDesconto || carrinho.cupom.codigo;
    Http.post(EndPoints.CUPOM_DESCONTO_VALIDAR, pedido)
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            if (!carrinho.cupomAplicado) {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) + data.valorTotal;
              Notification.success("Cupom aplicado!");
            } else {
              carrinho.cupomAplicado = true;
              carrinho.cupom = data;
            }
          } else {
            Notification.danger("Cupom indisponível");
            if (carrinho.cupomAplicado) {
              carrinho.cabecalho.valorDesconto =
                Number(carrinho.cabecalho.valorDesconto) -
                carrinho.cupom.valorTotal;
            }
            carrinho.cupomAplicado = false;
            carrinho.cupom = null;
            this.setState({ codigoCupomDesconto: "" });
          }

          this.props.atualizarCarrinho(carrinho);
          this.forceUpdate();

          if (finalizar) {
            this.finalizarPedido();
          }
        } else {
          Notification.danger(
            "Falha ao aplicar o cupom de desconto, verifique a conexão com a internet"
          );

          if (finalizar) {
            ModalNotification.confirmarComHandlerCancel(
              "Finalizar pedido",
              "O cupom de desconto aplicado ficou indisponível, deseja finalizar o pedido ?",
              () => this.finalizarPedido(),
              () => { }
            );
          }
        }
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  render() {
    let logoEmpresa = EmpresaUtils.getLogoEmpresa(this.state.empresa);
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    let corSecundaria = EmpresaUtils.getCorSecundaria(this.state.empresa);

    return (
      <Div
        column
        className="w-100"
        style={{
          paddingTop: 20,
          paddingBottom: 100,
          marginTop: 150,
          backgroundColor: Color.ECOMMERCE.CINZA_CLARO,
        }}
      >
        <Div
          className="border-bottom w-100"
          inline="between"
          style={{
            position: "absolute",
            top: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Div inline="center" handlerClick={() => this.props.history.goBack()}>
            <Image src={logoEmpresa} width="150px" margin="2" />
          </Div>
        </Div>
        {this.renderHeader(corPrimaria)}
        <Div inline="center">
          <If and value1={this.state.mostrarItensCarrinho}>
            <Div inline="start">
              {this.renderResumoDaCompra()}
              <TemplateItensCarrinhoMobile
                {...this.props}
                mostrarTodos={this.state.mostrarTodos}
                parametros={this.state.parametros}
                handlerAddItem={() => this.validarItensCheckout()}
              />
            </Div>
          </If>
          <If and value1={this.state.mostrarEntrega}>
            {this.renderResumoCompraEscondido()}
            <TemplateEntregaRetiradaMobile
              cabecalho={this.props.carrinho.cabecalho}
              parametros={this.state.parametros}
              pedidoEntrega={this.state.pedidoEntrega}
              pedidoRetirada={this.state.pedidoRetirada}
              pedidoEntregaOutro={this.state.pedidoEntregaOutro}
              handlerChangeTipoEntrega={(tipo) => {
                this.setState(
                  {
                    pedidoEntrega: tipo === EntregaRetiradaUtils.TIPO_ENTREGA,
                    pedidoRetirada: tipo === EntregaRetiradaUtils.TIPO_RETIRADA,
                    pedidoEntregaOutro:
                      tipo === EntregaRetiradaUtils.TIPO_ENTREGA_OUTRO,
                  },
                  () => this.calcularValorTaxaEntrega()
                );
              }}
              handerErrorEntregaRetirada={() =>
                this.setState({
                  mostrarErroEntregaRetirada: true,
                  mostrarEntrega: false,
                })
              }
              corPrimaria={corPrimaria}
              corSecundaria={corSecundaria}
            />
            <If
              and
              value1={this.state.pedidoEntrega || this.state.pedidoEntregaOutro}
            >
              <TemplatePedidoEntregaMobile
                pedido={this.state.pedido}
                horarios={this.state.horariosEntrega}
                handlerPedido={this.handlerPedido}
                parametros={this.state.parametros}
                empresa={this.state.empresa}
                carrinho={this.props.carrinho}
                listasDeCompra={this.props.listasDeCompra}
                horarioEntrega={this.state.horarioEntrega}
                handlerEnderecoSelecionado={(endereco) => {
                  this.setState(
                    {
                      pedido: {
                        ...this.state.pedido,
                        idClienteEndereco: endereco.idClienteEndereco,
                      },
                    },
                    () => {
                      let carrinho = {
                        ...this.props.carrinho,
                        enderecoEntrega: endereco,
                      };

                      this.props.setEnderecoEntrega(carrinho);
                    }
                  );
                }}
                handlerHorarioSelecionado={(horario) =>
                  this.setState({ horarioEntrega: horario })
                }
                corPrimaria={corPrimaria}
                corSecundaria={corSecundaria}
              />
            </If>
            <If and value1={this.state.pedidoRetirada}>
              <TemplatePedidoRetiradaMobile
                filialSelecionadaRetirada={this.state.filialSelecionadaRetirada}
                pedido={this.state.pedido}
                handlerPedido={this.handlerPedido}
                empresa={this.props.empresa}
                handlerFilialSelecionada={(filial) =>
                  this.setState({
                    filialSelecionadaRetirada: filial,
                  })
                }
                horarios={this.state.horariosEntrega}
                parametros={this.state.parametros}
                handlerHorarioSelecionado={(horario) =>
                  this.setState({ horarioRetirada: horario })
                }
                corPrimaria={corPrimaria}
                corSecundaria={corSecundaria}
              />
            </If>
          </If>
          <If and value1={this.state.mostrarPagamento}>
            {this.renderResumoCompraEscondido()}
            <TemplatePagamentoMobile
              recebimentoPedido={this.state.recebimentoPedido}
              pedido={this.state.pedido}
              carrinho={this.props.carrinho}
              handlerRecebimento={this.handlerRecebimento}
              handlerPedido={this.handlerPedido}
              //windowPagamentoDebito={this.state.windowPagamentoDebito}
              parametros={this.state.parametros}
              novosParamentros={this.state.novosParamentros}
              empresa={this.props.empresa}
              handlerInitPagamento={() =>
                this.setState({
                  recebimentoPedido: new RecebimentoPedidoLojaWeb(),
                })
              }
              handlerTipoPagamento={(pagamentoOnline) =>
                this.setState({ pagamentoOnline })
              }
              handlerClosePopUp={() => {
                if (Utils.isValueValid(this.state.windowPagamentoDebito)) {
                  this.state.windowPagamentoDebito.close();
                }
              }}
            />
            <Div
              style={{
                padding: 10,
                margin: 5,
                backgroundColor: Color.EMPRESA.SECUNDARIA,
                color: Color.ECOMMERCE.WHITE,
                width: "100%",
              }}
            >
              <InputDefault
                handlerChange={(e) => {
                  this.setState({
                    codigoCupomDesconto: e.target.value,
                  });
                }}
                label="Utilizar cupom de desconto"
                className="w-100"
                value={this.state.codigoCupomDesconto}
                handlerBlur={() => {
                  if (Utils.isValueValid(this.state.codigoCupomDesconto)) {
                    this.aplicarCupomDesconto();
                  } else {
                    this.removerCupomDesconto();
                  }
                }}
                placeHolder="Código do cupom"
              />
              <Label
                value="Aplicar"
                style={{
                  color: Color.ECOMMERCE.WHITE,
                  width: "100%",
                  textAlign: "center",
                  borderRadius: 5,
                  border: "1px solid white",
                  padding: 5,
                }}
                family="Bold"
                handlerClick={() => { }}
              />
            </Div>
          </If>
        </Div>
        <Div style={styles.containerBottom}>
          <If
            and
            value1={!this.state.mostrarTodos && this.state.mostrarItensCarrinho}
          >
            <Div
              responsive="12"
              inline="center"
              style={styles.containterMostrarTodos}
              pointer
              handlerClick={() => this.setState({ mostrarTodos: true })}
            >
              <Label
                value="Mostrar todos"
                family="Light"
                style={{ color: "#878787", fontSize: 15 }}
              />
              <I
                icon={Type.ICON.ARROW_CHEV_DOWN}
                marginLeft="2"
                marginBottom="2"
              />
            </Div>
          </If>

          <Div
            responsive="12"
            inline="center"
            style={{
              ...styles.containerContinuar,
              backgroundColor: corPrimaria,
            }}
            pointer
            handlerClick={() => {
              this.continuarFinalizacaoPedido();
            }}
          >
            <Label
              value={
                this.state.mostrarPagamento ? "Finalizar o pedido" : "Continuar"
              }
              family="Light"
            />
            <I
              icon={Type.ICON.ARROW_CHEV_RIGHT}
              marginLeft="2"
              marginBottom="2"
            />
          </Div>
        </Div>
        <ContatoWhatsappMobile
          empresa={this.state.empresa}
          zIndex={Zindex.MIL_E_DOIS}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "#25D366",
            padding: 10,
            borderRadius: 50,
            zIndex: this.props.zIndex || 1001,
          }}
        />
      </Div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    ...store.carrinhoState,
    ...store.parametrosPedidoState,
    ...store.listasDeCompraState,
    ...store.empresaState,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ atualizarCarrinho, setEnderecoEntrega }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItensCarrinhoMobile);
