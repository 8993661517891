import React from "react";
import { Div, Label } from "../../components";

const ErrorPaginaPrincipalNaoEncontrada = () => {
  return (
    <Div className="text-center" column>
      <Label
        value="Página principal não encontrada"
        family="Bold"
        style={{ fontSize: 30 }}
      />
      <Label
        value="As configurações básicas do site não foram aplicadas!"
        family="Bold"
        style={{ fontSize: 15 }}
      />
    </Div>
  );
};

export default ErrorPaginaPrincipalNaoEncontrada;
