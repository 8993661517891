import React, { Component } from "react";

import ComboButeco from "../../assets/images/combos/combo-boteco.png";
import ComboVinho from "../../assets/images/combos/combo-vinho.png";
import ComboSalada from "../../assets/images/combos/combo-salada.png";

import { CarouselProdutosGenerico, Div, I, If, Label } from "../../components";
import { Color, EndPoints, Http, Type, Utils } from "../../utilities";
import ItemCategoriaCombo from "../anuncios/ItemCategoriaCombo";
import CarouselProdutosCombo from "../../components/carousel/CarouselProdutosCombo";

const styles = {
  labelCombos: {
    color: "#46160E",
    fontWeight: "bold",
    fontSize: "30px",
  },
  iconMaisDepartamentos: {
    color: Color.EMPRESA.PRIMARIA,
  },
};

class CombosPaginaIncial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      combos: [],
    };
  }

  componentDidMount() {
    this.listarProdutosCombo();
  }

  listarProdutosCombo() {
    Http.get(EndPoints.PRODUTOS_COMBO)
      .then(({ status, data }) => {
        if (status) {
          this.setState({ combos: data });
        }
      })
      .catch((erro) => console.log(erro));
  }

  combos = () => {
    let produtos = [];

    let combosPagina = Utils.quebrarArray(
      Array.from([...this.state.combos]),
      3
    );

    combosPagina.forEach((combos, index) => {
      produtos.push({
        value: (
          <Div inline="start">
            {Utils.isValueValid(combos[0]) && (
              <ItemCategoriaCombo
                image={combos[0].urlImagemPrincipal}
                handlerClick={() => this.props.handlerClick(combos[0])}
              />
            )}
            {Utils.isValueValid(combos[1]) && (
              <ItemCategoriaCombo
                image={combos[1].urlImagemPrincipal}
                handlerClick={() => this.props.handlerClick(combos[1])}
              />
            )}
            {Utils.isValueValid(combos[2]) && (
              <ItemCategoriaCombo
                image={combos[2].urlImagemPrincipal}
                handlerClick={() => this.props.handlerClick(combos[2])}
              />
            )}
          </Div>
        ),
        active: index === 0,
      });
    });

    return produtos;
  };

  tiposCombos = () => {
    return [
      "Todos os combos",
      "Churras de domingo",
      "Almoço de família",
      "Alegria dos Pets",
    ];
  };

  render() {
    return (
      <Div style={{ width: 1150 }} responsive="12" pointer>
        <Div>
          <Label value="Combos" style={styles.labelCombos} marginRigh="5" />
          {Array.from(this.tiposCombos()).map((tipo, index) => {
            return (
              <Label value={tipo} key={index} marginLeft="5" marginRigh="5" />
            );
          })}
        </Div>
        <CarouselProdutosCombo isCombo itens={this.combos()} />
      </Div>
    );
  }
}

export default CombosPaginaIncial;
