import React from "react";
import "./spinner.css";
import logo from "../../assets/images/loading.svg";
import {Div, Image} from "../../components";

const Spinner = () => {
  return (
    <Div
      className="d-flex justify-content-center spinner-container modal"
      id="spinner"
    >
      <Div className="basic-container">
        <Div className="loader" />
        <Image className="img-spinner" src={logo}></Image>
      </Div>
    </Div>
  );
};

export default Spinner;
