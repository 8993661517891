import React, { Component } from "react";
import {
  Div,
  Image,
  InputDefault,
  InputInteger,
  Label,
  InputCartao,
  If,
  ModalNotification,
  Select,
} from "../../../components";
import { Color, EndPoints, Http, Utils } from "../../../utilities";

const styles = {
  item: {
    width: Utils.isViewTabletAll() ? window.innerWidth / 1.5 : "100%",
  },
  imagemCartao: {
    transform: Utils.isViewTabletAll()
      ? `translateX(${window.innerWidth / 1.75}px)`
      : `translateX(${window.innerWidth - 80}px)`,
  },
};

class TemplateCartaoMobile extends Component {
  constructor(props) {
    super(props);
  }

  renderMessagemBinInvalido() {
    ModalNotification.renderMessage({
      body: <Label value="Cartão não autorizado para esta transação" />,
      title: "Erro ao validar o cartão",
      handlerCancel: () => {},
    });
  }

  consultarBinCartao() {
    const { recebimentoPedido } = this.props;
    Http.get(
      EndPoints.PEDIDO_BIN_CARTAO.replace(
        "%s",
        String(recebimentoPedido.numeroCartao).substr(0, 7).replace(".", "")
      )
    )
      .then(({ status, data }) => {
        if (status) {
          if (data.situacao === "AUTORIZADA") {
            //   let bandeiras = Array.from(this.props.bandeirasSelect);
            let bandeiras = Array.from(this.props.bandeiras);
            let bandeira = bandeiras.filter(
              (b) => String(b.descricao).replace(" ", "") === data.bandeira
            )[0];

            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao: recebimentoPedido.numeroCartao,
              bandeiraCartao: bandeira.tipoBandeira || bandeira.descricao,
              idEmpresaFormaPagamento: Utils.isValueValid(bandeira)
                ? bandeira.idEmpresaFormaPagamento
                : null,
            });
          } else {
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao: "",
              idEmpresaFormaPagamento: "",
            });
            this.renderMessagemBinInvalido();
          }
        }
      })
      .catch((error) => {
        console.log(error);

        this.props.handlerRecebimento({
          ...recebimentoPedido,
          numeroCartao: "",
          idEmpresaFormaPagamento: "",
        });
        this.renderMessagemBinInvalido();
      });
  }

  render() {
    const { recebimentoPedido, bandeirasSelect } = this.props;

    let bandeiraSelecionada =
      Array.from(this.props.bandeiras).filter(
        (bandeira) =>
          bandeira.idEmpresaFormaPagamento ===
          recebimentoPedido.idEmpresaFormaPagamento
      )[0] || {};

    return (
      <Div inline="center">
        <InputCartao
          responsive="12"
          fontSizeLabel="20"
          placeholder="Número do cartão"
          value={recebimentoPedido.numeroCartao}
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
          }}
          handlerChange={(e) => {
            let numeroCartao = String(e.target.value);

            this.props.handlerRecebimento({
              ...recebimentoPedido,
              numeroCartao,
            });
          }}
          handlerBlur={
            () => recebimentoPedido.numeroCartao ?  
              this.consultarBinCartao() : 
              console.log(recebimentoPedido.numeroCartao)
          }
        />
        <Div inline="start" style={styles.item}>
          <Select
            responsive="12"
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                idEmpresaFormaPagamento: e.target.value,
              });
            }}
            values={bandeirasSelect}
            style={{
              border: "none",
              borderBottom: `1px solid #f2f2f2`,
              textAlignLast: "center",
            }}
            valueSelected={bandeiraSelecionada.idEmpresaFormaPagamento}
          />
          <Image
            src={bandeiraSelecionada.imagem}
            width="40px"
            marginBottom="3"
            style={{ position: "absolute", ...styles.imagemCartao }}
          />
        </Div>
        <Div inline="start" col="12">
          <Label
            value="Validade do cartão"
            family="Light"
            style={{ lineHeight: "12px", color: Color.ECOMMERCE.CINZA_ESCURO }}
          />
        </Div>
        <Div className="w-100" inline="start">
          <InputInteger
            maxLength="2"
            placeholder="Mês"
            responsive="12"
            value={recebimentoPedido.mesValidadeCartao}
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                mesValidadeCartao: e.target.value,
              });
            }}
            style={{
              border: "none",
              borderBottom: `1px solid #f2f2f2`,
              textAlignLast: "center",
            }}
          />
          <InputInteger
            maxLength="4"
            placeholder="Ano"
            responsive="12"
            value={recebimentoPedido.anoValidadeCartao}
            handlerChange={(e) => {
              this.props.handlerRecebimento({
                ...recebimentoPedido,
                anoValidadeCartao: e.target.value,
              });
            }}
            style={{
              border: "none",
              borderBottom: `1px solid #f2f2f2`,
              textAlignLast: "center",
            }}
          />
        </Div>
        <InputInteger
          maxLength="4"
          placeholder="Código de segurança"
          responsive="12"
          value={recebimentoPedido.codigoSegurancaCartao}
          handlerChange={(e) => {
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              codigoSegurancaCartao: e.target.value,
            });
          }}
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
          }}
        />
        <InputDefault
          value={recebimentoPedido.titularCartao}
          handlerChange={(e) => {
            this.props.handlerRecebimento({
              ...recebimentoPedido,
              titularCartao: e.target.value,
            });
          }}
          responsive="12"
          style={{
            border: "none",
            borderBottom: `1px solid #f2f2f2`,
            textAlignLast: "center",
          }}
          placeholder="Nome escrito no cartão"
        />
      </Div>
    );
  }
}

export default TemplateCartaoMobile;
