import React, { Component } from "react";
import { Div, If, Image, Label } from "../../components";
import { Utils } from "../../utilities";

import "./deptolojainstantanea.css";
import CarouselDepartamentosTopo from "../../components/carousel/CarouselDepartamentosTopo";

const getDepartamentos = (menus) => {
  let itens = [];

  if (Utils.isValueValid(menus)) {
    let menusDepartamentoOrdenados = Utils.sort(
      Array.from(menus).filter(
        (m) => m.tipo === "DEPARTAMENTO" && m.tipo !== "DESTAQUES"
      ),
      "ordem"
    );

    menusDepartamentoOrdenados.forEach((depto) => {
      itens.push({
        label: depto.descricao,
        image: depto.urlFoto,
        idDepartamentoSecao: depto.idDepartamentoSecao,
        chavePesquisa: depto.chavePesquisa,
        descricao: depto.descricao,
        subMenus: depto.submenus,
        tipo: depto.tipo,
      });
    });
  }

  return itens;
};

class DepartamentosLojaInstantanea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: false,
      departamentoSelecionado: {},
      parametros: props.parametros,
    };
  }

  UNSAFE_componentWillReceiveProps({ parametros }) {
    this.setState({ parametros });
  }

  getSecoesDepartamento(chavePesquisa) {
    let itens = [];
    let itemMenu = Array.from(this.props.menus).filter(
      (c) => String(c.chavePesquisa) === String(chavePesquisa)
    )[0];

    if (Utils.isValueValid(itemMenu)) {
      Array.from(itemMenu.submenus || itemMenu.subMenus || []).forEach((sc) => {
        itens.push({
          label: sc.descricao,
          idDepartamentoSecao: sc.idDepartamentoSecao,
          chavePesquisa: sc.chavePesquisa,
          descricao: sc.descricao,
          tipo: sc.tipo,
        });
      });
    }

    return itens;
  }

  getItensCarouselDepartamentos() {
    let itens = [];

    Array.from(getDepartamentos(this.props.menus)).forEach((depto) => {
      itens.push({
        descricao: depto.label,
        width: String(depto.label).length > 10 ? 210 : 120,
        value: (
          <Div inline="start">
            <ul className="d-flex align-items-center justify-content-start">
              <If and value1={Utils.isValueValid(depto.image)}>
                <Image
                  src={depto.image}
                  width="32px"
                  className="image-icon-teste color-icon-menu"
                  pointer
                />
              </If>

              <li className="Soccer cursor-pointer cool-link">
                <a
                  style={{
                    textTransform: "uppercase",
                    color: "#878787",
                    fontSize: "14px",
                    lineHeight: "14px",
                    paddingLeft: 5,
                    textAlign: "left",
                  }}
                  onClick={() =>
                    this.props.history.push(
                      `/produto/departamento/${depto.descricao}`,
                      {
                        departamento: depto,
                        empresa: this.props.empresa,
                        parametros: this.state.parametros,
                      }
                    )
                  }
                >
                  {depto.label}
                </a>
                <div className="Soccer-content">
                  <Div inline="start">
                    {Utils.quebrarArray(
                      Array.from(
                        this.getSecoesDepartamento(depto.chavePesquisa)
                      ),
                      6
                    ).map((secoes, index) => {
                      return (
                        <Div
                          className="d-flex flex-column align-self-start"
                          key={index}
                        >
                          {Array.from(secoes).map((sc, index) => {
                            return (
                              <a
                                key={index}
                                onClick={() =>
                                  this.props.history.push(
                                    `/produto/departamento/${sc.descricao}`,
                                    {
                                      departamento: sc,
                                      empresa: this.props.empresa,
                                      parametros: this.state.parametros,
                                    }
                                  )
                                }
                              >
                                {sc.descricao}
                              </a>
                            );
                          })}
                        </Div>
                      );
                    })}
                  </Div>
                </div>
              </li>
            </ul>
          </Div>
        ),
      });
    });

    return itens;
  }

  getItemDestaque = () => {
    let item = {};
    let menusDestaques = Utils.sort(
      Array.from(this.props.menus).filter((m) => m.tipo === "DESTAQUES"),
      "ordem"
    );

    if (Utils.isValueValid(menusDestaques)) {
      let itemDestaque = menusDestaques[0];
      item = (
        <Div
          pointer
          inline="start"
          handlerClick={() =>
            this.props.history.push(
              `/produto/departamento/${itemDestaque.descricao}`,
              {
                departamento: itemDestaque,
                empresa: this.props.empresa,
              }
            )
          }
        >
          <Image
            src={itemDestaque.urlFoto}
            width="28px"
            className="image-icon-teste color-icon-menu"
            pointer
            style={{
              marginBottom: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          />
          <Label
            pointer
            value={itemDestaque.descricao}
            style={{
              textTransform: "uppercase",
              color: "#878787",
              fontSize: "14px",
              marginTop: 5,
              lineHeight: "14px",
              paddingLeft: 5,
              textAlign: "left",
            }}
          />
        </Div>
      );
    }

    return item;
  };

  render() {
    let itens = this.getItensCarouselDepartamentos();
    let itemDestaque = this.getItemDestaque();

    return (
      <Div className="container-menus" inline="center">
        <CarouselDepartamentosTopo
          slidesPerPage={5}
          arrows
          itens={itens}
          itemDestaque={itemDestaque}
          handlerToggleDepartamentos={this.props.handlerToggleDepartamentos}
        />
      </Div>
    );
  }
}

export default DepartamentosLojaInstantanea;
